.comparison {
    &.slider {
        .outer-wrap {
            height: 40px;
            margin: 0;
            width: 100%;

            @media (max-width: $bp-tablet) {
                height: auto;
            }
        }

        .slider-options {
            display: inline-block;
            float: left;
            min-height: 28px;
            min-width: 50vw;
            position: relative;

            @media (max-width: 1279px) {
                float: none;
                width: 100%;
            }

            &::after {
                @media (min-width: 1279px) {
                    background: $jpp-dark-grey;
                    bottom: -8px;
                    content: '';
                    height: 5px;
                    left: -500px;
                    position: absolute;
                    right: 0;
                }
            }

            .wrapper {
                float: right;

                @media (max-width: 1279px) {
                    display: flex;
                    float: none;
                    justify-content: center;
                    width: 100%;
                }

                @media (max-width: $bp-tablet) {
                    flex-direction: column;
                }

                a {
                    @include font-bold;
                    color: $jpp-dark-grey;
                    font-size: 24px;
                    margin-left: 40px;
                    opacity: .55;
                    text-decoration: none;
                    text-transform: uppercase;

                    @media (max-width: $bp-tablet) {
                        line-height: 54px !important; // sass-lint:disable-line no-important
                        min-height: 54px !important; // sass-lint:disable-line no-important
                        text-align: center !important; // sass-lint:disable-line no-important
                    }

                    &.active {
                        color: $jpp-dark-blue;
                        opacity: 1;
                    }
                }
            }
        }

        .compare-slider {
            height: 690px;
            position: relative;

            @media (max-width: 1279px) {
                margin-bottom: 120px;
            }

            .slick-arrow {
                &.slick-next,
                &.slick-prev {
                    top: 0;
                }
            }

            .slick-slide {
                @include ease;
                display: flex;
                height: 560px;
                justify-content: center;
                margin: 48px;
                opacity: 0;
                width: 70vw;

                @media (max-width: 1279px) {
                    flex-direction: column;
                    height: auto;
                }

                &.slick-current {
                    @include ease;
                    cursor: grab;
                    opacity: 1;
                }
            }

            .slide {
                @media (min-width: 1279px) {
                    display: flex;
                    justify-content: center;
                }
            }

            .image {
                background: $white;
                display: block;
                height: 100%;
                margin: 0 auto;
                max-width: 100%;
                width: 560px;

                @media (min-width: 1279px) {
                    float: left;
                    width: 40%;
                }

                img {
                    margin: auto;
                    max-width: 100%;
                    position: static;
                    transform: translateX(0) translateY(0);
                    width: 480px;
                }
            }

            .description {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                padding: 48px;

                @media (max-width: 1600px) {
                    width: auto;
                }

                @media (max-width: 1279px) {
                    float: none;
                    height: auto;
                    margin: 0 auto;
                    max-width: 100%;
                    padding: 24px;
                    width: 580px;
                }
            }
        }
    }
}
