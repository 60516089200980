.page-product-configurable {

    .product-info-main {
        .page-title-wrapper.product {
            .page-title {
                line-height: 20px;
            }
        }
    }

    .swatch-option-tooltip {
        display: none !important; // sass-lint:disable-line no-important
    }

    .product-add-form {
        .product-options-wrapper {
            .fieldset {
                &::after {
                    position: fixed;
                    z-index: 42;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    content: ' ';
                    background: rgba(0, 0, 0, .5); // sass-lint:disable-line no-color-literals
                    opacity:0;
                    @include ease;
                    pointer-events:none;
                    transition-delay:0.08s;
                }
                &.active {
                    &::after {
                        opacity:1;
                        transition-delay:0s;
                        @media (min-width: $bp-mobile-l) {
                            content: none;
                        }
                    }
                }
            }
        }

        .swatch-opt {
            position: relative;
            margin: 0 0 $spacer;
            padding: 10px 80px 10px 13px;
            border: 1.5px solid $jpp-grey;
            @media (min-width:$bp-laptop) {
                .attr-placeholder {
                    transform:translateY(-18px);
                    width:130px;
                }
            }
            @media (max-width: #{$bp-mobile-l - 1}) {
                &:not(.active):not(.cancel) {
                    .swatch-attribute-options {
                        display:none;
                    }
                }
                .attr-placeholder {
                    transform:translateX(34px);
                    background-size:108px;
                }
            }

            @media (min-width: $bp-mobile-l) {
                margin-top: 16px;
                padding: 0;
                border: 0;
            }

            .close-option {
                display: none;
                float: right;
                cursor: pointer;
                letter-spacing: 1px;
                position:absolute;
                width:100vw;
                height:100vh;
                right:0;
                left:0;
                bottom:100%;
                font-size:0px;
                &:before {
                    pointer-events:none;
                    content:'DONE';
                    color: $white;
                    @include font-bold;
                    font-size: 14px;
                    position:absolute;
                    bottom:6px;
                    right:12px;
                    text-shadow:0px 0px 6px rgba(2,2,2,0.2);
                }
            }

            &.active {
                @media (max-width: #{$bp-mobile-l - 1}) {
                    @keyframes slideUp {
                        0% {
                            opacity:0;
                            transform:translateY(44vh);
                        }
                        50% {
                            opacity:1;
                        }
                        100% {
                            transform:translateY(0);
                        }
                    }
                    position: fixed;
                    z-index: 43;
                    bottom: 0;
                    left: 0;
                    box-sizing: border-box;
                    width: 100%;
                    max-height: 90%;
                    margin-bottom: 0;
                    padding: 10px;
                    background: $white;
                    animation:slideUp 0.44s;
                    animation-fill-mode:forwards;

                    .visual-options {
                        .swatch-wrapper {
                            border:1.5px solid #dfdfdf;
                            padding:8px;
                            padding-bottom:7px;
                            &.active {
                                border:1.5px solid $jpp-blue;
                            }
                            .swatch-option.image {
                                border:0px solid transparent!important;
                            }
                            .swatch-label {
                                margin:4px 0 0;
                                line-height:14px;
                            }
                        }
                    }

                    .swatch-attribute-options {
                        display: block;
                    }

                    .swatch-attribute-selected-option {
                        &::after {
                            content: none;
                        }
                    }

                    .close-option {
                        display: block;
                    }
                }
            }
            &.cancel {
                @media (max-width: #{$bp-mobile-l - 1}) {
                    position:fixed!important;
                    bottom:0;
                    left:0;
                    @keyframes slideDown {
                        0% {
                            transform:translateY(0);
                            opacity:1;
                        }
                        100% {
                            transform:translateY(44vh);
                        }
                    }
                    animation:slideDown 0.32s;
                    animation-fill-mode:forwards;
                }
            }
        }

        .swatch-attribute-label,
        .swatch-attribute-selected-option {
            @include font-normal;
            font-size: 14px;
            font-weight:600!important;
        }

        .swatch-attribute-label {
            &::after {
                content: ':';
            }
        }

        .swatch-attribute-selected-option {
            @include icon-after($icon-chevron-right, $jpp-off-black, 14px);
            padding-left: 5px;
            color: $jpp-dark-blue;

            &::after {
                position: absolute;
                top: 50%;
                right: $spacer;
                transform: translateY(-50%);

                @media (min-width: $bp-mobile-l) {
                    display: none;
                }
            }
        }

        .swatch-attribute-options {
            display: none;
            box-sizing: border-box;
            width: 100%;
            margin: 0;
            background-color: $white;

            @media (min-width: $bp-mobile-l) {
                position: static;
                display: block;
                height: auto;
                margin-top: 0;
                padding: 0;
                opacity: 1;
            }

            .swatch-label-container {
                display: block;
                margin-bottom: 5px;

                @media (min-width: $bp-mobile-l) {
                    display: none;
                }

                .swatch-attribute-selected-option {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.product-options-wrapper {
    &.everything-out-of-stock {
        .swatch-opt {
            .swatch-attribute {
                .swatch-option {
                    &.disabled {
                        &:before {
                            content : "Out of stock";
                        }
                    }
                }
            }
        }
    }
    .swatch-opt {
        .swatch-attribute {
            &.uom {
                .swatch-attribute-options {
                    @include flex-container;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }

                .swatch-wrapper {
                    @include flex-item;
                    flex-basis: auto;
                    flex-grow: 0;
                }
            }
        }

        .swatch-attribute-options {
            margin-top: 4px;
        }

        .attr-placeholder {
            background: url('../images/loader-2.gif') center center no-repeat rgba(255, 255, 255, .9);
        }

        .faux-attribute {
            height: 24px;
            margin: 0;
            @media (min-width: $bp-laptop) {
                height: 62px;
                margin-bottom: 18px;
            }
        }

        &[data-rendered="true"] {
            .attr-placeholder {
                display: none;
            }
        }

        &.active {
            .swatch-attribute {
                margin-bottom: 16px;

                @media (min-width: $bp-mobile-l) {
                    margin-bottom: 12px;
                }

                @media (max-width: #{$bp-mobile-l - 1}) {
                    .swatch-attribute-options {
                        display: flex;
                        overflow-x: auto;
                        white-space: nowrap;
                    }
                }
            }
        }

        .swatch-attribute {
            @media (min-width: $bp-mobile-l) {
                margin-bottom: 18px;

                &.visual-options {
                    margin-bottom: 17px;
                }
            }

            .label {
                display: flex;
                align-items: center;

                > a {
                    width: 20px;
                    margin-left: 4px;
                }
            }

            &.text-options {
                .swatch-label {
                    font-size: 1.3rem;
                    color: $jpp-off-black;
                }

                .selected {
                    .swatch-label {
                        color: $white;
                    }
                }
            }

            .swatch-option {
                @include ease;
                overflow: visible;

                &:focus {
                    box-shadow: 0px 0px 0px transparent;
                }

                .swatch-label {
                    @include ease;
                    display:block;
                    line-height:26px;
                }

                &.disabled {
                    .swatch-label-popup {
                        display: none;
                    }
                    // sass-lint:disable-block no-important
                    cursor: pointer;
                    pointer-events: initial;
                    color: $jpp-swatch-grey !important;
                    border: 1.5px dashed $jpp-swatch-grey !important;

                    &::after {
                        content: none;
                    }

                    &::before {
                        font-size: 10px;
                        position: absolute;
                        z-index: 1;
                        bottom: 124%;
                        left: 50%;
                        width: 150px;
                        height: 26.67px;
                        line-height: 26.67px;
                        padding: 0 12px;
                        content: 'Click for available options';
                        user-select: none;
                        transform: translateX(-50%);
                        text-align: center;
                        white-space: nowrap;
                        text-transform: uppercase;
                        pointer-events: none;
                        opacity: 0;
                        color: #fff;
                        border-radius: 4px;
                        background: #555;
                        @include ease;
                    }

                    &:hover::before {
                        transition-delay: .38s;
                        opacity: 1;
                    }

                    .swatch-label,
                    .swatch-price {
                        color: $jpp-swatch-grey-hover !important;
                    }

                    &.swatch-option { // increase specificity
                        &:hover {
                            border-color: $jpp-swatch-grey-hover !important;
                            background-color: unset !important;
                            opacity: 1 !important;
                        }
                    }

                    &.no-alternatives {
                        cursor: not-allowed;
                        &::before {
                            //display:none!important;
                            content:'No Variations In Stock'!important;
                        }
                    }
                }

                &:not(.disabled) {
                    .swatch-label-popup {
                        position: absolute;
                        bottom: calc(100% + 8px);
                        left: 50%;
                        align-items: center;
                        width: 268px;
                        max-width: calc(100vw - 24px);
                        transition-delay: 0s;
                        transform: translateX(-50%) translateY(3px);
                        @include smooth;
                        text-transform: none !important;
                        pointer-events: none;
                        opacity: 0;
                        @media (max-width: $bp-laptop) {
                            display: none;
                        }

                        span {
                            font-size: 12px;
                            line-height: 14px;
                            position: relative;
                            bottom: 100%;
                            left: 50%;
                            display: inline-block;
                            float: left;
                            padding: 8px 10px;
                            transform: translateX(-50%);
                            color: #fff;
                            border-radius: 4px;
                            background: $jpp-blue;
                            box-shadow: 0px 0px 12px rgba(2, 2, 2, 0.12);
                        }

                        & + .swatch-label-popup {
                            //Hide regular name-label for remaining items label
                            display: none;
                        }
                    }

                    &:hover {
                        border: 1px solid $jpp-blue;

                        .swatch-label-popup {
                            transition-delay: 0.18s;
                            transform: translateX(-50%) translateY(0);
                            opacity: 1;
                        }
                    }
                }

                &.text {
                    @include ease;
                    @include font-normal;
                    font-size: 14px;
                    line-height: 29px;
                    height: 30px;
                    min-height: 30px;
                    padding: 0 15px;
                    color: $jpp-off-black;
                    border: 1.5px solid $jpp-grey;
                    outline: 0;
                    background-color: $white;
                    margin-right:6px;
                    margin-bottom:6px;
                    &:hover {
                        border-color: $jpp-blue;

                        .swatch-label {
                            color: $jpp-blue;
                        }
                    }

                    &.selected {
                        color: $white;
                        border-color: $jpp-blue;
                        background-color: $jpp-blue !important; // sass-lint:disable-line no-important

                        .swatch-label,
                        .swatch-price {
                            color: $white;
                            @include ease;
                        }
                    }
                }
            }

            &:not(.text-options) {
                .swatch-wrapper {
                    display: inline-block;
                    margin-right: 6px;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    .color,
                    .image,
                    .swatch-label,
                    .swatch-price {
                        display: block;
                        float: none;
                    }

                    .swatch-label,
                    .swatch-price {
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 30px;
                        display: block;
                    }

                    .swatch-label {
                        margin: 8px 0;

                        @media (min-width: $bp-mobile-l) {
                            display: none;
                        }
                    }

                    .swatch-option {
                        width:40px;
                        height:40px;
                        margin:0;
                        padding:2px;
                        text-align:left;
                        border:1.5px solid $jpp-grey;
                        outline:none;
                        background-size:cover!important;
                        box-shadow:none!important;

                        @media (min-width: $bp-mobile-l) {
                            width:40px;
                            min-width:unset;
                            max-width:40px;
                            height:40px;
                            min-height:unset;
                            max-height:40px;
                        }
                        @media (min-width: $bp-laptop) {
                            width:40px;
                            min-width:unset;
                            max-width:40px;
                            height:40px;
                            min-height:unset;
                            max-height:40px;
                        }

                        &.image {
                            border:1.5px solid $jpp-grey;

                            &.selected {
                                border:1.5px solid $jpp-blue;
                            }

                            &:not(.disabled):hover {
                                border:1.5px solid $jpp-blue;
                            }

                            &.disabled {
                                border:1.5px dashed $jpp-swatch-grey !important;
                                opacity: 0.3;

                                img {
                                    opacity:0.5;
                                }
                            }
                        }
                    }

                    &.selected {
                        border-color:$jpp-blue;
                    }
                }
            }

            &:not(.adhesive) {
                &:last-child {
                    &.text-options {
                        .swatch-wrapper {
                            display:inline-block;
                            margin-right:6px;
                        }

                        .swatch-option {
                            line-height:29px;
                            height:30px;
                            margin:0;
                            padding:0 15px;
                            text-align:left;
                            border-color:$jpp-grey;
                            background:none;

                            .swatch-label,
                            .swatch-price {
                                font-size:1.3rem;
                                line-height:1.3rem;
                                color:$jpp-off-black;
                            }

                            .swatch-price {
                                display: none;
                            }

                            .swatch-label {
                                font-weight: 500;
                                line-height: 29px;
                                display: block;
                                margin: 0;
                            }

                            span {
                                display: block;
                                clear: both;
                            }

                            &:hover {
                                border-color: $jpp-blue;

                                span {
                                    color: $jpp-blue;
                                }
                            }

                            &.selected {
                                border-color: $jpp-blue;
                                background-color: $jpp-blue;

                                span {
                                    color: $white;
                                }
                            }

                            &.text {
                                min-height: 30px
                            }
                        }
                    }

                    &.visual-options {
                        .swatch-wrapper {
                            padding-bottom: 15px;

                            @media (min-width: $bp-mobile-l) {
                                padding-bottom: 4px;
                            }

                            .swatch-label,
                            .swatch-price {
                                @media (min-width: $bp-mobile-l) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#adhesiveWrapper { // sass-lint:disable-line no-ids
    // sass-lint:disable-block no-important
    .swatch-attribute.adhesive {
        .alert {
            a, p, span {
                font-size: 13px;
            }

            > a:nth-of-type(1) {
                font-size: 24px;
                font-weight: 800;
                float: right;
                margin-left: 16px;
                text-decoration: none !important;
            }

            p {
                margin-bottom: 0;
            }
        }

        > p.label {
            margin-bottom: 0;
        }

        span.swatch-attribute-label {
            font-size: 1.4rem;
            font-weight: 600!important;
        }

        span.swatch-attribute-selected-option {
            font-size: 1.4rem;
            font-weight: 600!important;
            padding-left: 0;
            color: $jpp-dark-blue;
        }
    }

    @media (max-width: $bp-mobile-l - 1) {
        .product-options-wrapper {
            .swatch-opt {
                margin-top: 5px;
                padding: 0;
                border: 0;
            }

            .swatch-attribute-options {
                position: static;
                display: block;
                height: auto;
                margin-top: 0;
                padding: 0;
                opacity: 1;

                .swatch-label-container {
                    display: none;
                }
            }

            .swatch-attribute-selected-option {
                &::after {
                    display: none;
                }
            }
        }
    }
}
