.footer-content-container {
    .footer-link-heading {
        margin-bottom: $spacer;
    }

    .footer-link-items {
        a {
            @include unset-underline;
            display: block;
            font-size: 14px;
            padding: 3px 0;
        }
    }
}
