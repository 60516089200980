//sass-lint:disable no-important
.product-list-colors {
    margin: #{$spacer * 2} 0;

    @media (min-width: $bp-laptop) {
        margin: #{$spacer * 3} 0;
    }

    .product-grid {
        @include clearfix;
        @include grid-collapse;
        display: flex;
        flex-wrap: wrap;

        > .product {
            @include grid-width(6);
            display: block;
            margin-bottom: $spacer * 2;
            margin-left: 0 !important;
            margin-right: 0 !important;

            @media (min-width: $bp-laptop) {
                @include grid-width(4);
            }

            @media (min-width: $bp-desktop-s) {
                @include grid-width(3);
            }

            @media (min-width: 1440px) {
                @include grid-width(2.4);
            }

            .product-item-photo {
                @include unset-underline;
            }

            .product-image-container {
                width: 100% !important;
            }

            .product-image-wrapper {
                height: auto;
                padding: 0 !important;
                position: static;
            }

            .product-image-photo {
                border: 0;
                position: static;
                width: 100%;
            }

            .product-item-name {
                text-align: center;

                * {
                    @include font-bold;
                    font-size: 22px;

                    @media (min-width: $bp-laptop) {
                        font-size: 26px;
                    }
                }

                .divider {
                    margin-left: -4px;
                }
            }
        }
    }
}
