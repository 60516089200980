.section-three-main {
    padding-bottom: $spacer * 1.5;

    @media (min-width: $bp-laptop) {
        padding-bottom: $spacer * 3;
    }

    &.right-align {
        .content-column {
            @media (min-width: $bp-laptop) {
                order: 0;
            }
        }

        .content-wrapper {
            @media (min-width: $bp-laptop) {
                margin-left: auto;
                margin-right: 0;
            }
        }

        .media-column {
            @media (min-width: $bp-laptop) {
                order: -1;
            }
        }
    }

    .content-wrapper {
        display: block;
        margin: 0 auto;
        max-width: 480px;
        width: 100%;

        @media (min-width: $bp-laptop) {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .heading {
        @include font-bold;
        color: $jpp-grey-3;
        margin-top: 0;
        text-transform: none;

        > span {
            font-size: 13px;
            letter-spacing: 2px;
        }
    }

    .action {
        @include unset-underline;
        display: inline-block;
        margin-top: $spacer;
    }

    .hs-cta-wrapper {
        display: block;
        margin-bottom: $spacer * 2;
    }

    .media-column {
        overflow: hidden;

        @media (min-width: $bp-desktop-s) and (max-width: $bp-desktop-xl) {
            max-height: 430px;
        }
    }
}
