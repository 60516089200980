@mixin alternating-table {
    table {
        width: 100%;
    }

    tr {
        & > th,
        > td {
            border: 4px solid $white;
            color: $jpp-off-black;
            font-weight: 500;
            line-height: 16px;
            padding: 12px 16px !important;
        }

        & > th {
            border-left: 0;
            width: 230px;
        }

        & > td {
            border-right: 0;
        }

        &:nth-child(even) {
            background-color: $jpp-off-white;
        }

        &:nth-child(odd) {
            background-color: $jpp-off-off-white;
        }
    }
}

.catalog-product-view {
    .column {
        &.main {
            margin-top: 12px;
            padding-bottom: 0;

            @media (max-width: $bp-laptop - 1) {
                margin-top:0;
            }

            @media (max-width: $bp-laptop - 1) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .product-info-price {
        @media (max-width: $bp-laptop - 1) {
            clear: both;

        }
        @media (max-width: $bp-tablet - 1) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .product-info-main {
        @media (max-width: $bp-laptop - 1) {
            float: unset;
            width: unset;
            max-width:100vw;
        }

        .product-badges-container {
            .flat-rate-shipping-wrapper {
                @media (max-width:$bp-tablet - 1) {
                    margin-top:22px;
                }
                @media (max-width:$bp-mobile-l - 1) {
                    margin:7px 0 14px;
                }
            }
            .flat-rate-shipping-container {
                p {
                    margin-right: 4px;
                }
            }
        }

        @media (max-width: $bp-tablet - 1) {
            display: flex;
            flex-wrap: wrap;

            > div {
                flex-basis: 100%;
                order: 990;
            }

            .page-title-wrapper {
                order: 10;
            }

            .product-info-price {
                order: 20;
            }

            .rewards-pdp {
                order: 30;
            }

            .stock {
                order: 35;
            }

            .product-add-form {
                order: 40;
            }

            .social-share-wrapper {
                flex-basis: auto;
                order: 50;
                margin-top: 0;
            }

            .sample-request-button {
                flex-basis: auto;
                order: 60;
                margin-top: 0;
                margin-left: 15px;
            }

            .product-badges-container {
                order: 70;
                margin: 12px 0 16px;
                margin: 0;
                text-align: center;

                @media (max-width: $bp-tablet - 1) {
                    flex-basis: 50%;
                }

                .flat-rate-shipping-container {
                    text-align: center;

                    @media (max-width: $bp-tablet - 1) {
                        text-align: right;
                    }
                }
            }
        }

        @media (min-width: $bp-laptop) {
            @include clearfix;
            @include grid-width(6);
            float: right;
            padding-right: 0;
        }
        @media (min-width: $bp-laptop) and (max-width: $bp-desktop-s - 1) {
            width:54%;
        }
        @media (min-width: $bp-desktop-s) {
            @include grid-width(7.5);
            float: right;
        }
        @media (min-width: $bp-desktop-xl) {
            width: 56%;
        }
    }

    .product-add-form {
        .product-options-bottom {
            @media (min-width: $bp-tablet) {
                display: inline-block;
            }
        }
    }

    .product {
        &.media {
            @media (max-width: $bp-laptop - 1) {
                float: unset;
                order: -1;
                width: unset;
                width:440px;
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
            }

            @media (min-width: $bp-laptop) {
                @include clearfix;
                @include grid-width(6);
                position: relative;
                z-index: auto;
                padding-left: 0;
            }

            @media (min-width: $bp-desktop-s) {
                @include grid-width(4.5);
                padding-left: 0;
            }

            @media (min-width: $bp-laptop) and (max-width: $bp-desktop-s - 1) {
                width:46%;
            }
            @media (max-width:$bp-laptop - 1) {
                min-height:485px;
            }
            @media (max-width:$bp-mobile-l - 1) {
                min-height:calc(91.46vw + 45px);
            }
            .new-product-flag {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 3rem;
                position: absolute;
                z-index: 10;
                top: 10px;
                left: 0;
                padding-right: 10px;
                padding-left: 10px;
                text-align: center;
                color: #fff;
                background: #f5821f;
            }

            .gallery-placeholder__image {
                display: inline-block;
                width: 100%;
                height: auto;
                vertical-align: top;
                border: 1px solid $jpp-grey;

            }

            @media (min-width: $bp-desktop-xl) {
                width: 44%;
            }

            .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
                margin-top: 15px;
            }

            #gauge-placeholder-image {
                z-index:10;
                width:100%;
                padding-bottom:100%;
                min-height:100%;
                height:0;
                position:absolute;
                display:flex;
                flex-direction:column;
                justify-content:center;
                border: 1px solid transparent;
                background:#fff;
                transition:0s;
                @media (min-width:$bp-laptop) {
                    width:calc(100% - 60px);
                    max-width:calc(100% - 60px);
                    padding-bottom:calc(100% - 62px);
                    left:auto!important;
                    right:0;
                    float:right;
                }
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%)!important;
                    max-width:calc(100% - 32px);
                    width:calc(100% - 32px);
                    opacity:1;
                    align-self:center;
                    transition:0s;
                }

            }
        }
    }

    .block.related {
        margin-top:48px;
    }
}

.deals-block {
    margin-bottom: 0;

    h5 {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        margin-bottom: 0;
        text-transform: uppercase;
        color: $jpp-orange;
    }

    p {
        font-size: 1.4rem;
        margin-top: 0;
        color: $jpp-off-black;
    }
}

.product-info-main {
    #is-hazardous {
        p {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin: 0;

            &:before {
                content: none;
            }
        }
    }

    .page-title-wrapper {
        .page-title {
            font-size: unset;
            line-height: 20px;
            margin: 0 !important;

            @media (max-width: $bp-laptop - 1) {
                margin: 24px 0 0 !important;
            }

            span {
                font-size: 28px;
                font-weight: 800;
                line-height: 28px;
                color: $jpp-off-black;
                @media (max-width: $bp-mobile-l) {
                    font-size: 20px;
                    line-height: 1.2;
                }
            }
        }
    }

    .product-info-sku {
        padding-top: 5px;

        .sku {
            font-size: 12px;
            line-height: 2rem;
            color: $jpp-off-black !important;

            .type {
                font-weight: 500;
                margin-right: 0;
            }

            .value {
                color: $jpp-dark-blue;
            }
        }
    }

    .product-info-stock-sku {
        padding-top: 5px;
        @media (min-width: $bp-tablet) {
            padding-bottom: 24px;
            border-bottom: 1px solid $jpp-grey;
        }
        @media (max-width: $bp-tablet - 1) {
            flex-basis: 100%;
        }

        .sku {
            font-size: 12px;
            line-height: 2rem;
            color: $jpp-off-black !important;

            .type {
                font-weight: 500;
                margin-right: 0;
            }

            .value {
                color: $jpp-dark-blue;
            }
        }

        @mixin rating-arrows {
            font-family: $icomoon;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: normal;
            height: 2rem;
            letter-spacing: 1px;
            line-height: 2rem;
            margin: 0;
            speak: none;
            vertical-align: top;
            z-index: 1;
        }

        .rating-summary,
        .reviews-actions {
            line-height: 2rem;
            display: inline-block;
            vertical-align: top;
        }

        .reviews-actions {
            margin-top: -2px;
        }

        .rating-summary {
            left: 0;
            margin-right: 5px;

            .rating-result {
                width: 70px;
                margin-left: 0;

                &,
                & > span {
                    &::before {
                        @include rating-arrows;
                        content: '\e97e' '\e97e' '\e97e' '\e97e' '\e97e';
                        color: $jpp-orange;
                    }
                }

                &::before {
                    content: '\e97d' '\e97d' '\e97d' '\e97d' '\e97d';
                    color: $jpp-orange;
                }
            }
        }

        .product-reviews-summary {
            display: inline-block;
            margin: 0 0 0 24px;
            @media (max-width: $bp-desktop-s) {
                width: 100%;
                margin-left: 0;
            }

            &.empty {
                a {
                    &::before {
                        @include rating-arrows;
                        margin-right: 5px;
                        content: '\e97d' '\e97d' '\e97d' '\e97d' '\e97d';
                        color: $jpp-orange;
                    }
                }
            }

            a {
                margin: 0;
                text-decoration: none !important;
                color: $jpp-dark-blue;

                &,
                & span {
                    font-size: 12px;
                }

                &:hover {
                    color: $jpp-dark-blue;
                }

                &.view {
                    &:after {
                        content: " | ";
                    }
                }
            }

            @media (max-width: $bp-tablet - 1) {
                display: block;
                margin: 0;
            }
        }
    }

    .price-box {
        margin: 16px 0 5px;

        @media (max-width: $bp-tablet - 1) {
            flex-basis: 50%;
            margin: 14px 0 16px;
        }
        @media (max-width: $bp-mobile-l) {
            margin-top:0;
            margin-bottom:16px;
        }

        .special-price {
            margin: 10px 0 -4px;
        }

        .old-price {
            text-decoration: none;

            span {
                font-size: 14px !important;
                color: #999;
            }

            .price-wrapper {

                .price {
                    font-size: 14px !important;
                    font-weight: normal;
                    text-decoration: line-through;
                    color: #999;
                }
            }
        }

        .savings {
            font-size: 14px !important;
            color: $jpp-dark-blue;

            @media (min-width: $bp-tablet) {
                margin-left: 6px;
            }

            @media (max-width: $bp-mobile-l) {
                display: inline-block;
            }

            em {
                font-style: normal;
            }
        }


        .price-wrapper {
            .price {
                font-size: 28px;
                font-weight: 800;
                line-height: 26px;
                color: $jpp-dark-blue;

                @media (max-width: $bp-laptop - 1) {
                    display: inline-block;
                    vertical-align: top;
                }
                @media (max-width: $bp-mobile-l) {
                    font-size: 24px;
                }
            }
        }
    }

    .stock {
        font-size: 14px;
        line-height: 18px;
        font-weight:600!important;
        &.available,
        &.unavailable {
            font-weight: 500;
            display: block;
            font-weight: 600;
            margin: 16px 0 6px;
            text-transform: unset;
        }

        span {
            font-size: 14px;
            color: $jpp-dark-blue;
            font-weight:600;
        }

        a.notify-of-stock {

            font-size: 14px;
            margin-left: 12px;
            color: $jpp-dark-blue;

            &:hover {
                color: $jpp-orange;
            }

            @media (max-width: $bp-mobile-l) {
                display: block;
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .alert {
        margin-bottom: 20px;
        padding: 15px;
        border: 1.5px solid transparent;

        &.alert-info {
            color: $jpp-info-blue;
            border-color: $jpp-info-blue;
            background-color: $jpp-info-blue-light;
        }

        &.stock {
            margin-bottom: $spacer;
        }
    }

    .discontinued {
        font-size: 38px;
        font-weight: 200;
        margin-bottom: 20px;
        color: $jpp-orange;
    }

    .box-tocart {
        margin-top: 0;
        margin-bottom: 0;

        .fieldset {
            @media (min-width: $bp-laptop) {
                display: flex;
            }
        }

        .qty {
            position: relative;
            height: 40px;
            margin-bottom: 0;
            padding: 0 !important;

            .label {
                display: none;
            }

            @media (max-width: $bp-laptop - 1) {
                display: inline-block !important;
                .control {
                    div.mage-error {
                        position: absolute;
                        top: 45px;
                        width: 300px;
                    }
                }
            }

            @media (min-width: $bp-laptop) {
                .control {
                    div.mage-error {
                        position: absolute;
                        top: 55px;
                        width: 300px;
                    }
                }
            }

            input {
                &.qty {
                    font-size: 14px;
                    font-weight: 800;
                    width: 84px;
                    height: 40px;
                    padding: 13px !important;
                    text-align: left;
                    border: 1px solid $jpp-grey;
                    background: $white;
                }
            }

            .input-group-button {
                button {
                    position: absolute;
                    z-index: 1;
                    right: 0;
                    padding: 0;
                    border: 0;
                    border-radius: 0;
                    background-color: $jpp-grey;
                    box-shadow: none;

                    &.qty-increase {
                        top: 0;
                    }

                    &.qty-decrease {
                        bottom: 0;
                    }

                    span {
                        font-size: 14px;
                        line-height: 14px;
                        color: $jpp-qty-increment-decrement-icon;

                        &.icon-pointer-up {
                            &::before {
                                font-family: 'icons-blank-theme';
                                font-size: 20px;
                                line-height: 20px;
                                content: '\e618';
                            }
                        }

                        &.icon-pointer-down {
                            &::before {
                                font-family: 'icons-blank-theme';
                                font-size: 20px;
                                line-height: 20px;
                                content: '\e607';
                            }
                        }
                    }
                }
            }
        }

        .actions {
            display: table-cell;
            display: -webkit-flex;
            padding: 0;

            @media (max-width: $bp-laptop - 1) {
                display: inline-block;
            }

            .action {
                &.tocart {
                    font-size: 14px;
                    font-weight: 800;
                    line-height: 14px;
                    width: 144px;
                    height: 40px;
                    margin-left: 8px;
                    padding: 13px !important;
                    white-space: nowrap;
                    color: $white;
                    background: $jpp-blue;

                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }

        .product-social-links {
            @media (min-width: $bp-mobile) {
                @include clearfix;
            }
            @media (max-width: $bp-laptop - 1) {
                padding-top: 10px;
            }
        }

        .requisition-list-action {

            .action {
                &.toggle {
                    &::after {
                        vertical-align: unset;
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }

            ul.list-items {
                li:not(:last-of-type) {
                    padding-left: 1rem;
                    border-bottom: 1px solid #e7e7e7;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        .product-addto-links {
            display: flex;
            margin-bottom: 0;

            > div {
                &:hover {
                    cursor: pointer;
                }
            }

            @media (max-width: $bp-laptop - 1) {
                justify-content: flex-start;
                margin-bottom: 0;

                .action.towishlist {
                    max-width:52px;
                }
                > * {
                    flex-basis: calc(100% / 3);
                    max-width: 96px;
                    margin-left: 15px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &.block-requisition-list {
                        margin-top: -2px;

                        .action.toggle {
                            &::after {
                                content: none;
                            }
                        }
                    }
                }
            }

            @media (min-width: $bp-laptop) {
                display: block;
                float: left;
                margin-top: 0;
                margin-left: 16px;
            }

            .block-requisition-list {
                display: inline;
                max-height: 13.33px;
                margin-bottom: 5px;
            }

            .action {
                display: block;
                margin-bottom: 6px;
                padding: 0;
                text-align: left;
                text-decoration: none !important;
                text-transform: uppercase;
                border: 0;
                border-radius: 0;
                background: none;

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }

                &:hover {
                    border: 0;
                    background: none;
                }

                span {
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 13px;
                    color: $jpp-blue;
                }
            }
        }
    }

    .mailto-container {
        display: none;
    }

    .rewards-pdp {
        display: block;
        clear: both;
        margin-bottom: 10px;
        text-transform: uppercase;
        min-height:14px;
        small {
            @media (max-width: $bp-mobile-l) {
                position: relative;
                display: block;
                width: 238px;
                margin-top: 8px;
            }
        }

        .rewards-more-link {
            a {
                font-weight: 600;
            }
        }

        a,
        &,
        & span {
            font-size: 14px;
            line-height: 1em;
            color: $jpp-orange;
        }
    }
}

.warehouse-stock-table {
    @include alternating-table;

    @media (min-width: $bp-mobile-l) {
        tr > th {
            white-space: nowrap;
        }
    }

    .dropship-notify {
        font-weight: 600;
        padding: 10px;
        color: $jpp-info-blue;
        border: 1px solid $jpp-info-blue;
        background-color: $jpp-info-blue-light;
    }
}

.login-extra-info {
    margin-top:20px;
    margin-bottom:24px;

    @media (min-width: $bp-laptop) {
        @include clearfix;
        @include grid-width(6);
        float: right;
        clear: right;
        margin-top: 0;
        padding-right: 0;
    }

    @media (min-width: $bp-desktop-s) {
        @include grid-width(7.5);
        float: right;
    }
    @media (min-width: $bp-desktop-xl) {
        width: 56%;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
    }

    a {
        font-size: 14px;
        line-height: 23px;
        color: $jpp-blue;
    }

    > span {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        display: block;
        margin-top: 24px;
        color: $jpp-off-black;

        @media (max-width: $bp-laptop - 1) {
            margin-bottom: 56px;
        }
    }

    .small {
        margin-top: $spacer;
    }

    .superheading {
        font-size: 8px;
        display: inline-block;
        vertical-align: super;
    }

    .quantity-breaks {
        h5 {
            font-size: 14px;
            margin-bottom: 0;
        }

        table {
            tr {
                td:nth-of-type(1) {
                    width: 80px;
                    padding-left: 0;
                }

                span.price {
                    font-size: 14px;
                }
            }
        }
    }
    #quantity-breaks {
        h5 {
            margin-bottom: 15px;
        }
        .prices-tier {
            background: transparent;
            padding-left: 0;
            margin-top: 0;
            padding-top: 0;
            &,
            & li {
                font-size: 1.4rem;
                line-height: 2.4rem;
            }
            li {
                margin-bottom: 4px;
            }
        }
    }
}


.product {
    &.info {
        &.detailed {
            margin-top: 24px;
            margin-bottom: 0;

            @media (min-width: $bp-laptop) {
                @include clearfix;
                @include grid-width(6);
                float: right;
                clear: right;
                margin-top: 0;
                padding-right: 0;
            }
            @media (min-width: $bp-laptop) and (max-width: $bp-desktop-s - 1) {
                width:54%;
            }
            @media (min-width: $bp-desktop-s) {
                @include grid-width(7.5);
                float: right;
            }
            @media (min-width: $bp-desktop-xl) {
                width: 56%;
            }
        }
    }

    &.data {
        &.items {
            @media (max-width: $bp-laptop - 1) {
                border-bottom: 1px solid $jpp-grey;
            }

            .item {
                &.title {
                    margin-right: 0;
                    @include smooth;
                    transition:all .24s ease, height 0s, width 0s, padding 0s, font-size 0s;
                    @media (max-width: $bp-laptop - 1) {
                        width: 100%;
                        margin-bottom: 0;
                    }

                    &:after {
                        position: absolute;
                        z-index: 1;
                        right: 1px;
                        bottom: -3px;
                        left: 0;
                        height: 4px;
                        content: '';
                        pointer-events: none;
                        opacity: 0;
                        background: $white;
                        @include smooth;
                    }

                    @media (min-width: $bp-tablet) {
                        &:not(.active):hover {
                            filter: brightness(1.032);
                        }
                    }

                    .switch {
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 14px;
                        height: auto;
                        padding: 13px 25px !important;
                        user-select: none;
                        text-decoration: none !important;
                        text-transform: uppercase;
                        color: $jpp-off-black;
                        border: 1px solid $jpp-grey;
                        border-bottom: 0;
                        border-left: 0;
                        background: $jpp-off-off-white;

                        &:hover {
                            color: $jpp-off-black;
                            background: $jpp-off-off-white !important;
                            @media (max-width: $bp-laptop) {
                                background: $white !important;
                            }
                        }

                        &.data {
                            @media (min-width: $bp-tablet) {
                                color: $jpp-off-black;
                            }
                        }

                        @media (min-width: $bp-laptop) and (max-width: $bp-laptop-xl - 1) {
                            padding:11px 14px!important;
                            font-size:12px!important;
                        }
                        @media (min-width: $bp-laptop-xl) and (max-width: $bp-desktop-s - 1) {
                            padding:13px 14px!important;
                            font-size:13px!important;
                        }
                        @media (max-width: $bp-laptop - 1) {
                            font-size: 18px;
                            line-height: 21px;
                            position: relative;
                            padding: 16px 30px 18px 0 !important;
                            color: $jpp-dark-blue;
                            border-top: 1px solid $jpp-grey;
                            border-right: 0;
                            border-bottom: 0;
                            background: none;

                            &::after {
                                font-family: 'icons-blank-theme';
                                font-size: 50px;
                                font-weight: 500;
                                line-height: 20px;
                                position: absolute;
                                right: 16px;
                                width: 20px;
                                content: '\e622';
                            }
                        }


                        .counter {
                            display: none;
                        }
                    }

                    &.dropship {
                        .switch {
                            position: relative;
                            z-index: auto;

                            &:before {
                                font-size: 24px;
                                font-weight: 700;
                                position: absolute;
                                z-index: auto;
                                top: 10px;
                                right: 10px;
                                content: "*";
                                color: $jpp-blue;
                            }
                        }
                    }

                    @media (min-width: $bp-laptop) {
                        &:first-child {
                            a {
                                border-left: 1px solid $jpp-grey;
                            }
                        }
                    }

                    &.active {
                        position: relative;

                        @media (min-width: $bp-laptop) {
                            pointer-events: none;
                        }
                        //Prevent Inactive Tab Render
                        + .data.item.content {
                            opacity: 1;
                        }

                        .switch {
                            color: $jpp-blue;
                            border-bottom: 0;
                            background: $white;

                            @media (max-width: $bp-laptop - 1) {
                                color: $jpp-dark-blue;

                                &:hover,
                                &:active,
                                &:focus,
                                &:visited {
                                    color: $jpp-dark-blue !important;
                                }
                            }

                            @media (max-width: $bp-laptop - 1) {
                                &::after {
                                    content: '\e621';
                                }
                            }

                            &:hover,
                            &:active,
                            &:focus,
                            &:visited {
                                color: $jpp-blue-hover;
                                background: $white !important;
                            }
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &.content {
                    font-weight: 500;
                    margin-top: 41px;
                    padding: 24px 0;
                    opacity: 0;
                    color: $jpp-off-black;
                    border-top: 1px solid $jpp-grey;
                    //Prevent Inactive Tab Render
                    border-bottom: 1px solid $jpp-grey;

                    &#description {
                        div[data-content-type="row"] {
                            max-width:100%!important;
                            > div {
                                padding:0!important;
                            }
                        }
                        h2 {
                            font-size:22px;
                            margin-bottom:8px;
                        }
                        h3 {
                            font-size:20px;
                            margin-bottom:8px;
                        }
                        .hs-form {
                            border:1px solid #dfdfdf;
                            padding:0 24px 24px 24px;
                            margin-top:24px;
                            @media (max-width:480px) {
                                padding:0 16px 16px 16px;
                            }
                            h2 {
                                margin-top:28px;
                                margin-bottom:0;
                                font-size:20px;
                            }
                            .hs-fieldtype-radio.field.hs-form-field {
                                margin-top:0;
                            }
                            div.actions {
                                margin:24px 0;
                            }
                            fieldset {
                                .hs-fieldtype-radio {
                                    margin-top:16px;
                                    ul {
                                        margin-bottom:0;
                                    }
                                    label {
                                        margin-top:0;
                                    }
                                }
                                &.form-columns-1 .input, &.form-columns-2 div:nth-of-type(2) .input {
                                    margin-right:0;
                                }
                            }
                            .hs-error-msgs li label {
                                font-size:12px;
                                margin-left:0;
                            }
                            div.actions {
                                margin-bottom:0;
                            }
                            .hs-fieldtype-select.hs-fieldtype-select .input:before {
                                height:37px;
                                top:1.5px;
                                left:1.5px;
                                bottom:1.5px;
                            }
                        }
                    }
                    @media (max-width: $bp-laptop - 1) {
                        margin: 0;
                        padding: 0 0 24px;
                        border: 0;
                        .attribute-icons {
                            margin-top: 3px;
                        }
                    }
                    @media (min-width: $bp-laptop) and (max-width: $bp-laptop-xl - 1) {
                        margin-top:37px;
                    }
                    .short-description {
                        line-height: 23px;
                        max-width: 630px;
                        font-size:16px;
                        iframe {
                            max-width: 100%;
                        }
                    }

                    .additional-attributes-wrapper {
                        @include alternating-table;
                    }

                    .review-list {
                        .block-title {
                            font-size: 14px;
                            font-weight: 800;
                            line-height: 23px;
                            margin-bottom: 8px;
                        }

                        .review-toolbar {
                            display: flex !important;
                            align-items: center;
                            margin-bottom: 24px;
                            padding: 0 16px;
                            text-align: unset;
                            border-top: 0;
                            background-color: $jpp-off-off-white;

                            * {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 23px;
                                display: inline-block;
                                margin: 0;
                                color: $jpp-off-black;
                            }

                            .pager {
                                margin-left: auto;

                                .limiter {
                                    display: block;

                                    select {
                                        font-family: 'Proxima Nova';
                                        font-size: 14px;
                                        line-height: 14px;
                                        height: 30px;
                                        margin: 0 8px 0 13px;
                                        padding: 6px 20px 8px 10px;
                                        border: 0;
                                        background: $jpp-off-white;
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 29px;
                            }
                        }

                        .review-item {
                            margin-bottom: 40px;
                            padding: 0;
                            border-bottom: 0;

                            .review-title {
                                font-size: 14px;
                                font-weight: 800;
                                line-height: 23px;
                                margin-bottom: 10px;
                                color: $jpp-blue;

                                .review-author {
                                    margin-left: 24px;
                                    color: $jpp-off-black;

                                    span {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .review-ratings {
                                float: unset;

                                & ~ .review-content,
                                & ~ .review-details {
                                    margin-left: 0;
                                }

                                span {
                                    font-size: 14px;
                                    font-weight: 800;
                                    line-height: 23px;
                                    margin: 0;
                                }

                                .rating-result {
                                    margin-left: 0;

                                    &::before {
                                        color: $jpp-off-off-white;
                                    }

                                    span {
                                        &::before {
                                            color: $jpp-orange;
                                        }
                                    }
                                }
                            }

                            .review-content {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 23px;
                                max-width: 630px;
                                margin: 12px 0 16px;
                                color: $jpp-off-black;
                            }

                            .review-date {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 23px;
                                color: $jpp-off-black;
                            }
                        }
                    }

                    > .related {
                        margin-top: $spacer * 2;
                        margin-bottom: 0 !important;

                        .block-title {
                            border: 0;
                            margin-bottom:0!important;
                        }

                        .block-content {
                            padding-left: 0;
                            list-style: none;
                        }

                        .item {
                            margin-bottom: 5px;
                            float: left;
                            padding: 10px;
                            &:nth-of-type(1) {
                                padding-left:0;
                            }
                            img{
                                max-width: 200px;
                            }
                            .related-post-title{
                                max-width: 200px;
                            }
                        }

                        .post-item-link {
                            @include unset-underline;
                        }
                    }
                }
            }
        }
    }
}

.review-add {
    margin-top: 5px;

    fieldset {
        margin-bottom: 0;
    }

    .block-title {
        display: block;
    }

    .notlogged {
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        color: $jpp-off-black;
        background: $white;

        a {
            font-size: 14px;
            text-decoration: none !important;
            color: $jpp-blue;

            &:hover {
                text-decoration: none !important;
                color: $jpp-blue-hover;
            }
        }
    }

    .review-legend {
        margin-bottom: 24px;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 23px;
            color: $jpp-off-black;
        }
    }

    form {
        .actions-primary {
            button.action {
                width: 383px;
                max-width: 100%;
                border: $jpp-orange !important;
            }
        }

        .review-field-ratings {
            span {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .control {
            width: 100% !important;

            .review-field-rating {
                display: block;
                margin-left: -10px;

                .label {
                    display: none;

                    span {
                        font-weight: 800;
                        line-height: 20px;
                    }
                }

                .control {
                    display: flex;
                    margin: 20px 10px 0 0;

                    .review-vote {
                        position: relative;
                        width: 65px;

                        @media (max-width: $bp-laptop - 1) {
                            width: 55px;
                        }

                        input {
                            display:block;
                            margin:0 auto;
                            &[type='radio'] {
                                &::before {
                                    position: absolute;
                                    left: 50%;
                                    cursor: pointer;
                                    transform: translateX(-50%);
                                    background-color: $jpp-grey;
                                }

                                &:checked {
                                    &::before {
                                        background: $jpp-blue;
                                    }
                                }
                            }
                        }

                        & > label {
                            font-size: 14px;
                            font-weight: 800;
                            line-height: 23px;
                            position: absolute;
                            top: -20px;
                            left: 50%;
                            width: 65px;
                            cursor: pointer;
                            transform: translateX(-50%);
                            text-align: center;

                            @media (max-width: $bp-laptop - 1) {
                                width: 55px;
                            }
                        }
                    }
                }
            }
        }

        .review-field-nickname,
        .review-field-summary,
        .review-field-text {
            max-width: 383px;
        }

        .field:not(.choice) > .label {
            width: auto;
        }

        .review-field-text {
            .label {
                top: 5px;
                bottom: unset !important;
            }

            textarea {
                height: 170px;
            }
        }

        .actions-toolbar {
            margin-left: 0 !important;

            & > .primary {
                text-align: left;
            }

            button {
                max-width: 383px;
                background: $jpp-orange !important;
            }
        }
    }
}
.fotorama {
    &::after {
        position:absolute;
        content:'';
        top:-3vh;
        left:-3vh;
        right:0;
        bottom:0;
        background:rgba(2,2,2,0.45);
        pointer-events:none;
        z-index:-1;
        width:100vw;
        height:100vh;
        opacity:0;
        transition:0.18s;
        @media (max-width:$bp-tablet) {
            top:-2vh;
            left:-2vh;
        }
    }
    &:not(.fotorama--fullscreen) {
        img {
            top:50%;
            left:50%;
            max-width:calc(100% - 2px)!important;
            transform:translateX(-50%) translateY(-50%)!important;
        }
        .fotorama__wrap {
            @media (min-width:$bp-mobile-l) and (max-width:$bp-laptop - 1) {
                max-height:485px;
                overflow-y:hidden;
            }
        }
        .fotorama__stage {
            z-index:2;
            .fotorama__arr {
                @media (max-width:$bp-desktop - 1) {
                    width:36px!important;
                    height:36px!important;
                    svg {
                        width:28px!important;
                        height:28px!important;
                    }
                }

            }
        }
    }
    .fotorama__stage.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--top:before,
    .fotorama__stage.fotorama__shadows--right:after, .fotorama__nav.fotorama__shadows--bottom:after {
        opacity:0.18;
    }
    .fotorama__grab {
        cursor:pointer!important;
    }
    .fotorama__arr {
        &:hover {
            svg {
                stroke:$jpp-orange;
            }
        }
        &.fotorama__arr--disabled {
            display:none!important;
            opacity:0!important;
        }
        .fotorama__arr__arr {
            width: 32px;
            height: 32px;
            background-image: none;
            svg {
                width: 32px;
                height: 32px;
                @include center-x-y;
                @include ease;
            }

        }
    }
    .fotorama__stage {
        .fotorama__stage__frame {
            opacity:0!important;
            .fotorama__img {
                transition: all 0.3s, opacity 0s;
            }
            &.fotorama__loaded {

                opacity:1!important;
                .fotorama__img {
                    opacity:1!important;
                }
            }

        }
    }
    .fotorama__nav__frame {
        &.video-thumb-icon {
            &::after {
                top:0;
                left:0;
                right:0;
                bottom:0;
                height:24px;
                width:24px;
                background-size:36px;
                background-position:-48px -24px;
                filter:contrast(1.15);
            }
        }
    }
    &.fotorama--fullscreen {
        top:3vh!important;
        left:3vh!important;
        right:3vh!important;
        bottom:3vh!important;
        width:calc(100% - 6vh)!important;
        box-shadow:0px 0px 24px rgba(2,2,2,0.24);
        overflow:visible;
        @media (max-width:$bp-tablet) {
            top:2vh!important;
            left:2vh!important;
            right:2vh!important;
            bottom:2vh!important;
            width:calc(100% - 4vh)!important;
        }
        &::after {
            opacity:1;
        }
        .fotorama__wrap {
            width:100%!important;
            background:#fff;
            .fotorama__stage {
                width:100%!important;
                .fotorama__stage__shaft {
                    width:100%!important;
                }
            }
        }

        .fotorama__wrap {
            .fotorama__stage {
                min-height:94vh!important;
                max-height:94vh!important;
                height:100%!important;
                @media (max-width:$bp-tablet) {
                    min-height:96vh!important;
                    max-height:96vh!important;
                }
                .fotorama__stage__shaft {
                    height:96vh;
                }
            }
        }
        .fotorama__nav-wrap--horizontal {
            height:96px!important;
            position:fixed;
            bottom:0;
            left:0;
            .fotorama__nav {
                background:transparent;
                .fotorama__thumb__arr {
                    top:6px;
                    bottom:8px;
                    &.fotorama__thumb__arr--left {
                        svg {
                            transform:translateX(12px) scale(0.8);
                        }
                    }
                    &.fotorama__thumb__arr--right {
                        svg {
                            transform:translateX(-12px) scale(0.8);
                        }
                    }
                }
                .fotorama__thumb--icon {
                    background:rgba(255,255,255,0.6);
                    background-position:center;

                }
            }
        }
    }
}
.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    transform:translate3d(0px, 0px, 0)!important;
    opacity:1!important;
}

.gallery-placeholder, .gallery-area {
    .fotorama__nav-wrap {
        opacity:0;
    }
}

@media (min-width: $bp-laptop) {

    .gallery-placeholder, .gallery-area {
        margin-bottom:64px;
    }
    .fotorama {
        margin-bottom:100px;
        &:not(.fotorama--fullscreen) {
            img {
                top: 50%;
                left: 50%;
                max-width: calc(100% - 1px) !important;
                transform: translateX(-50%) translateY(-50%) !important;
            }

            .fotorama__wrap {
                display: flex;
                flex-direction: row-reverse;
            }

            .fotorama__nav {
                align-self:flex-start;
                max-height:100%!important;
                width: 52px !important;
                margin-right: 8px;
            }

            .fotorama__nav-wrap {
                position: relative;
                top: auto;
                left: auto;
                width:60px;

            }

            .fotorama__thumb__arr {
                width: 52px;
                &.fotorama__thumb__arr--left {
                    svg {
                        transform:rotate(-90deg);
                    }

                }
                &.fotorama__thumb__arr--right {
                    svg {
                        transform:rotate(90deg);
                    }
                }
                .fotorama__thumb--icon {
                    width: 52px;
                    background: transparent !important;

                    svg {
                        width: 52px;
                        height: 24px;
                        @include ease;
                    }

                    &:hover {
                        svg {
                            stroke: $jpp-orange;
                        }
                    }
                }
            }

            .fotorama__thumb__arr--left {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.96) 0%, rgba(255, 255, 255, 0.64) 100%);

                .fotorama__thumb--icon {
                    transform: rotate(0deg);
                }
            }

            .fotorama__thumb__arr--right {
                background: linear-gradient(to top, rgba(255, 255, 255, 0.96) 0%, rgba(255, 255, 255, 0.64) 100%);

                .fotorama__thumb--icon {
                    transform: rotate(0deg);
                }
            }

            .fotorama__stage {
                flex: 1;
                align-self:flex-start;
                box-sizing: border-box;
                width: 100%;
                margin: auto;
                border: 1px solid $jpp-grey;

                @media (min-width: $bp-laptop) {
                    left: 0 !important;
                    width:calc(100% - 60px)!important;
                    min-width:calc(100% - 62px)!important;
                    max-width:calc(100% - 60px);
                    margin: 0 0 auto auto !important;
                }

                .fotorama__stage__shaft {
                    width: 100% !important;
                    max-width: 100% !important;
                    margin-right: auto;
                    margin-left: auto;

                    .fotorama__stage__frame {
                        width:100%!important;
                        min-width:100%;
                        .fotorama__img {
                            background-color: $white;
                            max-width: calc(100% - 32px) !important;
                        }
                    }
                }

                .fotorama__arr {
                    position: absolute;
                    top: 50% !important;
                    bottom: auto;
                    width: 44px;
                    height: 44px;
                    transform: translateY(-50%) !important;
                    background: rgba(255, 255, 255, 0.8);
                    box-shadow:0px 0px 2px rgba(2,2,2,0.2);
                    opacity:0;
                    display:block!important;
                    &.fotorama__arr--disabled {
                        display:none!important;
                    }
                    &:hover {
                        svg {
                            stroke:$jpp-orange;
                        }
                    }
                    .fotorama__arr__arr {
                        width: 32px;
                        height: 32px;
                        background-image: none;
                        svg {
                            width: 32px;
                            height: 32px;
                            @include center-x-y;
                            @include ease;
                        }

                    }
                    &.fotorama__arr--prev {
                        left: 8px !important;
                    }
                    &.fotorama__arr--next {
                        right: 8px !important;
                    }
                }

                &:hover {
                    .fotorama__arr {
                        opacity:1!important;
                        &.fotorama__arr--disabled {
                            opacity:0!important;
                        }
                    }
                }

                .fotorama__caption {
                    display: none;
                }
            }
        }

        &.fotorama--fullscreen {
            .fotorama__stage {
                .fotorama__stage__shaft {
                    .fotorama__stage__frame {
                        .fotorama__caption {
                            display: none;
                        }
                    }
                }
                .fotorama__zoom-in, .fotorama__zoom-out {
                    display:none;
                }
                .fotorama__arr__arr {
                    background-image: none;
                    position:relative;
                    svg {
                        margin:auto;
                        display:block;
                    }
                }
            }
            .fotorama__nav--thumbs {
                padding:24px 18px;
                .fotorama__nav__shaft {
                    .fotorama__nav__frame {
                        margin-right:8px;
                        &:last-of-type {
                            margin-bottom:8px;
                        }
                    }
                }
            }
        }
    }

    .fotorama__nav-wrap {

        .fotorama__nav {
            background: $white;

            .fotorama__nav__shaft {
                .fotorama__nav__frame {
                    width: 18px;
                    height: 30px;
                    .fotorama__dot {
                        width: 8px;
                        height: 8px;
                        border-color: $jpp-grey;
                    }
                    &.fotorama__active .fotorama__dot {
                        border-color: $jpp-blue;
                        background: $jpp-blue;
                    }
                    @media (min-width: $bp-laptop) {
                        width: 52px !important;
                        height: 52px !important;
                    }
                    @media (min-width: $bp-laptop) {
                        box-sizing: border-box;
                        margin-bottom: 8px;
                        padding: 0;
                        border: 1.5px solid $jpp-grey;
                        transition:0.18s ease;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                        &:hover {
                            cursor: pointer;
                            border: 1.5px solid $jpp-blue;
                        }
                    }
                    &.fotorama__active {
                        @media (min-width: $bp-laptop) {
                            border-color: $jpp-blue;
                        }
                    }
                }
            }
        }
    }

    .fotorama__thumb-border {
        display: none;
    }

    .fotorama__thumb {
        background: $white;
    }
}

.fotorama__fullscreen-icon:focus:after, .fotorama__zoom-out:focus:after, .fotorama__zoom-in:focus:after, .fotorama__arr:focus:after, .fotorama__stage__shaft:focus:after, .fotorama__nav__frame--thumb:focus .fotorama__thumb:after, .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    box-shadow:none;
}


@media (max-width: $bp-laptop - 1) {

    .fotorama__wrap {
        width:440px;
        max-width:100%!important;
        #gauge-placeholder-image {
            border:1px solid transparent!important;
            min-width:100%!important;
            width:440px!important;
            max-width:100%!important;
            margin-left:auto;
            margin-right:auto;
        }
    }
    .fotorama {
        width:440px!important;
        max-width:100%!important;
        margin-left:auto;
        margin-right:auto;
        .fotorama__stage {
            margin-left:auto;
            margin-right:auto;
        }
    }

    .fotorama__arr, .fotorama__nav--thumbs {
        display:none;
    }

    .fotorama__nav__shaft {
        .fotorama__nav__frame {
            width: 18px;
            height: 30px;
            .fotorama__dot {
                width: 8px;
                height: 8px;
                border-color: $jpp-grey;
            }
            &.fotorama__active .fotorama__dot {
                border-color: $jpp-blue;
                background: $jpp-blue;
            }
        }
    }
    .fotorama__caption {
        display: none;
    }
}
