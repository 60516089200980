.quickorder-index-index {
    .sku-list {
        position: inherit;
        width: 100%;
        z-index: unset;

        .ui-autocomplete {
            z-index: 100;
        }
    }

    .product-info {
        background: $white;
        display: none;
        position: absolute;
    }

    .description-container {
        margin-bottom: 20px;
        max-width: 600px;
    }

    .column .block-addbysku { // sass-lint:disable-line force-element-nesting
        .fieldset .fields .field {  // sass-lint:disable-line force-element-nesting
            display: none;

            @media (min-width: $bp-tablet) {
                display: block;

                &.actions-toolbar {
                    position: absolute;
                }
            }

            &.sku {
                display: block;
                padding-right: 0;

                input {
                    &[data-role='product-sku'] {
                        &.invalid-field {
                            border-color: $jpp-off-off-white;
                            &:focus {
                                border-color: $jpp-grey-2;
                            }
                        }

                        &.ui-autocomplete-loading {
                            background: url('../images/loader-2.gif') center center no-repeat rgba(255, 255, 255, .95);
                        }
                    }
                }
            }
        }

        .block-content .actions-toolbar { // sass-lint:disable-line force-element-nesting
            .primary {
                button {
                    float: right;
                }
            }

            .secondary {
                .remove,
                .add-row,
                .clear {
                    background: none;
                    border: 0;
                    box-shadow: none !important; // sass-lint:disable-line no-important
                    margin: 0;
                    outline: none !important; // sass-lint:disable-line no-important
                    padding: 0;
                    text-decoration: none;

                    &::before {
                        display: none;
                    }

                    span {
                        @include font-bold;
                        @include unset-hidden-props;
                        color: $jpp-blue;
                        display: block;
                        font-size: 14px;
                        position: static;
                        text-transform: uppercase;
                    }
                }

                .add-row {
                    @include font-bold;
                    @include unset-hidden-props;
                    color: $jpp-blue;
                    display: block;
                    float: left;
                    font-size: 14px;
                    margin: 15px 0;
                    position: static;
                    text-transform: uppercase;
                }
            }
        }
    }

    .form-addbysku {
        > .box {
            &.box-items {
                @media (min-width: $bp-tablet) {
                    width: 100%;
                }
            }
        }

        .fieldset {
            > .fields {
                margin: 20px 0 !important; // sass-lint:disable-line no-important
            }

            .deletable-item-title,
            .deletable-item {
                @include clearfix;
                border: 0;
                display: block;
                margin-left: -10px !important; // sass-lint:disable-line no-important
                margin-right: -10px !important; // sass-lint:disable-line no-important

                > .field {
                    width: 100%;

                    @media (min-width: $bp-tablet) {
                        margin: 0;
                    }
                }

                > .sku {
                    @media (min-width: $bp-tablet) {
                        @include grid-width(2);
                        margin-right: 0;
                        padding-right: 10px !important; // sass-lint:disable-line no-important
                    }
                }

                > .name {
                    @include grid-width(6);
                    display: none;

                    @media (min-width: $bp-tablet) {
                        @include grid-width(3);
                    }
                }

                > .uom {
                    @include grid-width(2);
                    display: none;

                    @media (min-width: $bp-tablet) {
                        @include grid-width(2);
                    }
                }

                > .price {
                    @include grid-width(2);
                    display: none;

                    @media (min-width: $bp-tablet) {
                        @include grid-width(1);
                    }
                }

                > .qty {
                    @include grid-width(2);
                    display: none;

                    @media (min-width: $bp-tablet) {
                        @include grid-width(2);
                    }
                }

                > .actions-toolbar {
                    @include grid-width(12);
                    margin-left: 0 !important; // sass-lint:disable-line no-important

                    @media (min-width: $bp-tablet) {
                        @include grid-width(2);
                        margin-left: 0 !important; // sass-lint:disable-line no-important
                    }

                    @media (min-width: $bp-laptop) {
                        margin: 0 !important; // sass-lint:disable-line no-important
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }

                .field {
                    @media (min-width: $bp-tablet) {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }

            .deletable-item-title {
                display: none;

                @media (min-width: $bp-laptop) {
                    background-color: $jpp-off-off-white;
                    border: 1px solid $jpp-grey;
                    display: block;
                    margin-bottom: 0 !important; // sass-lint:disable-line no-important
                    margin-left: 0 !important; // sass-lint:disable-line no-important
                    margin-right: 0 !important; // sass-lint:disable-line no-important
                }

                > .field {
                    @media (min-width: $bp-laptop) {
                        height: 100%;
                        margin-bottom: 0;
                        line-height: 4rem;

                        &:not(:last-child) {
                            border-right: 1px solid $jpp-grey;
                        }
                    }

                    label.label {
                        position: static;
                        padding-left: 0;

                        @media (min-width: $bp-laptop) {
                            margin-bottom: 0;
                            transform: none;
                        }

                        > span {
                            color: $jpp-off-black;
                            @media (min-width: $bp-laptop) {
                                @include font-bold;
                                font-size: 14px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

            }

            .deletable-item {
                border-bottom: 1px solid $jpp-grey !important; // sass-lint:disable-line no-important
                padding-bottom: 10px !important; // sass-lint:disable-line no-important

                @media (min-width: $bp-laptop) {
                    border-left: 1px solid $jpp-grey;
                    border-right: 1px solid $jpp-grey;
                    margin: 0 !important; // sass-lint:disable-line no-important
                    padding-bottom: 0 !important; // sass-lint:disable-line no-important
                }

                .field {
                    @media (min-width: $bp-laptop) {
                        border-right: 1px solid $jpp-grey;
                        height: 100%;
                        margin-bottom: 0;
                        min-height: 80px;
                        padding-top: 15px !important; // sass-lint:disable-line no-important
                    }
                }

                &.ajax-loading {
                    position: relative;
                    z-index: auto;
                    overflow: auto;

                    &:before {
                        background: url('../images/loader-2.gif') center center no-repeat rgba(255, 255, 255, .95);
                        bottom: 0;
                        content: " ";
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: auto;
                    }
                }

                label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                    display: inline-block;
                    margin-bottom: 0;
                    position: static;
                    transform: none;
                    padding-left: 0;

                    @media (max-width: ($bp-laptop - 1)) {
                        font-weight: 700;
                        text-transform: uppercase;
                    }


                    @media (min-width: $bp-tablet) {
                        display: block;
                    }

                    @media (min-width: $bp-laptop) {
                        display: none;
                    }

                    span {
                        color: $jpp-off-black;
                    }
                }

                > .qty {
                    input {
                        height: 50px;
                    }
                }

                > .actions-toolbar {
                    margin-top: 0;
                    padding: 15px;
                    @media (min-width: $bp-tablet) {
                        position: absolute !important; // sass-lint:disable-line no-important
                    }
                }

                .product-block {
                    &:not(:empty) {
                        background-color: $white;
                        border: 0;
                        margin-top: 10px;
                        min-width: calc(100vw - 30px);

                        @media (min-width: $bp-tablet) {
                            min-width: calc(100vw - 40px);
                        }

                        @media (min-width: $bp-laptop) {
                            min-width: 100%;
                        }
                    }
                }

                .name {
                    // sass-lint:disable-block force-element-nesting no-qualifying-elements
                    span.product-name {
                        color: $jpp-dark-blue;
                        cursor: pointer;
                        float: left;
                        font-size: 14px;
                        line-height: 16px;
                        width: 100%;

                        @media (min-width: $bp-laptop) {
                            width: 70%;
                            margin-left: 2%;
                        }
                    }

                    span.product-image-container {
                        float: left;
                        width: 30%;
                    }
                }

                .price {
                    @include font-bold;
                    color: $jpp-dark-blue;
                }
            }
        }
    }
}
