.minicart-freeshipping-container {
    width: 100%;
}

.minicart-freeshipping {
    background-color: $jpp-blue;
    box-sizing: border-box;
    order: 0;
    padding: 10px 10px 5px 50px;
    position: relative;
    width: 100%;

    .icon-free-shipping {
        @include icon($icon-jpp-freeshippingtracker, inherit, 32px);
        color: $white;
        left: 10px;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .progress-bar {
        background-color: $jpp-blue-bg;
        border-radius: 2px;
        box-shadow: 0 2px 3px $box-shadow inset;
        display: block;
        float: left;
        margin-bottom: 0;
        position: relative;
        width: 100%;
    }

    .bar {
        background: linear-gradient(271deg, $bright-green, $bright-yellow);
        display: block;
        height: 14px;
        margin: 2px;
        transition: width .5s ease;
        width: 0;
    }

    .qualification-label {
        clear: both;
        color: $white;
        display: block;
        font-size: 1.34rem;
        margin-top: 10px;
        padding-top: 5px;

        * {
            color: $white;
            font-size: 1.34rem;
        }

        a {
            @include unset-underline;
            color: $white;
        }
    }
}
