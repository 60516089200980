// sass-lint:disable no-important class-name-format
.cms-university {
    .main {

        section {
            &.main {
                padding-left: 20px;
                padding-right: 20px;

                img {
                    width: 100%;
                }
            }
        }

        .ada {
            @include clearfix;
            @include grid-collapse;

            > .top,
            > .text {
                @include grid-width(12);
            }

            > .blue-bg,
            > .image {
                display: none;
            }

            > .text,
            > .image {
                @media (min-width: $bp-tablet) {
                    @include grid-width(6);
                    display: block !important;
                }
            }

            > .top {
                margin-bottom: -80px;
                position: relative;

                img {
                    max-width: 500px;

                    @media (min-width: $bp-laptop) {
                        max-width: 700px;
                    }

                    @media (min-width: $bp-desktop-s) {
                        max-width: 900px;
                    }
                }
            }

            > .text {
                background: $blue-gradient-bg;
                padding-bottom: 40px;
                padding-top: 80px !important;
            }

            > .image {
                @media (min-width: $bp-tablet) {
                    margin-top: 10%;
                }

                @media (min-width: $bp-laptop) {
                    margin-top: 5%;
                }
            }
        }

        .related-products {
            padding: 0 !important;
            width: 100% !important;
        }

        .contact {
            .contact-form {
                margin: 0 auto;
                max-width: 700px;

                fieldset {
                    margin-bottom: 20px;
                    max-width: 100%;
                    width: 100%;
                }

                .form-columns-1 {
                    input {
                        width: 100% !important;
                    }
                }

                .no-list {
                    list-style: none;
                }

                .hs-form-field {
                    ul {
                        list-style: none;
                        padding-left: 0;
                    }

                    span {
                        @include font-normal;
                        text-transform: none;
                    }

                    > label {
                        > span {
                            @include font-bold;
                            text-transform: uppercase;
                        }
                    }
                }

                .hs-department,
                .hs-services_used {
                    .inputs-list {
                        @media (min-width: $bp-tablet) {
                            column-count: 2;
                        }
                    }
                }

                input {
                    &[type='radio'],
                    &[type='checkbox'] {
                        width: auto !important;
                    }
                }

                .hs-submit {
                    .actions {
                        transform: none !important;
                    }
                }
            }
        }
    }
}
