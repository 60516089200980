.three-links-wrapper {
    box-sizing: border-box;
    padding: 80px 0;
    position:relative;
    &::after {
        position:absolute;
        content:'';
        top:0;
        bottom:0;
        width:100vw;
        background:#f5f5f5;
        left:50%;
        transform:translateX(-50%);
        z-index:-1;
    }
    @media (max-width: $bp-laptop - 1) {
        margin: 0 auto;
        width:100%;
        position: relative;
        left: 0;
        padding:40px 0 16px;
    }

    .three-links-container {
        @media (max-width: $bp-laptop - 1) {
            margin: 0 auto;
            max-width: 490px;
            width: 100%;
        }
    }

    .col-item {
        margin-bottom: $spacer;
    }

    .three-links-item {
        height: 100%;
        margin-bottom: $spacer;

        .heading {
            @include font-bold;
            color: $jpp-blue;
            font-size: 25px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .action {
            display: inline-block;
        }
    }

    .card {
        position: relative;
        .image {
            img {
                width:100%;
            }
        }
        .card-content {
            padding-bottom: $spacer * 4;

            a {
                @include unset-underline;
            }
        }

        .action {
            bottom: $spacer;
            left: $spacer;
            position: absolute;
        }
    }
}
