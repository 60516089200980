.testimonials-section {
    padding:64px 0 0!important;
    @media (max-width:768px) {
        padding:48px 0 0!important;
    }
    h2 {
        margin-top:0;
        text-transform:none;
        text-align:center;
    }
}

.testimonials-wrapper {
    display:flex;
    flex-direction:row!important;
    justify-content:center;
    width:100%;
    max-width:92vw;
    margin-left:auto!important;
    margin-right:auto!important;
    position:relative;
    padding:32px 0 100px!important;

    @media (max-width:1200px) {
        max-width:100%;
        padding:32px 0 0!important;
        &:after {
            content:'flickity';
            display:none;
        }
    }
    &.flickity-enabled {
        width:474px;
        max-width:100%;
        margin-left:auto!important;
        margin-right:auto!important;
    }
    .flickity-viewport {
        //width:475.19px;
        width:100%;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
        .flickity-slider {
            //width:475.19px;
            max-width:100%;
        }
        .testimonial-card {
            margin-left:8px;
            margin-right:8px;
            margin-bottom:78px;
            opacity:0;
            pointer-events:none;
            transition:opacity 0.18s ease;
            &.is-selected {
                opacity:1;
                pointer-events:auto;
                transition:opacity 0.32s ease;
            }
            &:last-of-type {
                margin-right:8px;
            }
        }
    }
    .flickity-button {
        position:absolute;
        top:calc(50% - 32px);
        transform:translateY(-50%);
        border-radius:100%;
        background:#0088ce;
        @media (max-width:1660px) {
            transform:translateY(-50%) scale(0.8);
            border:4px solid #fff;
        }
        &:hover {
            background:#0088ce;
        }
        svg {
            width:18px;
            height:18px;
            top:50%;
            left:50%;
            transform:translateX(-50%) translateY(-50%);
            path {
                color:#fff;
            }
        }

        &.previous {
            left:-60px;
            @media (max-width:1200px) {
                left:-32px;
            }
            @media (max-width:480px) {
                left:-4px;
            }
        }
        &.next {
            right:-60px;
            @media (max-width:1200px) {
                right:-32px;
            }
            @media (max-width:480px) {
                right:-4px;
            }
        }
    }
    .testimonial-card {
        width:440px;
        border-radius:12px;
        background:#0088CE;
        position:relative;
        margin-right:32px;
        @media (max-width:1600px) {
            margin-right:16px;
            width:calc(33.333% - 10.666px);
        }
        @media (max-width:1200px) {
            max-width:calc(100% - 40px);
            width:440px;
        }
        &:last-of-type {
            margin-right:0;
        }
        &.orange-quote {
            background:#F57F29;
        }
        &.dark-blue-quote {
            background:#004F9D;
        }
        > div {
            width:100%;
            padding-bottom:100%;
            position:relative;

            > div.content {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                padding:40px;
                padding-bottom:162px;
                z-index:2;
                @media (max-width:1368px) {
                    padding:24px;
                    padding-bottom:128px;
                }
                @media (max-width:480px) {
                    //padding:36px;
                }
                h5, p {
                    color:#fff;
                }
                h5.testimonial-title {
                    font-size:22px;
                    line-height:26px;
                    margin-top:0;
                    @media (max-width:480px) {
                        font-size:18px;
                        line-height:22px;
                    }
                }
                p.testimonial-text {
                    font-size:16px;
                    line-height:1.4;
                    @media (max-width:1280px) {
                        font-size:16px;
                    }
                    @media (max-width:768px) {
                        font-size:14px;
                    }
                }
                p.testimonial-author {
                    font-size:18px;
                    font-weight:700;
                    text-transform:uppercase;
                }
                .quote-icon {
                    position:absolute;
                    bottom:40px;
                    left:40px;
                    @media (max-width:1368px) {
                        bottom:24px;
                        left:24px;
                        width:42px;
                        height:42px;
                    }

                }
                .testimonial-image {
                    border-radius:12px;
                    border:4px solid #fff;
                    box-shadow:0px 3px 6px rgba(2,2,2,0.12);
                    width:220px;
                    height:220px;
                    position:absolute;
                    bottom:-78px;
                    right:40px;
                    overflow:hidden;
                    @media (max-width:1368px) {
                        bottom:-58px;
                        right:24px;
                        height:180px;
                        width:180px;
                    }
                    @media (max-width:480px) {
                        bottom:-72px;
                        right:24px;
                        height:160px;
                        width:160px;
                    }
                    img {
                        transform:scale(1.01);
                    }
                    .testimonial-shop-link {
                        position:absolute;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
    
                        .cta-button {
                            height:25px;
                            font-size:12px;
                            color:#fff;
                            font-weight:600;
                            background:#0088ce;
                            position:absolute;
                            bottom:16px;
                            left:50%;
                            transform:translateX(-50%);
                            padding:3px 18px;
                            transition:0.18s ease;
                            @media (max-width:1280px) {
                                bottom:10px;
                            }
                        }
                        &:hover {
                            .cta-button {
                                filter:brightness(1.06);
                            }
                        }
                    }
                }
            }
            > div.background-gradient {
                position:absolute;
                z-index:1;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                opacity:0.2;
                border-radius:12px;
            }
        }


    }
}