body.product-guide-page {
    #sns_main {
        background:#f9f9f9;
    }
    div.breadcrumbs {
        margin-bottom:0;
    }
    .header-area {
        position:relative;
        width:100%;
        min-height:24.89vw;
        z-index:1;
        background:linear-gradient(to right, #00538F 0%, #007CA1 100%);
        padding:64px 16px 128px;
        overflow:hidden;
        transition:0.6s ease;
        display:flex;
        justify-content:center;
        flex-direction:column;
        @media (max-width:1368px) {
            padding:64px 16px 102px;
        }
        @media (max-width:1024px) {
            padding:64px 16px 92px;
        }
        @media (max-width:768px) {
            padding:64px 16px 74px;
        }
        @media (min-width:2000px) {
            min-height:680px;
        }

        .content {
            position:relative;
            z-index:3;
            transition:0.8s ease;
            transform:translateX(0);
        }
        .next-content, .prev-content {
            position:absolute;
            opacity:0;
        }
        .next-content {
            transform:translateX(200px);
            opacity:0;
        }
        .prev-content {
            transform:translateX(-200px);
            opacity:0;
            position:absolute;
        }
        .content, .next-content, .prev-content {
            &.loading {
                opacity:0;
                animation:fadeIn 0.6s;
                animation-fill-mode:forwards;
                animation-delay:0.4s;
            }
            &.loaded {
                animation:fadeIn 0.4s;
                animation-fill-mode:forwards;
                transform:translateX(0)!important;
                opacity:1;
                position:relative;
            }
        }

        h1 {
            font-weight:800;
            font-size:54px;
            text-align:center;
            line-height:1;
            text-shadow:0px 0px 12px rgba(2,2,2,0.1);
            margin:0;
            @media (max-width:1368px) {
                font-size:48px;
            }
            @media (max-width:1200px) {
                font-size:40px;
            }
            @media (max-width:768px) {
                font-size:32px;
            }
            @media (max-width:500px) {
                font-size:22px;
                transform:translateY(16px);
            }
            span {
                font-family: "proxima-nova";
                font-size:18px!important;
                font-weight:300!important;
                line-height:1;
            }
        }
        p {
            font-size:15px;
            width:568px;
            max-width:100%;
            margin:12px auto 10px;
            text-shadow:0px 0px 6px rgba(2,2,2,0.1);
            text-align:center;
            line-height:1.3;
            a {
                color:inherit!important;
            }
            @media (max-width:1368px) {
                font-size:14px;
                line-height:1.38;
                width:442px;
            }
            @media (max-width:1200px) {
                font-size:14px;
                line-height:1.38;
                width:410px;
            }
            @media (max-width:768px) {
                font-size:14px;
            }
            @media (max-width:500px) {
                transform:translateY(16px);
            }
        }
        h1, p {
            text-shadow:0px 0px 8px rgba(2,2,2,0.12);
        }
        h1, span {
            text-shadow:0px 0px 8px rgba(2,2,2,0.12);
        }

        .background-wrapper {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index:-1;
            overflow:hidden;
        }
        .background {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index:-1;
            background-position:center!important;
            background-size:cover!important;
            background-repeat:no-repeat!important;
            pointer-events:none;
            opacity:1;


        }
        .boxed-wrapper {
            width:1500px;
            margin:0 auto;
            max-width:92%;
            position:absolute;
            top:36px;
            left:50%;
            transform:translateX(-50%);
            z-index:99;
            @media (max-width:991px) {
                top:24px;
            }
            @media (max-width:768px) {
                max-width:calc(100% - 32px);
            }
            @media (max-width:560px) {
                top:16px;
            }
        }
        .back-button {
            font-size:12px;
            color:#0088ce;
            font-weight:800;
            display:flex;
            padding:5px 12px 4px;
            background:rgba(255,255,255,0.95);
            display:inline-block;
            transition:0.24s ease;
            opacity:1;
            box-shadow:0px 0px 12px rgba(2,2,2,0.12);
            text-decoration:none!important;
            text-transform:uppercase!important;

            @media (max-width:500px) {
                transform-origin:top left;
                transform:scale(0.88) translateY(-5px);
            }
            &.hidden {
                opacity:0!important;
                pointer-events:none!important;
            }
            span {
                font-size:inherit;
                color:inherit;
                font-weight:inherit;
                @media (max-width:768px) {
                    display:none;
                }
            }
            &.active {
                animation:fadeIn 0.4s;
                animation-fill-mode:forwards;
            }
            svg {
                transform:translateY(1.5px);
                margin-right:6px;
                height:11px;
                transition:0.24s ease;
                path {
                    stroke:#0088ce;
                }
            }
            &:hover {
                background:rgba(255,255,255,1);
                color:#0088ce;
                svg {
                    transform:translateX(-3px) translateY(1.5px);
                    path {
                        stroke:#0088ce;
                    }
                }
            }
        }
    }
    #sns_main {
        padding-bottom:0;
    }
    main.main-area {
        margin-bottom:0;
        padding-bottom:80px;
        background:#f9f9f9;
    }


    section.guide-page {
        width:1500px;
        max-width:92%;
        margin:0 auto;
        margin-top:-64px;
        padding:32px;
        position:relative;
        z-index:2;
        background:#fff;
        opacity:1;
        animation:fadeInSlide 0.4s;
        animation-delay:1s;
        animation-fill-mode:forwards;
        overflow:hidden;
        transition:0.8s ease;
        min-height:400px;
        @media (max-width:1024px) {
            margin-top:-48px;
            padding:24px;
        }
        @media (max-width:768px) {
            margin-top:-36px;
            padding:16px;
            max-width:calc(100% - 32px);
        }
        @media (max-width:500px) {
            margin-top:-16px;
        }

        /** Inner Wrapper for Ajax Translations **/
        > div {
            display:flex;
            width:100%;
            flex-wrap:wrap;
            position:relative;
            top:0;
            transition:0.8s ease;
            &.inner-wrapper {
                .inner-wrapper {
                    width:100%;
                    min-width:100%;
                }
                &.leaving {
                    opacity:0;
                    transform:translateX(-200px);
                }
            }
            &.next-page, &.prev-page {
                opacity:0;
                position:absolute;
                transition:0.8s ease;
            }
            &.next-page {
                transform:translateX(100%);
                &.loaded {
                    transform:translateX(0);
                    opacity:1;
                }
            }
            &.prev-page {
                right:calc(100% + 12px);
                &.loaded {
                    right:0;
                    opacity:1;
                }
            }
        }

        div.sidebar {
            width:332px;
            min-width:332px;
            margin-right:32px;
            background:#f9f9f9;
            display:flex;
            flex-direction:column;
            padding:32px;
            opacity:0;
            animation:fadeIn 0.4s;
            animation-delay:1.2s;
            animation-fill-mode:forwards;
            position:relative;

            @media (max-width:1400px) {
                width:248px;
                min-width:248px;
                margin-right:16px;
            }

            @media (max-width:1024px) {
                padding:12px 0;
                min-width:100%;
                margin-right:0;
                margin-top:-12px;
                margin-bottom:-8px;
                overflow-x:scroll;
                flex-direction:row;
                background:#fff;
                &::-webkit-scrollbar {
                    width: 0px;
                }
                &::-webkit-scrollbar-track {
                    background:#f9f9f9;
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background:#f9f9f9;
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background:#f9f9f9;
                    background: transparent;
                }
                &::before {
                    position:fixed;
                    content:'';
                    width:inherit;
                    height:inherit;
                    height:74px;
                    width:16px;
                    right:24px;
                    pointer-events:none;
                    background:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    @media (max-width:768px) {
                        right:16px;
                    }
                }
                .sidebar-image {
                    min-width:24px;
                    height:24px;
                    order:3;
                }
            }
            * {
                align-self:flex-start;
                pointer-events:auto;
            }
            label {
                font-weight:800;
                font-size:18px;
                margin-bottom:12px;
                @media (max-width:1400px) {
                    font-size:16px;
                }
                @media (max-width:1024px) {
                    display:none;
                }
                &:not(:nth-of-type(1)) {
                    margin-top:12px;
                }
            }
            a {
                color:#000;
                text-decoration:none!important;
                font-size:18px;
                margin-bottom:12px;
                transition:0.24s ease;
                @media (max-width:1400px) {
                    font-size:16px;
                }
                @media (max-width:1024px) {
                    font-size:14px;
                    margin-right:10px;
                    margin-bottom:0!important;
                    background:#f9f9f9;
                    display:inline-block;
                    font-weight:700;
                    padding:48px 10px 6px;
                    min-width:134px;
                    order:1;
                    &:last-of-type {
                        margin-right:0;
                    }
                    &.hide-mobile {
                        display:none;
                    }
                    &.last-mobile {
                        order:2;
                    }
                    &:hover, &:focus {
                        background:#fff;
                        box-shadow:0px 0px 12px rgba(2,2,2,0.12);
                    }
                }
                @media (max-width:400px) {
                    min-width:120px;
                }
                &:hover, &:focus {
                    color:$jpp-blue;
                }
            }
        }


        div.guides.grid {
            flex:1;
            > div {
                width:100%;
                display:flex;
                flex-wrap:wrap;
            }
            .grid-item {
                margin-right:32px;
                margin-bottom:32px;
                padding:32px;
                background:#f9f9f9;
                position:relative;
                min-height:320px;
                opacity:0;
                animation:fadeIn 0.4s;
                animation-delay:1.2s;
                animation-fill-mode:forwards;
                @media (max-width:1400px) {
                    margin-right:16px;
                    margin-bottom:16px;
                    min-height:256px;
                }
                @media (max-width:768px) {
                    &:nth-last-of-type(3) {
                        margin-bottom:16px!important;
                    }
                }
                @media (max-width:480px) {
                    min-height:40vw;
                }
                &.half {
                    width:calc(50% - 16px);
                    @media (max-width:1400px) {
                        width:calc(50% - 8px);
                    }
                    &:nth-of-type(2n) {
                        margin-right:0;
                    }
                }
                &.third {
                    width:calc(33.333% - 21.333px);
                    &:nth-of-type(3n) {
                        margin-right:0;
                    }
                    @media (max-width:1400px) {
                        width:calc(33.333% - 10.666px);
                    }
                    @media (max-width:768px) {
                        width:calc(50% - 8px);
                        &:nth-of-type(3n) {
                            margin-right:16px;
                        }
                        &:nth-of-type(2n) {
                            margin-right:0;
                        }
                    }
                }
                h5 {
                    margin:0;
                    text-align:center;
                    font-size:22px;
                    font-weight:800;
                    position:absolute;
                    bottom:20px;
                    left:32px;
                    right:32px;
                    backface-visibility:hidden;
                    line-height:1;
                    transition:0.24s ease;
                    @media (max-width:1400px) {
                        font-size:20px;
                        bottom:14px;
                        left:16px;
                        right:16px;
                    }
                    @media (max-width:768px) {
                        font-size:18px;
                    }
                    @media (max-width:480px) {
                        font-size:16px;
                        bottom:8px;
                        left:10px;
                        right:10px;
                    }
                    @media (max-width:400px) {
                        font-size:14px;
                    }
                }
                a {
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    z-index:2;
                    display:block;
                    padding:32px;
                    box-shadow:0px 0px 12px rgba(2,2,2,0);
                    background-color: rgba(255,255,255,0);
                    background:rgba(255,255,255,0);
                    transition:0.24s ease;
                    img {
                        display:block;
                        margin:0 auto;
                        width:260px;
                        height:auto;
                        max-width:calc(100% - 32px);
                        max-height:100%;
                        transform:translateY(-12px);
                        filter:drop-shadow(2px 4px 6px rgba(2,2,2,0.2));
                        position:absolute;
                        top:calc(50% - 16px);
                        left:50%;
                        transform:translateX(-50%) translateY(-50%) scale(0.97)!important;
                        transition:1s;
                        transition-timing-function:cubic-bezier(.7,.3,.31,.93);
                        @media (max-width:1400px) {
                            width:180px;
                        }
                        @media (max-width:560px) {
                            max-width:calc(100% - 24px);
                        }
                        @media (max-width:480px) {
                            width:calc(100% - 46px);
                            top:calc(50% - 12px);
                        }
                    }
                    &:hover {
                        box-shadow:0px 0px 24px rgba(2,2,2,0.09);
                        background-color:rgba(255,255,255,1);
                        background:rgba(255,255,255,1);
                        h5 {
                            color:$jpp-blue;
                        }
                        img {
                            transform:translateX(-50%) translateY(-50%) scale(1)!important;
                        }
                    }
                }
                &.mb-0 {
                    margin-bottom:0;
                }
            }
        }

        .toggle-area {
            display:flex;
            justify-content:center;
            flex-wrap:wrap;
            width:100%;
            padding-bottom:32px;
            @media (max-width:1024px) {
                padding-bottom:24px;
            }
            @media (max-width:768px) {
                padding-bottom:16px;
            }

            label {
                font-size:14px;
                display:inline-block;
                margin-right:12px;
                font-weight:400;
                text-transform:uppercase;
                line-height:36px;
                @media (max-width:768px) {
                    font-size:12px;
                    line-height:28px;
                }
                @media (max-width:560px) {
                    margin:0;
                    width:100%;
                    text-align:center;
                    transform:translateY(-4px);
                }
            }
            a.filter {
                border-radius:48px;
                padding:8px 24px;
                margin-right:10px;
                background:#f8f8f8;
                color:#000;
                font-size:14px;
                text-align:center;
                transition:0.24s ease;
                text-decoration:none!important;
                font-weight:700;

                @media (max-width:768px) {
                    font-size:12px;
                    padding:4px 14px;
                }
                @media (max-width:560px) {
                    transform:translateY(-4px);
                }
                @media (max-width:440px) {
                    width:calc(50% - 4px);
                    margin-right:8px;
                    &:nth-of-type(2n) {
                        margin-right:0;
                    }
                    &:nth-of-type(1), &:nth-of-type(2) {
                        margin-bottom:8px;
                    }
                }
                &:last-of-type {
                    margin-right:0;
                }
                &:not(.active):hover {
                    color:#0088ce;
                }
                &.active {
                    background:#0088ce;
                    color:#fff;
                }
            }
        }
        .products-grid {
            display:flex;
            flex-wrap:wrap;
            width:100%;
            position:relative;
            span.hidden.item {
                height:0px;
                width:0px;

                div {
                    height:0px;
                    width:0px;
                }
            }
            > .product, > .tile {
                width:calc(20% - 12px);
                margin:0 6px 12px 6px;
                position:relative;
                min-height:262px;
                background:#fff;
                filter:brightness(0.99);
                transition:0.24s ease;
                @media (max-width:1280px) {
                    width:calc(25% - 12px);
                }
                @media (max-width:1024px) {
                    width:calc(33.333% - 12px);
                }
                @media (max-width:768px) {
                    width:calc(50% - 12px);
                    margin-bottom:20px;
                }
                @media (max-width:480px) {
                    min-height:40vw;
                }
                &.hidden-item {
                    width:0px;
                    height:0px;
                    position:relative;
                }
                &:hover {
                    filter:brightness(1);
                }
                a {
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    overflow:hidden;
                    padding:16px;
                    @media (max-width:560px) {
                        padding:12px;
                    }
                }
                img {
                    display:block;
                    margin:0 auto;
                    margin-top:12px;
                    max-width:calc(100% - 24px);
                    width:150px;
                    @media (max-width:480px) {
                        margin-top:-12px;
                        max-width:calc(100% - 24px);
                    }
                }
                h3, h5 {
                    color:#000;
                    text-align:center;
                    font-size:16px;
                    font-weight:700;
                    margin-bottom:0;
                    position:absolute;
                    bottom:16px;
                    left:16px;
                    right:16px;
                    text-transform:none!important;
                    transition:0.24s ease;
                    @media (max-width:560px) {
                        font-size:14px;
                        left:12px;
                        right:12px;
                        bottom:12px;
                    }
                    @media (max-width:480px) {
                        bottom:8px;
                        left:10px;
                        right:10px;
                        font-size:12px;
                    }
                }
                &:hover {
                    box-shadow:0px 0px 12px rgba(2,2,2,0.068);
                    h3, h5 {
                        color:$jpp-blue;
                    }
                }

            }
        }

        .download {
            display:flex;
            width:100%;
            &:not(:last-of-type) {
                margin-bottom:32px;
            }
            @media (max-width:1024px) {
                flex-direction:column;
            }
            > div {
                width:50%;
                display:flex;
                flex-direction:column;
                justify-content:center;
                @media (max-width:1024px) {
                    width:100%;
                }
                .download-content {
                    width:400px;
                    margin:0 auto;
                    a {
                        align-self:flex-start;
                    }
                }
            }
        }
    }

    section.gift-guide {
        width:1500px;
        max-width:calc(100% - 32px);
        margin:0 auto;
        margin-top:-84px;
        box-shadow:0px 3px 12px rgba(2,2,2,0.08);
        padding:32px;
        display:flex;
        justify-content:column;
        position:relative;
        z-index:2;
        background:#fff;
        /** Inner Wrapper for Ajax Translations **/
        > div {
            display:flex;
            width:100%;
            &.next-page, &.prev-page {
                position:absolute;
                top:0;
                left:calc(100% + 12px);
            }
        }
    }


    .fade-item {
        animation:fadeIn 0.8s;
        animation-fill-mode:forwards;
    }
    .sidebar .fade-item {
        animation:fadeIn 0.8s;
        animation-fill-mode:forwards;
    }

    @for $i from 1 to 21 {
        .grid .fade-item:nth-child(#{$i}) { animation-delay: $i * 0.32s; }
        .sidebar .fade-item:nth-child(#{$i}) { animation-delay: $i * 0.08s; }
    }

    section.feature-row {
        display:flex;
        width:1500px;
        margin:0 auto;
        max-width:92%;
        background:#fff;
        &:nth-of-type(2) {
            margin-bottom:100px;
            @media (max-width:768px) {
                margin-bottom:72px;
            }
        }
        @media (max-width:1024px) {
            flex-direction:column;

            &:not(.column-reverse) {
                > div:nth-of-type(2) {
                    padding:32px;
                    min-height:40vw;
                    @media (max-width:$bp-mobile-l) {
                        padding:16px;
                    }
                }
            }
            &.column-reverse {
                flex-direction:column-reverse;
                > div:nth-of-type(1) {
                    padding:32px;
                    min-height:40vw;
                    @media (max-width:$bp-mobile-l) {
                        padding:16px;
                    }
                }
            }
        }
        > .column {
            width:50%;
            display:flex;
            justify-content:center;

            .content {
                width:400px;
                max-width:100%;
                display:flex;
                justify-content:center;
                flex-direction:column;
                @media (max-width:1024px) {
                    width:680px;
                    max-width:100%;
                }
                h3 {
                    margin-bottom:6px;
                    margin-top:0;
                    width:340px;
                    max-width:100%;
                    @media (max-width:1024px) {
                        width:680px;
                        max-width:100%;
                    }
                }
                a {
                    align-self:flex-start;
                }
            }
            .image {
                width:100%;
                img {
                    width:100%;
                    display:block;
                    margin:0 auto;
                }
            }

            @media (max-width:1024px) {
                width:100%;
            }
        }
        @media (max-width:768px) {
            max-width:calc(100% - 32px);
        }
    }

}

main.product-guide-index {
    .header-area {
        background:linear-gradient(to right, #00538F 0%, #007CA1 100%);
        min-height:442px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        position:relative;
        .content {
            width:586px;
            max-width:100%;
            margin:0 auto;
            margin-top:-18px;
            h1, p {
                color:#fff;
                text-shadow:0px 0px 6px rgba(2,2,2,0.1);
                text-align:center;
            }
            h1 {
                font-size:64px;
                margin-top:0;
                @media (max-width:1368px) {
                    font-size:48px;
                }
                @media (max-width:991px) {
                    font-size:32px;
                }
                @media (max-width:991px) {
                    font-size:26px;
                }
            }
        }
    }

    section.guide-tiles {
        width:1226px;
        max-width:calc(100% - 32px);
        margin:0 auto;
        margin-top:-40px;
        padding:40px;
        padding-bottom:24px;
        background:#fff;
        display:flex;
        flex-wrap:wrap;
        position:relative;
        @media (max-width:1368px) {
            padding:24px;
            padding-bottom:8px;
        }
        @media (max-width:991px) {
            padding:16px;
            padding-bottom:0;
        }

        .guide {
            background:#f5f5f5;
            margin-right:16px;
            width:calc(33.333% - 10.666px);
            position:relative;
            margin-bottom:16px;
            transition:0.24s ease;
            &:nth-of-type(3n) {
                margin-right:0;
            }
            @media (max-width:991px) {
                width:calc(50% - 8px);
                &:nth-of-type(3n) {
                    margin-right:16px;
                }
                &:nth-of-type(2n) {
                    margin-right:0;
                }
            }
            > div {
                width:100%;
                padding-bottom:115.26%;
            }
            &:hover {
                background:#fff;
                box-shadow:0px 0px 24px rgba(2,2,2,0.1);
                h2 {
                    color:#0088ce;
                }
            }
            a {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
            }
            h2 {
                font-size:26px;
                font-weight:800;
                text-transform:none!important;
                position:absolute;
                bottom:24px;
                left:12px;
                right:12px;
                margin:0;
                text-align:center;
                transition:0.18s ease;
            }
        }
    }
}


.category-product-guides {

    .header-area {
        z-index:1;
        @media (max-width:991px) {
            min-height:220px;
            .content h1 {
                font-size:32px;
            }
        }
        @media (max-width:480px) {
            min-height:220px;
            .content h1 {
                font-size:28px;
            }
        }
    }

    .guide-tiles {
        z-index:2;
        margin-bottom:100px!important;
        @media (max-width:768px) {
            margin-bottom:80px!important;
        }
        @media (max-width:480px) {
            margin-bottom:64px!important;
        }

        .guide {
            h2 {
                @media (max-width:768px) {
                    font-size:18px;
                    bottom:12px;
                }
            }
        }
    }

}

.product-guide-page {
    .page-bottom {
        div[data-content-type="row"] {
            &:nth-of-type(1) {
                .pagebuilder-column:nth-of-type(3) {
                    padding:16px 24px 24px;
                }
            }
            &:nth-of-type(2) {
                .pagebuilder-column:nth-of-type(2) {
                    padding:16px 24px 24px;
                }
            }
        }
    }
}
