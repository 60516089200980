.jpp-page-nav {
    background:#f7f7f7!important;
    height:60px;
    max-height:60px!important;
    margin-bottom:0!important;
    position:relative;
    z-index:2;

    @media (max-width:768px) {
        height:48px;
        max-height:48px!important;
    }
    @media (max-width:1500px) {
        &:before {
            position:absolute;
            right:19px;
            content:'';
            top:0;
            bottom:0;
            background:linear-gradient(to right, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 100%);
            z-index:5;
            width:32px;
        }
    }
    @media (max-width:768px) {
        &:before {
            right:0;
        }
    }
    &:after {
        position:absolute;
        content:'';
        top:100%;
        left:0;
        right:0;
        width:100%;
        height:64px;
        background:linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        opacity:0.8;
        pointer-events:none;
    }
    .nav-wrap {
        display:flex;
        width:1500px;
        max-width:100%;
        margin:0 auto;
        position:relative;
        z-index:1;
        transform:translateX(-20px);
        @media (max-width:1500px) {
            max-width:100%;
            padding:0 4%;
        }
        @media (max-width:1200px) {
            padding:0 15px;
            transform:translateX(-15px);
            overflow-x:scroll;
            overflow-y:hidden;
        }
        @media (max-width:768px) {
            max-width:calc(100% - 32px);
            max-width:100%;
            transform:translateX(0);
        }
        a {
            font-size:14px;
            text-transform:uppercase;
            color:#000;
            transition: 0.18s ease;
            height:60px;
            line-height:60px;
            padding:0 20px;
            font-weight:800;
            position:relative;
            z-index:3;
            background:transparent;
            box-shadow:0px 0px 16px rgba(0,0,0,0.0);
            white-space:nowrap;
            letter-spacing:0px!important;
            display:block;
            
            @media (max-width:1200px) {
                padding:0 15px;
            }
            @media (max-width:768px) {
                height:48px;
                line-height:48px;
                //margin-right:20px;
            }
            &:hover {
                color:#0088ce;
                background:#fff;
                box-shadow:0px 0px 16px rgba(0,0,0,0.08);
            }
            &:last-of-type {
                margin-right:0;
            }

        }
    }
}