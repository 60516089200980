.cms-rowmark {
    .main {
        .rowmark-header {
            .more-info {
                position: absolute;

                * {
                    @include font-bold;
                    color: $white;
                }
            }
        }

        .category-link {
            h3 {
                font-weight:700;
            }
        }
    }
}

.cms-lightblockers
.product {
    h3 {
        font-weight:700;
    }
}