.jpp-brands-slider {
    padding: #{$spacer * 2} 0;
    position:relative;
    z-index:5;
    opacity:0;
    pointer-events:none;
    display:flex;
    margin:auto;
    @include smooth;
    overflow:hidden;
    @media (max-width:480px) {
        padding:24px 0;
    }
    &.flickity-enabled {
        opacity:1;
        pointer-events:auto;
        display:block;
        + .loader-placeholder {
            display:none;
        }
    }
    @media (min-width: $bp-laptop) {
        padding: #{$spacer * 3} 0;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        display: block;
        top:0;
        bottom:0;
        width: 30px;
        z-index: 1;
    }

    &::before {
        background: linear-gradient(to right, $white 0%, rgba(255, 255, 255, 0) 100%);
        left: 0;
    }

    &::after {
        background: linear-gradient(to left, $white 0%, rgba(255, 255, 255, 0) 100%);
        right: 0;
    }

    //img {
    //    &.lazyload,
    //    &.lazyloading {
    //        visibility: hidden;
    //    }
//
    //    &.lazyloaded {
    //        visibility: visible;
    //    }
    //}

    .slick-track {
        align-items: center;
        display: flex;
    }

    .brand {
        box-sizing: border-box;
        padding: 0 $spacer;
        text-align: center;
        width:33.333%;
        height:122px;
        display:flex;
        flex-direction:column;
        justify-content:center;

        @media (max-width:480px) {
            width:42%;
        }

        @media (min-width: $bp-tablet) {
            padding: 0 #{$spacer * 1.5};
            width:25%;
        }

        @media (min-width: $bp-laptop) {
            padding: 0 #{$spacer * 2};
            width:20%;
        }

        a {
            img {
                height: auto;
                margin: 0 auto;
                width: 160px;
                display:block;
                transition:0.24s ease;
                filter:saturate(0);
                &::selection {
                    background:rgba(255,255,255,0);
                }
            }
            &:hover {
                img {
                    filter:saturate(1);
                    opacity:1!important;
                }
            }
        }
    }

    .prev-arrow,
    .next-arrow {
        display: none;
    }
}
