.page-header {
    .header-links-wrapper {
        float: right;
        position: relative;
        z-index: 250;
        order:3;
        margin-right:0;
        margin-left:auto;
        //Firefox Only
        @supports (-moz-appearance:none) {
            @media (min-width:$bp-desktop-s) {
                transform:translateY(-4px);
            }
        }
        a {
            color:#222;
            text-decoration: none !important;
        }

        @media (min-width: $bp-laptop) {
            padding-right:0;
        }
        @media (min-width: $bp-desktop-s) {
            margin-top:-10px;
        }
        @media (max-width:991px) {
            transform:translateY(-3px);
        }

        .header-links-container {
            @media (max-width:$bp-mobile-l) {
                display:flex;
                .icon-container {
                    align-self:flex-start;
                }
            }
            @media (min-width: $bp-laptop) {
                float: right;
            }
            @media (max-width:991px) {
                transform:translateX(4px) translateY(4px);
            }
        }

        .icon-container {
            @include unset-underline;
            display:inline-block;
            padding:0 10px;
            text-align:center;

            @media (min-width: $bp-desktop) {
                margin-left:8px;
            }

            &:hover {
                .icon {
                    &::before {
                        @include color-transition;
                        color: $jpp-orange;
                    }
                }
                svg {
                    path {
                        fill:$jpp-orange;
                        transition:0.18s ease;
                        &.no-fill {
                            fill:rgba(255,255,255,0)!important;
                        }
                        &.white-stroke {
                            stroke:#fff!important;
                        }
                        &.hover-stroke {
                            stroke:$jpp-orange;
                        }
                    }
                }
            }
        }

        .icon {
            display: block;
            line-height: normal;
            margin: 0;
        }

        svg {
            height:36px;
            width:auto;
            max-width:40px;
            display:inline-block;
            @media (max-width:1200px) {
                height:32px;
                max-width:36px;
            }
            path {
                fill:$jpp-blue;
                transition:0.18s ease;
                &.white-stroke {
                    stroke:#fff!important;
                }
            }
        }
        .text {
            display: none;

            @media (min-width: 991px) {
                display: block;
                font-size: 12px;
            }
        }

        .account-menu {
            position: relative;

            .account-menu-icon-container {
                a {
                    text-decoration: none !important;
                }
            }

            .ui-widget {
                @include smooth;
                opacity: 0;
                transform: translateY(12px);
            }

            &.active {
                .ui-widget {
                    opacity: 1;
                    transform: translateY(0);
                }

                .icon {
                    &::before {
                        @include color-transition;
                        color: $jpp-orange;
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
ul {list-style: none;}
        ul.account-submenu{background-color:#fff;border:0;border-top:2px solid #0088ce;box-shadow:0 2px 24px rgba(0, 0, 0, .16);min-width:208px;padding:10px 10px 10px 30px;position:absolute;right:0;text-align:left;top:100%;transform:translateX(88px) translateY(10px);z-index:201;pointer-events:none;opacity:0;transition:all .2s ease,height 0s;cursor:pointer}
        @media(max-width:1366px){
            ul.account-submenu{right:18px}
        }
        ul.account-submenu.active{opacity:1;transform:translateX(88px) translateY(0px);transition-delay:0.12s;pointer-events:auto}
        ul.account-submenu li:hover{background:none}
        ul.account-submenu li {border:0;margin-bottom:0;padding:0;position:relative}
        ul.account-submenu li img{display:inline;left: -15px;top:8px;opacity:.3;position:absolute;width:8px;height:8px;vertical-align:sub}
        ul.account-submenu li img:hover{opacity:1}
        ul.account-submenu li .account-menu-svg svg path{fill:#000000!important}
        ul.account-submenu li a{color:#0088ce;display:block;font-size:14px;font-weight:500;line-height:20px;margin-right:0;padding:3px 0;transition:all .24s ease,height 0s;text-decoration: none !important;}
        ul.account-submenu li a:hover {color:#f58220}
        ul.account-submenu li:hover img{opacity:1}
    }
}
