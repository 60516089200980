// sass-lint:disable class-name-format
body.page-layout-resources_layout {
    background-color: $jpp-off-white;
    @media (min-width:$bp-laptop) {
        h3 {
            font-size:24px;
        }
    }
    div.breadcrumbs {
        margin-bottom:0;
    }
    .page-title-wrapper {
        @include full-width;
        background-color: $jpp-blue;
        box-sizing: border-box;
        margin-bottom: $spacer * 2;
        margin-top: -$spacer;
        padding-left: $spacer;
        padding-right: $spacer;

        .page-title {
            margin: 0;
            padding: #{$spacer * 2.5} 0;
            text-transform: unset;

            @media (min-width: $bp-laptop) {
                padding: #{$spacer * 3.5} 0;
            }
        }

        .base {
            color: $white;
            font-size: 32px;
            text-transform: unset;

            @media (min-width: $bp-tablet) {
                font-size: 40px;
            }
        }

        .page-title-container  {
            @media (min-width: $bp-tablet) {
                text-align: center;
            }
        }
    }

    .resources-title-wrapper {
        @include full-width;
        background: $jpp-blue;
        box-sizing: border-box;
        padding: 32px 15px;
        margin-bottom: 20px;
        min-height:180px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        @media (min-width: $bp-tablet) {
            padding-bottom: 70px;
            padding-top: 70px;
            text-align: center;
            margin-bottom:54px;
        }

        .page-title {
            text-transform: unset;
            margin-bottom: 12px;
            margin-top: 0;

            @media (max-width: $bp-tablet) {
                text-align: center;
            }
            span {
                @include h2-typography;
                color: $white;
                margin: 0;
                text-transform: unset;
            }
        }
        .resource-landing {
            h1, h2, h3 {
                color: $white;
                text-transform: none;
            }
            h3 {
                font-weight:700;
            }
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.4rem;
            margin-bottom: 0;
            text-align:center;
            text-shadow: 1px 2px 3px rgba(2, 2, 2, .1);
            @media (max-width:$bp-mobile-l) {
                font-size:14px;
                line-height:1.4;
                width:328px;
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
            }
        }
        h2 {
            margin:0px auto;
            margin-bottom:0!important;
            text-shadow: 1px 2px 3px rgba(2, 2, 2, .1);
            @media (max-width:$bp-laptop - 1) {
                font-size:32px;
                span {
                    font-size:32px;
                }
            }
            @media (max-width:$bp-tablet - 1) {
                font-size:24px;
                span {
                    font-size:24px;
                }
            }

        }
        h3 {
            text-shadow: 1px 2px 3px rgba(2, 2, 2, .1);
            font-size: 2.4rem;
            line-height: 2.6rem;
            text-align:center;
            @media (max-width:$bp-mobile-l) {
                font-size:18px;
                width:230px;
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
            }
        }

        .page-subtitle {
            @include p-typography;
            color: $white;
            margin-bottom: 0;

            @media (max-width: $bp-tablet) {
                text-align: center;
            }
        }
        & + .page-title-card {
            display: none;
        }
    }

    .columns {
        @media (min-width: $bp-tablet) and (max-width: $bp-desktop-s - 1) {
            display: flex;
        }

        .sidebar-main {
            order: 0;

            @media (min-width: $bp-tablet) and (max-width: $bp-desktop-s - 1) {
                order: 0;
                width: 100%;
            }

            @media (min-width: $bp-desktop-s) {
                background-color: $white;
                box-shadow: 0 0 3px $jpp-grey;
                padding: $spacer;
                width: percentage(1 / 4);
            }

            .cms-menu {
                list-style: none;
                padding-left: 0;

                ul {
                    list-style: none;
                    padding-left: 0;

                    li {
                        display: inline-block;
                        margin-right: 10px;
                        @media (max-width: $bp-desktop-s - 1) {
                            margin-right:4px;
                            margin-bottom:2px;
                        }
                        @media (max-width: $bp-mobile-l - 1) {
                            margin-right:2px;
                            margin-bottom:-1px;
                        }
                        @media (min-width: $bp-desktop-s) {
                            display: block;
                            margin: 0 0 8px;
                        }

                        a {
                            @media (max-width: $bp-desktop-s - 1) {
                                @include button('primary');
                                display:inline-block;
                                float:left;
                                padding:8px 12px!important;
                                span {
                                    font-size:12px;
                                    font-weight:700!important;
                                }
                                &.active {
                                    background:$jpp-orange;
                                    &::before {
                                        display:none!important;
                                    }
                                }
                            }
                            @media (max-width: $bp-mobile-l - 1) {
                                padding:4px 8px 5px!important;
                                span {
                                    font-size:10px;
                                }
                            }
                            &.active {

                                span {
                                    font-weight: 700;
                                }

                                &::before {
                                    content: '\2022';
                                    display: inline-block;
                                    font-size: 1.8em;
                                    line-height: 1.7rem;
                                    margin-right: 3px;
                                    vertical-align: text-top;
                                }
                            }
                        }

                        span {
                            @media (min-width: $bp-desktop-s) {
                                font-size: 16px;
                            }
                        }
                    }
                }

                * {
                    @include unset-underline;
                }
            }

            .parent {
                > a {
                    display: block;
                    margin-bottom: 18px;
                    pointer-events: none;
                    color:#222;
                    @media (min-width: $bp-desktop-s) {
                        margin-bottom: 20px;
                    }

                    span {
                        @include font-bold;
                        font-size: 20px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .sidebar-additional {
            display:none;
        }
        .main {
            @media (min-width: $bp-tablet) and (max-width: $bp-desktop-s - 1) {
                order: 1;
                width: 100%;
            }

            @media (min-width: $bp-desktop-s) {
                width: percentage(2.88 / 4);
            }

            .category-list {
                padding-bottom:18px;
                h3 {
                    margin:0 auto 12px;
                }
            }
            .backbutton {
                display: inline-block;
                margin-top: $spacer;

                a {
                    @include button('border-blue');
                }
            }
        }
    }

    .tile-grid {
        @include clearfix;
        @include grid-collapse;
        margin-left:0;
        margin-right:0;
        padding:0;
        margin-bottom:80px;
        > .row {
            margin-left:0;
            margin-right:0;
        }
        .tile {
            margin-bottom: $spacer;
            text-align: center;
            width:calc(50% - 8px);
            margin-right:16px;
            margin-bottom:16px;
            padding:0!important;
            &:nth-of-type(2n) {
                margin-right:0;
            }
            @media (max-width:$bp-mobile-l - 1) {
                width:calc(50% - 6px);
                margin-right:12px;
                margin-bottom:12px;
            }
            @media (min-width:$bp-laptop) {
                width:calc(33.333% - 10.666px);
                &:nth-of-type(2n) {
                    margin-right:16px;
                }
                &:nth-of-type(3n) {
                    margin-right:0;
                }
            }
            > a {
                @include unset-underline;
                background-color: $white;
                box-shadow: 1px 3px 24px -12px rgba(2, 2, 2, 0.1);
                box-sizing: border-box;
                display: inline-block;
                padding: 24px $spacer;
                width: 100%;
                @include smooth;
                min-height:300px;
                @media (max-width:$bp-mobile-l - 1) {
                    padding:16px 12px;
                    min-height:270px;
                }
                @media (min-width:$bp-mobile-l) {
                    min-height:330px;
                }
                @media (min-width: $bp-tablet) {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    min-height: 350px;
                }
                svg, svg * {
                    @include smooth;
                }
                &:hover {
                    box-shadow:1px 3px 32px -12px rgba(2, 2, 2, 0.24);
                    svg {
                        * {
                            fill: $jpp-orange;
                        }
                    }
                }
            }

            .icon-wrap {
                margin-top: 10px;
            }

            svg {
                height: 56px;
                width: auto;
                @media (min-width:$bp-tablet) {
                    height:80px;
                }
            }

            .text-wrap {
                * {
                    color: $jpp-blue;
                }

                h4 {
                    font-size: 21px;
                    text-transform: none;
                    @media (max-width:$bp-mobile-l - 1) {
                        font-size:16px;
                    }
                    @media (max-width:420px) {
                        font-size:14px;
                    }
                }
                p {
                    line-height:1.35;
                    @media (max-width:$bp-mobile-l - 1) {
                        font-size:14px;
                        line-height:1.28;
                    }
                    @media (max-width:420px) {
                        font-size:12px;
                    }
                }
            }
        }
    }
}

// Resources Home Page
body.cms-resources {
    #sns_main, .column.main {
        background:transparent;
        padding:0;
        box-shadow:0px 0px 0px transparent;
    }
}