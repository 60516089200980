form.hs-form {
    width:600px;
    max-width:100%;
    &.hs-form-9de0cc52-ce41-4e56-bc5e-e92c9dbae8fa_cd3c59a2-f65f-409d-9b19-7c900d8bbb2c {
        &:not(.hs-video-form) {
            .form-columns-2,
            .form-columns-3 {
                .hs-form-field {
                    .hs-input {
                        min-width: 100%;
                    }
                }
            }
        }
    }

    fieldset {
        max-width: 100%;
    }

    label {
        &,
        & span {
            font-size: 1.4rem;
        }
    }
    .inputs-list {
        margin-bottom: 8px;
        min-height: 0;
        list-style: none;
        padding-left: 0;
        margin-top:5px!important;
        li {
            margin-bottom: 0;
            height: 34px;
            width: 100%;
            label {
                color: $black;

                input {
                    &[type="radio"],
                    &[type="checkbox"] {
                        margin-right: 8px;
                        box-shadow: 0 0 3px 0 $white;
                        width: auto;
                    }
                }

                input,
                span {
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }

    .hs-input {
        box-sizing: border-box!important;
    }

    .hs-fieldtype-radio {
        margin-top: 0;
        > label {
            margin: 20px 0 0;
            display: inline-block;
            vertical-align: top;
        }
        label {
            &.hs-form-radio-display {
                margin-top: -10px;
                line-height: 2.4rem;
                display: inline-block;
                vertical-align: sub;
            }
        }
    }

    .hs-fieldtype-phonenumber,
    .hs-fieldtype-text,
    .hs-fieldtype-textarea,
    .hs-fieldtype-select {
        position: relative;
        z-index: auto;
        .input {
            position: relative;
            z-index: auto;
            select,
            textarea,
            input {
                max-width: 100%;
                width: 100%;
                &.invalid,
                &.error {
                    border-color: $jpp-error-red;
                }
            }
        }
        legend {
            &.hs-field-desc {
                opacity: .7;
                font-size: 14px;
                margin-bottom: 0px;
                float: right;
                text-align: right;
                margin-right: 10px;
                padding-top: 14px;
                max-width: 80%;
                line-height: 18px;
                position: absolute;
                right: 0;
                top: 2px;
            }
        }
        > label {
            transform: translateY(36px);
            opacity: 1;
            font-weight: 400;
            z-index: 1;
            position: relative;
            color: $light-grey;
            transition: transform .1s ease-in-out;
            margin: 10px 0 5px 10px;
            display: inline-block;
            vertical-align: top;
            font-size: 1.4rem;
            line-height: 2.3rem;
            &,
            & span {
                color: $light-grey;
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
        &.input-active,
        &.input-filled {
            label {
                transform: translateY(6px);
                opacity: 1;
                &,
                & span {
                    color: $black;
                }
                .hs-form-required {
                    color: $jpp-blue;
                }
            }
        }
        &.input-active {
            .hs-input, select {
                border-color: $jpp-blue;
            }
        }
        &.input-filled {
            .hs-input, select {
                border-color: $jpp-success-green!important;
            }
        }
        &.hs-fieldtype-select {
            &.input-filled {
                .input {
                    &:before {
                        opacity: 0;
                        transition: .25s;
                    }
                }
            }
            .input {
                select {
                    color: $jpp-dark-grey;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    right: 20px;
                    bottom: 2px;
                    background: #ededed !important;
                    background-size: cover;
                    height: 34px;
                    pointer-events: none;
                    opacity: 1;
                    transition: opacity .35s;
                }
            }
        }
    }
    .hs-error-msgs {
        position: relative;
        z-index: auto;
        list-style: none;
        margin: 0;
        li {
            margin: 0;
            label {
                color: #c87872 !important;
                font-size: 13px;
                padding: 0;
                margin: 0;
                line-height: 15px;
                margin-top: 4px;
                margin-left: 10px;
                transform: translateY(0%);
                transition: .2s;
                animation: fadeIn .28s ease-in;

                a {
                    font-size:12px;
                }
            }
        }
    }
    &.form-columns-1,
    &.form-columns-2,
    &.form-columns-3 {
        position: relative;
        margin-bottom: 6px;
    }
    &.form-columns-1 {
        .hs-input {
            width: 100%;
        }
    }
    .legal-consent-container {
        margin:0;
        p {
            margin:24px 0;
            padding-right: 24px;
            font-size: 10px;
            line-height:16px;
            font-style: italic;
            color:#555;
        }
    }
    .hs-submit {
        .actions {
            input[type="submit"] {
                @include button('primary');
            }
        }
    }
    .hs-recaptcha {
        margin: 20px 0 !important;
    }
    .hs_phone{
        margin-bottom:0!important;
    }
}
