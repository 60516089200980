.checkout-cart-index {
    background:#F4F5F7;
    div.breadcrumbs {
        margin-bottom:0;
        display:none;
    }
    .page-title-wrapper {
        min-height:195px;
        width:100%;
        padding:16px;
        background:linear-gradient(to bottom, #0084C8 0%, #005480 100%);
        display:flex;
        flex-direction:column;
        justify-content:center;
        @media (max-width:768px) {
            min-height:140px;
        }
    }
    .page-title {
        margin:0;
        color:#fff;
        text-align:center;
        width:100%;
        display:block;
        transform:translateY(-18px);
        @media (max-width:768px) {
            transform:translateY(-16px);
        }
        span {
            font-size: 24px;
            @media (min-width: $bp-laptop) {
                font-size: 32px;
            }
        }
    }
    .page.messages {
        .message {
            margin-bottom:12px;
        }
    }
    .block-requisition-list {
        display:none;
    }

    div.block.crosssell {
        display:none;
    }

    #sns_main {
        margin-top:-70px;
        @media (max-width:991px) {
            margin-top:-32px;
        }
        .cart-empty {
            position:relative;
            z-index:1;
            width:600px;
            max-width:100%;
            margin-left:auto;
            margin-right:auto;
            background:#fff;
            box-shadow:0px 0px 12px rgba(2,2,2,0.06);
            padding:32px 16px;
            margin-top:40px;
            @media (max-width:991px) {
                margin-top:0;
                max-width:calc(100% - 32px);
            }
            p {
                text-align:center;
                margin-bottom:2px;
                &:last-of-type {
                    margin-bottom:0;
                }

                a {
                    text-decoration:none;
                }
            }
        }
    }
    #maincontent {
        width:100vw;
        display:flex;
        flex-direction:column;
        max-width:100%;
        @media (max-width:768px) {
            max-width:100%;
        }
        .page.messages {
            order:1;
            background:#0084C8;
            > div {
                width:400px;
                margin-left:auto;
                margin-right:auto;
                max-width: 100%;
                .message {
                    margin-top:5px!important;
                    width: 100%;
                }
            }
        }
        .page-title-wrapper {
            order:2;
        }
        .columns {
            order:3;
        }
    }

    .cart-container {
        display:flex;
        @media (max-width:991px) {
            flex-direction:column;
        }
        #cart-vue {
            margin-right:18px;
            width:calc(100% - 16px);

            &.empty {
                margin-right:0;
                width:100%;
            }

            @media (max-width:991px) {
                width:100%;
            }
        }
    }

    .cart.table-wrapper {
        position:relative;
        max-width:100%;
        margin:0 auto;
        z-index:1;
        &:after {
            position:absolute;
            content:'';
            top:40px;
            left:0;
            right:0;
            bottom:0;
            pointer-events:none;
            z-index:-1;
            background:#fff;
            box-shadow:0px 0px 12px rgba(2,2,2,0.06);
            @media (max-width:991px) {
                display:none;
            }
        }
        .items {
            border:0px solid transparent;

            thead {
                background:transparent;
                border:0px solid transparent;
                @media (max-width:991px) {
                    //opacity:0;
                    //pointer-events:none;
                    display:none;
                }
                + .item {
                    border: 0;
                }
            }

            th {
                padding: 10px 16px;

                &.item {
                padding-left:170px;
                    @media (min-width:992px) and (max-width:1368px) {
                        padding-left:132px;
                    }
                    @media (max-width:991px) {
                        //padding-left:16px;
                    }
                }

                span {
                    //font-weight:600;
                    //font-size: 13px;
                    //letter-spacing:0.25px;
                    line-height: 1;
                    text-transform: uppercase;
                    color:#fff;

                    font-size:12px;
                    letter-spacing:0.5px;
                    font-weight:300;
                }
            }

            > .item {
                border: 0;
                @media (max-width:991px) {
                    background:#fff;
                    box-shadow:0px 0px 12px rgba(2,2,2,0.06);
                    display:block;
                }
                &:not(:first-of-type) {
                    border-top: 1px solid $jpp-light-grey;
                    border-bottom: 1px solid $jpp-light-grey;

                    @media (max-width:991px) {
                        border:0px solid transparent!important;
                        margin-top:10px;
                    }
                }

                .col {
                    padding:16px;
                    padding-top:16px;

                    &.item {
                        display: flex;
                        padding: 0;

                        @media (max-width: $bp-tablet) {
                            border-bottom: 1px solid $jpp-light-grey;
                        }
                    }

                    div.list-price {
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: normal;
                        color: #999;
                        span {
                            font-size: 12px;
                            line-height: 12px;
                            text-decoration: line-through;
                        }
                    }
                    div.you-save {
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: normal;
                        color: $jpp-dark-blue;
                        span {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }

                    &.price, &.qty, &.subtotal {
                        position:relative;
                        min-width:122px;
                        @media (min-width:992px) and (max-width:1368px) {
                            min-width:104px;
                        }
                        > span, > div {
                            position:absolute;
                            top:16px;
                            left:16px;
                            right:16px;
                            bottom:16px;
                            display:flex;
                            flex-direction:column;
                            justify-content:center;
                        }
                        @media (min-width: $bp-tablet) {
                            border-left: 1px solid $jpp-light-grey;
                        }
                    }

                    &.qty {
                        @media (max-width: $bp-tablet) {
                            border-left: 1px solid $jpp-light-grey;
                            border-right: 1px solid $jpp-light-grey;
                        }
                    }
                    .mobile-elements-wrapper {
                        display:flex;
                        margin-bottom:16px;
                        position:relative;
                        @media (min-width:992px) {
                            display:none!important;
                        }
                    }
                    .mobile-price, .mobile-quantity, .mobile-subtotal {
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        position:relative;
                        div.list-price {
                            display:none;
                        }
                        @media (min-width:992px) {
                            display:none!important;
                        }
                    }

                    .mobile-quantity {
                        span.label {
                            font-size:12px;
                            text-transform:uppercase;
                            margin-right:4px;
                            color:#585858;
                            font-weight:300;
                        }
                    }
                    &.price, &.qty, &.subtotal {
                        @media (max-width:991px) {
                            display:none;
                        }
                    }
                    span.cart-price {
                        font-weight:700;
                    }
                    .mobile-elements-wrapper {
                        display:flex;
                        > div {
                            margin-right:12px;
                            &.mobile-price {
                                margin-right:6px;
                            }
                            &.mobile-subtotal {
                                margin-right:0;
                                span > span {
                                    color:$jpp-dark-blue;
                                }
                            }
                        }
                    }
                    input[type="number"] {
                        background:#ededed;
                        border:0px solid transparent;
                        box-shadow:inset 0px 0px 3px rgba(2,2,2,0.09);
                        height:30px;
                        line-height:30px;
                        font-weight:700;
                        margin-top:0;
                        text-align:right;
                        min-width:60px;
                        max-width:60px;
                        padding-top:2px;
                    }

                }
            }
        }

        .product-item-photo-actions {
            box-sizing: border-box;
            padding:16px;
            position:relative;
            width:150px;
            min-width:150px;
            height:150px;
            @media (min-width:992px) and (max-width:1368px) {
                width:116px;
                min-width:116px;
                height:116px;
            }
            @media (max-width:991px) {
                order:1;
                width:116px;
                min-width:116px;
                height:116px;
            }
            @media (max-width:480px) {
                order:1;
                width:80px;
                min-width:80px;
                height:80px;
                padding:10px;
            }

            a {
                position:absolute;
                top:16px;
                left:16px;
                right:16px;
                bottom:16px;
                width:auto;
                span {
                    width:100%;
                    height:100%;
                }

                img {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                    max-height:94%;
                    max-width:94%;
                    width:auto;
                }
                &:hover {
                    span {
                        color:$jpp-blue;
                    }
                }
            }
        }

        .product-item-photo {
            padding-right: 0;
            position: unset;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            @media (max-width:991px) {
                max-width:94%!important;
                max-height:94%!important;
            }
        }

        .actions-toolbar {
            margin-left:0!important;
            text-align: center;
            .requisition-list-button {
                display:none;
            }
            > .action {
                @include unset-underline;
                background: none;
                border: 0;
                font-size: 12px;
                line-height: 1;
                margin-bottom: 6px;
                padding: 0;

                &:hover, &:active, &:focus {
                    background: none;
                    border: 0;
                }

                span {
                    font-size: 12px;
                    line-height: 1;
                    text-transform: uppercase;
                }
            }
        }

        .product-item-details {
            padding: 20px;
            border-left:1px solid #ededed;
            display:flex;
            flex-direction:column;
            @media (min-width:992px) and (max-width:1368px) {
                padding:16px;
            }
            @media (max-width:991px) {
                width:100%;
                order:2;
                border-left:0px solid transparent;
            }
            @media (max-width:991px) {
                padding:16px;
            }
            .messages-wrapper {
                display:flex;
                flex-direction:column;
                > div {
                    margin-bottom:12px;
                    display:inline-block;
                    align-self:flex-start;
                }

                .message {
                    position:relative;
                    border:0px solid transparent!important;
                    padding:10px;
                    font-size:13px;
                    font-weight:600;
                    @media (min-width:992px) and (max-width:1368px) {
                        font-size:12px;
                    }
                    span {
                        font-size:13px;
                        font-weight:600;
                    }
                    &.no-free-shipping {
                        background:#FEE5E5;
                        color:#B72930;
                        svg path, svg line, ellipse {
                            stroke:#B72930;
                        }
                        svg {
                            left:11px!important;
                        }
                    }
                }
                .message, .alert {
                    padding-left:40px;
                    position:relative;
                    svg {
                        position:absolute;
                        top:50%;
                        left:8px;
                        transform:translateY(-50%);
                    }
                    span, a {
                        font-size:13px;
                        line-height:1.4;
                        font-weight:600;
                        @media (max-width:480px) {
                            font-size:12px;
                        }
                    }
                }
            }
            .product-item-name {
                margin-top:-2px;
                > a {
                    @include h5-typography;
                    @include unset-underline;
                    text-transform:none;
                    font-weight:700;
                    @media (max-width:480px) {
                        font-size:14px;
                    }
                }
            }
            .item-options {
                margin-top:6px;
                margin-bottom:8px;
                @media (max-width:991px) {
                    display:flex;
                    margin-bottom:16px;
                    > span {
                        margin-right:14px;
                    }
                }
                @media (max-width:768px) {
                    margin-top:0;
                }
                @media (max-width:480px) {
                    flex-direction:column;
                    > span {
                        margin:1px 0;
                    }
                }
                dd {
                    font-weight:500;
                }
                dt {
                    margin-right:6px;
                }
                dd, dt {
                    margin-bottom:0;
                    font-size:13px;
                    @media (max-width:768px) {
                        font-size:12px;
                        line-height:1.2;
                    }
                }
            }
            .warning {
                .alert, .alert-info {
                    border:0px solid transparent;
                    padding:0;
                    color:#DE7F00!important;
                    background:#FCF4E5!important;
                    margin:0;

                    svg path, svg line {
                        stroke:#DE7F00;
                    }
                }
                > .alert, .alert-info {
                    background:none!important;
                }
                svg {
                    left:11px!important;
                }
            }
            .alert {
                border:0px solid transparent;
                margin-bottom: 20px;
                padding:12px 14px;
                font-size:13px;
                font-weight:600;

                a {
                    font-size:14px;
                }
                &.alert-info {
                    background-color: $jpp-info-blue-light;
                    color: $jpp-info-blue;
                }
                &.stock {
                    margin-bottom: $spacer;
                }
            }

            .nonreturnable {
                color:#004F9D;
                background:#E5ECF6;
                svg path, svg line {
                    stroke:#004F9D;
                }
            }
            .ground, .no-free-shipping {
                background-color: $jpp-info-blue-light;
                color: $jpp-info-blue;
                svg path, svg line {
                    stroke:#004F9D;
                }
            }
            .preorder {
                color:#F5781F;
                background:#FEF1E8;
                svg path, svg line {
                    stroke:#F5781F;
                }
            }
            .sale {
                color:#61BB72;
                background:#EFF8F1;
                svg path, svg line {
                    stroke:#61BB72;
                }
            }

            .actions-wrapper {
                display:flex;
                margin-top:auto;
                margin-bottom:0;
                > div {
                    margin-right:8px;
                    &:last-of-type {
                        margin-right:0;
                    }
                }
            }
            a.action {
                border:1px solid #d7d7d7;
                border-radius:4px;
                text-decoration:none!important;
                display:inline-flex;
                padding:0 6px;
                height:22px;
                transition:0.18s ease;
                margin:0;
                svg {
                    margin-top:4px;
                    margin-right:4px;
                    path, line {
                        stroke:#787878;
                        transition:0.18s ease;
                    }
                }
                span {
                    font-size:11px;
                    font-weight:600;
                    line-height:22px;
                    color:#787878;
                    //text-transform:uppercase;
                    transition:0.18s ease;
                }
                &:hover {
                    svg path, svg line {
                        stroke:#0088ce;
                    }
                    border-color:#0088ce;
                    span {
                        color:#0088ce;
                    }
                }
                &.action-add-to-wishlist {
                    svg {
                        width:12px;
                        transform:translateX(-0.5px) translateY(0.5px);
                    }
                }
            }
        }

        .item-options {
            dt,
            dd {
                font-size: 14px;
            }
        }

        .addon-item {
            border-top: none !important;
            border-bottom: none !important;
            @media (max-width:991px) {
                width:calc(100% - 116px);
                float:right;
                margin-right:0;
                margin-left:auto;
            }
            @media (max-width:768px) {
                width:calc(100% - 10vw);
            }
        }

        .item-info {
            @media (max-width:991px) {
                width:100%;
                min-width:100%;
                display:block;
            }
            .addon-product-wrapper {
                .product-addon-empty-column {
                    width:150px;
                    padding: 16px;
                    @media (max-width: 1368px) {
                        width:116px;
                        min-width:116px;
                        height:116px;
                    }
                    @media (max-width:991px) {
                        display:none;
                    }
                    @media (max-width: 480px) {
                        width:40px;
                        min-width:40px;
                        height:40px;
                    }
                }
                .product-item-photo-actions {
                    border-left: 1px solid $jpp-light-grey;
                }
                .product-item-details {
                    flex-basis: 60%;
                    @media (max-width:991px) {
                        flex-basis: 100%;
                    }
                    .product-item-name {
                        font-weight:700;
                        margin:0 0 2px;
                    }
                }
            }
            &.addon-info {
                .product-addon-empty-column {
                    background:#F4F5F7;
                    box-shadow:-4px 5px 4px 4px #f4f5f7;
                    @media (max-width:$bp-tablet) {
                        display:none;
                    }
                }
                .actions-toolbar {
                    .towishlist,
                    .action-edit {
                        display: none;
                    }
                }
                .product-item-photo-actions {
                    flex-basis: auto;
                    border-right: none;
                    height:100px;
                    width:100px;
                    min-width:100px;
                    @media (max-width: 1368px) {
                        width:76px;
                        min-width:76px;
                    }
                    span {
                        width:100%;
                        height:100%;

                        img {
                            position:absolute;
                            top:50%;
                            left:50%;
                            transform:translateX(-50%) translateY(-50%);
                        }
                    }
                }
                .product-item-details {
                    padding: 16px;
                    //height:100px;
                    align-self: center;
                    border-left:0px solid transparent;
                    .item-options {
                        display:none;
                    }
                    .product-item-name {
                        margin-bottom:4px!important;
                        line-height:1.2;
                        > span {
                            font-size: 14px;
                            font-weight:700;
                            line-height:1.2;
                        }
                    }

                    .item-options,
                    .product-item-name {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }
                .actions-wrapper {
                    div:nth-of-type(1) {
                        display:none;
                    }
                }
            }
        }
    }

    .cart-container {
        width: 1500px;
        max-width:92%;
        margin-left:auto;
        margin-right:auto;
        @media (max-width:991px) {
            max-width:calc(100% - 32px);
        }
        .form-cart {
            float: none;
            width:100%;
            max-width:100%;
            margin-left:auto;
            margin-right:auto;
            margin-bottom:40px;
            @media (max-width:991px) {
                margin-bottom:0;
            }
            .actions {
                border-top: 0;
                padding: 16px;
                display:flex;
                justify-content:flex-end;
                margin-top:12px;
                box-shadow:0px 0px 12px rgba(2,2,2,0.06);
                background:#fff;
                &:not(.full-width) {
                    display:inline-flex;
                    float:right;
                    margin-right:0;
                    width:388px;
                    max-width:100%;
                    @media (max-width:480px) {
                        width:100%;
                    }
                }
                &.full-width {
                    @media (max-width:1380px) {
                        flex-direction:column;
                        .reward-checkout {
                            margin-bottom:12px;
                        }
                    }
                    @media (max-width:991px) {
                        width:340px;
                        margin-right:0;
                        margin-left:auto;
                    }
                    @media (max-width:480px) {
                        width:100%;
                    }
                }
                @media (max-width:$bp-desktop-s) {
                    flex-direction:column;
                }
                .reward-checkout {
                    flex:1;
                    display:flex;

                    .rewards-messages, .reward-icon {
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                    }
                    .reward-icon {
                        margin-right:14px;
                        justify-content:center;

                        @media (max-width:991px) {
                            justify-content:flex-start;
                        }
                    }
                    .reward-message-half {
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        margin-bottom:0;
                        span {
                            font-size:14px;
                            line-height:1.2;
                            text-transform:none;
                            color:#000;
                        }
                        &:nth-of-type(2) {
                            margin-bottom:0;
                        }
                    }

                }
                .reward-amount {
                    @media (max-width:991px) {
                        margin-bottom:4px!important;
                    }
                    span {
                        font-size:14px;
                        line-height:1.2;
                    }
                }
                .reward-limit {
                    span {
                        font-size:12px;
                        opacity:0.6;
                    }
                }
                .reward-balance {
                    font-size:14px;
                    display:inline-block;
                    text-transform:uppercase;
                    max-height:36px;
                    line-height:36px;
                    padding-right: 10px;
                    @media (max-width:$bp-tablet) {
                        text-align:left;
                        max-height:100%;
                        line-height:1.4;
                        margin:6px 0 12px;
                        span {
                            line-height:1.4;
                        }
                    }
                    span {
                        font-size:14px;
                    }
                }
                .button-wrapper {
                    align-self:flex-end;
                    @media (max-width:991px) {
                        width:308px;
                        max-width:100%;
                        display:flex;
                    }
                    @media (max-width:$bp-tablet) {
                        display:flex;
                    }
                    @media (max-width:$bp-mobile-l) {
                        width:100%;
                        //flex-direction:column;
                    }
                    button {
                        margin:0;
                        flex:1;
                        &:nth-of-type(1) {
                            margin-right:6px;
                        }
                    }
                }
                .action.clear, .action.update {
                    background:transparent!important;
                    padding:10px 16px!important;
                    border:1.5px solid #CDCDCD!important;
                    max-width:178px;
                    @media (max-width:991px) {
                        width:calc(50% - 3px);
                        span > span {
                            display:none;
                        }
                    }
                    @media (max-width:$bp-tablet) {
                        //margin-left:0;
                        //margin-right:16px;
                    }
                    @media (max-width:$bp-mobile-l) {
                        //width:100%;
                        //margin-left:0;
                        //margin-right:0;
                        //max-width:100%;
                    }
                    span {
                        font-size:12px;
                        font-weight:600;
                        color:#000!important;
                    }
                    &:hover, &:active, &:focus {
                        background:#0088ce!important;
                        border:1.5px solid #0088ce!important;
                        color:#fff!important;
                        span {
                            color:$white!important;
                        }
                    }
                }
                .action.update {
                    margin-right:0!important;
                }
            }

            .action {
                &.clear,
                &.update {
                    @include button('primary');

                    &:before { // sass-lint:disable-line pseudo-element
                        content: none;
                    }
                }
            }
        }

        .cart-freeshipping {
            background: linear-gradient(to bottom, #3473b1 0%, #004f9d 100%);
            box-sizing: border-box;
            order: 0;
            padding: 10px 10px 10px 44px;
            position: relative;
            width: 100%;
            margin-bottom:12px;

            .icon-free-shipping {
                @include icon($icon-jpp-freeshippingtracker, $white, 24px);
                left: 10px;
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .qualification-label {
                clear: both;
                color: $white;
                display: block;
                font-size: 14px;
                line-height:1.2;
                margin: 0;
                padding: 0;
                &:nth-of-type(1) {
                    transform:translateY(2px);
                }
                a {
                    @include unset-underline;
                    color: $white;
                    font-size: 14px;
                    line-height:1.2;
                    span {
                        color: inherit;
                        font-size:14px;
                    }
                }
                span {
                    font-size:14px;
                    line-height:1.2;
                }
            }
        }

        .checkout-methods-items {
            text-align: right;
            margin-top:12px;
            .action {
                &.primary {
                    width: 100%;
                    border:0px solid transparent!important;
                    padding:0 16px!important;
                    height:50px;
                    line-height:50px;
                    span {
                        font-size:16px;
                        line-height:50px;
                        user-select:none;
                    }
                }

                &.multicheckout {
                    @include invert-button;
                    white-space: nowrap;
                }
            }
        }
    }

    .cart-summary {
        background:none;
        display:flex;
        flex-wrap:wrap;
        flex-direction:column;
        float:none;
        padding: 0;
        width:314px;
        position:relative;
        margin-top:40px;
        @media (min-width:992px) and (max-width:1368px) {
            width:260px;
        }
        @media (max-width:991px) {
            width: 340px;
            max-width:100%;
            margin-top:12px;
            margin-left:auto;
            margin-right:0;
        }
        @media (max-width:480px) {
            width:100%;
        }
        > * {
            box-sizing: border-box;
        }

        .block {
            background:#fff;
            box-shadow:0px 0px 12px rgba(2,2,2,0.06);
            margin-bottom: 12px;
            padding:16px;
            position:relative;

            &.discount, &.giftcard {
                width:100%;

                .actions-toolbar {
                    > .primary {
                        button {
                            @include invert-button;
                        }
                    }

                    > .secondary {
                        button {
                            @include button;
                        }
                    }
                }
            }

            &.discount {
                width:100%;
                .title {
                    display:none;
                }
                div.field {
                    margin-bottom:0;
                    @media (max-width:480px) {
                        flex:2;
                    }
                }
                .fieldset.coupon {
                    display:flex;
                    margin:0!important;
                    width:100%;
                    label {
                        height:35px;
                        line-height:37px;
                        font-weight:700;
                        margin:0;
                        color:rgba(0,0,0,0.75);
                    }
                    input, button {
                        height:35px;
                        line-height:35px;
                    }
                    input {
                        width:160px;
                        margin-right:8px;
                        @media (max-width:480px) {
                            width:calc(100% - 6px);
                        }
                    }
                    .actions-toolbar {
                        flex:1;
                        @media (max-width:480px) {
                        }
                    }
                    .primary {
                        width:100%;
                    }
                    button {
                        width:100%;
                        padding:0px 10px!important;
                        line-height:36px;
                        margin:0;
                        border:1px solid #0088ce!important;
                        span {
                            font-size:12px;
                            line-height:35px;
                            display:block;
                        }
                    }
                }
            }

            &.shipping {
                .title {
                    margin-top:-4px;
                    strong {
                        font-size:14px;
                        font-weight:800;
                        text-transform:uppercase;
                    }
                }
                p.field.note {
                    line-height:14px;
                    display:none;
                }
                a.phone-link {
                    text-decoration:none!important;
                    pointer-events:none;
                    color:#353535;
                    display:inline-block;
                    transform:translateY(2px);
                    &:hover {
                        color:#353535;
                    }
                }

                .fieldset.estimate {
                    margin:10px 0 0!important;
                }
                #block-summary > fieldset.estimate > div {
                    > button.action.primary {
                        border-radius:0px;
                        border:1px solid #CDCDCD!important;
                        padding:5px 14px;
                        background-color:#fff!important;
                        height:37px;
                        line-height:35px;
                        padding:0 16px!important;
                        width:100%;
                        span {
                            font-size:12px;
                            font-weight:600;
                            color:#222!important;
                        }
                        &:hover {
                            background-color:#0088ce!important;
                            border-color:#0088ce!important;
                            span {
                                color:#fff!important;
                            }
                        }
                    }
                    .jpp-component-select {
                        margin-bottom:0;
                        + button {
                            margin-top: 8px;
                        }
                        label {
                            display:none;
                        }
                    }
                }

                form {
                    fieldset {
                        margin-left:0;
                    }
                }


            }

            &.shipping, &.discount {
                width:100%;
            }


            > .title {
                border-top: 0;
                padding: 0;

                &:after {
                    content: none;
                }

                strong {
                    .column.main & {
                        @include h5-typography;
                    }
                }
            }

            > .content {
                display: block;
            }

            .fieldset {
                .field {
                    &:not(.choice) {
                        > .label {
                            padding: 0 0 0 10px;
                        }
                    }
                }
            }
        }
        .table-wrapper {
            margin-top:-4px;
        }
        .totals-methods-container {
            padding: 16px;
            width:100%;
            box-shadow:0px 0px 12px rgba(2,2,2,0.06);
            background:#fff;
            th.mark {
                font-size:16px;
                font-weight:700;
                span {
                    font-weight:700;
                }
            }
            tr.rewardpoints {
                th {
                    display:flex;
                    a {
                        order:2;
                        margin-left:8px;
                        text-decoration:none!important;
                        span {
                            font-size:14px!important;
                        }
                    }
                }
            }
            th, td {
                padding:4px 0!important;
                @media (min-width:991px) and (max-width:1368px) {
                    font-size:13px!important;
                }
                @media (min-width:991px) and (max-width:1368px) {
                    padding:0!important;
                }
                span {
                    @media (min-width:991px) and (max-width:1368px) {
                        font-size:13px!important;
                    }
                }
            }
            .cart-logos {
                margin-top:16px;
                text-align:left;
                height:auto;
                display:flex;
                img {
                    height:21px;
                    width:36px;
                    margin-right:4px;
                    background:#f7f7f7;
                    box-shadow: inset 0 0 1px 0 #9d9d9d;
                    &:last-of-type {
                        margin-right:0;
                        padding:5px;
                    }
                }
            }
        }
    }
    .cart-buttons {
        margin: 0 0 12px;
        text-align: right;
        position:absolute;
        top:0;
        right:0;
        @media (max-width: $bp-mobile-l) {
            margin: 10px 0;
        }
        .cart-continue-shopping {
            @include invert-button('secondary');
            border-color:#fff;
            position: relative;
            border:1.5px solid $jpp-orange!important;
            padding:10px 16px!important;
            height:36px;
            opacity:0.75;
            transition:0.18s ease;
            display:none;
            span {
                font-size:12px!important;
                font-weight:600!important;
                color:#fff;
            }
            &:hover {
                opacity:1;
            }
            &:before {
                content: none;
            }
        }
    }

    .cart-totals {
        border-top: 0;
        padding-top: 0;
        .sub {
            .mark strong, .amount .price {
                font-size: 14px;
                text-transform: uppercase;
            }
            .amount .price {
                font-size: initial;
            }
            .mark {
                font-size: 1.6rem;
            }
        }
        th, td {
            padding:0;
            &.amount {
                padding:0;
            }
        }
        .grand {
            th, td {
                //padding:24px 0 4px!important;
                padding:24px 0 0!important;

                strong, span {
                    @media (min-width:991px) and (max-width:1368px) {
                        font-size:20px!important;
                    }
                }
            }
            .mark strong,
            .amount .price {
                @include h4-typography;
                text-transform:none;

                @media (min-width: $bp-laptop) {
                    font-size:20px;
                }
            }
            .amount .price {
                color: $jpp-dark-blue;
            }
        }
    }

    .cart-logos {
        height: 24px;
    }

    .loading-mask {
        .loader {
            > img {
                width: unset;
            }
        }
    }

    .amsl-cart-page {
        .divider span {
            display: none;
        }
        .am-social-wrapper {
            margin-top:40px;
            .am-buttons {
                width: 320px;
                margin: 0 auto;
            }
        }
        .am-sl-button {
            background:#fff!important;
        }
    }

}
