.content-container {
    margin: 0 auto;
    width:$content-max;
    max-width:100%;
}

.flex-container,
.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column,
.vertical {
    flex-direction: column;
}

.flex-h-center,
.center {
    justify-content: center;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-align-center {
    align-items: center;
}

.clearfix,
.grid-container {
    @include clearfix;
}

.grid-collapse {
    @include grid-collapse;
}

.grid-collapse-md {
    @media (min-width: $bp-laptop) {
        @include grid-collapse;
    }
}

.col-xs-1 {
    @include grid-width(1);
}

.col-xs-2 {
    @include grid-width(2);
}

.col-xs-3 {
    @include grid-width(3);
}

.col-xs-4 {
    @include grid-width(4);
}

.col-xs-5 {
    @include grid-width(5);
}

.col-xs-6 {
    @include grid-width(6);
}

.col-xs-7 {
    @include grid-width(7);
}

.col-xs-8 {
    @include grid-width(8);
}

.col-xs-9 {
    @include grid-width(9);
}

.col-xs-10 {
    @include grid-width(10);
}

.col-xs-11 {
    @include grid-width(11);
}

.col-xs-12 {
    @include grid-width(12);
}


.col-sm-1 {
    @media (min-width: $bp-tablet) {
        @include grid-width(1);
    }
}

.col-sm-2 {
    @media (min-width: $bp-tablet) {
        @include grid-width(2);
    }
}

.col-sm-3 {
    @media (min-width: $bp-tablet) {
        @include grid-width(3);
    }
}

.col-sm-4 {
    @media (min-width: $bp-tablet) {
        @include grid-width(4);
    }
}

.col-sm-5 {
    @media (min-width: $bp-tablet) {
        @include grid-width(5);
    }
}

.col-sm-6 {
    @media (min-width: $bp-tablet) {
        @include grid-width(6);
    }
}

.col-sm-7 {
    @media (min-width: $bp-tablet) {
        @include grid-width(7);
    }
}

.col-sm-8 {
    @media (min-width: $bp-tablet) {
        @include grid-width(8);
    }
}

.col-sm-9 {
    @media (min-width: $bp-tablet) {
        @include grid-width(9);
    }
}

.col-sm-10 {
    @media (min-width: $bp-tablet) {
        @include grid-width(10);
    }
}

.col-sm-11 {
    @media (min-width: $bp-tablet) {
        @include grid-width(11);
    }
}

.col-sm-12 {
    @media (min-width: $bp-tablet) {
        @include grid-width(12);
    }
}

.col-md-1 {
    @media (min-width: $bp-laptop) {
        @include grid-width(1);
    }
}

.col-md-2 {
    @media (min-width: $bp-laptop) {
        @include grid-width(2);
    }
}

.col-md-3 {
    @media (min-width: $bp-laptop) {
        @include grid-width(3);
    }
}

.col-md-4 {
    @media (min-width: $bp-laptop) {
        @include grid-width(4);
    }
}

.col-md-5 {
    @media (min-width: $bp-laptop) {
        @include grid-width(5);
    }
}

.col-md-6 {
    @media (min-width: $bp-laptop) {
        @include grid-width(6);
    }
}

.col-md-7 {
    @media (min-width: $bp-laptop) {
        @include grid-width(7);
    }
}

.col-md-8 {
    @media (min-width: $bp-laptop) {
        @include grid-width(8);
    }
}

.col-md-9 {
    @media (min-width: $bp-laptop) {
        @include grid-width(9);
    }
}

.col-md-10 {
    @media (min-width: $bp-laptop) {
        @include grid-width(10);
    }
}

.col-md-11 {
    @media (min-width: $bp-laptop) {
        @include grid-width(11);
    }
}

.col-md-12 {
    @media (min-width: $bp-laptop) {
        @include grid-width(12);
    }
}

.col-lg-1 {
    @media (min-width: 1200px) {
        @include grid-width(1);
    }
}

.col-lg-2 {
    @media (min-width: 1200px) {
        @include grid-width(2);
    }
}

.col-lg-3 {
    @media (min-width: 1200px) {
        @include grid-width(3);
    }
}

.col-lg-4 {
    @media (min-width: 1200px) {
        @include grid-width(4);
    }
}

.col-lg-5 {
    @media (min-width: 1200px) {
        @include grid-width(5);
    }
}

.col-lg-6 {
    @media (min-width: 1200px) {
        @include grid-width(6);
    }
}

.col-lg-7 {
    @media (min-width: 1200px) {
        @include grid-width(7);
    }
}

.col-lg-8 {
    @media (min-width: 1200px) {
        @include grid-width(8);
    }
}

.col-lg-9 {
    @media (min-width: 1200px) {
        @include grid-width(9);
    }
}

.col-lg-10 {
    @media (min-width: 1200px) {
        @include grid-width(10);
    }
}

.col-lg-11 {
    @media (min-width: 1200px) {
        @include grid-width(11);
    }
}

.col-lg-12 {
    @media (min-width: 1200px) {
        @include grid-width(12);
    }
}
