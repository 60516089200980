.account {
    &.customer-account-index, &.customer-account-accent_license {
        .column.main { // sass-lint:disable-line force-element-nesting
            .block {
                margin-bottom: $account-spacer;

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: $bp-tablet - 1) {
                    border-bottom: 1px solid $jpp-grey;
                }

                .block-title {
                    > strong {
                        @include h4-typography;
                        font-size:20px;
                        text-transform: none;

                        @media (min-width: $bp-tablet) {
                            font-size: 20px;
                        }
                    }
                }

                .box {
                    @media (min-width: $bp-tablet) {
                        margin-bottom: 0 !important; // sass-lint:disable-line no-important
                    }
                }

                &.block-dashboard-info {
                    .block-title {
                        display: none;
                    }

                    .actions-toolbar {
                        a.action { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                            @include dashboard-button;
                        }
                    }

                    .block-content {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        &::before,
                        &::after {
                            content: none;
                        }

                        .box {
                            @include customer-container;
                            flex-basis: 100%;
                            @media (min-width: $bp-laptop) {
                                flex-basis: calc(50% - 6px);
                                float: none;
                                width: auto;
                                flex: auto;
                            }
                            &.box:nth-of-type(2) {
                                margin-left: 12px;
                            }
                            &.box-information {
                                margin-bottom:12px!important;
                                @media (min-width: $bp-laptop) {
                                    margin-bottom:0!important;
                                }
                                .box-title {
                                    > span {
                                        font-size:20px;
                                        @media (min-width: $bp-tablet) {
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                        }

                        .box-title {
                            line-height: inherit;
                            margin-bottom: 20px;

                            > span {
                                @include h4-typography;
                                text-transform: none;

                                @media (min-width: $bp-tablet) {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }

                &.block-dashboard-orders,
                &.block-dashboard-addresses,
                &.block-dashboard-account-inquiry {
                    @include customer-container;

                    .block-content {
                        .box-title {
                            > span {
                                @include h5-typography;
                            }
                        }
                        .value {
                            float: right;
                        }
                        .actions-toolbar {
                            .primary {
                                margin-top: 24px;
                                width: 200px;
                                float: right;
                            }
                        }
                    }
                }

                &.block-dashboard-account-inquiry {
                    .block-title.order {
                        font-size: 2rem;
                        padding-bottom: 0;
                        margin-bottom: 24px;
                    }
                    .block-content {
                        display:flex;
                        flex-wrap:wrap;
                        .box {
                            width:calc(50% - 12px);
                            float:none;
                            @media (max-width:$bp-mobile-l) {
                                width:100%;
                            }
                            &.box-1 {
                                margin-right:24px;
                                @media (max-width:$bp-mobile-l) {
                                    margin-right:0;
                                }
                            }
                        }
                        .actions-toolbar {
                            width:100%;
                        }
                    }
                }

                &.block-dashboard-orders {
                    .block-title.order {
                        font-size:2rem;
                        padding-bottom:0;
                        margin-bottom:0!important;
                    }
                    .order-number {
                        @include h5-typography;
                        margin: 0;
                    }

                    .item {
                        border: 1px solid $jpp-grey;
                        display: inline-block;
                        height: 46px;
                        position: relative;
                        text-align: center;
                        text-transform: uppercase;
                        vertical-align: middle;
                        width: 46px;
                        @include smooth;

                        a {
                            display:block;
                            width:100%;
                            height:100%;
                            position:relative;
                        }
                        &:hover {
                            border:1px solid $jpp-blue;
                        }
                        img {
                            max-height:calc(100% - 2px);
                            max-width:calc(100% - 2px);
                            position:absolute;
                            top:50%;
                            left:50%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                        .counter {
                            @include center-x-y;
                            @include h5-typography;
                            //background: $shadow-overlay;
                            background:$shadow-overlay-gradient;
                            text-shadow:0px 0px 6px rgba(2,2,2,0.9);
                            box-sizing: border-box;
                            color: $white;
                            height: 46px;
                            width: 46px;
                            margin: 0;
                            text-align: center;
                            line-height:46px;
                        }
                    }

                    .table-wrapper {
                        tr {
                            &:not(:first-child) {
                                border-top: 1px solid $jpp-grey;
                            }
                            &:last-of-type {
                                td:nth-of-type(1) {
                                    padding-bottom:0;
                                }
                            }
                        }

                        .col {
                            padding: 24px 0;

                            &::before {
                                content: none;
                            }

                            &.actions {
                                width:140px;
                            }
                        }

                        .action {
                            @include dashboard-button;
                            margin-right: 0;

                            &:not(:last-child) {
                                margin-bottom: $account-spacer;
                            }
                        }
                    }
                }

                &.block-dashboard-addresses {
                    .block-title {
                        margin-bottom:0!important;
                        font-size:20px;
                        padding:0 0 20px;
                        @media (max-width:$bp-tablet - 1) {
                            padding:0px 0 20px;
                        }
                    }

                    .block-content {
                        .box-title {
                            float: left;
                            margin-bottom: $account-spacer;
                        }

                        .box-actions {
                            margin-top: 0;
                            .action {
                                @include unset-underline;
                                display:inline-block;
                                margin-left:10px;
                                width:20px;
                                height:20px;
                                transform:translateY(-2px);
                                > span {
                                    @include h5-typography;
                                    color: $jpp-dark-blue;
                                    display:none;
                                }
                                svg {
                                    @include ease;
                                    width:20px;
                                    height:20px;
                                }
                                &:hover {
                                    svg {
                                        stroke:$jpp-orange;
                                    }
                                }
                            }
                        }

                        .box-content {
                            clear: both;
                        }
                    }

                    address {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 16px;

                        @media (min-width:$bp-laptop) {
                            margin-bottom:0;
                        }
                    }
                }
            }

            .box-reward-points {
                position:relative;
                .rewards-logo {
                    width:75px;
                    position:absolute;
                    top:18px;
                    right:18px;
                }
                .actions-toolbar {
                    display: flex;
                    @media (min-width: $bp-tablet) {
                        justify-content: flex-end;
                    }

                    &::before,
                    &::after {

                        content: none;
                    }

                    .primary,
                    .secondary {
                        flex-basis: calc(50% - 12px);
                        span {
                            @include smooth;
                            &:hover {
                                @include smooth;
                            }
                        }
                    }
                }

                .points {
                    color: $jpp-orange;
                    display: block;
                    font-size: 3.2rem;
                    font-weight: 800;
                    letter-spacing: 0;
                    line-height: 3.9rem;
                    margin-bottom: 20px;

                    small {
                        color: inherit;
                        font-size: 1.6rem;
                        font-weight: 800;
                        line-height: 2rem;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
