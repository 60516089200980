.sales-order-print {
    font-size: 16px;
    .logo {
        img {
            max-width: 25%;
            margin: 25px 0;
        }
    }
    .order-actions.bottom,
    .vcp-banner-wrapper,
    .top-bar,
    .jpplus-top-banner,
    .nav-sections {
        display: none;
    }
    .page-main {
        .columns {
            .main {
                width: 100%;
                margin: 0;
                float: none;
            }
        }
        .page.messages {
            display: none;
        }

        .page-title-wrapper {
            position: static;
            padding-left: 0;
            margin-left: 0;
            background: none;
            margin-bottom: 25px;
            padding-bottom: 25px;

            & > * {
                position: relative;
                z-index: 2;
            }

            .page-title {
                transform: none;
                margin: 0;
                padding: 0;
                font-size: 2rem;
            }

            .base {
                color: $black;
            }

            .order-status {
                display: block;
            }

            .order-date {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .order-totals {
            max-width: 500px;
            float: right;
            clear: left;
            width: 100%;
        }
        .order-details-items {
            border: 0;
        }

        .gauge-order-top {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            .block-order-details-view {
                width: 70%;
                margin-bottom: 12px;
                background: $white;

                .block-content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .box {
                        flex: 0 0 50%;
                        padding: 25px 20px 0;
                    }

                    .box-title {
                        text-transform: uppercase;
                    }
                }
            }

            .customer-container.account-card.order-overview {
                width: 30%;
                margin-left: 10px;

                dl {
                    dt {
                        float: left;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .block-order-details-view,
        .customer-container,
        .gauge-order-special-instructions,
        .gauge-order-top-ship-info {
            box-shadow : none;
            border: 1px solid rgba(0, 0, 0, .10);
        }

        .block-order-details-view,
        .account-card order-overview {
            margin-top: 0;
            margin-bottom: 0;
        }


        .gauge-order-special-instructions,
        .gauge-order-top-ship-info {
            margin-bottom: 12px;
            .special-content-wrapper,
            .top-ship-info-wrapper {
                width: 100%;
                padding: 20px;
                background: $white;


                .table-special-instructions,
                .table-top-ship-info {
                    th.col {
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    td.col-content {
                        min-height: 20px;
                    }
                }
            }
        }
        #order-print-totals {
            th {
                font-weight: 500;
            }
            td,
            th {
                &.grand_total-content,
                &.col-grand_total {
                    border-bottom: none;
                    &,
                    span,
                    strong {
                        font-weight: 700;
                    }
                }
            }
        }
        #my-orders-table {
            table-layout: fixed;
            font-size: 16px;
            p,
            span {
                font-weight: 400;
            }
            .col {
                &.name {
                    width: 50%;
                    .image {
                        display: table-cell;
                        max-width: 75px;
                        border: 1px solid rgba(0, 0, 0, .075);
                        img {
                            height: auto;
                        }
                    }
                    .info {
                        display: table-cell;
                        padding: 0;
                        vertical-align: top;
                        padding-left: 12px;

                    }
                }
                &.sku {
                    width: 15%;
                }
                &.qty {
                    width: 10%;
                }
                &.price {
                    width: 25%;
                    .unit-price,
                    .subtotal-price {
                        display: block;
                        clear: both;
                    }
                    .blue {
                        color: $jpp-off-black;
                    }
                }
            }
        }

        .customer-container-title {
            display: none;
        }
    }
}
