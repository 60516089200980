/******************** NAV ********************/
.nav-sections{
    margin-bottom: 0px !important;
    background-color: #fff;
    @media screen and (max-width: 768px){
        z-index:5000;
    }
}
    #nav {
        display: flex;
        height: 48px;
        justify-content: center;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        z-index: 103;
        position:relative;
    }

    #nav > .wrapper {
        display: flex;
        position: relative
    }

    #nav .menu-item {
        padding: 16px 0;
        position: relative;
        z-index: 101;
        cursor: pointer;
        display: inline-block;
        text-decoration: none !important;
    }

    #vue-product-guide-nav {
        width: 160px;
        display: block;
        align-items: center;
        justify-content: center;
        .dropdown{
            a{
                text-decoration:none!important;
            }
        }
    }

    #nav .menu-item .img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center
    }

    @media(max-width: 991px) {
        #nav .menu-item .img-wrapper {
            top: 0 !important
        }
    }

    #nav .menu-item span {
        font-size: 14px;
        line-height: 14px;
        color: #353535;
        font-weight: 700;
        transition: 0.24s;
        position: relative;
        align-items: center;
        white-space: nowrap
    }

    #nav .wrapper .menu-item::after {
        transition: all .24s ease, height 0s;
        transition-delay: 0s;
        background-color: transparent;
        content: '';
        display: block;
        height: 2px;
        opacity: 0;
        position: absolute;
        width: 100%;
        z-index: auto
    }

    #nav .level1trigger:hover .menu-item::after {
        background-color: #f58220;
        opacity: 1
    }

    @media(max-width: 991px) {
        #nav .wrapper .menu-item::after {
            display: none
        }
    }

    #vue-product-guide-nav .img-wrapper span {
        color: #fff !important;
        text-shadow: 0 0 2px rgba(2, 2, 2, .2), 0 0 3px rgba(2, 2, 2, .18), 0 0 3px rgba(2, 2, 2, .18), 0 0 4px rgba(2, 2, 2, .18);
        z-index: 102;
        margin-bottom: 0
    }

    #nav .menu-item .icon {
        color: rgb(255, 255, 255);
        height: 22px;
        line-height: 20px;
        list-style-type: none;
        margin-right: 4px;
        position: relative;
        width: 22px;
        transform: matrix(0.9, 0, 0, 0.9);
        white-space: nowrap;
        z-index: 102;
        overflow-x: hidden;
        overflow-y: hidden;
        text-align: left;
        fill: rgb(255, 255, 255);
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.2)) drop-shadow(0 0 1px rgba(0, 0, 0, 0.2)) drop-shadow(0 0 1px rgba(0, 0, 0, 0.2)) drop-shadow(0 0 1px rgba(0, 0, 0, 0.2));
        font-weight: 700;
        font-size: 13px
    }

    #nav .menu-item .bg {
        background: linear-gradient(120deg, #52ccff 0%, #003fe6 100%);
        position: absolute;
        inset: 0;
        z-index: 101;
        opacity: 1;
        color: #fff !important;
        white-space: nowrap
    }

    #nav .menu-item .bg::after {
        position: absolute;
        content: '';
        inset: 0;
        z-index: -1;
        background-size: cover;
        opacity: 0.58
    }

    #nav .valentines .bg {
        background: linear-gradient(120deg, #ffa2a2 0%, #b10019 100%)
    }

    #nav .valentines .bg::after {
        background: url("https://d2we78jv268onp.cloudfront.net/mainmenu/product-guides/valentines-hearts.gif")
    }

    #nav .wedding .bg {
        background: linear-gradient(120deg, #F0E9FF 0%, #C9C1FF 100%)
    }

    #nav .wedding .bg::after {
        background: url("https://d2we78jv268onp.cloudfront.net/mainmenu/product-guides/valentines-hearts.gif")
    }

    #nav .spring .bg, #nav .summer .bg {
        background: linear-gradient(120deg, #52ccff 0%, #003fe6 100%)
    }

    #nav .spring .bg::after, #nav .summer .bg::after {
        background: url("https://d2we78jv268onp.cloudfront.net/mainmenu/product-guides/cloud-background-2.gif")
    }

    #nav .fall .bg {
        background: linear-gradient(120deg, #dbb466 0%, #db8166 100%)
    }

    #nav .fall .bg::after {
        background: url("https://d2we78jv268onp.cloudfront.net/mainmenu/giftguides/fall-background.gif")
    }

    #nav .holiday .bg {
        background: linear-gradient(120deg, #addfff 0%, #e65aff 100%)
    }

    #nav .holiday .bg::after {
        background: url("https://d2we78jv268onp.cloudfront.net/mainmenu/product-guides/snow-background.gif")
    }

    #vue-product-guide-nav .dropdown {
        width: 900px;
        height: 400px;
        z-index: 111;
        background: #fff;
        padding: 24px 0;
        display: flex;
        column-gap: 24px;
        position: absolute;
        right: 0;
        top: 100%;
        justify-content: center;
        line-height: 24px;
        text-align: left;
        box-shadow: 0 40px 40px rgba(0, 0, 0, .35);
        border-top: 1px solid #f2f2f2;
        transition-delay: 200ms;
        transition: opacity .2s;
        pointer-events: none;
        opacity: 0
    }

    #vue-product-guide-nav:hover .dropdown {
        opacity: 1;
        pointer-events: auto
    }

    #vue-product-guide-nav .featured-col {
        display: flex;
        flex-direction: column;
        gap: 18px
    }

    #vue-product-guide-nav .featured-guide {
        position: relative;
        height: 100%;
        min-height: 154px;
        width: 300px
    }

    #vue-product-guide-nav .featured-guide {
        background: linear-gradient(120deg, #7dd3ff 0%, #ebf7ff 100%);
        display: flex;
        justify-content: center
    }

    #vue-product-guide-nav .featured-guide a {
        text-decoration: none;
        font-weight: 800;
        color: #FFFFFF;
        height: 100%;
        transition: 0.18s ease;
        border: 1px solid transparent;
        width: 100%
    }

    #vue-product-guide-nav .featured-guide a label {
        position: absolute;
        bottom: 0;
        left: 12px;
        transition: 0.18s ease;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 800;
        cursor: pointer
    }

    #vue-product-guide-nav .featured-guide a:hover {
        border: 1px solid #0088ce
    }

    #vue-product-guide-nav .featured-guide a:hover label {
        color: #0088ce
    }

    #vue-product-guide-nav .featured-guide a img {
        height: 175px;
        position: absolute;
        top: 45%;
        left: 71%;
        transform: translate(-50%, -50%);
        filter: drop-shadow(0 0 12px rgba(2, 2, 2, 0.12))
    }

    #vue-product-guide-nav .dyn-guides {
        width: 100%;
        height: 100%;
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        row-gap: 24px
    }

    #vue-product-guide-nav .guide {
        display: flex;
        position: relative;
        width: 100%;
        background: #f5f5f5;
        height: 100%;
        border: 1px solid transparent;
        transition: 0.18s ease;
        cursor: pointer
    }

    #vue-product-guide-nav .guide .image-area img {
        position: absolute;
        height: 66px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }

    #vue-product-guide-nav .guide label {
        color: #000;
        font-weight: 800;
        font-size: 14px;
        transition: 0.18s ease;
        text-transform: uppercase;
        cursor: pointer;
        align-self: center;
        margin-left: 8px
    }

    #vue-product-guide-nav .seasonal-guides {
        display: flex;
        width: calc(100% - 378px);
        height: 100%;
        align-self: flex-start;
        column-gap: 24px
    }

    #vue-product-guide-nav .dynamic-guides, #vue-product-guide-nav .static-guides {
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: 24px
    }

    #vue-product-guide-nav .guide:hover {
        border: 1px solid #0088ce;
        background: #fff
    }

    #vue-product-guide-nav .guide:hover a label {
        color: #0088ce
    }

    #vue-product-guide-nav .guide a {
        width: 100%;
        display: flex
    }

    #vue-product-guide-nav .guide .image-area {
        position: relative;
        width: 86px;
        height: 100%
    }

    #vue-product-guide-nav .image-area img {
        position: absolute;
        height: 66px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }

    #vue-product-guide-nav .hidden {
        visibility: hidden
    }

    @media(min-width: 992px) and (max-width: 1510px) {
        #nav .menu-item .type-reducer-priority1 {
            display: none
        }
    }
    @media(min-width: 992px) and (max-width: 1430px) {
        #nav .menu-item .type-reducer-priority2 {
            display: none
        }
    }
    @media(min-width: 992px) and (max-width: 1320px) {
        #nav .menu-item .type-reducer {
            display: none
        }
    }
    @media(min-width: 992px) and (max-width: 1150px) {
        #nav .menu-item span {
            font-size: 13px
        }
        .level1trigger {
            margin-right: 14px !important
        }
    }
    @media(min-width: 992px) and (max-width: 1055px) {
        .level1trigger {
            margin-right: 13px !important
        }
        #nav #vue-product-guide-nav {
            width: 125px
        }
    }
    @media(min-width:992px) and (max-width:1300px){
        #nav .wrapper .level1trigger:nth-child(10) .megamenu-category{
            right:5px;
        }
    }
    @media(min-width: 992px) {
        .mobile-only {
            display: none !important
        }
        #nav::before {
            background: rgba(0, 0, 0, 0.12);
            position: absolute;
            top: 100%;
            width: 100%;
            height: 100vh;
            left: 0;
            content: "";
            transition: 0.4s ease-in-out;
            opacity: 0;
            backdrop-filter: blur(4px);
            transition-delay: 0s
        }
        #nav.active::before {
            transition: 0.8s ease-in-out;
            opacity: 1;
            transition-delay: 0.18s
        }
        #nav:not(.active)::before {
            pointer-events: none
        }
        .level1trigger {
            margin-right: 18px
        }
        #nav .level1trigger:hover:not(#vue-product-guide-nav) a:not(.black-friday) span {
            color: #0088CE !important;
        }
        #nav .menu-item .orange {
            color: #f58220;
        }
        #nav .menu-item .blue {
            color: #0088ce;
        }
        #nav .level1trigger:hover span::before {
            opacity: 1
        }
        #vue-product-guide-nav {
            margin-right: 0 !important
        }
        #nav .level1trigger:not(.active) .megamenu-category {
            display: none
        }
        #nav .menu-item {
            width: 100%;
            padding: 14px 0;
            max-height: 100%
        }
        #nav .column {
            height: 100%;
            margin: 0 24px
        }
        #nav .column:nth-last-of-type(2) {
            margin-right: 24px
        }
        #nav .column.image {
            display: flex;
            width: 240px;
            overflow: hidden;
            margin-left: 0;
            justify-content: center
        }
        #nav .column.image img {
            height: 100%
        }
        #nav .level-three-wrapper {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            align-self: flex-start
        }
        #nav .level-three-wrapper img {
            display: inline-flex;
            width: 21px;
            height: 21px;
            border: 1px solid #ededed;
            border-radius: 100%;
            top: 5px;
            object-fit: cover;
            object-position: center center;
            opacity: 0.5
        }
        .megamenu-category {
            position: absolute;
            display: flex;
            height: 400px;
            top: 100%;
            border-top: 1px solid #f2f2f2;
            box-shadow: 0 40px 40px rgba(0, 0, 0, .35);
            z-index: 5000;
        }
        .megamenu-category .sub-menu {
            background: #f5f5f5;
            display: flex;
            flex-direction: column;
            min-width: 256px;
            padding: 16px 0 16px 16px;
            z-index: 102
        }
        .megamenu-category .sub-menu a {
            height: 36px;
            line-height: 36px;
            user-select: none;
            font-size: 14px;
            font-weight: 700;
            background: transparent;
            transition: 0.18s ease;
            color: #000;
            opacity: 0.68;
            padding-left: 12px;
            white-space: nowrap;
            text-decoration: none !important;
        }
        .megamenu-category .sub-menu a.active {
            background: #fff;
            color: #0088CE;
            opacity: 1;
            box-shadow: -2px 0 6px rgba(2, 2, 2, 0.08)
        }
        .megamenu-category .sub-panel {
            height: 100%;
            display: inline-flex;
            z-index: 103;
            background-color: #fff
        }
        .megamenu-category .sub-panel:not(.active) {
            display: none
        }
        .megamenu-category .sub-panel.no-children {
            padding: 0
        }
        .megamenu-category .sub-panel a {
            color: #353535;
            font-size: 14px;
            padding-bottom: 6px;
            transition: 0.24s;
            white-space: nowrap;
            text-overflow: ellipsis;
            align-self: flex-start;
            width: 100%;
            text-decoration: none !important;
        }
        #nav .level-three-wrapper a:last-of-type {
            padding-bottom: 0
        }
        .megamenu-category .sub-panel a.level-three {
            font-weight: 700
        }
        .megamenu-category .sub-panel a:hover {
            color: #0088ce
        }
    }
    @media(max-width: 991px) {
        .desktop-only {
            display: none !important
        }
        #header > .page-header {
            padding-top: 18px;
            padding-bottom: 4px
        }
        #header .logo {
            padding-bottom: 15px
        }
        #header .phone {
            font-weight: 800;
            font-size: 14px;
            color: rgb(0, 136, 206);
            transition: color .2s;
            white-space: nowrap;
            padding-top: 11px;
            padding-right: 4px
        }
        #header .phone:hover {
            color: #f58220
        }
        #search-bar {
            flex-basis: 100%;
            margin: 0 0 16px 0
        }
        .page-header .header-links-wrapper svg {
            height: 32px;
            max-width: 36px
        }
        .cart-counter {
            position: absolute;
            right: -35px;
            bottom: -6px;
            margin-bottom: 0
        }
        .cart-meta .counter-number {
            display: inline-block;
            transform: translateX(-7px) translateY(-7px) scale(0.8);
            text-align: center;
            font-size: 10px;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 2px solid #fff;
            line-height: 18px;
            background: #0088ce;
            color: #fff;
            position: relative;
            margin-bottom: 0
        }
        .cart-meta .counter-number::after {
            display: none
        }
        .cart-meta .parenthesis {
            display: none
        }
        .nav-toggle {
            transform: translateY(-7px)
        }
        .nav-toggle span {
            display: block;
            width: 25px;
            height: 4px;
            border-radius: 2px;
            margin: 0 0 2px;
            background-color: rgb(0, 136, 206);
            transition: background-color .2s
        }
        .nav-toggle:hover span {
            background-color: #f58220
        }
        #nav {
            z-index: 5000;
            position: fixed;
            width: 100%;
            height: 100vh;
            left: 0;
            top: 0;
            border: none;
            pointer-events: none
        }
        #nav.active {
            pointer-events: auto
        }
        #nav.active::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 1px;
            background: rgba(2, 2, 2, 0.5);
            z-index: 0;
            transition: 0.94s ease;
            transition-delay: 0.2s;
            pointer-events: auto;
            transform: translateZ(0);
            backdrop-filter: blur(4px)
        }
        #nav::after {
            display: none
        }
        #nav > .wrapper {
            position: absolute;
            width: 360px;
            max-width: 85vw;
            height: 100%;
            left: 0;
            transition: 0.74s ease;
            transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transform: translateX(-100vw);
            pointer-events: none;
            background: #f5f5f5;
            flex-direction: column;
            overflow: hidden auto;
            z-index: 103;
            padding-bottom: 40px
        }
        #nav.active > .wrapper {
            transform: translateX(0);
            pointer-events: auto
        }
        #nav .wrapper > label {
            font-size: 18px;
            font-weight: 800;
            margin: 16px
        }
        #nav .menu-item {
            height: 48px;
            border: 1px solid #ECECEC;
            background: #fff;
            padding: 0 10px;
            margin: 0 16px 8px;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            line-height: 48px;
            color: #000
        }
        #nav .menu-item span {
            font-size: 16px;
            font-weight: 700;
            line-height: 48px
        }
        #vue-product-guide-nav {
            width: 100%
        }
        /*level 2*/
        #mobilemenu .panel {
            position: fixed;
            width: 360px;
            max-width: 85vw;
            height: 100%;
            left: 0;
            transition: 0.74s ease;
            transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transform: translateX(-100vw);
            pointer-events: none;
            background: #f5f5f5;
            flex-direction: column;
            overflow: hidden auto;
            padding-bottom: 40px
        }
        #mobilemenu .panel.active {
            transform: translateX(0);
            pointer-events: auto
        }
        #mobilemenu .panel-2 {
            z-index: 104
        }
        #mobilemenu .panel-3 {
            z-index: 105
        }
        #mobilemenu .panel-4 {
            z-index: 106
        }
        #mobilemenu .category:not(.active) {
            display: none
        }
        #mobilemenu .panel > header {
            display: flex;
            margin: 4px
        }
        #mobilemenu .panel > header a {
            font-size: 18px;
            font-weight: 800;
            color: #000;
            padding: 12px;
            text-decoration: none !important;
        }
        #mobilemenu .panel > header a span {
            font-weight: 800
        }
        #mobilemenu .panel > header a:last-of-type {
            margin-left: auto
        }
    }

    .arrow-left::before {
        content: '\2039';
        font-size: 24px;
        line-height: 18px;
        padding-right: 4px
    }

    .arrow-right::after {
        content: '\203A';
        font-size: 24px;
        line-height: 18px;
        padding-left: 4px
    }

    .breadcrumbs {
        background-color: #f2f6fb;
        height: 48px;
        max-width: 100%;
        padding: 0;
        width: 100%;
        margin: 0 0 20px;
    }

    .breadcrumbs .items {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;
        max-width: 92%;
        width: 1500px;
        font-size: 1.2rem;
        color: #a3a3a3;
        list-style: none none
    }

    .breadcrumbs .items .breadcrumbs-item:not(:last-child) {
        display: inline-block;
        text-decoration: none
    }

    .breadcrumbs .items .breadcrumbs-item {
        font-size: 12px;
        font-weight: 500;
        line-height: 48px;
        text-transform: uppercase
    }

    .breadcrumbs .items .breadcrumbs-item a {
        color: #6d6d6d;
        font-size: 12px;
        text-decoration: none !important
    }

    .breadcrumbs .items .breadcrumbs-item:not(:last-child)::after {
        color: #353535;
        content: '/';
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        margin-bottom: 2px;
        padding: 0 5px;
        vertical-align: middle
    }

    .breadcrumbs .items .breadcrumbs-item strong {
        color: #0088ce
    }

    .breadcrumbs .items > li {
        display: inline-block;
        vertical-align: top
    }

    .breadcrumbs a {
        color: #333;
        text-decoration: none
    }

    .breadcrumbs a:visited {
        color: #333;
        text-decoration: none
    }

    .breadcrumbs a:hover {
        color: #333;
        text-decoration: underline
    }

    .breadcrumbs a:active {
        color: #333;
        text-decoration: none
    }

    .breadcrumbs strong {
        font-weight: 400
    }
.jpp-nav-overlay {
    background-color: $black;
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: .3s opacity ease;
    width: 100vw;
    z-index: 150;

    &.nav-open {
        opacity: .5;
        pointer-events: all;
        transition: .3s opacity ease;
    }
    @media (min-width: $bp-laptop) {
        display: none;
    }
}

