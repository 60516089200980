img {
    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        @include smooth;
        opacity: 1;
    }
}
