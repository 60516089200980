.avatax-index-index {

    h4 {
        @include h5-typography;
        font-size:20px;
        margin-top:0;
    }
    .form-tax-exempt {
        .message {
            > *:first-child {
                &::before {
                    content: none;
                }
            }
        }

        .button-upload-container {
            margin: 20px 0 0;
        }
    }

    #sns_main { // sass-lint:disable-line no-ids
        #tax-form { // sass-lint:disable-line no-ids
            margin-top: 20px;

            &.collapse {
                display: none;
            }
        }

        .fieldset {
            margin-bottom: 0;

            .mage-error[required] { // sass-lint:disable-line force-attribute-nesting
                border-color: $jpp-error-red;
            }

            .mage-error[generated] { // sass-lint:disable-line force-attribute-nesting
                left: 0;
                margin-top: 0;
            }

            select {
                background: $jpp-off-off-white;
                border: 1.5px solid $jpp-off-off-white;
                color: $light-grey;

                &.altered {
                    border-color: $jpp-success-green;
                    color: $jpp-off-black;
                }
            }

            input[type='file'] { // sass-lint:disable-line force-attribute-nesting no-qualifying-elements
                clip: rect(0 0 0 0);
                display: block;
                height: 25px;
                left: 0;
                position: absolute;
                top: 0;
                width: 66px;
            }

            #avatax-selected-files { // sass-lint:disable-line no-ids
                span {
                    clear: both;
                    display: block;
                    font-size: 1.2rem;
                    line-height: 1.4rem;

                    &:first-child {
                        margin-top: 10px;
                    }

                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }

            .field.no-expiration {
                input {
                    margin-top: 0;
                    margin-right: 10px;
                }
                label {
                    span {
                        font-size: 13px;
                        margin: 2px 0;
                    }
                }
                &:before {
                    content: none;
                }
            }
        }

        .actions-toolbar {
            margin-left: 0;
            margin-top: 0;
            padding-top: 20px;
        }

        span.tooltip { // sass-lint:disable-line force-element-nesting no-qualifying-elements
            position: relative;
            z-index: auto;
        }

        a[data-toggle='collapse'] { // sass-lint:disable-line force-attribute-nesting no-qualifying-elements
            & + .collapse {
                background-color: #c4e3f3; // sass-lint:disable-line no-color-literals
                border: 1.5px solid $jpp-dark-blue;
                color: $jpp-dark-blue;
                display: none;
                left: calc(100% + 10px);
                margin: 0;
                min-width: 150px;
                padding: 5px;
                position: absolute;
                top: 0;
                z-index: 1;

                .collapsed {
                    display: none;
                }

                span,
                p {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    margin: 0;
                }
            }

            &:hover {
                & + .collapse {
                    display: block;
                }
            }
        }
    }
}
