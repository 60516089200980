// sass-lint:disable no-important
.cms-dremel {
    .main {
        .jpp-header {
            .column {
                .image {
                    img {
                        max-width: 100% !important;
                    }
                }

                &:first-of-type {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }
    }
}
