.blog-page {
    .post-holder {
        .post-content {
            .youtube-embed {
                position: relative;
                z-index: auto;
                width: 100%;
                padding-bottom: 56.25%;
                margin-top: 24px;
                margin-bottom: 24px;
                iframe {
                    position: absolute;
                    z-index: auto;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
