div.home-feature-pages-block {
    display:flex;
    padding:4px 0 128px;
    width:1500px;
    max-width:92vw;
    margin-left:auto;
    margin-right:auto;

    //Was 1600?
    @media (max-width:1400px) {
        width:1000px;
        max-width:92vw;
    }
    @media (max-width:1400px) {
        flex-direction:column;
    }
    @media (max-width:991px) {
        padding:4px 4vw 96px;
        max-width:100%;
    }
    @media (max-width:991px) {
        padding:4px 20px 96px;
    }
    @media (max-width:480px) {
        padding:4px 16px 64px;
    }
    .overview {
        width:426px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        background:#0088ce;
        background:linear-gradient(135deg, #0088ce, #00679c);
        padding:32px 52px 48px;
        position:relative;
        margin-right:8px;
        @media (min-width:1401px) {
            min-height:519px;
        }
        @media (min-width:1800px) {
            min-height:533px;
        }
        @media (max-width:1400px) {
            width:100%;
            align-self:flex-start;
            margin-right:0;
        }
        @media (max-width:991px) {
            padding:16px 16px 32px;
        }
        &:before {
            position:absolute;
            content:'';
            bottom:-1px;
            left:-1px;
            right:-1px;
            height:17px;
            background:#fff;
            pointer-events:none;
            z-index:2;
            @media (max-width:1400px) {
                height:16px;
            }
            @media (max-width:660px) {
                height:12px;
            }
        }
        h2 {
            font-size:32px;
            text-transform:none;
            margin-top:0;
            color:#fff;
        }
        span {
            color:#fff;
            font-size:13px;
            margin-bottom:4px;
            @media (max-width:991px) {
                font-size:12px;
            }
        }
        @media (max-width:1400px) {
            h2 {
                margin-top:24px;
                margin-bottom:0;
            }
            span {
                margin-top:18px;
                margin-bottom:8px;
            }
            h2, span {
                text-align:center;
            }
        }

        .toggle-wrapper {
            display:flex;
            flex-direction:column;
            @media (max-width:1400px) {
                flex-direction:row;
                justify-content:center;
            }
            @media (max-width:991px) {
                flex-wrap:wrap;
                width:250px;
                margin-left:auto;
                margin-right:auto;
            }
            a.toggle {
                margin-bottom:8px;
                border-radius:20px;
                background:#004F9D;
                font-size:16px;
                color:#fff;
                text-decoration:none!important;
                text-align:center;
                transition:0.18s ease;
                height:38px;
                line-height:38px;
                align-self:flex-start;
                padding:0 24px;
                font-weight:700;
                cursor:pointer;
                user-select:none;

                @media (max-width:1400px) {
                    margin-right:8px;
                    font-size:14px;
                    height:34px;
                    line-height:34px;
                    &:last-of-type {
                        margin-right:0;
                    }
                }
                @media (max-width:991px) {
                    height:30px;
                    line-height:30px;
                    padding:0 14px;
                    font-size:12px;
                }
                @media (max-width:991px) {
                    width:calc(50% - 4px);
                    &:nth-of-type(2n) {
                        margin-right:0;
                    }
                }
                &:not(.active):hover {
                    filter:brightness(1.08);
                }
                &.active {
                    background:#fff;
                    color:#004F9D;
                }
            }
        }
        a.browse-all {
            text-decoration:none!important;
            font-weight:800;
            color:#fff;
            align-self:flex-start;
            margin-top:22px;
            font-size:14px;
            @media (max-width:1400px) {
                margin-left:auto;
                margin-right:auto;
                margin-bottom:14px;
            }
            @media (max-width:991px) {
                margin-top:18px;
                margin-bottom:0;
            }
        }
    }
    .feature-page-links {
        display:flex;
        flex-wrap:wrap;
        flex:1;
        //justify-content:center;
        position:relative;
        @media (min-width:1401px) {
            max-height:533px;
        }
        @media (max-width:1400px) {
            min-width:calc(100% + 16px);
            transform:translateX(-8px);
        }
        &:not(.flickity-enabled) {
            opacity:0;
            pointer-events:none;
        }
        .flickity-viewport {
            width:100%;
        }
        .link-page {
            display:flex;
            flex-wrap:wrap;
            flex:1;
            //justify-content:center;
            width:100%;
            position:relative;
            padding:0 8px;
        }
        .tile {
            width:calc(25% - 12px);
            margin-right:16px;
            margin-bottom:16px;
            position:relative;
            border-bottom-left-radius:4px;
            border-bottom-right-radius:4px;
            transition:0.18s ease;
            &:nth-of-type(4n) {
                margin-right:0;
            }
            &:last-of-type {
                margin-right:0!important;
            }
            &:hover {
                box-shadow:0px 0px 12px rgba(2,2,2,0.12);
                filter:brightness(1.04);
                a {
                    background:#fff;
                    span {
                        //color:#F57F29;
                    }
                }
            }

            @media (max-width:991px) {
                width:calc(33.333% - 10.666px);
                &:nth-of-type(4n) {
                    margin-right:16px;
                }
                &:nth-of-type(3n) {
                    margin-right:0;
                }
            }
            @media (max-width:660px) {
                width:calc(50% - 8px);
                margin-right:12px;
                margin-bottom:12px;
                &:nth-of-type(3n) {
                    margin-right:12px;
                }
                &:nth-of-type(2n) {
                    margin-right:0;
                }
            }

            a {
                text-decoration:none!important;
                font-size:14px;
                font-weight:800;
                display:block;
                width:100%;
                padding-bottom:100%;
                transition:0.18s ease;
                background:transparent;
                overflow:hidden;
                position:relative;

                img {
                    width:100%;
                    background:#222222ad;
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    z-index:2;
                    max-height:calc(100% - 40px);
                }
                span {
                    color:#fff;
                    font-size:14px;
                    font-weight:800;
                    text-align:center;
                    position:absolute;
                    left:0;
                    right:0;
                    bottom:0;
                    line-height:40px;
                    z-index:3;
                    transition:0.18s ease;
                    display:block;
                    background:transparent;

                    @media (max-width:660px) {
                        font-size:12px;
                    }
                    @media (max-width:400px) {
                        font-size:10px;
                    }
                    &:before, &:after {
                        position:absolute;
                        content:'';
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        background:linear-gradient(135deg, #0088ce, #00679c);
                        z-index:-2;
                        pointer-events:none;
                        transition: 0.18s ease;
                    }
                    &:after {
                        background:#fff;
                        opacity:0;
                        z-index:-1;
                        pointer-events:none;
                        display:none;
                    }
                }
                &:hover {
                    span:after {
                        opacity:1;
                    }
                    span:before {
                        filter:brightness(1.1);
                    }
                }
            }
        }

        .flickity-button {
            position:absolute;
            top:calc(50% - 8px);
            transform:translateY(-50%);
            border-radius:100%;
            background:#0088ce;
            border:4px solid #fff;
            @media (max-width:1660px) {
                transform:translateY(-50%) scale(0.8);
            }
            &:disabled {
                opacity:0;
            }
            svg {
                width:18px;
                height:18px;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                path {
                    color:#fff;
                }
            }
    
            &.previous {
                left:-15px;
                //display:none;
                @media (max-width:1660px) {
                    left:-12px;
                }
                @media (max-width:660px) {
                    left:-10px;
                }
            }
            &.next {
                right:-15px;
                @media (max-width:1660px) {
                    right:-12px;
                }
                @media (max-width:660px) {
                    right:-10px;
                }
            }
        }
        .flickity-page-dots {
            bottom:-28px;
            .dot {
                height:20px;
                width:20px;
                border:1.5px solid #0088ce;
                background:transparent;
                opacity:0.8;
                transition:0.18s ease;
                &.is-selected {
                    background:#0088ce;
                    opacity:1;
                }
            }
        }
    }
}