a,
.link,
.alink {
    @include link;
    @include font-normal;
    font-size: 16px;
    line-height: 20px;

    &.white {
        color: $white;

        &:visited {
            color: $white;
        }
    }
}
.messages {

    a,
    .link,
    .alink {
        font-size: 13px;
    }
}
