.page-header {
.minicart-wrapper{
    float: right;
    padding: 0 10px;
    position: relative;
    z-index: 150;
    order:4;
    margin-right:0;
    margin-left:12px;
    margin-top: -10px;
    @media (max-width:1200px) {
        margin-left:0;
        margin-top: -14px;
    }
    a {
        text-decoration: none !important;
        color: #222 !important;
    }
}
    .minicart-wrapper .block-minicart {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none none;
        background: #fff;
        border: 0 solid transparent;
        min-width: 100%;
        width: 320px;
        z-index: 101;
        box-sizing: border-box;
        position: absolute;
        top: calc(100% - 4px);
        right: 0;
        box-shadow: 0 8px 24px rgba(0, 0, 0, .5);
        transition: all .2s ease, height 0s;
        transform: translateY(10px);
    }

    .minicart-wrapper .block-minicart.active {
        transform: translateY(0px);
        transition-delay: 0.12s;
        transition: all .2s ease, height 0s
    }

    .minicart-wrapper .block-minicart .minicart-content-wrapper {
        order: 1;
        padding: 0 0 10px;
        width: 100%
    }
    .minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
        content: none !important;

    }
    .minicart-wrapper .minicart-items-wrapper {
        border: 0;
        margin: 0;
        padding: 0;
        overflow-x: inherit !important
    }

    .minicart-items-wrapper {
        border: 1px solid #ccc;
        margin: 0 -20px;
        border-left: 0;
        border-right: 0;
        overflow-x: auto;
        padding: 15px
    }

    .minicart-wrapper .actions-toolbar{
        text-align: right;
        margin-right: 10px;
        p{
            font-size:13px;
        }
    }

    .minicart-wrapper .block-minicart .subtitle.empty {
        padding: 15px 15px 6px
    }

    .minicart-wrapper .minicart-items-wrapper > ol {
        padding: 14px 10px 0
    }

    .minicart-items {
        margin: 0;
        padding: 0;
        list-style: none none
    }

    .minicart-wrapper .minicart-items-wrapper > ol li:only-of-type {
        margin-bottom: 0
    }

    .minicart-items .product-item:first-child {
        padding-top: 0
    }

    .minicart-wrapper .block-minicart li {
        margin: 0
    }

    .minicart-wrapper .product-item {
        padding: 0
    }

    .product-item {
        font-size: 14px;
        vertical-align: top
    }

    .minicart-items .product-item > .product:before, .minicart-items .product-item > .product:after {
        content: '';
        display: table;
    }

    .minicart-wrapper .product-item .product .product-item-photo {
        border: 1px solid #ededed;
        margin-right: 12px;
        height: 70px;
        width: 70px;
        overflow: hidden;
        position: static
    }

    .minicart-items .product > .product-item-photo, .minicart-items .product > .product-image-container {
        float: left
    }

    .minicart-wrapper .product-item .product .product-item-photo .product-image-container {
        width: calc(100% - 10px) !important;
        display: block;
        margin: 0 auto;
    }

    .abs-reset-image-wrapper, .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper,
    .minicart-items .product-image-wrapper, .block-giftregistry-shared-items .product-image-wrapper {
        height: auto;
        padding: 0 !important;
    }

    .product-image-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    .abs-reset-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo,
    .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo,
    .block-giftregistry-shared-items .product-image-wrapper .product-image-photo {
        position: static;
    }

    .product-image-photo {
        bottom: 0;
        display: block;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    .minicart-wrapper .subtotal {
        margin-top: 20px !important;
    }

    .block-minicart .subtotal {
        margin: 0 10px 10px;
        text-align: right;
    }

    .minicart-wrapper .subtotal .label {
        float: left;
        font-size: 16px;
    }

    .minicart-wrapper .subtotal span {
        font-weight: normal;
        margin: 0;
    }

    .abs-colon:after, .block-minicart .subtotal .label:after, .minicart-items .details-qty .label:after,
    .minicart-items .price-minicart .label:after, .order-details-items .order-gift-message .label:after,
    .order-details-items .block-order-details-gift-message .label:after, .block-wishlist-management .wishlist-select .wishlist-name-label:after {
        content: ': ';
    }

    .minicart-wrapper .subtotal span.price-wrapper {
        font-weight: 800;
        color: #004f9d;
        font-size: 20px;
    }

    .minicart-wrapper .product-item-pricing {
        position: relative;
        margin-bottom: 20px;
        display:grid;
        grid-template-columns:auto auto 1fr;
    }

    .minicart-wrapper .product-item-pricing .qty-container, .minicart-wrapper .product-item-pricing .price-container {
        display: inline-block;
        line-height: 24px;
        vertical-align: top;
        left:3px;
    }

    .minicart-wrapper .product-item-details .actions {
        bottom: -3px;
        left: 83px;
        position: absolute;
    }

    .minicart-wrapper .product-item-details {
        padding-left: 84px;
        line-height: 16.8px;
    }

    .minicart-wrapper .product-item-name {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 0 !important;
    }

    .minicart-items .product-item-name {
        font-weight: 400;
        margin: 0 0 10px;
        display: block;
    }
    .minicart-items .action.delete:before {
        content: '' !important;
    }
    .minicart-items .action.delete svg path{
        fill:none !important;
    }
    .minicart-wrapper .product-item-name a {
        text-decoration: none !important;
        color: #353535;
        font-size: 14px;
        line-height: 16.8px;
    }

    .minicart-wrapper .product-item-details .actions .delete span {
        color:#777;
        font-size:70%!important;
        opacity:0.6;
        text-transform:lowercase;
        transition:0.18s ease;
        height: 100%;
        width: 100%;
        margin:0;
        position: relative;
    }

    .minicart-wrapper .product-item-details .actions .delete svg {
        color:#777;
        height:13px;
        width:14px;
        opacity:0.58;
        transition:0.18s ease;
        left:-5px;
        position: relative;
    }

    .minicart-wrapper .block-content > .actions > .primary .action.primary, .minicart-wrapper .block-content > .actions .secondary .action.primary {
        float: left;
        font-size: 13px;
        font-weight: normal;
        line-height: 14px;
        margin: 0 !important;
        padding: 10px !important;
        padding-top: 11px !important;
        width: 100%;
    }

    .action.primary {
        font-weight: 800;
        text-decoration: none !important;
        transition: all .24s ease, height 0s;
        transition-delay: 0s;
        border-radius: 0 !important;
        color: #fff;
        font-size: 14px !important;
        line-height: 100%;
        padding: 13px 25px !important;
        text-transform: uppercase;
        background-color: #0088ce !important;
        border: 1px solid #0088ce !important;
    }

    .minicart-wrapper .block-content > .actions {
        float: right;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0 10px;
        width: 50%;
    }

    .minicart-wrapper .block-content > .actions > .secondary::after {
        color: #f9f9f9;
        content: '\2192';
        margin-left: -37px;
        margin-top: 8px;
        position: absolute;
        pointer-events: none;
        line-height: 20px;
        font-size: 14px;
    }

    .minicart-wrapper .block-content > .actions > .secondary {
        text-align: left;
        width: 140px;
        height: 36px;
    }

    .minicart-wrapper .product-item-pricing span.price-wrapper {
        font-weight: 800;
        margin-left:6px;
        font-size: 16px;
    }

    .minicart-wrapper .product .actions {
        line-height:24px;
        justify-self:end;
        position: relative;
        text-align: left;
        top:0;
        left:0;
        bottom:0;
        margin:0;
        float:unset;
    }

    .minicart-wrapper .product-item-details .actions .delete {
        text-decoration:none!important;
        display:flex;
        align-items:center;
    }

    .minicart-wrapper .product .actions > .primary, .minicart-wrapper .product .actions > .secondary {
        display: inline;
    }

    .minicart-wrapper .actions .delete:hover span, .minicart-wrapper .actions .delete:hover svg {
        color: #353535;
        opacity: 0.88;
        transition: all .24s ease, height 0s;
        transition-delay: 0s;
    }

    .action.primary:hover, .action.primary:focus {
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
        transition: all .24s ease, height 0s;
        transition-delay: 0s;
        background-color: #1e95d4 !important;
        cursor: pointer;
    }

    .minicart-wrapper .block-content > .actions > .secondary button.action.primary {
        display: block;
        width: 140px;
        text-align: left;
        padding-left: 22px !important;
    }

    .minicart-wrapper .product-item-details .actions div:nth-of-type(2) {
        max-width:110px;
    }

    .minicart-wrapper .product-item-details .actions div:nth-of-type(2) > div {
        font-size:12px;
        transform:translateY(-4px);
        text-align:end;
    }

    .minicart-wrapper .product-item-details .actions div:nth-of-type(2) > div button:nth-of-type(1) {
        margin-left: 6px;
    }

    .minicart-wrapper .product-item-details .actions div:nth-of-type(2) > div button {
        border: 0;
        color: #fff;
        background: #0088ce;
        font-size: 10px;
        transition: 0.18s ease;
    }

    .minicart-wrapper .product-item-details .actions div div button {
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
        font-weight: 700;
        padding: 7px 15px;
        vertical-align: middle;
    }

    .minicart-freeshipping-container .jpp-loader {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.6);
        cursor: wait;
    }

    .minicart-freeshipping-container .jpp-loader span {
        color: #353535;
        font-size: 10px;
        line-height: 12px;
    }

    .block-minicart .subtitle.empty {
        display: block;
        font-size: 14px;
        padding: 30px 0 20px;
        text-align: center
    }

    .minicart-wrapper .block-minicart .subtitle.empty a {
        text-decoration: none !important;
        font-size: 14px;
        font-weight: normal
    }

    .minicart-wrapper .block-minicart .subtitle.empty a em {
        font-style: normal
    }

    .page-header .minicart-wrapper a {
        color: #222
    }

    .minicart-freeshipping-container {
        width: 100%
    }

    .minicart-wrapper .block-minicart .minicart-freeshipping {
        padding: 12px 10px 5px 50px
    }

    .minicart-freeshipping {
        background-color: #0088ce;
        box-sizing: border-box;
        order: 0;
        padding: 10px 10px 5px 50px;
        position: relative;
        width: 100%
    }

    .minicart-freeshipping .icon-free-shipping {
        color: #fff;
        left: 10px;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }

    .minicart-freeshipping .progress-bar {
        background-color: #0277b3;
        border-radius: 2px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, .16) inset;
        display: block;
        float: left;
        margin-bottom: 0;
        position: relative;
        width: 100%
    }

    .minicart-freeshipping .bar {
        background: linear-gradient(271deg, #00ea3e, #f5ff60);
        display: block;
        height: 14px;
        margin: 2px;
        transition: width .5s ease;
        width: 0
    }

    .minicart-wrapper .block-minicart .minicart-freeshipping .qualification-label {
        padding-top: 0;
        color: #fff;
        font-size: 14px
    }

    b, strong {
        font-weight: bolder
    }

    .minicart-freeshipping .qualification-label a {
        text-decoration: none !important;
        color: #fff !important;
    }

    .minicart-freeshipping .qualification-label * {
        color: #fff;
        font-size: 14px
    }

    .block p:last-child {
        margin: 0
    }

    #minicart-drop-down:not(.active) {
        opacity: 0;
        pointer-events: none
    }

    /********************* HEADER ICONS ********************/
    .header-links-wrapper {
        margin-top: -10px;
        display: flex;
        font-size: 12px;
        line-height: 24px
    }

    .page-header .header-links-wrapper .icon-container {
        text-decoration: none !important;
        display: inline-block;
        padding: 0 10px;
        text-align: center
    }

    .header-links-wrapper a {
        color: #222
    }

    .page-header .header-links-wrapper .icon {
        display: block;
        line-height: normal;
        margin: 0
    }

    .page-header .header-links-wrapper svg {
        height: 36px;
        width: auto;
        max-width: 40px;
        display: inline-block
    }

    @media(min-width: 991px) {
        .page-header .minicart-wrapper .cart-meta {
            line-height: 0;
            transform: translateX(-2px) translateY(-2px)
        }
    }
    @media(max-width: 991px) {
        .page-header .minicart-wrapper .jpp-cart:hover span.counter-number {
            background: #f58220 !important
        }
    }

    .header-links-wrapper .icon-container:hover svg path {
        fill: #f58220
    }

    .header-links-wrapper .icon-container:hover svg path.hover-stroke {
        stroke: #f58220
    }

    .minicart-wrapper{
        svg {
            height:36px;
            width:auto;
            max-width:40px;
            display:inline-block;
            transform:translateY(1px);
            @media (max-width:991px) {
                height:32px;
                max-width:36px;
            }
            path, rect {
                fill:$jpp-blue;
                transition:0.18s ease;
                &.no-fill {
                    fill:none!important;
                }
                &.white-stroke {
                    stroke:#fff!important;
                }
            }
        }
    }

    .page-header .minicart-wrapper .jpp-cart:hover svg path, .page-header .minicart-wrapper .jpp-cart:hover svg rect {
        fill: #f58220
    }

    .page-header .minicart-wrapper .jpp-cart:hover svg path.no-fill, .page-header .minicart-wrapper .jpp-cart:hover svg rect.no-fill {
        fill: none !important
    }

    .cart-meta {
        //display: none;
        display:block;

        @media (min-width: 991px) {
            line-height: 0;
            transform:translateX(-2px) translateY(-2px);
        }

        span {
            font-size: 12px;
            margin: 0;
            &.label {
                @media (max-width:991px) {
                    display:none;
                }
            }
            &.cart-counter {
                @media (max-width:991px) {
                    //background:#0088ce;
                    border-radius:100%;
                    height:16px;
                    width:16px;
                    line-height:16px;
                    border:1px solid #fff;
                    //bottom:2px;
                    bottom:3px;
                    right:-3px;
                    position:absolute;
                }
                @media(min-width: 991px){
                    bottom: 26px;
                }

                span.counter-number {
                    @media (max-width:991px) {
                        line-height:16px;
                        display:inline-block;
                        transform:translateX(-7px) translateY(-7px) scale(0.8);
                        width:100%;
                        text-align:center;
                        font-size:10px;
                        width:20px;
                        height:20px;
                        border-radius:100%;
                        border:2px solid #fff;
                        line-height:18px;
                        text-align:center;
                        background:#0088ce;
                        color:#fff;
                        position:relative;
                        transition:0.18s ease;
                    }
                    span {
                        font-size:inherit!important;
                        color:inherit!important;
                        @media (max-width:991px) {
                            height:20px;
                            display:block;
                            line-height:22px;
                            position:absolute;
                            top:50%;
                            left:50%;
                            transform:translateX(-50%) translateY(-50%);
                        }
                        &.parenthesis {
                            &:nth-of-type(1) {
                                margin-left: 3px;
                                margin-right: 1px;
                            }
                            &:nth-of-type(3) {
                                margin-left: 1.5px;
                                margin-right:2px
                            }
                            @media (max-width:991px) {
                                display:none;
                            }
                        }
                    }
                    &::after {
                        @media (max-width:991px) {
                            display:none;
                        }
                    }
                }
            }
        }

        .counter-number {
            @include icon-after('\e607', $jpp-off-black, 22px);
            color: $jpp-blue;
            position: relative;

            &::after {
                font-family: 'icons-blank-theme', serif;
                position: absolute;
                right: -15px;
                top: -4px;
            }
        }


    }

}
