#home-resources {
    background:linear-gradient(45deg, #004589 0%, #002951 100%);
    padding:88px 0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    overflow:hidden;
    @media (max-width:768px) {
        padding: 48px 0;
    }
    h2, p {
        color:#fff;
    }
    h2 {
        font-size:36px;
        margin-top:0;
        text-transform:none;
        text-align:center;
        padding:0 16px;
    }
    p {
        font-size:20px;
        line-height:24px;
        text-align:center;
        margin-bottom:24px;
        width:778px;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
        padding:0 16px;
        @media (max-width:991px) {
            width:526px;
        }
    }

    .resources-toggles {
        display:flex;
        justify-content:center;
        margin-bottom:48px;
        padding:0 16px;
        @media (max-width:768px) {
            flex-wrap:wrap;
            width:378px;
            max-width:100%;
            margin-left:auto;
            margin-right:auto;
        }
        @media (max-width:480px) {
            flex-direction:column;
            margin-bottom:32px;
            transform:scale(0.9);
        }
        .toggle {
            margin-right:16px;
            height:52px;
            background:#fff;
            border-radius:8px;
            text-decoration:none!important;
            width:190px;
            transition:0.2s ease;
            position:relative;
            font-size:18px;
            font-weight:700;
            line-height:52px;
            display:flex;
            padding:0 12px;
            border:1.5px solid transparent;
            cursor:pointer;
            user-select:none;
            @media (max-width:991px) {
                font-size:14px;
                line-height:40px;
                height:40px;
                padding:0 6px;
            }
            @media (max-width:768px) {
                margin-right:8px;
                width:calc(50% - 4px)!important;;
                &:nth-of-type(2n) {
                    margin-right:0;
                }
                &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
                    margin-bottom:8px;
                }
            }
            @media (max-width:480px) {
                padding: 0 5px;
                margin-left:auto!important;
                margin-right:auto!important;
                width:178px!important;
            }
            span {
                font-size:18px;
                font-weight:700;
                line-height:52px;
                transition:0.2s ease;
                @media (max-width:991px) {
                    font-size:14px;
                    line-height:40px;
                    height:40px;
                }
            }
            svg {
                margin-top:10px;
                margin-right:8px;
                @media (max-width:991px) {
                    margin-top:4px;
                    transform:scale(0.9);
                }
                @media (max-width:480px) {
                    margin-right:5px;
                }
                path, line, rect {
                    transition:0.2s ease;
                }
            }
            &:last-of-type {
                margin-right:0;
                width:230px;
                &:before {
                    position:absolute;
                    content:'';
                    pointer-events:none;
                    border-radius:8px;
                    background:linear-gradient(to right, #0088ce 0%, #00CEC7 100%);
                    z-index:0;
                    opacity:0;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    transition:0.18s;
                    border:1.5px solid transparent;
                }
            }
            &.webinars {
                color:#0088ce;
                &.active {
                    background:#0088ce;
                }
            }
            &.videos {
                color:#F57F29;
                &.active {
                    background:#F57F29;
                }
            }
            &.blogs {
                color:#004F9D;
                &.active {
                    background:#004F9D;
                }
            }
            &.vts {
                border:0px solid transparent!important;
                span {
                    color:#004F9D;
                    z-index:2;
                    background: -webkit-linear-gradient(#0088ce, #00CEC7);
                    -webkit-background-clip:text!important;
                    -webkit-text-fill-color:transparent;
                }
                svg {
                    z-index:2;
                }
                &.active {
                    background:transparent;
                    -webkit-background-clip:text!important;
                    -webkit-text-fill-color:transparent;
                    span {
                        background:#fff;
                    }
                    svg path, svg line, svg rect {
                        stroke:#fff;
                    }
                    &:before {
                        opacity:1;
                        border:1.5px solid #ffffff4a;
                    }
                }
            }
            &.active {
                color:#fff!important;
                border:1.5px solid #ffffff4a;
                svg path {
                    stroke:#fff!important;
                }
            }
        }
    }

    .resources-pages {
        position:relative;

        @media (max-width:1120px) {
            overflow-x:scroll;
            &::-webkit-scrollbar {
                width:0px;
                height:0;
            }
            &::-webkit-scrollbar-track {
                background: #E8E8E8;
            }
            &::-webkit-scrollbar-thumb {
                background: #0088ce;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #0096E3;
            }
        }
        > div {
            opacity:0;
            pointer-events:none;
            position:absolute;
            top:0;
            left:50%;
            transform:translateX(-50%);
            display:flex;
            justify-content:center;
            transition:opacity 0.32s ease;
            &:not(.recent-resources) {
                @media (max-width:1120px) {
                    min-width:1100px;
                    justify-content:flex-start;
                    left:0!important;
                    transform:translateX(16px)!important;
                }
            }
            &.vts-banner {
                @media (max-width:1120px) {
                    justify-content:center;
                    min-width:0;
                }
                @media (max-width:991px) {
                    min-width:0;
                    width:calc(100vw - 32px);
                    justify-content:flex-start;
                    left:0!important;
                    //transform:translateX(16px)!important;
                    transform:translateX(0)!important;
                }
                @media (max-width:768px) {
                    width:234px;
                    margin-left:auto;
                    margin-right:auto;
                }
            }
            &.recent-resources {
                @media (max-width:991px) {
                    min-width:818px;
                    justify-content:flex-start;
                    left:0!important;
                    transform:translateX(16px)!important;
                }
            }

            &.active {
                transition-delay:0.5s;
                transition:opacity 0.24s ease;
                opacity:1;
                pointer-events:auto;
                position:relative;
            }
        }
        .resource-card {
            border-bottom-left-radius:4px;
            border-bottom-right-radius:4px;
            width:260px;
            margin-right:16px;
            display:flex;
            flex-direction:column;
            background:#fff;
            min-height:308px;
            position:relative;
            @media (max-width:991px) {
                width:234px;
            }
            img {
                height:145px;
                background:#222; //placeholder
                align-self:flex-start;
                width:100%;
            }
            .label-banner {
                height:22px;
                line-height:23px;
                font-size:12px;
                color:#fff;
                padding:0 12px 0 16px;
                margin-top:10px;
                align-self:flex-start;
                font-weight:600;
                text-transform:uppercase;
            }
            &.webinar {
                .label-banner {
                    background:#0088ce;
                }
            }
            &.video {
                .label-banner {
                    background:#F57F29;
                }
            }
            &.blog {
                .label-banner {
                    background:#004F9D;
                }
            }
            &:last-of-type {
                margin-right:0;
            }
            h5 {
                font-size:18px;
                text-transform:none!important;
                font-weight:700;
                padding:0 16px;
                margin-top:10px;
            }
            .cta {
                font-size:14px;
                color:#0088ce;
                position:absolute;
                bottom:4px;
                height:40px;
                line-height:40px;
                transition:0.18s ease;
                text-align:center;
                align-self:flex-start;
                display:inline-block;
                font-weight:800;
                text-transform:uppercase;
                text-decoration:none!important;
                left:50%;
                transform:translateX(-50%);
                width:100%;
                &:hover {
                    color:#F57F29;
                }
            }
            &.view-all {
                a {
                    position:absolute;
                    font-weight:700;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    text-decoration:none!important;
                    div {
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        width:calc(100% - 24px);
                        svg {
                            display:block;
                            margin:0 auto;
                        }
                        span {
                            font-weight:700;
                            text-align:center;
                            display:block;
                            margin-top:12px;
                        }
                    }
                }
                &.webinars {
                    background:#E5F3FA;
                    span {
                        color:#0088ce;
                    }
                }
                &.videos {
                    background:#FEF2E9;
                    span {
                        color:#F57F29;
                    }
                }
                &.blogs {
                    background:#E5EDF5;
                    span {
                        color:#004F9D;
                    }
                }
            }
            &.go-to-vts {
                width:978px;
                max-width:100%;
                position:relative;
                background-size:cover;
                background-repeat:no-repeat;
                background-position:center center;
                background:#0088ce;
                overflow:hidden;
                @media (max-width:768px) {
                    width:234px;
                    height:308px;
                }
                a {
                    display:block;
                    position:absolute;
                    top:-1px;
                    left:0;
                    right:0;
                    bottom:-1px;;
                    display:flex;
                    text-decoration:none!important;
                    @media (max-width:768px) {
                        flex-direction:column-reverse;
                    }
                }
                .left-side {
                    background:linear-gradient(120deg, #0088CE 0%, #007BBA 100%);
                    clip-path: polygon(0 0, 100% 0, 84% 100%, 0% 100%);
                    width:52%;
                    padding:16px;
                    display:flex;
                    justify-content:center;
                    flex-direction:column;
                    position:relative;
                    z-index:2;
                    @media (max-width:768px) {
                        width:100%;
                        clip-path:none;
                    }
                    div.inner-content {
                        width:284px;
                        max-width:100%;
                        margin-left:32px;
                        @media (max-width:768px) {
                            margin-left:0;
                        }
                        h3 {
                            font-size:18px;
                            color:#fff;
                            line-height:1.4;
                            font-weight:600;
                            text-transform:none;
                            @media (max-width:768px) {
                                font-size:16px;
                                text-align:center;
                            }
                            @media (max-width:480px) {
                                margin-top:5px;
                            }
                            span {
                                font-size:24px;
                                text-transform:uppercase;
                                font-weight:800!important;
                                color:#fff;
                                @media (max-width:768px) {
                                    font-size:20px;
                                    text-align:center;
                                }
                                @media (max-width:480px) {
                                    font-size:18px;
                                    display:block;
                                    margin-top:6px;
                                }
                            }
                        }

                        .vts-button {
                            height:44px;
                            line-height:44px;
                            color:#0088ce;
                            background:#fff;
                            text-align:center;
                            width:140px;
                            margin-top:12px;
                            font-weight:800;
                            @media (max-width:768px) {
                                height:40px;
                                line-height:40px;
                                font-size:14px;
                                margin:12px auto 0;
                            }
                        }
                    }

                }
                .right-side {
                    background:url('https://jpmagento-public.s3.amazonaws.com/home/resources-section/vts-banner-image.jpg');
                    background-position:center center;
                    background-size:cover;
                    width:57%;
                    position:absolute;
                    z-index:1;
                    top:0;
                    right:0;
                    bottom:0;
                    @media (max-width:768px) {
                        width:100%;
                        position:relative;
                        top:auto;
                        right:auto;
                        bottom:auto;
                        height:145px;
                    }
                }
            }
        }
    }
}
