.modal-footer {
    @include flex-container;
    border-top: 0;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0;
    display:flex;

    button {
        flex:1;
        height:40px;
        line-height:40px;
        padding:0 24px!important;
        &:first-of-type {
            margin-right:8px!important;
            margin-left:0;
        }
        &:last-of-type {
            margin-left:8px!important;
            margin-right:0;
        }
        @media (max-width: #{$bp-tablet - 1}) {
            width: auto;
        }
        &.action-primary, &.action-accept {
            @include button('primary');
        }
        &.action-secondary, &.action-dismiss {
            @include button('secondary');
        }
    }
}

.message {
    > div,
    > span {
        &::before {
            display: none !important; // sass-lint:disable-line no-important
        }
    }

    &.notice {
        background-color:$alert-blue-light;
        border-color: $jpp-dark-blue;
        color: $jpp-dark-blue;
    }
    &.error, &.message-error {
        color:$jpp-error-red;
        border-color:$jpp-error-red;
        background:$alert-red-light;
    }
    &.warning {
        &.warning {
            color:$jpp-alert-yellow;
            border:1px solid $jpp-alert-yellow;
            background:$jpp-alert-yellow-light;
        }
    }
    pre {
        white-space:normal;
    }
}

.page-wrapper {
    .page.messages {
        position:fixed;
        bottom:2vw;
        width:400px;
        max-width: 92%;
        z-index:9999999999;
        pointer-events:auto;
        background:transparent!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        .messages {
            display:flex;
            flex-direction:column;
            background:transparent!important;
            z-index: 9999999999;
            position: relative;
            @media(max-width: 690px){
                margin-bottom: 55px;
            }
        }
        .message {
            padding:20px 28px 20px 20px;
            box-shadow:rgba(0,0,0,0.15) 0 5px 10px 3px;
            position:relative;
            overflow:hidden;
            pointer-events:auto;
            display:inline-block;
            align-self:flex-start;
            margin:0!important;
            border-radius:4px;
            border-width:1px 1px 1px 4px;
            border-style:solid;
            border-color:$jpp-default-blue-border-light $jpp-default-blue-border-light $jpp-default-blue-border-light $jpp-default-blue-border-dark;
            backface-visibility: hidden;
            perspective: 1000;
            background:$jpp-default-blue-background;
            transform:translateZ(0);
            z-index: 9999999999;
            @keyframes messageIn {
                0% {
                    opacity:0;
                }
                100% {
                    opacity:1;
                }
            }
            @keyframes messageOut {
                0% {
                    opacity:1;
                    pointer-events:none;
                }
                100% {
                    opacity:0;
                    pointer-events:none;
                }
            }
            animation:messageIn 0.7s, messageOut 1.2s 5s;
            animation-fill-mode:forwards;
            &.leaving {

                animation:messageOut 0.4s;
                animation-fill-mode:forwards;
            }
            &:after {
                position:absolute;
                content:'';
                top:0;
                bottom:0;
                width:100%;
                background:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 100%);
                left:-100%;
                opacity:0;
                z-index:999;
                pointer-events:none;
                @keyframes newMessageShine {
                    0% {
                        left:-100%;
                    }
                    40% {
                        opacity:0.26;
                    }
                    80% {
                        opacity:0.18;
                    }
                    100% {
                        left:100%;
                        opacity:0;
                    }
                }
                animation:newMessageShine 0.88s 0.4s;
                animation-fill-mode:forwards;
            }
            div {
                cursor:default;
            }
            div, a {
                font-size:14px!important;
                font-weight:100;
                text-decoration:none!important;
                color:$jpp-default-blue-text!important;
            }
            a{
                text-decoration:underline!important;
            }
            &.notice,
            &.info {
                a {
                    color:lighten($jpp-dark-blue, 20%);
                }
                &:before {
                    background:$jpp-dark-blue;
                }
                &:after {
                    background:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 100%);
                }
                .close{
                    position: absolute;
                    right: 4px;
                    top: 7px;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    cursor: pointer;
                    span{
                        display: inline-block;
                        top: 2px;
                        left: 2px;
                        text-align: center;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        border-radius: 100%;
                        padding-top: 1px;
                        font-size: 30px;
                        font-weight: 100;
                    }
                }
            }
            // Dark success message
            &.success {
                border:0;
                background:$jpp-success-dark-grey;
                a,div{
                    color:$white!important;
                }
                .close{
                    position: absolute;
                    right: 4px;
                    top: 7px;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    cursor: pointer;
                    span{
                        display: inline-block;
                        top: 2px;
                        left: 2px;
                        text-align: center;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        border-radius: 100%;
                        padding-top: 1px;
                        font-size: 30px;
                        font-weight: 100;
                    }
                }
            }
            // New light green success message, not currently in use
            //&.success {
            //    border-color:$jpp-success-green-border-light $jpp-success-green-border-light $jpp-success-green-border-light $jpp-success-green-border-dark;
            //    background:$jpp-success-green-background;
            //    a,div{
            //        color:$jpp-success-green-text!important;
            //    }
            //}
            &.error {
                border-color:$jpp-error-red-border-light $jpp-error-red-border-light $jpp-error-red-border-light $jpp-error-red-border-dark;
                background:$jpp-error-red-background;
                a,div{
                    color:$jpp-error-red-text!important;
                }
                .close{
                    position: absolute;
                    right: 4px;
                    top: 7px;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    cursor: pointer;
                    span{
                        display: inline-block;
                        top: 2px;
                        left: 2px;
                        text-align: center;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        border-radius: 100%;
                        padding-top: 1px;
                        font-size: 30px;
                        font-weight: 100;
                    }
                }
            }
            &.warning {
                border-color:$jpp-warning-yellow-border-light $jpp-warning-yellow-border-light $jpp-warning-yellow-border-light $jpp-warning-yellow-border-dark;
                background:$jpp-warning-yellow-background;
                a,div{
                    color:$jpp-warning-yellow-text!important;
                }
                .close{
                    position: absolute;
                    right: 4px;
                    top: 7px;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    cursor: pointer;
                    span{
                        display: inline-block;
                        top: 2px;
                        left: 2px;
                        text-align: center;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        border-radius: 100%;
                        padding-top: 1px;
                        font-size: 30px;
                        font-weight: 100;
                    }
                }
            }
            &.reward-checkout {
                border: medium none;
                float: left;
                margin: unset;
                padding: unset;
                text-align: left;
                text-transform: uppercase;

                @media (min-width: $bp-laptop-xl) {
                    display: flex;
                }

                @media screen and (max-width: 1023px) {
                    width: 100%;
                }

                .reward-message-half {
                    @media (min-width: $bp-laptop-xl) {
                        flex-basis: 50%;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }

                    @media screen and (max-width: 1023px) {
                        margin-bottom: 10px;

                        br {
                            display: none;
                        }
                    }
                }

                .reward-amount {
                    strong {
                        font-size: 98%;

                        &,
                        & .price {
                            color: $jpp-orange;
                            font-weight: 700;
                        }
                    }
                }

                .reward-balance {
                    strong {
                        color: $jpp-blue;
                        font-size: 98%;
                    }
                }
            }
        }

    }

}
