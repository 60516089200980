.checkout-container {
    aside.opc-sidebar {
        position: relative;
        left: 0;
        visibility: visible;
        order: 999;
        margin: 0;
        margin-top: 0;
        @media (max-width: $bp-tablet - 1) {
            width: 100%;
        }

        .modal-inner-wrap {
            overflow: unset;
            transform: none;
            box-shadow: none;
        }

        //Order Summary Block
        .opc-block-summary:nth-of-type(1) {
            > .title:nth-of-type(1) {
                padding-bottom: 18px;
                border-bottom: 1px solid #dfdfdf;
                @media (max-width:480px) {
                    padding-bottom:14px;
                }
            }
            tr.discount, tr.rewardpoints {
                th.mark {
                    span.title, .title {
                        font-weight:400;
                    }
                }
                td.amount {
                    span.price {
                        color:$jpp-success-green;
                    }
                }
            }
            tr.rewardpoints {
                th {
                    display:flex;
                    a {
                        order:2;
                        margin-left:8px;
                        text-decoration:none!important;
                        span {
                            font-size:14px!important;
                        }
                    }
                }
            }
            tr.shipping {
                th.mark {
                    display:flex;
                    span.value {
                        font-size:14px!important;
                        margin-left:8px;
                        display:none;
                    }
                }
            }
            .actions-toolbar {
                margin-top: 10px;
            }
        }
    }

    #opc-sidebar {
        display: flex !important;
        flex-direction: column !important;

        .opc-block-summary {
            order: 1;
            @media (max-width:480px) {
                padding:16px;
            }
        }

        .opc-payment-additional.discount-code {
            order: 2;
        }

        .opc-payment-additional.rewardpoints {
            order: 3;
        }

        .order-reward-box {
            order: 4;
            .statement-wrapper {
                transform:translateY(2px);
            }
        }

        .checkout-freeshipping-wrapper {
            order: 5;

            p.color-dark-blue {
                margin-top:5px;
            }
        }

        .opc-block-shipping-information {
            order: 6;
        }

        @media (max-width: $bp-tablet - 1) {
            display: flex;
            flex-direction: column;
            order: 20;
            width: 100%;
            margin-bottom: $spacer;
            opacity: 1;
            //Reorder For Checkout Button At Bottom For Mobile
            .opc-block-shipping-information {
                display: none;
                order: 6;
            }
            .checkout-freeshipping-wrapper {
                order: 1;
            }
            .order-reward-box {
                order: 2;
            }
            .opc-payment-additional.rewardpoints {
                order: 3;
            }
            .opc-payment-additional.discount-code {
                order: 4;
            }
            .opc-block-summary {
                order: 5;
            }
        }
    }

    .opc-block-summary {
        .minicart-items-wrapper {
            overflow: visible;
            max-height: none;
        }
    }

    .order-reward-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        padding:14px 12px 14px 18px!important;
        padding-left:58px!important;
        text-align: left;

        &::before {
            font-size: 8px;
            font-weight: 700;
            line-height: 25px;
            position: absolute;
            top: 16px;
            left: 15px;
            display: inline-block;
            min-width: 25px;
            height: 25px;
            margin: auto 18px auto 0;
            content: 'JPP';
            transform: translateY(1px);
            text-align: center;
            vertical-align: top;
            border: 1.5px solid $jpp-orange;
            border-radius: 9999px;
        }

        span:nth-of-type(1) {
            margin: auto 0;
        }

        strong {
            margin: auto 2px;
        }

        span:nth-of-type(2) {
            margin: auto 0;
        }

        &,
        & span {
            font-size: 1.4rem;
            line-height: 16px;
            color: $jpp-orange;
        }
        .small span {
            font-size:11px;
        }
    }

    .payment-option.rewardpoints {
        text-align: center;

        .payment-option-title {
            display: none;
        }

        .payment-option-content {
            margin-top: -4px;
        }

        .payment-option-inner {
            font-size: 1.2rem;
            line-height: 1.6rem;

            span {
                font-size: 12px;
                line-height:14px;
                .small {
                    font-size:10px;
                    line-height:12px;
                }
            }

            label.label {
                font-size: 12px;
            }

            &.rewards-details {
                .reward-points-available {
                    font-size:14px;
                }
                > span {
                    display:block;
                    margin:0 auto;
                    margin-top:10px;

                    &.spending-amount {
                        width:230px;
                    }
                    &.rewards-remaining {

                    }
                    &.cancel-spending {
                        width:200px;
                    }
                }
            }
        }

        .actions-toolbar {
            width: 100%;
            margin-top: 10px;
            text-align: center;

            .primary {
                width: 100%;
            }
        }

        .action.action-use {
            text-transform: uppercase;
            border-color: $jpp-blue;
            border-radius: 0;
            background: transparent;
            @include ease;

            span {
                font-size: 1.2rem;
                font-weight: 800;
                color: $jpp-blue;
                @include ease;
                margin:0;
                > span {
                    line-height:20px;
                }
            }

            &:hover {
                border-color: $jpp-blue-hover;
                background: $jpp-blue-hover;

                span {
                    color: $white;
                }
            }
        }
    }

    .order-reward-box,
    .payment-option.rewardpoints {
        @include sidebar-box;
        padding:24px;
        @media (max-width:480px) {
            padding:16px;
        }
    }

    .opc-block-summary {
        @include sidebar-box;
        padding:24px;
        @media (max-width:480px) {
            padding:16px;
        }
        &:not(.item-action-box) {
            .actions-toolbar {
                button {
                    margin-top: 35px !important;
                }
            }
        }

        &.item-action-box {
            // sass-lint:disable-block no-important
            display: block;
            order: 21;
            width: 100%;
            margin-top: 12px;

            @media (max-width: $bp-laptop - 1) {
                display: none !important;
            }

            .actions-toolbar {
                display: flex;

                .primary {
                    float: right !important;
                    width: auto !important;

                    button {
                        width: 285.33px !important;
                        max-width: 100% !important;
                        height: 42px;
                        margin-top: 0;
                        margin-right: 0;
                        padding: 0 16px !important;
                    }
                }
            }

            .bottom-totals {
                float: left;
                flex: 1;
                padding-right: 16px;

                .table-totals {
                    margin: 0;
                    padding: 0;

                    .grand {
                        display: flex;
                        height: 40px;

                        .amount,
                        .mark {
                            line-height: 42px;
                            margin: 0;
                            padding: 0;
                            padding-bottom: 0 !important;
                            text-align: left;

                            span.price {
                                line-height: 42px;
                            }

                            a.action.delete {
                                float: right;
                                text-decoration: none !important;

                                span {
                                    font-size: 14px;
                                }
                            }
                        }

                        .mark {
                            padding-right: 10px;
                        }
                    }

                    tr.shipping {
                        th.mark {
                            display:flex;
                            span.value {
                                font-size:14px;
                                margin-left:8px;
                            }
                        }
                    }
                }

                td {
                    line-height: 5rem;
                }
            }
        }

        &.review-items {
            order: 10;
            width: 100%;
            background-color: $white;
            box-shadow: 0 2px 16px 0 $box-shadow-light;

            @media (max-width: $bp-tablet - 1) {
                order: 30;
            }
            textarea + p, input + p {
                margin:0;
            }

            .title {
                font-size: 16px;
                text-transform: uppercase;
                color: $jpp-dark-blue;
                @media (max-width:$bp-tablet - 1) {
                    font-size:14px;
                }
            }

            a.split-shipment {
                text-decoration:none!important;
                text-transform:uppercase;
                font-weight:700;
                color:$jpp-blue;
                font-size:14px;
                @include smooth;
                transform:translateY(2px);
                &:hover {
                    color:$jpp-orange;
                }
            }
            .product-item {
                &:not(:first-child) {
                    border-top: 1px solid #e9e9e9;
                }
                &:last-of-type {
                    padding-bottom:0;
                }
                .product-image-wrapper {
                    padding-bottom: 100% !important;
                    border: 1px solid $jpp-grey;

                    img {
                        @include center-x-y;
                    }
                }
                .message.notice {
                    background:#cce7f5;
                    border:1px solid #004f9d;
                    color:#004f9d;
                }
                .product-item-details {
                    padding: 1px 0 0 105px;
                    @media (max-width:$bp-tablet - 1) {
                        padding: 2px 0 0 64px;
                    }
                    .product-item-inner {
                        margin-bottom: 0;
                    }

                    .alert {
                        border: 1.5px solid transparent;
                        margin-top:10px;
                        margin-bottom: 20px;
                        padding: 12px 14px 10px;
                        font-size:13px;
                        line-height:1.5;
                        @media (max-width:480px) {
                            margin-bottom:12px;
                            padding:8px;
                        }
                        br + br {
                            display:none;
                        }
                        &.alert-info {
                            background-color: $jpp-info-blue-light;
                            border-color: $jpp-info-blue;
                            color: $jpp-info-blue;
                        }

                        &.stock {
                            margin-bottom: $spacer;
                        }
                    }
                }

                .message {
                    &.warning {
                        color:#6f4400;
                        border:1px solid #6f4400;
                    }
                    &.notice {
                        float: left;
                        clear: both;
                        width: 100%;
                        margin: 0 0 15px;
                        background:#cce7f5;
                        border:1px solid #004f9d;
                        color:#004f9d;
                    }
                }

                .product-item-name {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 16px;
                    margin: 0 0 6px;
                    text-transform: uppercase;
                    @media (max-width:$bp-tablet - 1) {
                        font-size:14px;
                        line-height:14px;
                    }
                }

                .details-qty {
                    display: inline-block;
                    margin-top: 0;
                    order:2;
                    @media (max-width:480px) {
                        margin-top:-6px;
                    }
                    .label,
                    .value {
                        font-size: 14px;
                        margin-right: 0;
                        @media (max-width:$bp-tablet - 1) {
                            font-size:12px;
                        }
                        span {
                            @include font-normal;
                            font-size: 14px;
                            @media (max-width:$bp-tablet - 1) {
                                font-size:12px;
                            }
                        }
                    }
                    .label {
                        &::before {
                            display:none;
                        }
                    }
                    .value {
                        &::after {
                            //margin: 0 6px 0 3px;
                            //content: ' |';
                            //color: $jpp-grey;
                        }
                    }
                }
                .attribute-wrapper {
                    display:inline-block;
                }
                .options {
                    display: inline-block;
                }

                .item-options, .product-info-wrapper {
                    .label,
                    .values {
                        @include font-normal;
                        font-size: 14px;
                        margin-right: 0;
                        @media (max-width:$bp-tablet - 1) {
                            font-size:12px;
                        }
                        span {
                            @include font-normal;
                            font-size: 14px;
                            @media (max-width:$bp-tablet - 1) {
                                font-size:12px;
                            }
                        }
                    }

                    .label {
                        &::after {
                            content: ': ';
                        }
                        &::before {
                            margin: 0 8.5px 0 8px;
                            content: ' |';
                            color: $jpp-grey;
                            @media (max-width:480px) {
                                margin: 0 6px 0 6.5px;
                            }
                        }
                    }
                    .attribute-wrapper {
                        order:3;
                    }
                    .attribute-wrapper:last-of-type {
                        .label::before {
                            display:none;
                        }
                    }
                    .attribute-wrapper:last-of-type {
                        width:100%;
                        order:1;
                        height:15px;
                        transform:translateY(-8px);
                        span {
                            font-weight:700;
                            font-size:12px;
                        }
                    }
                }
                .product-info-wrapper {
                    display:flex;
                    flex-wrap:wrap;
                }
                .subtotal {
                    @include font-bold;
                    color: $jpp-dark-blue;
                }
            }

            > .message.notice {
                margin-bottom:0;
            }
            .um-purchaseorderfield {
                margin-top:12px;
                .field {
                    margin-bottom:0;
                }
            }
        }

        .step-title {
            line-height: 24px;
            margin-bottom: 20px;
            color: $jpp-off-black;
        }

        .title-wrapper {
            margin-right: -24px;
            margin-left: -24px;
            padding: 12px 24px;
            background-color: #f2f6fa;
            @media (max-width:480px) {
                margin-left:-16px;
                margin-right:-16px;
            }
        }

        .title {
            @include font-bold;
            font-size: 20px;
            line-height: 19px;

            @media (min-width: 640px) {
                margin-bottom: 10px;
            }
            @media (max-width:$bp-tablet - 1) {
                font-size:16px;
            }
            &::after {
                display: none;
            }
        }

        #shipping-method-buttons-container, #shipping-method-buttons-container-sidebar { // sass-lint:disable-line no-ids
            margin: 20px 0 0;

            div.primary { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                display: inline-block;
                float: none;
                width: 100%;
                vertical-align: top;
            }

            button {
                width: 100%;
                margin: 0;

                &.checkout {
                    // sass-lint:disable-block no-important
                    border-color: $jpp-success-green !important;
                    background-color: $jpp-success-green !important;

                    &:active,
                    &:focus,
                    &:hover {
                        filter: brightness(1.1);
                    }
                }
            }
        }

        #shipping-method-buttons-container-sidebar {
            margin-top: -24px;
        }

        #shipping-method-buttons-container {
            margin-top: 0;

            button {
                width: 285.33px;
                max-width: 100%;
            }
        }

        .table-totals {
            margin-top: 18px;

            .mark,
            .amount {
                font-size: 16px;
                padding: 0 0 5px;

                span {
                    font-size: 16px;
                }
            }

            .rewardpoints {
                a {
                    &.delete {
                        cursor: pointer;
                    }
                }
            }

            .discount {
                .title {
                    padding-bottom: 0;
                    border-bottom: 0px solid transparent;
                }
            }

            .grand {
                .mark {
                    padding-top: 10px;

                    strong {
                        @include font-bold;
                        font-size: 20px;
                        @media (max-width:$bp-tablet - 1) {
                            font-size:18px;
                        }
                    }
                }

                .amount {
                    padding-top: 10px;

                    .price {
                        @include font-bold;
                        font-size: 20px;
                        color: $jpp-dark-blue;
                        @media (max-width:$bp-tablet - 1) {
                            font-size:18px;
                        }
                    }
                }
            }
        }

    }

    .action-close {
        display: none;
    }

    .items-in-cart {
        margin: 12px 0;

        .minicart-items {
            margin: 6px 0 12px;

            .product-item {
                padding:24px 0 10px;
                &:first-child {
                    padding-top:0;
                }
                .product-image-container {
                    @media (max-width:$bp-tablet - 1) {
                        width:52px!important;
                        height:52px!important;
                    }
                }
                &.is-addon-product {
                    font-size: 14px;
                    border: none;
                    margin-top: -20px;
                    padding-top: 0;
                    padding-bottom:26px;
                    @media (max-width:$bp-tablet - 1) {
                        margin-top:0;
                        padding-bottom:12px;
                    }
                    .product {
                        width:100%;
                        display:flex;
                        &:not(.options) {
                            padding-left: 34px;
                            @media (max-width:$bp-tablet - 1) {
                                padding-left:27px;
                            }
                        }
                        .product-image-container {
                            width: 60px !important;
                            height: 60px !important;
                            @media (max-width:$bp-tablet - 1) {
                                width:40px!important;
                                height:40px!important;
                            }
                        }
                    }
                    .product-item-details {
                        padding-top:0;
                        padding-left:0;
                        margin-left:11px;
                        .product-info-wrapper {
                            .attribute-wrapper:last-of-type {
                                transform:translateY(-4px);
                            }
                        }
                    }
                    .options,
                    .details-qty {
                        line-height: 2rem;
                        .values,
                        .label,
                        .value {
                            font-size: 14px;
                        }
                    }
                    .details-qty {
                        //display:none;
                    }
                    .product-item-name {
                        margin-bottom: 0;
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .step-content {
            margin-bottom: 24px;
        }

        .content {
            display: block !important; // sass-lint:disable-line no-important
        }

        .table-checkout-shipping-method {
            tbody tr {
                border-bottom: 0 solid transparent;

                &:last-of-type {
                    border-bottom: 0px solid transparent;
                }
            }

            tbody td {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 4px;
                border: 8px solid transparent;
            }

            .col-method {
                label {
                    span {
                        &:first-of-type {
                            font-weight: 700;
                            color: $jpp-dark-blue;
                            @media (max-width:$bp-tablet - 1) {
                                font-size:14px;
                            }
                        }

                        &:nth-of-type(2) {
                            font-size: 14px;
                            display: block;
                            transform: translateY(-4px);
                            @media (max-width:480px) {
                                font-size:13px;
                            }
                        }
                    }
                }

                span.pickup-notice {
                    font-size:13px!important;
                    line-height:1.4;
                    margin-top:4px;
                }
            }
        }
    }
}
