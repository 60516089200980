body {
    .message.global.demo {
        position: relative;
        z-index: 101;
        background-color: $jpp-error-red;
    }
    .preorder-sticker {
        position: absolute;
        width: 231px;
        height: 72px;
        top: 7px;
        padding-top: 3px;
        color: $jpp-orange;
        border: 5px solid $jpp-blue;
        border-radius: 100% / 100%;
        background-color: white;
        font-family: proxima-nova;
        font-size: 20px;
        line-height: 20px;
        padding-top:10px;
        font-weight: 800;
        text-align: center;
        text-transform: uppercase;
        transform: rotate(-3deg) scale(.9);
        z-index: 9;
        @media (max-width:$bp-tablet) {
            transform: rotate(-3deg) scale(0.5); 
        }
        
        &.right {  
            right:0;
            transform: rotate(3deg) scale(.9);
            @media (max-width:$bp-tablet) {
                transform: rotate(3deg) scale(0.5); 
            }
        }
    }

    &.fotorama__fullscreen {
        .preorder-sticker, .new-product-flag {
            display:none;
        }
    }
}

.product-info-price.swatch-option-loading {
    content:none;

    .price-box {
        position:relative;
        z-index:auto;
        content:none;

        &:before {
            position:absolute;
            z-index:2;
            top:0;
            bottom:-4px;
            left:0;
            width:300px;
            max-width:100%;
            content:" ";
            background:url('../images/loader-blue.gif') left center no-repeat #fff;
            background-size:40px;
        }
    }
}

.page-product-configurable {
    .product-info-price.swatch-option-loading.initial-loading {
        .price {
            &:before {
                background:#fff;
            }
        }
    }
}

.product.data.items {
    .item.content.loading {
        &:before {
            position: absolute;
            z-index: 2;
            top: 50px;
            right: 0;
            bottom: 0;
            left: 0;
            content: " ";
            background: url(../images/loader-blue.gif) left center no-repeat #fff;
            background-size: contain;
            display: block;
        }
    }
}
