// sass-lint:disable no-important class-name-format
.aw-et-product-page {
    .product-badges-container {
        display: none;
    }

    .product {
        &.detailed {
            width: 100%;
        }
    }

    .qty-inp-block {
        width: auto;
    }

    .login-extra-info {
        width: 100%;
    }

    .event-date-container {
        display: flex;

        .start-date,
        .end-date {
            > span {
                border: 4px solid $jpp-grey;
                border-radius: 6px;
                display: inline-block;
                padding: 2px 10px;

                span {
                    margin: 0;

                    &:first-child {
                        font-size: 12px;
                        text-transform: uppercase;
                    }

                    &:last-child {
                        @include font-bold;
                        font-size: 32px;
                    }
                }
            }
        }

        .through {
            @include font-bold;
            display: block;
            font-size: 14px;
            margin: 10px 0;
            text-transform: uppercase;

            @media (min-width: 600px) {
                font-size: 12px;
                margin: 0;
                padding: 0 5px;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            @media (min-width: $bp-tablet) {
                font-size: 14px;
            }
        }
    }

    .aw-et__form-container-wrapper {
        width: 50%;
        float: left;
        clear: none;
    }

    .aw-et__view-options-container {
        .summary-container {
            .summary-header {
                border-bottom: 1px solid $jpp-grey;
                padding-bottom: 14px;
            }

            .title-sum {
                @include font-bold;
                font-size: 20px;
                text-transform: uppercase;
            }

            .price {
                color: $jpp-dark-blue;
                font-size: 16px;
                font-weight: 800;
            }
        }

        .sector {
            border: 0;

            .sector-storefront-description {
                border-bottom: 1px solid $jpp-grey;
                padding-bottom: 11px;

                .name {
                    @include font-bold;
                    color: $black;
                    font-size: 20px;
                    text-transform: uppercase;
                }
            }

            .arrow {
                display: none;
            }

            .header-sector-tickets {
                padding: 0;
            }

            .block__status-qty {
                display: none;
            }

            .tickets-type {
                display: block !important; //sass-lint:disable-line
                padding-left: 0;
            }

            .tickets-type-item {
                background-color: $white;
                padding: 10px 0;
            }

            // .item-content-ticket {
            //     align-items: center;
            //     display: flex;
            // }

            .ticket-type-storefront-description,
            .ticket-price {
                margin: 0 10px 0 0;
            }

            .price,
            .unit-price {
                color: $jpp-dark-blue;
                font-size: 16px;
                font-weight: 800;
            }
        }

        .sectors {
            font-family: unset;

            .tickets-type {
                .qty-inp-block {
                    width: auto;
                }
            }
        }
    }

    .product-social-links {
        display: none;
    }

    .aw-et {
        &.additional-attributes-wrapper {
            display: inline-block;
            margin: 0 auto;
            width: 100%;
        }
    }

    @media (min-width: $bp-tablet) {
        .aw-et__view-options-container {
            .options-container,
            .summary-container {
                width: 100%;
            }
        }

        .aw-et-view-options-wrapper {
            display: block;
            padding-left: 10px;

            .summary-container {
                margin-left: 0;
            }
        }
    }
}
