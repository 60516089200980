// sass-lint:disable no-important class-name-format force-element-nesting
.page-layout-resources_layout {
    &:not(.cms-resources) {
        .resources-title-wrapper {
            .resource-landing {
                display: none;
                position: fixed;
                left: -99999px;
                right: -999999px;
            }

            .page-title {
                margin-bottom: 0;
            }
        }
    }

    .column.main {
        background-color: $white;
        //border-top: 8px solid $jpp-blue;
        padding: $spacer $spacer 60px;

        @media (min-width: $bp-desktop-s) {
            box-shadow: 0 0 3px $jpp-grey;
            padding: 40px #{$spacer * 1.5} 60px;
        }

        * {
            @include unset-underline;
        }

        h1 {
            color: $jpp-blue;
            font-size: 32px;
            margin-top: 0;
            text-transform: none;
        }

        h3 {
            margin-top: $spacer;

            a {
                @include font-bold;
                display: inline-block;
                font-size: 24px;
                margin-bottom: 10px;
            }
        }

        h4 {
            a {
                @include font-bold;
                display: inline-block;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }

        .tile-grid {
            .tile {
                > a {
                    display: table;
                    .tile-wrap {
                        vertical-align: middle;
                        display: table-cell;
                    }
                }
            }
        }
    }
}
