@keyframes fadein {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
body.cms-webinars, body.cms-videos {
    #sns_main {
        background:#f9f9f9;
        padding-bottom:0;
        margin-bottom:-12px;
    }
    div.breadcrumbs {
        margin-bottom:0;
    }
    .flex {
        display:flex;
    }
    .vertical {
        flex-direction:column;
    }
    .center {
        justify-content:center;
    }
    
    a.blue-button {
        display: inline-block;
        flex: 1;
        margin-right: 12px;
        padding: 0 16px !important;
        height: 40px !important;
        line-height: 40px !important;
        font-size:14px!important;
        text-align: center;
        user-select:none;
        &:last-of-type {
            margin-right:0;
        }
        &.secondary {
            background:transparent!important;
            border:1.5px solid #0088CE!important;
            color:#0088CE!important;
            &:hover {
                background:#0088CE!important;
                color:#FFF!important;
                border:1.5px solid #0088CE!important;
            }
        }
    }
    h3 {
        font-size:18px;
        font-weight:500;
        text-transform:none!important;
        width:640px;
        max-width:100%;
        margin:0 auto!important;
        line-height:1.4;
    }

    .flickity {
        position:relative;
        transition:0.24s ease;
        opacity:0;
        pointer-events:none;
        display:flex;
        margin:auto;
        padding:16px;
        overflow:hidden;
        @media (max-width:$bp-desktop-s) {
            padding:16px 0;
            overflow:unset;
        }
        &.flickity-enabled {
            opacity:1;
            pointer-events:auto;
            display:block;
            + .loader-placeholder {
                display:none;
            }
        }
        .flickity-button {
            border-radius:100%;
            transition:0.24s ease;
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            box-shadow: 0px 0px 8px rgba(2, 2, 2, 0.05);
            display:none;
            &:disabled {
                opacity:0.25;
            }
            &.previous {
                left:-50px;
                content:'';
                background-image:url('https://jpmagento-public.s3.amazonaws.com/icons/arrow-left.svg');
                background-size:100% 100%;
                background-repeat:no-repeat;
            }
            &.next {
                right:-50px;
                content:'';
                background-image:url('https://jpmagento-public.s3.amazonaws.com/icons/arrow-right.svg');
                background-size:100% 100%;
                background-repeat:no-repeat;
            }
        }
    }

    .slider-wrapper {
        position:relative;
        margin:80px auto 32px;
        display:inline-block;
        left:50%;
        transform:translateX(-50%) translateY(-12px);
        max-width:100%;
        @media (max-width:991px) {
            margin:48px auto;
        }
        @media (max-width:768px) {
            margin:48px auto 32px;
        }
        &::before, &::after {
            position:absolute;
            content:'';
            top:0;
            bottom:0;
            width:22px;
            z-index:2;
            pointer-events:none;
            @media (max-width:$bp-desktop-s) {
                display:none;
            }
        }
        &::before {
            left:-4px;
            background:linear-gradient(to right, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
        }
        &::after {
            right:-4px;
            background:linear-gradient(to left, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
        }
        &.dark-blue {
            &::before {
                background:linear-gradient(to right, rgba(0,79,157,1) 20%, rgba(0,79,157,0) 100%);
            }
            &::after {
                background:linear-gradient(to left, rgba(0,79,157,1) 20%, rgba(0,79,157,0) 100%);
            }
        }
        &.loaded {
            .prev-button, .next-button {
                opacity:1;
                pointer-events:auto;

                @media (max-width:768px) {
                    transform-origin:left;
                    transform:scale(0.72);
                }
            }
        }
        .prev-button, .next-button {
            cursor:pointer;
            width:48px;
            height:48px;
            box-shadow:0px 0px 8px rgba(2, 2, 2, 0.05);
            background:#fff;
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            opacity:0;
            pointer-events:none;
            transition:0.24s ease;
            z-index:3;
            border-radius:100%;
            border:1px solid #dfdfdf;
            &:hover {
                svg {
                    stroke:#f58220;
                }
            }
            &:disabled {
                pointer-events:none;
                box-shadow:0px 0px 8px rgba(2, 2, 2, 0.0);
                opacity:0.3;
            }
            svg {
                width:26px;
                height:26px;
                position:absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                transition:0.18s ease;
            }
        }
        .prev-button {
            left:-64px;
            @media (max-width:$bp-desktop-s) {
                right:auto;
                top:auto;
                bottom:-72px;
                left:0px;
            }
            @media (max-width:768px) {
                bottom:-40px;
            }
        }
        .next-button {
            right:-64px;
            @media (max-width:$bp-desktop-s) {
                right:auto;
                top:auto;
                bottom:-72px;
                left:60px;
            }
            @media (max-width:768px) {
                left:42px;
                bottom:-40px;
            }
        }
        &.dark-blue {
            .prev-button, .next-button {
                background:rgba(255,255,255,0.1);
                border:1px solid rgba(255,255,255,0.04);
                svg {
                    stroke:#fff;
                }
                &:hover {
                    background:rgba(255,255,255,1);
                    svg {
                        stroke:#0088CE;
                    }
                }
            }
        }
    }

    .header-area {
        background:#1d4165;
        min-height:520px;
        padding: 24px 16px;
        position: relative;
        z-index:1;
        min-height:31.25vw;
        @media (max-width:1024px) {
            min-height:410px;
        }
        @media (max-width:991px) {

        }
        @media (max-width:480px) {
            min-height:340px;
        }
        h1, h2, h3 {
            color: #fff;
            text-align: center;
            text-shadow: 0px 0px 12px rgba(2, 2, 2, 0.12);
        }
        h1 {
            margin-top:0;
            @media (max-width:991px) {
                font-size:32px;
            }
            @media (max-width:480px) {
                font-size:26px;
            }
        }
        h2 {
            font-size:24px;
            font-weight:600;
            @media (max-width:991px) {
                font-size:20px;
            }
            @media (max-width:480px) {
                font-size:18px;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 500;
            text-transform: none;
            width: 668px;
            max-width:100%;
            margin: 0 auto;
            @media (max-width:991px) {
                font-size:16px;
                width:486px;
            }
            @media (max-width:480px) {
                font-size:14px;
            }
        }

        .content-wrapper {
            position:relative;
            z-index:3;
            opacity:0;
            animation:fadein 1s;
            animation-fill-mode:forwards;
            animation-delay:0.16s;
            transform:translateY(-18px);
        }

        .background {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            &.cover {
                background: rgba(2, 2, 2, 0.62);
                z-index:2;
                opacity:0.95;
                &::after {
                    position:absolute;
                    content:'';
                    top:0;
                    left:0;
                    bottom:0;
                    right:0;
                    //background-image:radial-gradient(circle at 90% 150%, rgba(0,79,157,0.6) 0%, rgba(0,79,157,0) 100%), radial-gradient(circle at -15% 20%, rgba(0,79,157,0.6) 0%, rgba(0,79,157,0) 100%);
                    background-image:radial-gradient(circle at 105% 110%, rgba(0,150,157,0.44) 0%, rgba(0,79,157,0) 60%), radial-gradient(circle at -15% 20%, rgba(0,79,157,0.44) 0%, rgba(0,79,157,0) 100%), radial-gradient(circle at 50% 50%, rgba(0,79,157,0.4) 0%, rgba(0,79,157,0) 100%);
                }
            }
            &.image {
                filter:saturate(0);
                background: url("https://jpmagento-public.s3.amazonaws.com/landing-pages/webinars/images/header/Webinars-Header-2.jpg")!important;
                background-position: center top!important;
                background-size: cover!important;
                background-repeat:no-repeat!important;
                opacity:0;
                animation:fadein 1s;
                animation-fill-mode:forwards;
                animation-delay:0.24s;
            }
        }

        .buttons {
            display:flex;
            justify-content:center;
            margin-top:24px;
            .white-button {
                height:40px;
                line-height:38px;
                display:inline-block;
                border:1px solid #fff;
                text-transform:uppercase;
                text-align:center;
                padding:0 18px;
                font-weight:800;
                font-size:14px;
                color:#fff;
                transition:0.18s ease;
                &:hover {
                    background:#fff;
                    color:#0088CE;
                }
                &:nth-of-type(1) {
                    margin-right:12px;
                }
            }
        }
    }

    section {
        margin:0!important;
        padding:64px 16px;
        position:relative;

        @media (min-width:1600px) {
            padding:92px 16px;
        }
        @media (max-width:991px) {
            padding:48px 16px;
        }

        h2, h3 {
            text-align:center;
        }
        h2 {
            span.thin {
                font-weight:300;
                font-size:inherit!important;
                color:inherit!important;
            }
            @media (max-width:991px) {
                font-size:28px;
            }
            @media (max-width:600px) {
                font-size:24px;
            }
        }
        h3 {
            @media (max-width:991px) {
                font-size:16px;
            }
        }

        &.upcoming-webinars {
            position:relative;
            z-index:2;
            padding-top:0;
            padding-bottom:40px;
            h3 {
                opacity:0;
                animation:fadein 0.18s;
                animation-fill-mode:forwards;
                &.no-webinars {
                    color:#666;
                    transform:translateY(100px);
                    @media (max-width:768px) {
                        transform:translateY(64px);
                    }
                }
            }
            .inner-page {
                width:1500px;
                max-width:100%;
                min-height:25vw;
                box-shadow:0px 0px 12px rgba(2,2,2,0.02);
                padding:64px 16px 24px;
                margin:0 auto;
                margin-top:-48px;
                background:#fff;
                overflow-x:hidden;
                @media (max-width:$bp-tablet) {
                    padding-top:40px;
                }
                @media (max-width:$bp-mobile-l) {
                    padding-top:24px;
                }
                .content {
                    position:relative;
                    z-index:2;
                    opacity:0;
                    animation:fadein 1s;
                    animation-fill-mode:forwards;
                    animation-delay:0.16s;
                }
                &:before {
                    position:absolute;
                    z-index:1;
                    content:'';
                    width:100vw;
                    top:20%;
                    bottom:0;
                    background:linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%);
                    pointer-events:none;
                    left:0;
                    @media (max-width:$bp-desktop-s) {
                        top:50%;
                    }
                }
            }  
            .slider-wrapper {
                margin:64px auto 32px;
                @media (max-width:$bp-tablet) {
                    margin-top:40px;
                }
            }
            .webinars-slider {
                width:1024px;
                max-width:100%;
                position:relative;

                .webinar {
                    background: #f9f9f9;
                    box-shadow: 0px 0px 8px rgba(2, 2, 2, 0.05);
                    border: 1px solid #dfdfdf;
                    padding: 24px;
                    margin-right: 16px;
                    width: 320px;
                    max-width: 100%;
                    transition:0.24s ease;
                    position:relative;
                    z-index:2;
                    border-radius:8px;
                    -webkit-tap-highlight-color:transparent;
                    &:last-of-type {
                        margin-right:0;
                    }
                    @media (max-width:1024px) {
                        width:31%;
                        margin-right:12px;
                    }
                    @media (max-width:991px) {
                        padding:24px 16px 16px 16px;
                    }
                    @media (max-width:768px) {
                        width:44%;
                        padding:24px 16px 16px 16px;
                    }
                    @media (max-width:600px) {
                        width:60%;
                    }
                    @media (max-width:400px) {
                        width:calc(100% - 12.4vw);
                    }
                    h4, h5, p {
                        cursor:default;
                    }
                    .webinar-image {
                        img {
                            display: block;
                            margin: 0 auto;
                            height: 160px;
                            width: 160px;
                            border-radius: 100%;
                            user-select:none;
                            @media (max-width:1024px) {
                                width:13vw;
                                height:13vw;
                            }
                            @media (max-width:768px) {
                                width:100px;
                                height:100px;
                            }
                        }
                    }

                    .webinar-title {
                        h4 {
                            text-align:center;
                            font-size:22px;
                            letter-spacing:-0.2px;
                            text-transform:none;
                            line-height:1.1;
                            transform:translateY(3px);
                        }
                    }

                    .date-and-time {
                        h5.date {
                            font-size:18px;
                            color:#0088ce;
                            text-align:center;
                            margin-bottom:0;
                            font-weight:700;
                            @media (max-width:991px) {
                                font-size:16px;
                            }
                        }
                        p {
                            text-align:center;
                            font-weight:700;
                            @media (max-width:991px) {
                                font-size:14px;
                            }
                        }
                    }

                    .buttons {
                        display:flex;
                        margin-top:18px;
                        @media (max-width:1024px) {
                            flex-direction:column-reverse;
                            a.blue-button {
                                width:100%;
                                margin-right:0!important;
                                &:last-of-type {
                                    margin-bottom:12px;
                                }
                            }
                        }
                        @media (max-width:768px) {
                            a.blue-button {
                                height:32px!important;
                                line-height:32px!important;
                                font-size:12px!important;
                            }
                        }
                    }
                }
            }
        }

        &.jpp-experts {
            background: #004f9d;
            overflow-x:hidden;
            h2, h3 {
                color:#fff;
            }

            .expert-slider {
                width:844px;
                max-width: 100%;
                @media (max-width:$bp-desktop-s) {
                    overflow:hidden;
                }
                .flickity-viewport, .flickity-slider {
                    position:relative;
                    transition:height 0.4s ease;
                    @media (min-width:991px) {
                        height:420px;
                    }
                }
                .expert {
                    padding: 24px;
                    background: #fff;
                    margin-right: 16px;
                    border-radius:8px;
                    -webkit-tap-highlight-color:transparent;
                    position:relative;
                    width:calc(33.333% - 10.666px);

                    @media (max-width:768px) {
                        padding:16px;
                        width:30%;
                        h4 {
                            font-size:18px;
                        }
                        p {
                            font-size:14px;
                        }
                    }
                    @media (max-width:600px) {
                        width:80%;
                    }
                    @media (max-width:400px) {
                        width:calc(100% - 25vw);
                    }
                    img {
                        width:212px;
                        height:auto;
                        display:block;
                        margin:auto;
                        border-radius:4px;
                        overflow:hidden;
                        box-shadow:0px 1px 6px rgba(2,2,2,0.08);
                        @media (max-width:991px) {
                            width:100%;
                            height:auto;
                        }
                    }
                    h4, p {
                        text-align:center;
                        cursor:default;
                    }
                    h4 {
                        font-size:20px;
                        color:#0088ce;
                        margin-top:22px;
                    }
                    p {
                        color:#000;
                        margin-bottom:6px;
                    }
                    p:last-of-type {
                        margin-bottom:0;
                    }
                }
            }
        }
        &.featured-webinar {
            //background:#fff;
            //background:linear-gradient(to bottom, #f9fcfe 0%, #2891c6 100%);
            background:linear-gradient(to bottom, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 100%);
            z-index:1;
            position:relative;
            padding:64px 16px 20px;
            @media (min-width:1600px) {
                padding:92px 16px 20px;
            }
            @media (max-width:991px) {
                padding-bottom:16px;
            }

            h2 + h3 {
                padding-bottom:0;
                @media (max-width:768px) {
                    padding-bottom:0;
                }
            }
            .vid-title {
                transform:translateY(44px);
                h3 {
                    font-size:20px;
                    font-weight:800;
                    text-transform:none!important;
                    width:928px;
                    max-width:100%;
                    margin:0 auto;
                    text-align:center;
                    margin-bottom:60px;
                    padding:12px;
                    border-radius:24px;
                    padding-bottom:48px;
                    color:#fff;
                    background:#121212;
                    @media (max-width:768px) {
                        font-size:18px;
                        border-radius:12px;
                    }
                }
            }
            .featured-webinar-video {
                position:relative;
                //width:1100px;
                width:928px;
                max-width:100%;
                margin:64px auto 0;
                margin-top:8px;
                border-radius:24px;
                box-shadow:0px 0px 24px rgba(2,2,2,0.16);

                &::before {
                    position:absolute;
                    content:'';
                    z-index:-1;
                    top:0;
                    bottom:0;
                    left:50%;
                    width:100vw;
                    transform: translateX(-50%) translateY(-50%);
                    background:linear-gradient(to bottom, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.18) 100%);
                    pointer-events:none;
                }

                .vid-wrap {
                    width:100%;
                    padding-bottom:56.25%;
                    position:relative;
                    z-index:2;
                    border-radius:24px;
                    border-top-left-radius:0!important;
                    border-top-right-radius:0!important;
                    overflow:hidden;
                    background:#222;
                    @media (max-width:768px) {
                        border-radius:12px;
                    }
                    .play-cover {
                        background:linear-gradient(315deg, rgba(2, 2, 2, 0.6) 0%, rgba(2, 2, 2, 0.4) 100%);
                        position:absolute;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        z-index:2;
                        transition:0.4s ease;
                        opacity:1;
                        cursor:pointer;
                        display:none;
                        svg {
                            margin:0 auto;
                            width:70px;
                            height:70px;
                            display:block;
                            position:absolute;
                            top:calc(50% - 2px);
                            left:50%;
                            transform:translateX(-50%) translateY(-50%) scale(1);
                            filter:drop-shadow(0px 0px 5px #555);
                            transition:0.18s ease;
                            background:#00699e;
                            border-radius:100%;
                            @media (max-width:768px) {
                                width:14vw;
                                height:14vw;
                            }
                        }
                        &:hover {
                            svg {
                                transform:translateX(-50%) translateY(-50%) scale(1.04);
                            }
                        }
                    }
                    video, iframe {
                        width:100%;
                        height:100%;
                        position:absolute;
                        z-index:1;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        background:#000;
                    }
                }
                &.playing {
                    .play-cover {
                        opacity:0;
                        pointer-events:none;
                    }
                }
            }
        }
        &.previous-webinars {
            position:relative;
            z-index:2;
            padding:20px 16px 64px;
            transition:height 0.6s ease;
            transition-delay:0.6s;
            @media (min-width:1600px) {
                padding:20px 16px 92px;
            }
            @media (max-width:991px) {
                padding-top:16px;
            }
            h2, h3 {
                color:#fff;
            }
            .webinar-filters {
                display:flex;
                justify-content:center;
                max-height:64px;
                transform:scale(0.92);
                position:relative;
                z-index:5;
                @media (max-width:991px) {
                    width:100%;
                }
                @media (max-width:768px) {
                    flex-direction:column;
                    max-height:none;
                }
                .filter, .searchbar-wrapper {
                    width:280px;
                    margin-right:32px;
                    position:relative;
                    align-self:flex-start;
                    z-index:2;
                    border-radius:4px;
                    @media (max-width:991px) {
                        margin-right:12px;
                        width:calc(33.333% - 8px);
                    }
                    @media (max-width:768px) {
                        width:100%;
                        margin-right:0!important;
                    }
                }
                .filter {
                    background:#fff;
                    display:flex;
                    flex-direction:column;
                    padding:20px;
                    overflow:hidden;
                    height:64px;
                    transition:all 0.5s, height 0.6s ease;
                    transition-timing-function:cubic-bezier(0.79, 0.13, 0.26, 0.91);
                    box-shadow:0px 4px 12px rgba(2, 2, 2, 0);
                    z-index:1;
                    position:relative;
                    @media (max-width:768px) {
                        margin-bottom:12px;
                    }
                    &.active {
                        height:auto;
                        box-shadow:0px 8px 32px rgba(2, 2, 2, 0.32);
                        z-index:5;
                        .toggle {
                            svg {
                                transform:rotate(-180deg) translateZ(0);
                                -webkit-tap-highlight-color:transparent;
                            }
                        }
                    }
                    .toggle {
                        display:flex;
                        padding-bottom:16px;
                        cursor:pointer;
                        label {
                            font-size:20px;
                            color:#0088ce;
                            font-weight:700;
                            cursor:pointer;
                            user-select:none;
                        }
                        svg {
                            margin-left:12px;
                            transition:0.24s ease;
                            transform-origin:center center;
                            backface-visibility:hidden;
                            @media (max-width:768px) {
                                margin-left:auto;
                            }
                        }
                    }
                    .options {
                        display:flex;
                        flex-direction:column;
                        div {
                            margin-bottom:12px;
                            input {
                                margin-top:0;
                                top:0;
                            }
                            label {
                                user-select:none;
                                font-size:16px;
                                font-weight:500;
                                margin-left:6px;
                                transform:translateY(-1px);
                                display:inline-block;
                                color:#000;
                            }
                            &:last-of-type {
                                margin-bottom:0;
                            }
                        }
                    }
                }
                .searchbar-wrapper {
                    margin-right:0;
                    position:relative;
                    @media (max-width:768px) {
                        margin-right:0;
                    }
                    input {
                        height:64px;
                        padding:20px;
                        padding-top:26px;
                        font-size:16px;
                        border:0px solid transparent!important;
                        position:relative;
                        z-index:1;
                        background:#f9f9f9;
                        border-radius:4px;
                        &::placeholder {
                            color:rgba(0,0,0,0.48);
                        }
                    }
                    .submit {
                        background:transparent;
                        border-radius:100%;
                        border:0px solid transparent;
                        position:absolute;
                        z-index:2;
                        right:12px;
                        top:50%;
                        transform:translateY(-50%);
                        height:40px;
                        width:40px;
                        padding:0;
                        &:active, &:focus {
                            box-shadow:0px 0px 4px rgba(2,2,2,0);
                        }
                        svg {
                            transform:scale(0.84) translateX(-50%) translateY(-50%);
                            position:absolute;
                            top:50%;
                            left:50%;
                        }
                    }
                }
            }
            .previous-webinars-grid {
                margin: 40px auto 32px;
                min-height:334px;
                width:1420px;
                max-width: 100%;
                position:relative;
                @media (max-width:480px) {
                    max-height:238px;
                }
                &:not(.flickity-enabled) {
                    display:flex;
                    flex-wrap:wrap;
                    max-height:754px;
                    pointer-events:none;
                    * {
                        opacity:0!important;
                    }
                    @media (max-width:1280px) {
                        max-height:1100px;
                    }
                }
                & + .loader-placeholder {
                    position:absolute;
                    top:48%!important;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                }
                &.flickity-enabled {
                    & + .loader-placeholder {
                        display:none;
                    }
                }

                @media (max-width:991px) {
                    margin:32px auto;
                }
                > .webinar {
                    //Hiding Until Paginated
                    opacity:0;
                }
                .flickity-button {
                    display:none!important;
                    opacity:0!important;
                }
                .flickity-viewport, .flickity-slider {
                    width:100%;
                    position:relative;
                }
                .flickity-page-dots {
                    display:flex;
                    width:100%;
                    justify-content:center;
                    list-style-type:none;
                    padding:0;
                    margin:32px 0 12px;
                    position:absolute;
                    bottom:-80px;
                    @media (max-width:480px) {
                        bottom:-64px;
                    }
                    li.dot {
                        width:20px;
                        height:20px;
                        border-radius:100%;
                        background:rgba(255,255,255,0);
                        border:1.5px solid #fff;
                        margin-right:10px;
                        transition:0.2s ease;
                        cursor:pointer;
                        @media (max-width:991px) {
                            width:16px;
                            height:16px;
                        }
                        &:only-of-type {
                            display:none;
                        }
                        &:last-of-type {
                            margin-right:0;
                        }
                        &:hover {
                            background:rgba(255,255,255,0.5);
                            border:1.5px solid rgba(255,255,255,0.5);
                        }
                        &.is-selected {
                            background:rgba(255,255,255,1)!important;
                            cursor:default;
                        }
                    }
                }
                .not-found {
                    color:#fff;
                    font-size:16px;
                    opacity:0;
                    animation:fadein 0.6s;
                    animation-fill-mode:forwards;
                }
                .previous-webinars-page, .previous-videos-page {
                    position:relative;
                    display: flex;
                    flex-wrap: wrap;
                    width:100%;
                    opacity:0;
                    transform:scale(0.92);
                    transition:all 0.5s, opacity 1.2s, transform 1s;
                    transition-timing-function:cubic-bezier(0.785, 0.165, 0.345, 0.945);
                    pointer-events:none;
                    > .webinar {
                        opacity:1;
                    }
                    &.is-selected {
                        opacity:1;
                        transform:scale(1);
                        pointer-events:auto;
                    }
                }
                .webinar {
                    background: #fff;
                    width: calc(50% - 5px);
                    max-width: 100%;
                    margin-right:10px;
                    margin-bottom:10px;
                    border-radius:6px;
                    overflow:hidden;
                    display:flex;
                    flex-direction:column;
                    justify-content:flex-start;
                    transition:0.4s ease;
                    box-shadow:0px 0px 12px rgba(2,2,2,0.18);
                    &:nth-of-type(2n) {
                        margin-right:0;
                    }

                    @media (min-width:992px) {
                        width: calc(33.333% - 10.666px);
                        margin-right: 16px;
                        margin-bottom: 16px;
                        border-radius:8px;
                        &:nth-of-type(2n) {
                            margin-right: 16px;
                        }
                        &:nth-of-type(3n) {
                            margin-right: 0;
                        }
                    }
                    @media (min-width:1280px) {
                        width:calc(25% - 12px);
                        &:nth-of-type(3n) {
                            margin-right:16px;
                        }
                        &:nth-of-type(4n) {
                            margin-right:0;
                        }
                    }
                    h4 {
                        margin:0;
                        text-transform:none;
                        //font-size:22px;
                        font-size:20px;
                        font-weight:700;
                        //margin:6px 0;
                        cursor:default;
                        text-align:center;
                        @media (max-width:768px) {
                            font-size:16px;
                        }
                    }

                    .webinar-title, .buttons {
                        padding:20px 16px;
                        @media (max-width:768px) {
                            padding:10px 16px;
                        }
                        @media (max-width:480px) {
                            transform:scale(0.94);
                        }
                    }

                    .webinar-image {
                        a {
                            position:relative;
                            display:block;
                            padding-bottom:56.25%;
                            overflow:hidden;
                            img {
                                position:absolute;
                                width:100%;
                                left:0;
                                right:0;
                                top:0;
                            }
                        }
                    }
                    .webinar-title {
                        padding-bottom:0!important;
                    }

                    .buttons {
                        margin-top:auto;
                        display:flex;
                        justify-content:center;
                        padding-bottom:28px;
                        @media (max-width:768px) {
                            padding:16px;
                        }
                        @media (max-width:600px) {
                            display:flex;
                            flex-direction:column-reverse;
                        }
                        a.blue-button {
                            display:inline-block;
                            margin-right:12px;
                            height:34px!important;
                            font-size:12px!important;
                            padding:0 14px!important;
                            line-height:33px!important;
                            max-width:120px;
                            @media (max-width:600px) {
                                width:100%;
                                max-width:100%;
                                margin-right:0!important;
                                &:nth-of-type(2) {
                                    margin-bottom:6px;
                                }
                            }
                            &:last-of-type {
                                margin-right:0;
                            }
                            &.secondary, &.download-files {
                                background:#fff!important;
                                border:1.5px solid #0088CE!important;
                                color:#0088CE!important;
                                line-height:31px!important;
                                &:hover {
                                    background:#0088CE!important;
                                    color:#FFF!important;
                                    border:1.5px solid #0088CE!important;
                                }
                            }

                        }
                    }
                }
            }
        }

        &.resources {
            min-height: 39.89vw;
            position: relative;
            background:linear-gradient(to right, #ededed 0%, #e9e9e9 50%, #eeeeee 100%);
            @media (max-width:1200px) {
                padding-top:186px;
                min-height:calc(40vw + 186px);
            }
            .background-image {
                background: url("https://jpmagento-public.s3.amazonaws.com/landing-pages/webinars/resources-bg.png");
                background-position: center center;
                background-size: cover;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                @media (max-width:1200px) {
                    top:186px;
                }
            }
            .side-card {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 28vw;
                padding:5vw;
                max-width: 100%;
                z-index: 1;
                @media (max-width:1200px) {
                    width:calc(100% - 32px);
                    max-width:100%;
                    padding:64px 32px 32px;
                    left:auto;
                    top:0;
                    min-height:186px;
                    transform:translateY(0);
                }
                @media (max-width:768px) {
                    padding:46px 32px 32px;
                }
                @media (max-width:500px) {
                    padding:40px 0;
                }
                h2 {
                    margin-top:0;
                    font-size:24px;
                    text-align:left;
                    @media (max-width:1200px) {
                        text-align:center;
                    }
                    @media (max-width:768px) {
                        width:358px;
                        max-width:100%;
                        margin-left:auto;
                        margin-right:auto;
                    }
                    @media (max-width:500px) {
                        font-size:20px;
                    }
                }

                a.blue-button {
                    display:inline-block;
                    min-width:140px;
                    @media (max-width:1200px) {
                        position:relative;
                        left:50%;
                        transform:translateX(-50%);
                    }
                }
            }
        }

        &.webinar-suggestions {
            padding-top:100px;
            padding-bottom:200px;
            background:#fff;
            @media (max-width:991px) {
                padding-bottom:100px;
            }
            h2 {
                color:#0088ce;
                text-transform:none!important;
            }
            .form-area {
                width:500px;
                max-width:100%;
                margin:0 auto;
            }
            .form-columns-2 {
                > div {
                    width:calc(50% - 8px);
                    &:nth-of-type(1) {
                        transform:translateX(0)
                    }
                    &:nth-of-type(2) {
                        transform:translateX(16px);
                    }
                }
            }
            div.input {
                margin-right:0;
                box-shadow:none;
                border-radius:0px;

                input.hs-input {
                    box-shadow:none;
                    border-radius:0;
                    padding:0 10px!important;
                }
                textarea {
                    border-radius:0;
                    border:1px solid #ededed;
                    box-shadow:none;
                    padding:10px!important;
                    min-height:88px;
                }
            }
            .field {
                margin-bottom:12px!important;
            }
            .hs-error-msgs {
                position:absolute!important;
                width:100%;
                label {
                    margin-top:4px!important;
                    transform:translateY(0)!important;
                }
            }
            .hs-main-font-element {
                display:none;
            }
            .legal-consent-container {
                margin:0!important;
                margin-bottom:12px!important;
                padding:0!important;
                p {
                    margin:0!important;
                    font-size:10px;
                    width:100%;
                    padding:0!important;
                    line-height:1.2;
                    opacity:0.75;
                    a {
                        font-size:inherit!important;
                    }
                }
            }

            .hs-button.primary {
                min-width:180px;
                box-shadow:none;
                margin:0 auto;
                display:block;
            }
        }

        // Hidden/Toggleable Modals
        &.video-view, &.description-view {
            position:fixed;
            z-index:999;
            height:100vh;
            width:100%;
            pointer-events:none;
            opacity:0;
            top:0;
            left:0;
            right:0;
            bottom:0;
            transition:0.76s ease;
            transition-delay:0.2s;

            &.active {
                opacity:1;
                pointer-events:auto;
                transition-delay:0s;

                .modal {
                    transform:translateX(-50%) translateY(0);
                    transition-delay:0.2s;
                    opacity:1;
                }
            }
            a.close {
                font-size:14px;
                font-weight:800;
                color:#fff;
                text-shadow:0px 0px 12px rgba(2,2,2,0.12);
                position:absolute;
                z-index:2;
                top:-5px;
                right:-40px;
                filter:drop-shadow(2px 4px 12px #222);
                opacity:0.33;
                transition:0.2s ease;
                &:hover {
                    opacity:1;
                }
                @media (max-width:768px) {
                    top:-38px;
                    right:-8px;
                }
            }
            .modal {
                top:10vh;
                left:50%;
                transform:translateX(-50%) translateY(5vh);
                width:740px;
                margin:auto;
                max-width:calc(100% - 32px);
                max-height:calc(100% - 48px);
                position:absolute;
                z-index:2;
                background:#fff;
                transition:0.68s ease;
                //transition-timing-function:cubic-bezier(0.79, 0.13, 0.26, 0.91);
                transition-timing-function:cubic-bezier(0.785, 0.165, 0.345, 0.945);
                transition-delay:0s;
                opacity:0;
                box-shadow:0px 2px 48px rgba(2,2,2,0.4);
                display:flex;
                flex-direction:column;
                @media (max-width:1400px) {
                    top:24px;
                    width:540px;
                    h2 {
                        font-size:28px!important;
                    }
                }
                @media (max-width:768px) {
                    top:16px;
                    max-height:calc(100% - 32px);
                    h2 {
                        font-size:24px!important;
                    }
                }
                .video-wrapper {
                    video, .video {
                        background:#222;
                    }
                }
                .content-wrapper {
                    padding:24px;
                    overflow-y:scroll;
                    &::-webkit-scrollbar {
                        width:8px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #E8E8E8;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #0088ce;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #0096E3;
                    }
                    h2 {
                        margin-top:6px;
                        margin-bottom:18px;
                        text-transform:none!important;
                        text-align:left;
                    }
                    p {
                        line-height:1.4;
                    }
                }
                .webinar-image {
                    width:100%;
                }
                .button-area {
                    background:#f9f9f9;
                    padding:24px;
                    margin-bottom:-24px;
                    margin-left:-24px;
                    margin-top:28px;
                    width:calc(100% + 48px);
                    display:flex;
                    a.blue-button {
                        height:50px!important;
                        line-height:50px!important;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        svg {
                            margin-right:10px;
                            transform:scale(0.84);
                        }
                    }
                    .register {
                        width:300px;
                        max-width:300px;
                        display:block;
                        margin:0 auto;
                    }
                }
            }

            .background {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:rgba(2,2,2,0.5);
                z-index:1;
            }
        }
    }

}

.featured-previous-wrapper {
    background:linear-gradient(to bottom, #f9fcfe 0%, #87d7ff 33%, #4fb1e4 50%, #1f92cf 66%, #007cbb 100%);
    overflow:hidden;
    position:relative;
    &::before {
        position:absolute;
        content:'';
        height:100%;
        width:130vw;
        background-image:radial-gradient(circle at 50% 50%, rgba(0, 136, 206, 0.66) 0%, rgba(0, 136, 206, 0) 40%), radial-gradient(circle at 20% 74%, rgba(206, 0, 193, 0.2) 0%, rgba(0, 136, 206, 0) 48%), radial-gradient(circle at 96% 108%, rgba(49, 233, 181, 0.46) 0%, rgba(0, 136, 206, 0) 62%);
        z-index:1;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        pointer-events:none;
        filter:brightness(0.8) contrast(0.72) saturate(0.8);
    }
    section {
        z-index:2;
        position:relative;
    }
}

// Loader SVG

.loader-placeholder {
    left:50%!important;
    position:absolute!important;
    top:50%!important;
    width:44px;
    z-index:9000!important;
    transform:translateX(-22px) translateY(-44px);
    .loader {
        position: relative;
        margin: 0px auto;
        width: 40px;
        height:40px;
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        .circular-loader {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin: auto;
        }
        .loader-path {
            stroke-dasharray: 150,200;
            stroke-dashoffset: -10;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
            &.white {
                animation: dash 1.5s ease-in-out infinite, white 6s ease-in-out infinite!important;
            }
        }        
    }
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}

@keyframes color {
    0% {
        stroke: #0088ce;
    }
    40% {
        stroke: #0088ce;
    }
    66% {
        stroke: #0088ce;
    }
    80%, 90% {
        stroke: #0088ce;
    }
}


@keyframes white {
    0% {
        stroke: #FFF;
    }
    40% {
        stroke: #FFF;
    }
    66% {
        stroke: #FFF;
    }
    80%, 90% {
        stroke: #FFF;
    }
}

/* Single Webinars Post CSS */

body.blog-post-view {
    .webinar-main {
        padding:0!important;
        padding-top:0!important;
        border-top:0px solid transparent!important;
        width:900px!important;
        max-width:100%!important;
        float:none!important;
        margin:0 auto!important;
        box-shadow:0px 0px 0px transparent!important;
        background:transparent!important;
        p {
            @media (max-width:500px) {
                font-size:14px;
                line-height:1.4;
            }
        }
        section {
            box-shadow:0 0 24px rgba(0,0,0,0.06);
            background:#fff;
            margin-bottom:32px;
            padding:44px!important;
            @media (max-width:991px) {
                padding:24px!important;
                padding-top:0!important;
            }
            &.webinar-post {
                padding-top:0!important;
                padding-bottom:44px!important;
                @media (max-width:991px) {
                    padding-bottom:24px!important;
                }
                @media (max-width:500px) {
                    padding-bottom:16px!important;
                }
                .top-area {
                    position:relative;
                    &::after {
                        position:absolute;
                        content:'';
                        background:linear-gradient(to bottom, #fff 0%, #f9f9f9 22%, rgba(0, 79, 157, 0.11) 100%);
                        top:-20px;
                        left:50%;
                        right:0;
                        bottom:0;
                        z-index:-5;
                        transform:translateX(-50%);
                        width:100vw;
                    }
                }
            }
            &.video-post {
                .top-area {
                    &::after {
                        background:linear-gradient(to bottom, #1a1a1a 0%, #163048 22%, rgba(0,79,157,1) 100%)!important;
                        filter:brightness(0.5)!important;
                    }
                }
            }
        }
        + .sidebar.sidebar-main {
            display:none!important;
        }
        .page-title-wrapper {
            display:none!important;
        }
    }
    .post-holder {
        margin-bottom:0;

        > div:nth-last-of-type(2) {
            padding-bottom:0!important;
        }
    }
    .post-view.webinar {
        h2 {
            text-transform:none;
            margin-top:0;
            margin-bottom:32px;
            text-align:center;
            width:100%;
            font-weight:700;
            font-size:24px;
            @media (max-width:991px) {
                margin-top:24px;
            }
            @media (max-width:500px) {
                font-size:20px;
                margin-bottom:24px;
            }
        }
        .video-wrapper {
            min-width:calc(100% + 88px);
            background:#222;
            transform:translateX(-44px);
            position:relative;
            padding-bottom:0!important;
            .inner {
                width:100%;
                padding-bottom:56.25%;
                position:relative;
                video, iframe {
                    position:absolute;
                    height:100%;
                    width:100%;
                    max-width:100%;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }
            }

            @media (max-width:991px) {
                min-width:calc(100% + 48px);
                transform:translateX(-24px);
            }
        }
        .webinar-title {
            h1 {
                color:#004f9d;
                text-transform:none;
                margin-bottom:14px;
                margin-top:38px;
                @media (max-width:991px) {
                    margin-top:24px;
                }
            }
        }
        .webinar-categories {
            margin-bottom:28px;
            span {
                background:#f9f9f9;
                padding:0 16px;
                display:inline-block;
                height:34px;
                line-height:34px;
                font-size:14px;
                font-weight:600;
                margin-right:8px;
                user-select:none;
                text-transform:capitalize;
                box-shadow:inset 0px 0px 16px rgba(2,2,2,0.08);
                &:last-of-type {
                    margin-right:0;
                }
                @media (max-width:991px) {
                    height:30px;
                    line-height:30px;
                    font-size:12px;
                }
            }
        }

        .webinar-hosts {
            display:flex;
            flex-wrap:wrap;
            margin-bottom:32px;
            .host {
                display:flex;
                margin-right:22px;
                &:first-of-type {
                    @media (max-width:500px) {
                        margin-bottom:6px;
                    }
                }
                &:last-of-type {
                    margin-right:0;
                }
                .host-image {
                    width:68px;
                    margin-right:14px;
                    @media (max-width:500px) {
                        width:60px;
                    }
                }
                .host-info {
                    position:relative;
                    display:flex;
                    flex-direction:column;
                    h5, span {
                        margin:0;
                        width:100%;
                        line-height:24px;
                    }
                    .host-name {
                        color:#0088ce;
                        font-size:16px;
                        text-transform:none;
                        font-weight:700;
                    }
                    .host-email, .host-phone {
                        font-size:14px;
                    }
                }
            }
        }
        .webinar-description, .webinar-downloads, .featured-products {
            padding-bottom:44px;
            @media (max-width:991px) {
                padding-bottom:24px;
            }
            @media (max-width:500px) {
                padding-bottom:0;
            }
        }
        .webinar-description {
            font-size:16px;
            color:#000;
            line-height:1.4;
            @media (max-width:480px) {
                font-size:14px;
            }
        }
        .webinar-downloads {
            display:flex;
            flex-wrap:wrap;
            justify-content:center;
            width:646px;
            max-width:100%;
            margin-left:auto;
            margin-right:auto;
            padding-top:0!important;
            @media (max-width:768px) {
                flex-direction:column;
            }
            a.download-button {
                background:linear-gradient(to right, #0088ce 0%, #00ADCE 100%);
                color:#fff;
                height:50px;
                line-height:50px;
                text-align:center;
                padding:0 24px;
                margin-right:12px;
                margin-bottom:12px;
                text-decoration:none!important;
                font-weight:700;
                flex:1;
                max-width:207.33px!important;
                transition:0.24s ease;
                align-self:center;
                &:hover {
                    filter:brightness(1.08);
                    box-shadow:0px 0px 12px rgba(2,2,2,0.12);
                }
                &:last-of-type {
                    margin-right:0;
                }
                @media (max-width:991px) {
                    height:44px;
                    line-height:44px;
                    font-size:14px;
                }
                @media (max-width:768px) {
                    margin-right:0!important;
                }
            }
        }
        .featured-products {
            overflow:hidden;
            min-width:calc(100% + 44px);
            transform:translateX(-22px);
            @media (max-width:991px) {
                min-width:calc(100% + 24px);
                transform:translateX(-12px);
            }
            .slider-wrapper {
                padding:24px;
                background:#f9f9f9;
                position:relative;
                z-index:1;
                max-height:254px;
                @media (max-width:991px) {
                    padding:16px;
                }
                &::before, &::after {
                    position:absolute;
                    content:'';
                    top:0;
                    left:0;
                    bottom:0;
                    width:14px;
                    background:linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
                    pointer-events:none;
                    z-index:3;
                    opacity:0.32;
                    opacity:0;
                }
                &::after {
                    left:auto;
                    right:0;
                    background:linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
                    opacity:0.2;
                }
            }
            .featured-products-slider {
                opacity:0;
                transition:opacity 0.32s ease;
                &.flickity-enabled {
                    opacity:1;
                }
                .flickity-button {
                    position:absolute;
                    top:50%;
                    transform:translateY(-50%);
                    border-radius:100%;
                    background:#0088ce;
                    z-index:5;
                    transition:0.24s ease;
                    height:32px;
                    width:32px;
                    &:hover {
                        filter:brightness(1.08);
                    }
                    &::before {
                        position:absolute;
                        content:'';
                        background:url('https://jpmagento-public.s3.amazonaws.com/landing-pages/webinars/white-arrow.png');
                        background-size:contain;
                        background-repeat:no-repeat;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        width:16px;
                        height:12px;
                    }
                    &[disabled] {
                        opacity:0;
                    }
                    &.previous {
                        left:6px;
                        @media (max-width:991px) {
                            left:2px;
                        }
                        &::before {
                            transform:translateX(-50%) translateY(-50%) rotate(180deg);
                        }
                    }
                    &.next {
                        right:6px;
                        @media (max-width:991px) {
                            right:2px;
                        }
                        &::before {
                            top:calc(50% + 0.5px);
                        }
                    }
                    svg {
                        display:none;
                    }
                }
                .product {
                    background:#fff;
                    box-shadow:0px 0px 8px rgba(2,2,2,0.1);
                    text-decoration:none!important;
                    width:190px;
                    height:190px;
                    margin-right:12px;
                    display:block;
                    position:relative;
                    z-index:2;

                    &:hover {
                        label {
                            color:#0088ce;
                        }
                    }
                    .product-image-container {
                        width:100%!important;
                        .product-image-wrapper {
                            width:70%!important;
                            padding-bottom:70%!important;
                            margin:0 auto;
                            img {
                                margin-top:4px;
                                height:100%!important;
                            }
                        }
                    }

                    img {
                        margin:0 auto;
                        margin-top:28px;
                        display:block;
                    }
                    label {
                        color:#000;
                        font-weight:700;
                        font-size:14px;
                        line-height:1.06;
                        text-align:center;
                        position:absolute;
                        bottom:10px;
                        left:10px;
                        right:10px;
                        transition:0.18s ease;
                        letter-spacing:-0.2px;
                    }
                }
            }
        }
        .post-bottom {
            display:none;
        }
    }
    section.related-webinars {
        @media (max-width:991px) {
            padding-top:12px!important;
        }
        .related-webinars-grid {
            padding:24px;
            padding-bottom:12px;
            background:#f9f9f9;
            display:flex;
            flex-wrap:wrap;
            min-width:calc(100% + 44px);
            transform:translateX(-22px);
            @media (max-width:991px) {
                min-width:calc(100% + 24px);
                transform:translateX(-12px);
                padding:16px;
            }
            .related-webinar {
                display:block;
                width:calc(33.333% - 8px);
                margin-right:12px;
                margin-bottom:12px;
                background:#fff;
                box-shadow:0px 0px 8px rgba(2,2,2,0.1);
                position:relative;
                text-decoration:none!important;
                &:nth-of-type(3n) {
                    margin-right:0;
                }
                &:hover {
                    .webinar-title span {
                        color:#0088ce;
                    }
                }
                @media (max-width:768px) {
                    width:calc(50% - 6px);
                    margin-right:12px;
                    &:nth-of-type(3n) {
                        margin-right:12px;
                    }
                    &:nth-of-type(2n) {
                        margin-right:0;
                    }
                }
                .image-area {
                    background:#004163;
                    padding-bottom:56.25%;
                    width:100%;
                }
                .webinar-title {
                    padding:14px;
                    @media (max-width:768px) {
                        padding:12px 14px 10px;
                    }
                    span {
                        font-size:16px;
                        font-weight:700;
                        transition:0.18s ease;
                        color:#000;
                    }
                }
            }
        }
        a.blue-button {
            display:inline-block;
            margin-top:24px;
            margin-left:auto;
            margin-right:auto;
            font-size:14px!important;
            position:relative;
            left:50%;
            transform:translateX(-50%);
            padding:13px 20px!important;
            text-align:center;
        }
    }
    section.subscribe {
        padding-top:44px!important;
        @media (max-width:991px) {
            padding-top:24px!important;
        }
        .hs-form {
            width:360px;
            max-width:100%;
            margin:0 auto;
            .hs_email .input {
                margin-right:0;
            }
            .hs-main-font-element p span {
                font-size:24px!important;
                font-weight:700!important;
                text-align:center;
                margin-top:0;
                margin-bottom:0;
                text-transform:none;
                display:block;
                line-height:1.4;
            }

            input.hs-button {
                width:100%;
            }
            .legal-consent-container {
                p {
                    a {
                        font-size:10px;
                    }
                }
            }
        }
    }
}