.page-layout-cms-showroom-page {
    .column {
        &.main {
            background: $white;
            padding-left: 20px;
            padding-right: 20px;
            @media (min-width: $bp-laptop) {
                padding-left: 40px;
                padding-right: 40px;
            }
            @media (min-width: $bp-desktop-xl) {
                padding-left: 0;
                padding-right: 0;
            }

            #authenticationPopup {  // sass-lint:disable-line no-ids
                & + [data-content-type='row'] {
                    padding: 0 !important; // sass-lint:disable-line no-important

                    & + [data-content-type='row'] {
                        padding-top: 40px !important; // sass-lint:disable-line no-important
                        @media (min-width: $bp-laptop) {
                            padding-top: 88px !important; // sass-lint:disable-line no-important
                        }
                    }
                }
            }

            & > [data-content-type='row'] {
                background: $jpp-grey-4;
                margin: 0 !important;// sass-lint:disable-line no-important
                padding: 0 40px !important; // sass-lint:disable-line no-important

                @media (min-width: $bp-laptop) {
                    padding: 0 88px !important; // sass-lint:disable-line no-important
                }

                &:last-of-type {
                    padding-bottom: 40px !important; // sass-lint:disable-line no-important
                    @media (min-width: $bp-laptop) {
                        padding-bottom: 88px !important; // sass-lint:disable-line no-important
                    }
                }

                > [data-element='inner'] {
                    margin: 0 !important;  // sass-lint:disable-line no-important
                }

                .pagebuilder-column {
                    padding: 0 !important; // sass-lint:disable-line no-important
                }

                > .pagebuilder-column-group {
                    padding-bottom: 60px;

                    .pagebuilder-column {
                        @media (min-width: $bp-tablet) {
                            figure {
                                display: inline-block;
                                width: calc(100% - 24px);
                            }

                            &:nth-child(2n) {
                                figure {
                                    float: left;
                                }
                            }

                            &:nth-child(2n + 1) {
                                figure {
                                    float: right;
                                }
                            }
                        }
                    }
                }

                [data-content-type='text'] {
                    font-size: 2rem;
                    line-height: 3rem;
                }

                [data-content-type='buttons'] {
                    padding: 0 !important; // sass-lint:disable-line no-important

                    .pagebuilder-button-primary {
                        background: #f37021; // sass-lint:disable-line no-color-literals
                        border: 0;
                        border-radius: 0;
                        color: $white;
                        font-size: 125%;
                        font-weight: 600;
                        margin-top: 32px;
                        max-width: 100%;
                        padding: 8px 24px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 240px;

                        &:hover {
                            box-shadow: 1px 2px 6px rgba(2, 2, 2, .2); // sass-lint:disable-line no-color-literals
                            filter: brightness(1.1);
                            transition: .22s;
                        }
                    }
                }
            }

            .page-header-blue {
                background: $jpp-blue;
                margin-bottom: 0 !important; // sass-lint:disable-line no-important
                min-height: 262px;
                position: relative;
                text-align: center;
                z-index: auto;

                h1 {
                    border: 2px solid $white;
                    box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);// sass-lint:disable-line no-color-literals
                    color: $white;
                    display: inline-block;
                    font-weight: 300;
                    left: 50%;
                    margin: 0;
                    padding: 25px 65px;
                    position: absolute;
                    text-shadow: 2px 2px 2px rgba(0, 0, 0, .2);// sass-lint:disable-line no-color-literals
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    width: auto;

                    &::after,
                    &::before {
                        background: $white;
                        content: ' ';
                        left: 100%;
                        position: absolute;
                        top: 100%;
                        z-index: auto;
                    }

                    &::before {
                        height: 2px;
                        width: 20px;
                    }

                    &::after {
                        height: 20px;
                        width: 2px;
                    }

                    span {
                        color: $white;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
