.jpp-page-header {
    margin-top:-20px!important;
    padding-top:20px!important;
    width:100%;
    min-height:320px;
    background:#fff;
    @media (max-width:991px) {
        padding:12px 0 32px 0!important;
    }
    @media (max-width:768px) {
        padding:12px 16px 24px 16px!important;
    }
    .pagebuilder-column-group {
        width:1500px;
        max-width:92%;
        margin-left:auto;
        margin-right:auto;
        min-height:320px;
        //@media (max-width:1200px) {
        //    padding-top:0!important;
        //}
        @media (max-width:991px) {
            flex-direction:column-reverse;
            .pagebuilder-column {
                width:100%!important;
                padding:0!important;
            }
        }
        @media (max-width:768px) {
            max-width:100%;
        }
        h1 {
            width:600px;
            max-width:100%;
            margin-top:0;
            @media (max-width:991px) {
                width:698px;
            }
        }
        div[data-content-type="text"] {
            width:528px;
            max-width:100%;
            @media (max-width:991px) {
                width:698px;
            }
            @media (max-width:480px) {
                font-size:14px;
            }
        }
    }
}