.customer-service-wrapper {
    margin: #{$spacer * 4} 0;

    .customer-service {
        .content {
            margin: 10px auto;
            padding: $spacer * 1.5;
            position: relative;

            @media (min-width: 600px) {
                margin-left: 0;
                margin-right: 40px;
            }

            @media (min-width: $bp-tablet) {
                margin-bottom: -100px;
                margin-right: 100px;
            }

            @media (min-width: $bp-desktop-s) {
                box-shadow: 2px 3px 10px $box-shadow;
                margin-bottom: -350px;
                padding: 100px 60px;
                width: 30%;
            }
        }

        .heading {
            margin-bottom: $spacer;
        }

        .superheading {
            @include font-bold;
            font-size: 13px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .subheading {
            @include font-bold;
            font-size: 26px;
            letter-spacing: normal;
            text-transform: none;

            @media (min-width: $bp-desktop-s) {
                font-size: 33px;
            }
        }

        .image-container {
            box-sizing: border-box;

            @media (min-width: 600px) {
                padding-left: 20px;
            }

            @media (min-width: $bp-tablet) {
                padding-left: 40px;
            }

            @media (min-width: $bp-laptop) {
                padding-left: 100px;
            }

            @media (min-width: $bp-desktop-s) {
                padding-left: 140px;
            }

            img {
                width: 100%;
            }
        }
    }
}
