.large-categories-wrapper {
    margin: 80px 0 20px;
    @media (max-width:$bp-laptop) {
        margin: 64px 0 20px;
    }
    @media (max-width:$bp-tablet) {
        margin: 40px 0 20px;
    }
    .large-categories-container {
        margin: 0 auto;
        width:1240px;
        max-width:100%;

        @media (max-width: $bp-laptop - 1) {
            width:490px;
            max-width:100%;
        }

        .large-category-item {
            margin-bottom: $spacer * 2;

            @media (min-width: $bp-laptop) {
                display: flex;
                flex-wrap: wrap;
            }

            &:nth-child(even) {
                .content {
                    @media (min-width: $bp-laptop) {
                        margin-bottom: $spacer;
                        margin-top: 0;
                        order: -1;

                        &::before {
                            bottom: unset;
                            top: 0;
                        }
                    }
                }

                .link {
                    margin-bottom: 0;
                }
            }

            &:hover {
                .image {
                    transform: scale(1.03);
                    transition: transform .2s ease;
                }

                .link {
                    color: $jpp-orange;
                    transition: color .3s ease;
                }
            }
        }

        .image-container {
            display: block;
            line-height: 0;
            overflow: hidden;
        }

        .image {
            height: auto;
            width: 100%;
        }

        .content {
            margin-left: $spacer * 2.5;
            margin-top: $spacer;
            position: relative;

            @media (min-width: $bp-laptop) {
                margin-left: $spacer * 4;
            }

            &::before {
                background-color: $jpp-dark-grey;
                bottom: 0;
                content: '';
                height: 160px;
                left: -$spacer;
                position: absolute;
                width: 1px;


            }

            * {
                @include unset-underline;
            }

            .heading {
                font-weight:700;
                color:#222!important;
                font-size: 20px;
            }

            .link {
                @include font-bold;
            }
        }
    }
}
