@import
'footer/newsletter',
'footer/links';

//hide the automatic recaptcha badge only, but not on the checkout
body:not(.checkout-index-index) > div > .grecaptcha-badge {
    visibility: hidden;
}

.page-footer {
    background-color:#f5f5f5;


    > .content {
        border: 0;
        box-sizing: border-box;
        padding-top: $spacer * 2;
        padding-bottom:0;

        @media (max-width:$bp-mobile-l) {
            padding-left:0;
            padding-right:0;
        }
    }

    .footer-content-container {
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $bp-laptop) {
            @include grid-collapse;
        }

        > .column {
            margin-bottom: 30px;
        }
    }
    .copyright-container {
        display:flex;
        flex-direction:column;
        justify-content:center;
        min-height:40px;
        background:#1A597F;
        padding:0 16px;
        p, a, div {
            color:#fff;
        }
    }
    .copyright {
        margin-top:24px;
        padding:20px 0;
        position:relative;
        display:flex;
        flex-direction:column;
        width:1500px;
        max-width:92%;
        margin:0 auto;
        @media (max-width:$bp-laptop) {
            max-width:100%;
        }
        > p, > div {
            z-index:1;
            position:relative;
            line-height:2;
            margin:0;
            text-align:center;
        }
        @media (min-width:$bp-laptop) {
            margin-top:0;
        }
        @media (min-width:$bp-desktop) {
            flex-direction:row;
            justify-content:space-between;
            padding:0;
            > p, > div {
                line-height:40px;
            }
            > p {
                align-self:flex-start;
                text-align:left!important;
            }
            > div {
                align-self:flex-end;
                text-align:right!important;
            }
        }

        a {
            @include unset-underline;
        }
    }

    .footer.content {
        width:100%;
        max-width:100%;
        padding:0;
        margin-top:0;
    }
    .jpp-footer {

        h2, h5, p, label, a {
            color:#fff;
            margin:0;
        }

        .footer-banner {
            //min-height:524px;
            min-height:27.29vw;
            background:#003554;
            display:flex;
            justify-content:center;
            flex-direction:column;
            position:relative;
            @media (max-width:768px) {
                min-height:40vw;
                padding:40px 0;
            }
            h2 {
                text-transform:none;
                font-weight:700;
                font-size:32px;
                margin-bottom:32px;
                width:766px;
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
                @media (max-width:1280px) {
                    font-size:24px;
                }
                @media (max-width:768px) {
                    font-size:20px;
                }
                @media (max-width:480px) {
                    font-size:16px;
                    margin-bottom:12px;
                    width:296px;
                }
            }
            p {
                font-size:20px;
                @media (max-width:1200px) {
                    font-size:18px;
                    line-height:20px;
                }
                @media (max-width:768px) {
                    font-size:18px;
                    line-height:20px;
                }
                @media (max-width:480px) {
                    font-size:14px;
                    line-height:18px;
                }
            }
            h2, p {
                text-align:center;
            }

            .banner-button {
                height:52px;
                line-height:53px;
                background:#fff;
                padding:0 24px;
                text-align:center;
                color:#0088CE;
                text-decoration:none!important;
                font-weight:800;
                display:inline-block;
                position:relative;
                left:50%;
                transform:translateX(-50%);
                transition:0.2s ease;
                font-size:14px;
                @media (max-width:480px) {
                    height:38px;
                    line-height:39px;
                    padding:0 16px;
                    font-size:12px;
                }
                &:hover {
                    background:#F57F29;
                    filter:brightness(1.05);
                    color:#fff;
                    box-shadow:0px 0px 12px rgba(2,2,2,0.12);
                }
            }
            .content {
                position:relative;
                z-index:2;
            }
            .background-image {
                position:relative;
                background:url('https://jpp.trgcdn.com/media/homepage/Homepage-footer-image-BW.jpg');
                background-size:cover;
                background-position:center center;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                z-index:1;
                &:before {
                    position:absolute;
                    content:'';
                    background:linear-gradient(0deg, #013e5d 0%, #0071ab 100%);
                    opacity:0.6;
                    pointer-events:none;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }
            }
        }
        .footer-main {
            //background:#004670;
            background:linear-gradient(336deg, #004670 0%, #00578c 100%);
            padding:0 16px 84px;
            margin:0 auto;
            @media (max-width:$bp-laptop) {
                padding:0 16px 60px;
            }
            .footer-message, .footer-columns {
                width:1500px;
                max-width:92%;
                margin:0 auto;
                @media (max-width:$bp-laptop) {
                    max-width:100%;
                }
            }
            .footer-message {
                padding:84px 0;
                display:flex;
                flex-direction:column;
                justify-content:center;
                width:92%;
                @media (max-width:$bp-laptop) {
                    padding:60px 0;
                }
                @media (max-width:$bp-mobile-l) {
                    width:100%;
                }
                h2 {
                    font-size:32px;
                    margin-bottom:18px;
                    text-transform:none;
                    @media (max-width:1280px) {
                        font-size:24px;
                    }
                    @media (max-width:768px) {
                        font-size:20px;
                    }
                    @media (max-width:480px) {
                        font-size:18px;
                    }
                }
                p {
                    font-size:20px;
                    @media (max-width:1280px) {
                        font-size:16px;
                        line-height:20px;
                    }
                    @media (max-width:768px) {
                        font-size:16px;
                        line-height:20px;
                    }
                    @media (max-width:480px) {
                        font-size:14px;
                        line-height:18px;
                        width:296px;
                        margin-left:auto;
                        margin-right:auto;
                    }
                }
                h2, p {
                    text-align:center;
                }
            }
            .footer-columns {
                display:flex;
                //justify-content:center;
                justify-content:space-between;
                @media (max-width:1280px) {
                    //flex-direction:column;
                    display:grid;
                    grid-template-columns:1fr 1fr;
                    grid-template-rows:auto auto;
                    width:726px;
                    max-width:100%;
                }
                @media (max-width:640px) {
                    display:flex;
                    flex-direction:column;
                    width:92%
                }
                @media (max-width:480px) {
                    width:100%;
                }
                h5 {
                    font-size:20px;
                    font-weight:700;
                    text-transform:none;
                    margin-bottom:12px;
                }
                p, a {
                    font-size:14px;
                    color:#fff;
                }

                > div {
                    margin-right:64px;
                    > div {
                        @media (max-width:1280px) {
                            margin:0 auto;
                        }
                        @media (max-width:640px) {
                            margin-left:0;
                        }
                    }
                    &:nth-of-type(1) {
                        @media (max-width:1280px) {
                            grid-row:1/2;
                            grid-column:2/3;
                        }
                        @media (max-width:640px) {
                            order:2;
                        }
                    }
                    &:nth-of-type(2) {
                        @media (max-width:1280px) {
                            grid-row:2/3;
                            grid-column:1/3;
                        }
                        @media (max-width:640px) {
                            order:3;
                        }
                    }
                    &:nth-of-type(3) {
                        @media (max-width:1280px) {
                            grid-row:1/2;
                            grid-column:1/2;
                        }
                        @media (max-width:640px) {
                            order:1;
                        }
                    }
                    &:last-of-type {
                        margin-right:0;
                    }
                }
                .subscribe-form {
                    width:380px;
                    max-width:100%;
                    @media (max-width:1400px) {
                        width:270px;
                    }
                    @media (max-width:1280px) {
                        margin:0 auto;
                    }
                    @media (max-width:640px) {
                        margin-top:48px;
                        margin-left:0;
                    }
                    .input-active, .input-filled {
                        label {
                            color:#fff!important;
                            span {
                                color:#fff!important;
                            }
                        }
                    }
                    .input {
                        width:312px;
                        max-width:100%;
                        @media (max-width:1400px) {
                            width:270px;
                        }
                    }
                    .hs-email {
                        @media (max-width:1280px) {
                            margin-top:-12px;
                        }
                    }
                    .hs-error-msgs {
                        label {
                            margin:0;

                            a {
                                font-size:9px;
                                line-height:12px;
                            }
                        }
                    }
                    .legal-consent-container {
                        width:312px;
                        max-width:100%;
                        .hs-richtext {
                            opacity:0.7;
                            font-size:10px;
                            line-height:14px;
                            margin:10px 0 16px;
                            color:#fff;
                        }
                    }

                    form.hs-form .hs-submit .actions input[type="submit"] {
                        background-color:#F57F29!important;
                        border:0 solid transparent!important;
                        width:312px;
                        max-width:100%;
                        height:52px;
                        transition:0.2s ease;
                        @media (max-width:1280px) {
                            height:42px;
                        }
                        &:hover {
                            filter:brightness(1.05);
                        }
                    }
                    >div{
                        >p{
                            margin-bottom:15px;
                        }
                    }
                }
                .navigation-links {
                    display:flex;
                    justify-content:center;
                    @media (max-width:1280px) {
                        margin:60px auto 0;
                        width:562px; //For IE which can't justify-content center grid elements
                        max-width:100%;
                    }
                    @media (max-width:640px) {
                        flex-direction:column;
                    }
                    > div {
                        display:flex;
                        flex-direction:column;
                        //flex:1;
                        margin-right:64px;
                        @media (max-width:640px) {
                            margin-bottom:28px;
                            margin-left:0;
                            margin-right:auto;
                        }
                        &:last-of-type {
                            margin-right:0;
                            @media(max-width:640px) {
                                margin-bottom:0;
                            }
                        }
                        h2 {
                            @media (max-width:1368px) {
                                font-size:18px;
                            }
                        }
                        a {
                            text-decoration:none!important;
                            margin-bottom:4px;
                            align-self:flex-start;
                            position:relative;

                            &:before {
                                bottom:2.5px;
                                left:0;
                                right:0;
                                height:1px;
                                background:#fff;
                                position:absolute;
                                content:'';
                                pointer-events:none;
                                opacity:0;
                                transition:0.18s ease;
                            }
                            &:hover {
                                &:before {
                                    opacity:0.88;
                                }
                            }
                            &:last-of-type {
                                margin-bottom:0;
                            }
                        }
                    }
                }

                .contact-us {

                    .footer-logo {
                        width:200px;
                    }
                    h5 {
                        margin-bottom:0;
                        font-size:18px;
                        + a {
                            margin-bottom:20px;
                            display:inline-block;
                            + h5 {
                                margin-bottom:10px;
                            }
                        }
                    }
                    a {
                        text-decoration:none!important;
                        font-size:inherit!important;
                        display:inline-block;
                    }
                    > div > a {
                        align-self:flex-start;
                    }
                    .social-links {
                        display:flex;
                        a {
                            text-decoration:none;
                            margin-right:14px;
                            display:flex;
                            flex-direction:column;
                            justify-content:center;
                        }
                    }

                    > div {
                        @media (max-width:1280px) {
                            width:200px;
                        }
                    }
                }
            }
        }
    }

}
