// sass-lint:disable no-important
.cms-catalogs {
    .main {
        .catalog {
            a {
                @include unset-underline;
            }
        }

        .requestform {
            input {
                &[type='radio'],
                &[type='checkbox'] {
                    display: inline-block;
                    height: 20px !important;
                    margin-right: 15px;
                    width: 20px !important;
                }
            }

            .hs-error-msgs {
                position: absolute;
                top: 130%;
                transform: none;
            }

            .hs-submit {
                margin-top: 40px;
            }
        }

        .description {
            a {
                @include unset-underline;

                small {
                    @include font-bold;
                    color: $jpp-blue;
                    font-size: 14px;
                }
            }
        }
    }
}
