.block-bundle {
    .block-title {
        h5 {
            // sass-lint:disable-block no-important
            color: $jpp-dark-blue !important;
            font-size: 14px !important;
        }
    }

    .bundle-items {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        .bundle-item {
            margin-bottom: 8px;

            * {
                font-size: 14px;
                line-height: 23px;
            }

            a {
                @include unset-underline;
            }
        }
    }
}
