.product-badges-container {
    width: auto;

    @media (min-width: $bp-tablet) {
        @include clearfix;
        margin-bottom: $spacer;
    }

    @media (min-width: $bp-tablet) {
        @include clearfix;
        display: inline-block;
        float: right;
        margin-top: $spacer;
    }

    @media (min-width: $bp-laptop) {
        margin-top: 0;
    }
}

.attribute-icons {
    
    .attribute-icon {
        display: inline-block;
        height: 30px;
        margin-right: 1px;
        margin-bottom:0;
        vertical-align: top;
        width: 30px;
    }
}

.product-info-main {
    .product-badges-container {
        @media (min-width: $bp-tablet) {
            margin-bottom: -35px;
            position: relative;
            top: -35px;
        }
    }
}

.flat-rate-shipping-wrapper {
    @media (min-width: $bp-tablet) {
        @include clearfix-before;
        clear: right;
        display: block;
        float: right;
        width: auto;
    }
    a {
        &:hover {
            color:$jpp-blue!important;
        }
    }
    .content {
        @media (min-width: $bp-tablet) {
            @include unset-underline;
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    img,
    .text {
        display: inline-block;

        @media (max-width: $bp-laptop) {
            vertical-align: top;
        }

    }

    img {
        height: auto;
        width: 30px;
    }

    .text {
        vertical-align: middle;

        @media (max-width: $bp-laptop) {
            line-height: 1.2rem;
            padding-top: 3px;
        }
    }

    p,
    span {
        font-size: 10px;
        line-height: normal;
        margin: 0;
    }
}
