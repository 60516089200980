div.jpp-main-categories {
    margin-bottom:84px;
    @media (max-width:1280px) {
        margin-bottom:48px;
    }
    @media (max-width:768px) {
        padding:0;
    }
    h2 {
        font-size:36px;
        text-transform:none;
        margin:0;
        margin-bottom:12px;
        display:inline-block;
        @media (max-width:1280px) {
            font-size:26px;
        }
        @media (max-width:768px) {
            padding:0 16px;
        }
        + span {
            font-size:16px;
            line-height:16px;
            display:inline-block;
            transform:translateX(10px) translateY(-5px);
            @media (max-width:1054px) {
                transform:translateX(0) translateY(-5px);
            }
            @media (max-width:768px) {
                padding:0 16px;
            }
            @media (max-width:480px) {
                display:none;
            }
        }
    }
    .category-tiles {
        display:flex;
        flex-wrap:wrap;
        min-width:calc(100% + 32px);
        transform:translateX(-16px);
        position:relative;
        @media (max-width:1280px) {
            justify-content:center;
        }
        @media (max-width:991px) {
            min-width:100%;
            transform:translateX(0);
            justify-content:space-around;
        }
        @media (max-width:660px) {
            justify-content:center;
        }
        .tile {
            width:236px;
            height:236px;
            border-radius:8px;
            padding:16px;
            margin:8px;
            transition:all 0.18s ease, height 0s, width 0s;
            position:relative;
            @media (min-width:1281px) and (max-width:1610px) {
                width:14vw;
                height:14vw;
            }
            @media (max-width:1280px) {
                width:calc(23vw - 16px);
                height:calc(23vw - 16px);
            }
            @media (max-width:991px) {
                width:calc(30.666vw - 16px);
                height:calc(30.666vw - 16px);
            }
            @media (max-width:660px) {
                width:calc(50vw - 32px);
                height:calc(50vw - 32px);
            }
            a {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
            }
            img {
                display:block;
                margin:0 auto;
                max-width:94%;
                max-height:128px;
                position:absolute;
                left:50%;
                top:50%;
                transform:translateX(-50%) translateY(-54%);
                @media (max-width:768px) {
                    max-height:60%;
                    transform:translateX(-50%) translateY(-58%);
                }
            }
            h5 {
                font-size:16px;
                line-height:18px;
                margin:0;
                position:absolute;
                left:10px;
                right:10px;
                bottom:12px;
                text-transform:none;
                text-align:center;
                font-weight:700;
                transition:0.18s ease;
                @media (max-width:768px) {
                    font-size:14px;
                    line-height:15px;
                    bottom:10px;
                }
                @media (max-width:480px) {
                    left:6px;
                    right:6px;
                    bottom:6px;
                }
            }
            &:hover {
                box-shadow:0px 0px 12px rgba(2,2,2,0.12);
                h5 {
                    color:#0088ce;
                }
            }
        }
    }
}