// primaries
// sass-lint:disable no-color-literals
$jpp-blue: #0088ce;
$jpp-blue-hover: #1e95d4;
$jpp-dark-blue: #004f9d;
$jpp-blue-bg: #0277b3;
$jpp-blue-bg-2: #0076bd;
$blue-gradient-bg: linear-gradient(to right, #0175bd 40%, #5eb1e5 100%);
$jpp-orange: #f58220;
$jpp-orange-hover: #f6913a;
$jpp-marigold: #ffc324;
$jpp-marigold-field-background: #fff8e6;
$jpp-marigold-hover: darken($jpp-marigold, 20%);
$jpp-light-yellow: #fffdec;

// neutrals
$jpp-off-white: #f9f9f9;
$jpp-off-off-white: #ededed;
$jpp-grey: #dfdfdf;
$jpp-grey-2: #777;
$jpp-grey-3: #646464;
$jpp-grey-4: #f5f5f5;
$jpp-grey-5: #e2dfdd;
$jpp-off-black: #353535;
$jpp-dark-grey: #222;

$jpp-swatch-grey: #dedede;
$jpp-swatch-grey-hover: #b1b1b1;

$white: #fff;
$black: #000;
$box-shadow: rgba(0, 0, 0, .16);
$box-shadow-light: rgba(0, 0, 0, .08);
$jpp-grey-700: #646464;
$jpp-dash-btn-border: #e7e7e7;
$shadow-overlay: rgba(58, 58, 58, .56);
$shadow-overlay-gradient:linear-gradient(120deg, rgba(2,2,2,0.38) 0%, rgba(2,2,2,0.54) 50%, rgba(2,2,2,0.38) 100%);

// form
$light-grey: #b3b3b3;
$light-grey-2: #b9b9b9;
$white-2: #f3f3f3;

// tiles
$tile-blue: #004f9d;
$tile-blue-2: #0075bc;
$tile-light-blue: #86b9d8;
$tile-orange: #f57f1a;
$tile-dark-grey: #575757;
$tile-grey: #b0b0b0;

// alerts
// New alert colors
$jpp-default-blue-background: #e8f5fc;
$jpp-default-blue-border-light: #8ec5ef;
$jpp-default-blue-border-dark: #1c96de;
$jpp-default-blue-text: #145c9b;

$jpp-success-dark-grey: #2e2f32;

$jpp-success-green-background: #e8fce8;
$jpp-success-green-border-light: #b3dd84;
$jpp-success-green-border-dark: #79de1c;
$jpp-success-green-text: #149b26;

$jpp-error-red-background: #fce8e9;
$jpp-error-red-border-light: #ef8e92;
$jpp-error-red-border-dark: #de1c24;
$jpp-error-red-text: #9b1419;

$jpp-warning-yellow-background: #fcfce8;
$jpp-warning-yellow-border-light: #efd38e;
$jpp-warning-yellow-border-dark: #deab1c;
$jpp-warning-yellow-text: #9b9214;




// Old alert colors
$jpp-success-green: #68bb5b;
$jpp-success-green-dark: #3c763d;
$alert-green-opacity: rgba(104, 187, 91, .2);
$alert-green-light:#e1f1de;
$jpp-light-green: #edfaea;
$jpp-error-red: #C81313;
$jpp-error-red-dark: #791217;
$jpp-alert-yellow:#C28800;
$jpp-alert-yellow-light:#FCF4E5;
$alert-red-opacity: rgba(184, 31, 39, .2);
$alert-red-light:#F9E6E6;
$alert-blue-opacity: rgba(0, 136, 206, .2);
$alert-blue-light:#cce7f5;
$bright-green: #00ea3e;
$bright-yellow: #f5ff60;
$jpp-info-blue-light: #cce7f5;
$jpp-info-blue: #004f9d;
$jpp-info-blue-dark: #004f9d;

// badges

$badge-yellow: #fcf1d5;
$badge-yellow-text: #714401;


// social icons
$jpp-facebook: #3b5998;
$jpp-twitter: #0d95e8;

// semantics
$jpp-white: $jpp-off-white;
$jpp-charcoal: $jpp-off-black;
$jpp-light-grey: $jpp-off-off-white;

$alert-green: $jpp-success-green;
$alert-dark-green: $jpp-success-green-dark;
$alert-red: $jpp-error-red;
$alert-red-dark: $jpp-error-red-dark;

$breadcrumbs-background: #f2f6fb;
$jpp-mobile-thumbnail-bullet-border: #afafaf;
$jpp-qty-increment-decrement-icon: #222;
