.newsletter-manage-index {
    .form {
        .fieldset {
            max-width: unset !important; // sass-lint:disable-line no-important

            .heading {
                color: $jpp-off-black;
                font-size: 20px;
                font-weight: 500;
                text-transform: unset;

            }

            .col-sm-6 {
                &.padding-left-none {
                    padding-left: 0;
                }

                &.padding-right-none {
                    padding-right: 0;
                }
            }

            .field {
                margin-bottom: 20px;
            }

            .unsubscribe-info {
                color: $jpp-grey-2;
                font-size: 14px;
                font-style: italic;
                margin: 15px 0 50px;
            }

            .title-section {
                padding-top: 20px;
            }
        }

        .actions-toolbar {
            text-align: left !important; // sass-lint:disable-line no-important
            padding:0!important;
            box-shadow:0px 0px 0px transparent!important;
        }
    }

    .reward-settings {
        @include customer-container;

        h3 {
            &.title {
                @include customer-container-title;
            }
        }

        #products-interests {
            height:160px;
        }
    }
}
