@mixin fast {
    transition:0.18s ease;
}

@mixin smooth {
    transition:0.24s;
}

@mixin slow {
    transition:0.8s ease-in-out;
}

#topbar{background:#111;height:40px;z-index:200;position: relative;}
#topbar .inner-wrapper{width:1500px;max-width:92%;margin-left:auto;margin-right:auto;display:flex;justify-content:flex-end}
#topbar li,#topbar a{color:#fff;font-weight:800;outline: 0!important;text-decoration: none !important;}
#topbar .left-message{align-self:start;flex-grow:1;overflow:hidden;height:40px;position: relative;}
#topbar .left-message::after{content:"";display:inline-block;position:absolute;top:0;right:0;bottom:0;width:50px;z-index:2;background:linear-gradient(90deg,rgba(17,17,17,0) 0%,#111 100%);pointer-events:none}
#topbar .left-message a{height:40px;display:inline-block;line-height:40px;font-size:12px;font-weight:500;position:absolute;z-index:1;margin-left:10px;opacity:1;transition:opacity 0.6s,left 4s linear;white-space:nowrap;outline: 0!important;text-decoration: none;}
#topbar .left-message a:not(.active){opacity:0;pointer-events:none}
#topbar .left-message a span{font-weight:600;margin-left:8px;z-index:1;font-size:12px}
#topbar .left-message span.arrow-right::after{padding-left:6px;top:1.5px;position:relative}
#topbar .left-message a:before{top:10px;bottom:10px;left: -10px;right: -10px;background:rgba(0,136,206,0.3);border-radius:4px;content:"";position:absolute;pointer-events:none;opacity:0;transition:0.18s ease;z-index: -1}
#topbar .left-message a:hover:before{opacity:1}
#topbar .top-bar-menu{flex-basis:min-content}
#topbar ul{margin:0;padding:0;display:flex;list-style: none;}
#topbar li{position: relative;}
#topbar li.active{z-index:2;position: relative;}
#topbar ul li>a{margin:0 10px;line-height:40.5px;display:block;height:40px;transition:0.24s;opacity:0.9;font-size:12px;text-transform:uppercase;font-weight:500;letter-spacing:0.2px}
#topbar ul li>a:hover,#topbar li.active a{opacity:1}
#topbar ul li .dropdown{position:absolute;top:100%;left:0;opacity:1;display:flex;flex-direction:column;transform:translateY(-1px);transition-delay:0.12s;z-index:2;background:#fff;padding:16px 16px 14px;transition:0.24s;box-shadow:0 0 24px rgba(2,2,2,0.2)}
#topbar ul li:not(.active) .dropdown{opacity:0;transform:translateY(4px);pointer-events:none}
#topbar ul li .dropdown a{font-size:13px;font-weight:500;color:#0088CE;white-space:nowrap;padding:0;line-height:16px;margin-bottom:7px;height:auto}
#topbar ul li .dropdown a:last-of-type{margin-bottom:0}
#topbar ul li .dropdown a:hover{color:#F58220}
#topbar ul li .dropdown::before{position:absolute;content:"";height:2px;top:0;left:0;right:0;background:#0088CE}
#topbar ul li.rewards a{padding:0 0 0 6px;margin:0;opacity:1}
#topbar li.rewards a:hover{filter:brightness(1.1)}
#topbar li.rewards svg{width:95.24px;height:40px}
