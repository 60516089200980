// sass-lint:disable no-important
@mixin button($type: 'primary', $bg-color: null) {
    @include font-bold;
    @include unset-underline;
    @include smooth;
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    box-sizing: border-box;
    color: $white;
    font-family: $proxima-nova;
    font-size: 14px !important;
    line-height: 100%;
    padding: 13px 25px !important;
    text-transform: uppercase;

    @if $type == 'primary' {
        background-color: $jpp-blue !important;
        border: 1px solid $jpp-blue !important;
    }
    @else if $type == 'secondary' {
        background-color: $jpp-orange !important;
        border: 1px solid $jpp-orange !important;
    }
    @else if $type == 'marigold' {
        background-color: $jpp-marigold !important;
        border: 1px solid $jpp-marigold !important;
    }
    @else if $type == 'border-grey' {
        background-color: $white !important;
        border: 1px solid $jpp-grey-3 !important;
    }
    @else if $type == 'border-white' {
        background-color: transparent !important;
        border: 1px solid $white !important;
    }
    @else if $type == 'border-blue' {
        background-color: $white !important;
        border: 2px solid $jpp-blue !important;
        color: $jpp-blue;
    }
    @else if $type == 'border-orange' {
        background-color: $white !important;
        border: 1px solid $jpp-orange !important;
        color: $jpp-orange;
    }

    span {
        @include font-bold;
        @include smooth;
        color: $white;
        font-family: $proxima-nova;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;

        @if $type == 'border-grey' {
            color: $jpp-grey-3;
        }
        @if $type == 'border-white' {
            color: $white;
        }
        @else if $type == 'border-blue' {
            color: $jpp-blue;
        }
        @else if $type == 'border-orange' {
            color: $jpp-orange;
        }
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 12px 0 $box-shadow;
        @include smooth;

        span {
            @include smooth;
        }
        @if $type == 'primary' {
            background-color: $jpp-blue-hover !important;
        }

        @else if $type == 'secondary' {
            background-color: $jpp-orange-hover !important;
        }

        @else if $type == 'marigold' {
            background-color: $jpp-marigold-hover !important;
        }

        @else if $type == 'border-grey' {
            background-color: $jpp-grey-3 !important;

            span {
                color: $white;
            }
        }

        @else if $type == 'border-white' {
            background-color: transparent !important;
            color: $white;

            span {
                color: $white;
            }
        }

        @else if $type == 'border-blue' {
            background-color: $jpp-blue !important;
            color: $white;

            span {
                color: $white;
            }
        }

        @else if $type == 'border-orange' {
            background-color: $jpp-orange !important;
            color: $white !important;

            span {
                color: $white !important;
            }
        }
    }
}

@mixin invert-button($type: 'primary') {
    @include button($type);
    @include unset-underline;
    background-color: $white !important;

    @if $type == 'primary' {
        border: 2px solid $jpp-blue !important;

        span {
            color: $jpp-blue;
        }
    }
    @else if $type == 'secondary' {
        border: 2px solid $jpp-orange !important;

        span {
            color: $jpp-orange;
        }
    }

    &:hover,
    &:focus {
        span {
            color: $white;
        }
    }
}

@mixin dashboard-button {
    @include unset-underline;
    border: 1px solid $jpp-dash-btn-border;
    color: $jpp-off-black;
    display: block;
    height: 32px;
    text-align: center;

    &:hover {
        box-shadow:0px 1px 4px rgba(2,2,2,0.12);
        span {
            color:$jpp-blue;
        }
    }
    span {
        @include font-bold;
        font-size: 12px;
        line-height: 31px;
        text-transform: uppercase;
    }
}

.action {
    @include smooth;
    span {
        @include smooth;
        &:hover {
            @include smooth;
        }
    }
    &.primary {
        @include button('primary');
    }

    &.secondary,
    &.subscribe {
        @include button('secondary');
    }
}

.button-marigold {
    @include button('marigold');
    @include unset-underline;
    background:linear-gradient(to right,#FFC324 0%,#f5b200 50%,#FFC324 100%);
    text-shadow:1px 1px 2px rgba(0, 0, 0, 0.14);
    &:hover {
        background:linear-gradient(to right,#FFC324 0%,#f5b200 50%,#FFC324 100%);
    }
}

a {
    &.primary,
    &.secondary {
        @include unset-underline;
    }
}

.orange-button {
    @include button('border-orange');
    @include unset-underline;
    color: $jpp-orange !important;
    display: inline-block;
    width: auto;
}

.large-blue-button,
.large-orange-button {
    display: block;
    max-width: 500px;
    text-align: center;
    width: 100%;

    &:hover {
        color: $white;
    }
}

.large-blue-button {
    @include button('primary');
    font-size: 18px !important;
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

.large-orange-button {
    @include button('secondary');
    font-size: 18px !important;
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

.bluebutton,
.button-blue,
.blue-button,
.pagebuilder-button-primary {
    @include button('primary');
    color:$white!important;
    font-size:14px!important;
    height:auto!important;
    line-height:normal!important;

    * {
        font-size:14px!important;
        line-height:normal!important;
    }

}

.btn-blue {
    @extend .blue-button;
    height: 40px !important;
}

.orangebutton,
.button-orange,
.pagebuilder-button-secondary {
    @include button('secondary');
    font-size: 14px !important;
    height: auto !important;
    line-height: normal !important;
}

.button-white {
    @include button('border-white');
    height: auto !important;
    line-height: normal !important;
}

.page-wrapper {
    .main {
        .hs-form {
            .hs-button {
                &.primary {
                    @include button('primary');
                    font-weight: 800 !important;
                    height: auto !important;
                    line-height: normal !important;
                }
            }
        }
    }
}


.inline-button {
    height:22px;
    min-width:70px;
    font-size:12px;
    font-weight:600;
    line-height:20px;
    text-transform:uppercase;
    text-decoration:none!important;
    text-align:center;
    padding:0 8px;
    background:#fff;
    border:1px solid #c9c9c9;
    color:#444;
    border-radius:4px;
    margin-right:8px;
    display:inline-block;
    @include smooth;
    &:hover {
        border-color:$jpp-blue;
        color:$jpp-blue;
    }
}