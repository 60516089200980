.checkout-freeshipping-wrapper {
    @include sidebar-box;
    //padding: #{$spacer * 1.2};
    position: relative;
    padding:14px 24px;
    padding-top:8px;
    height:67px;
    display:flex;
    justify-content:center;
    flex-direction:column;
    @media (max-width:480px) {
        height:60px;
    }
    @media (max-width: $bp-tablet - 1) {
        margin-bottom: 12px;
        order: 30;
        width: 100%;
    }

    @media (min-width: $bp-tablet) {
        margin-top: 0;
    }

    .icon-free-shipping {
        @include icon($icon-jpp-freeshippingtracker, inherit, 32px);
        left:14px;
        margin: 0;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
    }

    .qualification-label {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        padding-left:34px;
        @media (max-width:480px) {
            font-size:13px;
        }
        a {
            color:inherit;
        }
        * {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .progress-bar {
        background-color: $jpp-grey;
        bottom: 0;
        display: block;
        left: 0;
        margin-bottom: 0;
        position: absolute;
        width: 100%;
    }

    .bar {
        background: linear-gradient(271deg, $bright-green, $bright-yellow);
        display: block;
        height: 7px;
        transition: width .5s ease;
        width: 0;
    }
}
.marketing-opt-in-messaging{
    margin-top: 15px;
    .opt-in-message-wrapper{
        display: flex;
        align-items: center;
    }
    .opt-in-message{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        margin-left: 15px;
        color: #646464;
    }
    .opt-in-checkbox{
        margin: 0;
        top: 0;
    }
    .opt-in-email-wrapper{
        .opt-in-message{
            margin-bottom: 0;
            margin-right: 0;
        }
        .opt-in-form-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .opt-in-checkbox{
                margin-left: 20px;
                top: 9px;
            }
        }
    }
}
