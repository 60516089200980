.page-header {

    a.logo-link {
        display:inline-block;
        order:1;
        width:172px;
        @media (min-width: $bp-tablet) {
            width:220px;
        }

    }
    .logo {
        margin: 0;
        padding-left: 0;
        width:auto;
        margin-right:calc(50% - 172px);
        transition:0s;
        max-width:172px;
        height: 50px;
        @media (min-width: $bp-tablet) {
            margin-right:calc(25% - 220px);
            padding-left:0;
            max-width:220px;
        }

        @media (max-width: 991px) {
            padding-bottom: 15px;
        }

        img {
            max-width: 220px;
            width: 100%;
        }
    }
}
