.checkout-onepage-success {

    .page.messages,  // sass-lint:disable-line force-element-nesting no-qualifying-elements
    #sns_main {
        margin: 0 auto;
        max-width: 100%;
        width: 1000px;
    }
    .page-title-wrapper {
        a.action.print {
            display:none;
        }
    }
    .checkout-success-container {
        box-shadow: 0 2px 16px 0 $box-shadow-light;

        .checkout-success {
            background-color: $white;
            border: 6px solid $jpp-light-green;
            padding: #{$spacer * 1.2};
            position: relative;
            margin-top:-24px;
            @media (min-width: $bp-tablet) {
                @include clearfix;
                border: 10px solid $jpp-light-green;
                padding: #{$spacer * 3} #{$spacer / 1.5};
            }
            @media (min-width: $bp-laptop) {
                @include clearfix;
                margin-top:-32px;
            }
            p.subheading {
                margin:0;
                font-weight:700;
            }
            p.order-string {
                font-weight:700;
                a {
                    text-decoration:none!important;
                }
            }
        }

        .image-heading-container {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;

            @media (min-width: $bp-tablet) {
                display: block;
            }
        }

        .success-icon {
            max-width: 54px;
            width: 100%;
            @media (max-width:$bp-mobile-l) {
                margin:0 12px;
            }
            @media (min-width: $bp-tablet) {
                max-width: 100px;
                position: absolute;
                right: 80%;
                top: 50%;
                transform: translateY(-50%);
            }

            @media (min-width: $bp-laptop) {
                max-width: 120px;
                right: 75%;
            }
        }

        .order-details {
            @media (min-width: $bp-tablet) {
                padding-left: 25%;
            }

            @media (min-width: $bp-laptop) {
                padding-left: 33%;
            }
        }

        .heading {
            @include font-bold;
            font-size: 20px;
            margin: 0 0 0 10px;

            @media (min-width: $bp-tablet) {
                margin: 0 0 10px;
            }
        }

        .order-string {
            text-transform: uppercase;
        }

        .actions-toolbar {
            margin-top: 20px;

            .action {
                margin-bottom: 10px;
                width: 100%;

                @media (min-width: $bp-tablet) {
                    margin-top: 0;
                    width: auto;
                }
            }

            .print {
                @media (min-width: $bp-tablet) {
                    float: left;
                    margin-left: 0;
                    margin-right: 15px;
                }
            }
        }
    }

    .feedback-container {
        background-color: $white;
        box-shadow: 0 2px 16px 0 $box-shadow-light;
        margin-top: $spacer;
        padding: #{$spacer * 1.2};

        @media (min-width: $bp-tablet) {
            padding: #{$spacer * 1.2};
        }
        @media (min-width: $bp-desktop) {
            padding: 64px;
        }
        .hbspt-form {
            @media (min-width: $bp-laptop) {
                @include clearfix;
                position: relative;
            }
            fieldset {
                div {
                    position:relative;
                }
            }
            .left-pane, .hs-richtext.hs-main-font-element {
                @media (min-width: $bp-laptop) {
                    float: left;
                    margin: 0;
                    width: 325px;
                    transform:translateY(10px);
                }
                padding-bottom: 20px;
                h3 {
                    color: $jpp-blue;
                    font-size: 20px;
                    margin-top: 0;
                }
            }
            .hs-form {
                margin-bottom: 0;
                padding-left:0;
                padding-right:0;

                @media (min-width: $bp-laptop) {
                    width:calc(100% - 420px);
                    float: right;
                }
                .hs-button.primary {
                    min-width:240px;
                    background:$jpp-blue!important;
                    border:0px solid transparent!important;
                    background-image:none!important;
                }


                .hs-form-field {
                    margin:0;
                    label {
                        margin:8px 0 7px 10px;
                    }
                    > label > span {
                        font-size:14px;
                        font-weight:600;
                        margin-top:24px;
                        margin-bottom:0;
                    }
                    .hs-form-checkbox {
                        height:40px;
                        label {
                            display:flex;
                            input {
                                margin-top:0;
                            }
                            input + span {
                                line-height:13px;
                                padding-top:6px;
                            }
                        }
                    }
                }

                textarea {
                    border-radius:0px;
                    border-width:1.5px;
                    border-color:#ededed;
                    box-shadow:0px 0px 12px rgba(2,2,2,0);
                }

                input {
                    &[type='text'],
                    &[type='email'] {
                        height: 36px;
                        width: 100%;
                        max-width:100%;
                        border-radius:0px;
                        box-shadow:0px 0px 0px transparent;
                    }
                }
    
                .input {
                    margin-right: 0;
                }
                .hs_additional_order_comments label {
                    margin-left:10px!important;
                    font-size:14px!important;
                    font-weight:600;
                    vertical-align: top;
                    position:relative;
                }
                .form-columns-1 {
                    &:nth-of-type(1) {
                        > div > label > span {
                            font-size:18px;
                            font-weight:800;
                            margin-top:8px;
                            margin-bottom:8px;
                        }
                        ul.inputs-list {
                            margin-bottom:24px;
                        }
                    }
                    &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
                        ul.inputs-list {
                            display:flex;
                            flex-wrap:wrap;
                            li {
                                @media (min-width:$bp-laptop) {
                                    width:50%;
                                    &:nth-child(odd) {
                                        transform:translateX(-10px);
                                    }
                                }
                                label {
                                    span {
                                        font-size:13px;
                                    }
                                }
                            }
                        }
                    }

                    .hs-fieldtype-textarea, .hs-fieldtype-text {
                        label {
                            font-size:14px;
                            font-weight:600;
                        }
                        .hs-error-msgs {
                            label {
                                font-size:12px;
                                margin-bottom:0;
                            }
                        }
                    }
                }
                .hs-fieldtype-textarea {
                    label {
                        color:#b3b3b3;
                    }
                    &.input-filled, &.input-active {
                        color:#000;
                    }
                }
                .hs-fieldtype-text {
                    label span {
                        text-transform:capitalize;
                    }
                }
                .form-columns-2 {
                    @include clearfix;
                    @include grid-collapse;
                    margin-left:0;
                    margin-right:0;
                    width:100%;
                    > div:nth-of-type(1) {
                        padding-left:0;
                        padding-right:6px;
                    }
                    > div:nth-of-type(2) {
                        padding-left:6px;
                        padding-right:0;
                    }
                    > .hs-fieldtype-text {
                        @include grid-width(6);
                    }
                }
                .legal-consent-container {
                    margin:0;
                    transform:translateY(18px);
                    p {
                        line-height:1.4;
                        font-size:10px;
                        margin:0;
                    }
                }
            }

            .hs-fieldtype-radio {
                margin:0;
            }
            .hs-error-msg {
                label {
                    transform:translateY(0)!important;
                }
            }


            .hs_online_shopping_experience { // sass-lint:disable-line class-name-format
                @media (min-width: $bp-tablet) {
                    margin-top: 0;
                }
                ul {
                    margin-bottom:0!important;
                }
                ul li label {
                    margin-left:0!important;
                }
                > label {
                    margin-left:0!important;
                    > span {
                        @include font-bold;
                        color: $jpp-off-black;
                        font-size: 20px;
                    }
                }

                > .hs-field-desc {
                    background: linear-gradient(to right, #dc2929, #ffce2e 51.14%, #68bb5b 100%); // sass-lint:disable-line no-color-literals
                    display: block !important; // sass-lint:disable-line no-important
                    height: 6px;
                    margin-top: 30px;
                    position: relative;

                    &::before,
                    &::after {
                        color: $jpp-off-black;
                        font-size: 12px;
                        position: absolute;
                        top: -18px;
                    }

                    &::before {
                        content: 'Unsatisfied';
                        left: 0;
                    }

                    &::after {
                        content: 'Satisfied';
                        right: 0;
                    }
                }

                > .input {
                    margin: 0;
                }

                .inputs-list {
                    align-items: center;
                    display: flex;
                    //justify-content: center;
                    justify-content:space-between;
                    padding-left:0;
                    padding-top:8px;
                    padding-bottom:10px;
                    text-align:center;

                    > li {
                        width:24px;
                        > label {
                            input,
                            span {
                                @include font-normal;
                                box-shadow:none;
                                display:block;
                                margin:0 auto;
                                text-align:center;
                            }
                        }
                     }
                }
            }
        }
    }
}
