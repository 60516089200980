.toolbar-blog-posts {
    .pages {
        .page {
            @include unset-underline;

            span {
                color: $jpp-blue;
            }
        }

        .current {
            .page {
                span {
                    color: $jpp-off-black;
                }
            }
        }

        .action {
            border: 0;

            &::before {
                color: $jpp-blue;
            }

            &.next {
                margin-left: 0;
            }

            &.previous {
                margin-right: 0;
            }
        }
    }
}
