body.cms-videos {
    div.breadcrumbs {
        display:none;
    }
    .webinars.header-area .background.image {
        &.videos {
            background:none!important;
            opacity:0;
        }
    }
    .header-area {
        .background.cover {
            &::after {
                background-image:radial-gradient(circle at 105% 110%, rgba(157,51,0,0.44) 0%, rgba(0,79,157,0) 60%),radial-gradient(circle at -15% 20%, rgba(157,46,0,0.44) 0%, rgba(0,79,157,0) 100%),radial-gradient(circle at 50% 50%, rgba(157,138,0,0.4) 0%, rgba(0,79,157,0) 100%)!important;
                filter:saturate(2) hue-rotate(10deg);
            }
        }
    }
    section.recent-videos {
        .recent-videos-slider {
            width:1280px;
            .video {
                min-height:0;
                padding:0;
                border:0;
                width:400px;
                margin-right:24px;
                position:relative;
                overflow:hidden;
                background-size:cover;
                background-position:center;
                background-color:#111;
                .inner-wrapper {
                    width:100%;
                    padding-bottom:56.25%;
                    z-index:3;
                }

                .video-title, .buttons {
                    z-index:2;
                }
                .buttons {
                    display:none;
                }
                .video-title {
                    position:absolute;
                    bottom:20px;
                    left:24px;
                    z-index:3;
                    width:calc(100% - 24px);
                    h4 {
                        color:#fff;
                        margin:0;
                        font-size:20px;
                        max-width:calc(100% - 24px);
                        text-transform:none;
                        text-shadow:0px 0px 4px rgba(2,2,2,0.6), 0px 0px 6px rgba(2,2,2,0.12);
                    }
                }
                &:before {
                    z-index:2;
                    position:absolute;
                    content:'';
                    bottom:0;
                    left:0;
                    right:0;
                    height:128px;
                    background:linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
                }
                img.featured-image {
                    display:block;
                    position:absolute;
                    z-index:1;
                }
                a.watch {
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    z-index:3;
                }
            }
        }
    }

    .featured-previous-wrapper {
        background:linear-gradient(to bottom, #f9fcfe 0%, #ffcf87 33%, #201c14 50%, #000000 66%, #000000 100%);
        &::before {
            background-image:radial-gradient(circle at 38% 80%, rgba(245,130,32,0.34902) 0%, rgba(0,136,206,0) 82%),radial-gradient(circle at 20% 74%, rgba(206,0,0,0.2) 0%, rgba(0,136,206,0) 48%),radial-gradient(circle at 96% 108%, rgba(245,130,32,1) 0%, rgba(0,136,206,0) 62%)!important;
            filter:saturate(1.4);
        }
    }

    section.previous-videos {

        .previous-videos-grid {
            margin: 40px auto 32px;
            min-height:334px;
            width:1420px;
            max-width: 100%;
            position:relative;
            @media (max-width:480px) {
                max-height:238px;
            }
            &:not(.flickity-enabled) {
                display:flex;
                flex-wrap:wrap;
                max-height:754px;
                pointer-events:none;
                * {
                    opacity:0!important;
                }
                @media (max-width:1280px) {
                    max-height:1100px;
                }
            }
            & + .loader-placeholder {
                position:absolute;
                top:48%!important;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
            }
            &.flickity-enabled {
                & + .loader-placeholder {
                    display:none;
                }
            }

            @media (max-width:991px) {
                margin:32px auto;
            }
            > .webinar {
                //Hiding Until Paginated
                opacity:0;
            }
            .flickity-button {
                display:none!important;
                opacity:0!important;
            }
            .flickity-viewport, .flickity-slider {
                width:100%;
                position:relative;
            }
            .flickity-page-dots {
                display:flex;
                width:100%;
                justify-content:center;
                list-style-type:none;
                padding:0;
                margin:32px 0 12px;
                position:absolute;
                bottom:-80px;
                @media (max-width:480px) {
                    bottom:-64px;
                }
                li.dot {
                    width:20px;
                    height:20px;
                    border-radius:100%;
                    background:rgba(255,255,255,0);
                    border:1.5px solid #fff;
                    margin-right:10px;
                    transition:0.2s ease;
                    cursor:pointer;
                    @media (max-width:991px) {
                        width:16px;
                        height:16px;
                    }
                    &:only-of-type {
                        display:none;
                    }
                    &:last-of-type {
                        margin-right:0;
                    }
                    &:hover {
                        background:rgba(255,255,255,0.5);
                        border:1.5px solid rgba(255,255,255,0.5);
                    }
                    &.is-selected {
                        background:rgba(255,255,255,1)!important;
                        cursor:default;
                    }
                }
            }
            .not-found {
                color:#fff;
                font-size:16px;
                opacity:0;
                animation:fadein 0.6s;
                animation-fill-mode:forwards;
            }
            .previous-videos-page {
                position:relative;
                display: flex;
                flex-wrap: wrap;
                width:100%;
                opacity:0;
                transform:scale(0.92);
                transition:all 0.5s, opacity 1.2s, transform 1s;
                transition-timing-function:cubic-bezier(0.785, 0.165, 0.345, 0.945);
                pointer-events:none;
                > .webinar {
                    opacity:1;
                }
                &.is-selected {
                    opacity:1;
                    transform:scale(1);
                    pointer-events:auto;
                }
            }
            .webinar {
                background: #111;
                background-size:cover;
                background-position:center;
                width: calc(50% - 5px);
                max-width: 100%;
                margin-right:10px;
                margin-bottom:10px;
                border-radius:0px;
                overflow:hidden;
                display:flex;
                flex-direction:column;
                justify-content:flex-start;
                transition:0.4s ease;
                box-shadow:0px 0px 12px rgba(2,2,2,0.18);
                position:relative;
                &:nth-of-type(2n) {
                    margin-right:0;
                }

                @media (min-width:992px) {
                    width: calc(33.333% - 10.666px);
                    margin-right: 16px;
                    margin-bottom: 16px;
                    border-radius:8px;
                    &:nth-of-type(2n) {
                        margin-right: 16px;
                    }
                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
                @media (min-width:1280px) {
                    width:calc(25% - 12px);
                    &:nth-of-type(3n) {
                        margin-right:16px;
                    }
                    &:nth-of-type(4n) {
                        margin-right:0;
                    }
                }
                h4 {
                    margin:0;
                    text-transform:none;
                    font-size:18px;
                    font-weight:700;
                    cursor:default;
                    text-align:left;
                    color:#fff;
                    max-width:calc(100% - 52px);
                    z-index:3;
                    text-shadow:0px 0px 4px rgba(2,2,2,0.6), 0px 0px 6px rgba(2,2,2,0.12);
                    @media (max-width:768px) {
                        font-size:16px;
                    }
                }

                .webinar-title, .buttons {
                    padding:20px 16px;
                    z-index:3;
                    position:absolute;
                    bottom:16px;
                    left:20px;
                    @media (max-width:768px) {
                        padding:10px 16px;
                    }
                    @media (max-width:480px) {
                        transform:scale(0.94);
                    }
                }

                .webinar-image {
                    position:relative;
                    &:before {
                        z-index:2;
                        content:'';
                        background:linear-gradient(to top, rgba(0,0,0,0.88) 0%, rgba(0,0,0,0) 100%);
                        position:absolute;
                        bottom:0;
                        left:0;
                        right:0;
                        height:100px;
                        pointer-events:none;
                    }
                    a {
                        position:relative;
                        display:block;
                        padding-bottom:56.25%;
                        overflow:hidden;
                        img {
                            position:absolute;
                            min-width:102%;
                            height:102%;
                            top:50%;
                            left:50%;
                            transform:translateX(-50%) translateY(-50%);
                        }
                    }
                }
                .webinar-title {
                    padding:0!important;
                }

                .buttons {
                    margin-top:auto;
                    display:flex;
                    justify-content:center;
                    padding-bottom:28px;
                    display:none;
                    @media (max-width:768px) {
                        padding:16px;
                    }
                    @media (max-width:600px) {
                        display:flex;
                        flex-direction:column-reverse;
                    }
                    a.blue-button {
                        display:inline-block;
                        margin-right:12px;
                        height:34px!important;
                        font-size:12px!important;
                        padding:0 14px!important;
                        line-height:33px!important;
                        max-width:120px;
                        @media (max-width:600px) {
                            width:100%;
                            max-width:100%;
                            margin-right:0!important;
                            &:nth-of-type(2) {
                                margin-bottom:6px;
                            }
                        }
                        &:last-of-type {
                            margin-right:0;
                        }
                        &.secondary, &.download-files {
                            background:#fff!important;
                            border:1.5px solid #0088CE!important;
                            color:#0088CE!important;
                            line-height:31px!important;
                            &:hover {
                                background:#0088CE!important;
                                color:#FFF!important;
                                border:1.5px solid #0088CE!important;
                            }
                        }

                    }
                }
            }
        }

    }

}