.vcpaccount-index-index, .cms-vcp {
    .vcpaccount-wrapper {
        @include customer-container;
        h2 {
            margin-top: 0;

            img {
                max-width: 260px;
            }
        }

        #vcp-qualify-o-meter {
            margin: 35px 0;

            h4 {
                font-size:  1.6rem;
                font-weight: 800;
                line-height: 2.2rem;
                text-align: left;
                text-transform: uppercase;
            }

            .progress {
                background: $jpp-off-off-white;
                height: 14px;
                line-height: 1.4rem;
                position: relative;
                width: 100%;
                z-index: auto;
                overflow:hidden;
                box-shadow:inset 0px 0px 6px rgba(2,2,2,0.08);
            }

            #vcpProgressBar {
                background: rgb(255, 195, 36);
                background: linear-gradient(to right, rgba(255, 195, 36, 1) 0%, rgba(104, 187, 91, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc324', endColorstr='#68bb5b',GradientType=1 );
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                z-index: auto;
                &::before {
                    position:absolute;
                    content:'';
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    background:linear-gradient(to right, #ffdb7d 0%, #ffc324 100%);
                    @include smooth;
                    opacity:1;
                    z-index:1;
                }
                &.almost-complete {
                    &::before {
                        opacity:0;
                    }
                }
            }

            .instructions {
                font-size: 1.65rem;
                font-weight: 500;
                line-height: 2.2rem;
                margin: 10px 0 0;
                text-align: center;
                width: 100%;
                //opacity:0;
                .percent {
                    color: $jpp-blue;
                    font-weight: 700;
                }
            }
        }
    }

    .hbspt-form {
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;

        * {
            &,
            &::after,
            &::before {
                box-sizing: border-box;
            }
        }

        #hsForm_a641447e-bfdd-4f35-9827-738277978ac1 {
            opacity:0;
            .hs-form-field {
                .hs-error-msgs {
                    padding-top:4px;
                    li {
                        min-height:14px;
                        margin:0;
                        max-height:18px;
                        label {
                            margin-left:12px;
                        }
                    }
                    label.hs-error-msg {
                        font-size:12px;
                        line-height:12px;
                        margin:0;
                        white-space:nowrap;
                        color:$jpp-error-red;
                    }
                    li label a {
                        display:none;
                    }
                }
            }
            fieldset.form-columns-2 .input, fieldset.form-columns-1 .input, fieldset.form-columns-3 .input  {
                margin-right:0;
            }
            fieldset {
                max-width: 100%;
                @media (min-width: $bp-tablet) {
                    margin-bottom: $account-spacer;
                }

                .field {
                    &.hs-fieldtype-select {
                        > label {
                            z-index:2!important;
                        }
                        div.input {
                            position:relative;
                            &::before {
                                position:absolute;
                                content:'';
                                top:1.5px;
                                left:1.5px;
                                bottom:1.5px;
                                right:20px;
                                transition:0.14s ease;
                                transition-delay:0.1s;
                                opacity:1;
                                pointer-events:none;
                                z-index:1;
                                background: $jpp-marigold-field-background!important;
                            }
                        }
                        &.input-filled,
                        &.input-active {
                            div.input {
                                &::before {
                                    opacity:0;
                                    transition-delay:0s;
                                }
                            }
                        }
                        &.input-filled {
                            border-color:$jpp-success-green;
                        }
                    }
                    &:not(.hs-fieldtype-checkbox) {
                        padding-top:32px;
                    }
                    @media (max-width: $bp-tablet - 1) {
                        margin-bottom: $account-spacer;
                    }

                    &.SELECT, .hs-fieldtype-select,
                    &.INPUT, .hs-fieldtype-text {
                        @include jpp-field-element;
                        @media (min-width: $bp-tablet) {
                            margin-bottom: 0;
                        }
                        padding: 0;
                        padding-top:26px!important;
                        width: 100%;

                        &.hs-email {
                            .no-list {
                                a {
                                    font-size: 1.2rem;
                                }
                            }
                        }

                        input {
                            &:not([type='radio']) {
                                &:not([type='checkbox']) {
                                    width: 100%;
                                }
                            }
                        }

                        select {
                            @include jpp-field-basic;
                            cursor: pointer;
                            option {
                                color:#000;
                            }
                        }

                        .hs-input {
                            width: 100%;
                        }

                        input {
                            &:not([type='radio']) {
                                &:not([type='checkbox']) {
                                    @include jpp-input;
                                    box-sizing: border-box;
                                    margin: 0;
                                    max-width: unset;
                                }
                            }
                        }

                        &.input-filled,
                        &.input-active {
                            textarea,
                            select {
                                color: $jpp-off-black;
                                &:focus,
                                &:active {
                                    border-color: $jpp-blue;
                                }
                            }

                            input {
                                &:not([type='radio']) {
                                    &:not([type='checkbox']) {
                                        color: $jpp-off-black;
                                    }
                                }
                                &.invalid, &.error {
                                    border-color:$jpp-error-red!important;
                                }
                            }

                        }

                        &.input-no-value, &:not(.input-filled) {
                            input {
                                &:not([type='radio']) {
                                    &:not([type='checkbox']) {
                                        background: $jpp-marigold-field-background;
                                        border-color: $jpp-marigold;
                                        &:hover {
                                            background: $jpp-marigold-field-background!important;
                                        }
                                        &:focus {
                                            background:#ededed;
                                        }
                                    }
                                }
                            }

                            select {
                                background: $jpp-marigold-field-background;
                                border-color: $jpp-marigold;
                                &::before {
                                    position:absolute;
                                    content:'';
                                    top:0;
                                    left:0;
                                    bottom:0;
                                    right:20px;
                                    background: $jpp-marigold-field-background;
                                    opacity:1;
                                }
                            }
                        }

                        &.input-active {
                            &.input-no-value, &:not(.input-filled) {
                                > label {
                                    color: #000;
                                }
                            }
                        }

                        > label {
                            position:absolute;
                            top:-10px;
                            span {
                                text-transform: none;
                                text-transform:capitalize;
                            }
                        }

                    }

                    &.hs-fieldtype-checkbox {
                        margin-bottom:0;
                        padding-top:18px!important;
                        > label {
                            padding-top:5px!important;
                            display:block;
                            width:100%;
                            margin-bottom:12px;
                            span {
                                font-size:18px;
                            }
                        }
                        &.vcp-field-incomplete {
                            > label {
                                background: $jpp-marigold-field-background!important;
                                border-color: $jpp-marigold!important;
                            }
                        }
                        .hs-form-checkbox {
                            label {
                                input {
                                    width:20px!important;
                                    span {
                                        transform:translateY(1px);
                                    }
                                }
                            }
                        }
                        > label {
                            border-bottom: 2px solid transparent;
                            line-height: 3.8rem;
                            padding: 0 15px;
                        }
                        input + span {
                            color: $black;
                            font-size:1.4rem;
                        }
                        div.input {
                            margin-left: 0;
                            margin-right: 0;
                        }

                        ul.inputs-list {
                            @include flex-container;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            li {
                                @include flex-item;
                                height:auto!important;
                                min-height:34px;
                                @media (min-width: $bp-tablet) {
                                    flex-basis: calc(50% - #{$account-spacer});
                                }

                                label {
                                    span {
                                        color:$black;
                                        font-weight:400;
                                        text-transform:none;
                                        display:inline-block;
                                        transform:translateY(4px);
                                    }
                                }
                            }
                        }
                    }
                }

                &.form-columns-2 {
                    margin-left: 0;
                    margin-right: 0;

                    .field {
                        padding: 0;

                        @media (min-width: $bp-tablet) {
                            width: calc(50% - 6px);
                        }

                        &:last-child {
                            float: right;
                        }
                    }
                }

                &.form-columns-3 {
                    margin-left: 0;
                    margin-right: 0;

                    .field {
                        float: left;
                        padding: 0;
                        @media (min-width: $bp-tablet) {
                            width: calc(33.33% - 8px);

                            &:first-child {
                                margin-right: 12px;
                            }

                            &:last-child {
                                float: right;
                            }
                        }
                    }
                }
            }
            .legal-consent-container {
                margin:0;
                transform:translateY(-12px);
                .hs-richtext {
                    p {
                        font-size:12px;
                        color:#777;
                        line-height:1.2;
                        margin:0;
                    }
                }
            }
            .actions {
                margin-top:0;
            }
            .hs-button.primary {
                @include button('primary');
                @include smooth;
                background-image:none;
                box-shadow:0px 0px 12px rgba(2,2,2,0);
                width:200px;
            }
        }
    }
    @keyframes fadein {
        0% {
            opacity:0;
        }
        100% {
            opacity:1;
        }
    }
    #hsForm_a641447e-bfdd-4f35-9827-738277978ac1, .init-reveal {
        animation: fadein 0.6s ease;
        animation-fill-mode:forwards;
        animation-delay:1s;
    }
}

.vcpaccount-complete-index {
    .block-vcpaccount-complete {
        @include customer-container;
    }
}
