@mixin customer-container {
    background: $white;
    box-shadow: 0 2px 16px $box-shadow-light;
    box-sizing: border-box;
    font-weight: 500;
    margin-bottom: $account-spacer;
    padding: 16px;

    @media (min-width: $bp-tablet) {
        padding: 24px;
    }
    @media (max-width: $bp-laptop) {
        box-shadow: 0px 2px 12px $box-shadow-light;
    }
    @media (max-width: $bp-mobile-l) {
        box-shadow: 0px 2px 10px $box-shadow-light;
    }
    > .block-title {
        @include customer-container-title;
    }

    #products_interested_test {
        height:120px;
    }
}

@mixin toolbar-account-container {
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 1),
    0 7px 16px $box-shadow-light;
    margin-left: 0;
    margin-top: -30px;
    position: relative;
    z-index: auto;

    &:before {
        background: $white;
        height: 5px;
        left: 0;
        position: absolute;
        top: -5px;
        width: 100%;
        z-index: auto;
    }
}

@mixin customer-container-title {
    color: $jpp-off-black;
    //font-size: 1.6rem;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2rem;
    margin: 0;
    margin-bottom: 20px !important;
    text-align: left !important;
    text-transform: none;
    @media (min-width: $bp-tablet) {
        padding: 0 0 20px;
    }
    @media (max-width: $bp-tablet) {
        padding-top: 8px;
    }
    @media (max-width: $bp-mobile-l) {
        padding-bottom: 16px;
        font-size: 1.6rem;
    }
    @media (max-width: $bp-mobile) {
        padding-bottom: 16px;
        font-size: 1.4rem;
    }
    span,
    strong {
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        margin: unset !important;
    }
}

@mixin account-table {
    a,
    span {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
}

@mixin account-product-list-style {
    .product-items {
        @include flex-container;
        flex-wrap: wrap;
        font-size: 1.4rem;
        font-weight: 500;

        .product-item {
            @include flex-item;
            border-top: 1px solid $jpp-grey;
            flex-basis: 100%;
            padding: 20px 0;

            &:first-child {
                border-top: 0;
            }
        }

        .product-item-info {
            @include flex-container;
            align-items: flex-start;
            flex-basis: 100%;
            justify-content: flex-start;

            > span {
                display: block;
            }

            .product-item-photo {
                align-self: center;
                flex-basis: 100px;
                padding: 0;

                .product-image-container {
                    border: 1px solid $jpp-grey;
                }
            }

            .product-item-name {
                flex-basis: 40%;
                font-size: 1.4rem;
                margin-bottom: 0;
                margin-top: 0;

                a {
                    font-weight: 800;
                    text-decoration: none !important;
                    text-transform: uppercase;
                }
            }

            .price-container {
                flex-basis: 15%;
                text-align: right;

                .price {
                    font-weight: 600;
                }

            }

            .product-item-inner {
                flex-basis: auto;
                margin-left: auto;
                min-width: 140px;
                text-align: right;

                .product-item-actions {
                    a {
                        @include account-buttons;
                        display: block;
                        text-align: center;
                        min-width: 140px;

                        &.edit {
                            @include account-buttons('primary');
                            margin-bottom: $account-spacer;
                        }
                    }
                }
            }
        }
    }
}



.account {

    .page-wrapper {
        background: linear-gradient(to right, #fff 0%, #f9f9f9 50%, #fff 100%);
        @media (max-width: $bp-tablet) {
            background: $jpp-off-white;
        }
    }

    &.customer-account-index {
        .page-title-card {
            display: none;
        }
    }

    &.customer-account-index {
        .page-main {
            @media (max-width: ($bp-laptop - 1)) {
                .page-title-wrapper {
                    position: relative;
                    z-index: auto;
                    margin-bottom: -20px;
                    text-align: center;

                    .page-title {
                        @media (max-width: $bp-mobile-l) {
                            text-align: center;
                        }
                    }

                    & + .columns {
                        position: relative;
                        z-index: auto;
                    }
                }
            }
        }

    }

    .page-main {
        width: 1200px;
        padding-top: 0;

        .page-title-wrapper {
            @include full-width;
            box-sizing: border-box;
            padding-right: $spacer;
            padding-left: $spacer;
            background-color: $jpp-blue;

            + .page.messages {
                position: relative;
                z-index: 1;
                transform: translateY(-26px);

                .message {
                    margin: 0 0 18px;
                }
            }

            .page-title {
                margin: 0;
                padding: 64px 0;
                transform: translateY(-8px);
                text-transform: unset;
                @media (min-width: $bp-tablet) {
                    transform: translateY(-18px);
                    padding: 80px 0;
                }
            }

            .base {
                font-size: 24px;
                text-transform: unset;
                color: $white;

                @media (min-width: $bp-tablet) {
                    font-size: 40px;
                }
            }

            .page-title-container {
                @media (min-width: $bp-tablet) {
                    text-align: center;
                }
            }
        }

        .page.messages { // sass-lint:disable-line force-element-nesting
            .message {
                margin-top: $account-spacer;
                //margin-bottom: 2 * $account-spacer;
                @media (min-width: $bp-tablet) {
                    margin-top: 0;
                    //margin-bottom: $account-spacer;
                }
            }

            .messages {
                background: $white;

                @media (min-width: $bp-tablet) {
                    margin: 0 auto;
                }
            }
        }

        .columns {
            margin-bottom: #{$spacer * 4};
            @media (max-width: $bp-mobile-l) {
                margin-bottom: #{$spacer * 2};
            }
            @media (min-width: $bp-tablet) {
                @include clearfix;
                @include grid-collapse;
                position: relative;
                margin-top: -#{$spacer * 2};
                margin-bottom: #{$spacer * 4};
            }

            @media (min-width: $bp-laptop) {
                margin-bottom: #{$spacer * 5};
                padding: 0 #{$spacer / 2};
            }

            .main {
                @media (min-width: $bp-tablet) {
                    @include grid-width(8);
                }

                @media (min-width: $bp-laptop) {
                    @include grid-width-margin(9);
                    margin-left: 0;
                    padding: 0;
                }

                > * {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                > .form,
                > form {
                    //@include customer-container;

                    .legend {
                        float: none;
                        width: 100%;
                        margin-bottom: 36px;
                        margin-left: 0;
                        text-align: center;

                        > span {
                            @include h5-typography;
                        }
                    }

                    .field {
                        > .control {
                            float: none;
                            width: 100%;
                        }

                        &::before {
                            content: none;
                        }
                    }

                    .actions-toolbar {
                        @include customer-container;
                        @include toolbar-account-container;

                        button, a {
                            font-size: 1.4rem;
                            min-width: 140px;
                            max-width: 100%;
                            text-align: center;
                            //@include ease;
                        }

                        .secondary {
                            button, a {
                                &:hover {
                                    color: $jpp-blue;
                                    box-shadow: 0px 1px 4px $box-shadow-light;

                                    span {
                                        color: $jpp-blue;
                                    }
                                }
                            }
                        }

                        > div {
                            float: none;
                            vertical-align: middle;
                        }
                    }
                }

                .reward-settings {
                    margin-top: 15px;

                    .actions-toolbar {
                        margin-bottom: 0;
                    }
                }

                .giftcard-account,
                .account-billing-agreements {
                    @include customer-container;
                }

                .account-billing-agreements {
                    .actions-toolbar {
                        margin-left: 0;
                    }

                    fieldset {
                        margin: 16px 0 0;

                        legend {
                            margin-bottom: 16px;

                            span {
                                font-size: 20px;
                                font-weight: 800;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .giftcard-account {
                    .actions-toolbar {
                        margin-left: 0;
                    }
                }
            }

            .sidebar {
                @include customer-container;
                @media (max-width: $bp-tablet) {
                    flex-basis: auto;
                    width: 320px;
                    max-width: 100%;
                }
                @media (min-width: $bp-tablet) {
                    @include grid-width-margin(4);
                    float: right;
                    padding: 24px;
                }

                @media (min-width: $bp-laptop) {
                    @include grid-width-margin(3);
                    float: right;
                }

                .block-collapsible-nav {
                    @media (max-width:767px) {
                        position:relative;
                        margin-bottom:0;
                        > .title {
                            display:none;
                        }

                        > .content {
                            display:block;
                            padding-top:0;
                            border-bottom:0px solid transparent;
                        }
                    }
                    .block-collapsible-nav-content {
                        background:transparent;
                        padding:6px 0 0 0;

                        .nav.item {
                            padding:0;
                            &.current {
                                strong {
                                    border:0px solid transparent;
                                    font-weight:700;
                                    color:#0088CE;
                                    padding:5px 0;
                                    cursor:default;
                                }
                            }

                            a {
                                text-decoration:none!important;
                                font-weight:700;
                                color:#555;
                                padding:5px 0;
                                border:0px solid transparent;

                                &:hover {
                                    background:transparent;
                                    color:#0088ce;
                                }
                            }
                        }
                    }
                    @media (min-width: $bp-laptop) {
                        margin-bottom:0;
                    }
                }
            }
        }
    }

    .account-nav-heading {
        span {
            @include font-bold;
            font-size: 20px;
        }
    }

    .account-nav {
        @media (min-width: $bp-tablet) {
            margin-bottom: 0;
        }

        .content {
            background: transparent;
            @media (min-width: $bp-tablet) {
                padding: 0;
                background-color: $white;
            }

            .item {
                a,
                strong {
                    @include unset-underline;
                    @include h5-typography;
                    font-weight: 700;
                    margin: 0;
                    padding-left: 0;
                    text-transform: none;
                    color: $jpp-grey-2;
                    border-left: 0;

                    &:hover,
                    &:active {
                        color: $jpp-blue;
                        background: none;
                    }
                }

                &.current {
                    strong {
                        color: $jpp-blue;
                        border-left: 0;
                    }
                }

                .delimiter {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .toolbar {
        margin-bottom: $account-spacer;

        ul.pages-items {
            display: inline-block;
            width: auto;

            li.item {
                width: 22px;

                &.current {
                    user-select: none;
                }

                a {
                    text-decoration: none !important;
                    @include ease;

                    &:hover {
                        color: $jpp-orange;
                    }

                    &.action.next, &.action.previous {
                        position: relative;
                        overflow: hidden;
                        width: 22px;
                        margin-right: 0;
                        margin-left: 0;
                        border: 0px solid transparent;

                        &:before {
                            font-size: 42px;
                            transform: translateX(-5px) translateY(1px);
                        }
                    }
                }

                &.pages-item-next, &.pages-item-previous {
                    position: relative;
                    transform: translateY(4px);
                }
            }
        }

        .pager {
            float: none;
        }

        .toolbar-amount,
        .limiter {
            span,
            strong,
            label {
                @include h5-typography;
                font-size: 12px!important;
                font-weight: 600;
            }
        }

        .toolbar-amount {
            float: left;
            padding-top: 0;
        }
    }

    .actions-toolbar {
        @media (max-width: #{$bp-tablet - 1}) {
            @include flex-container;
            margin-top: 10px;
        }

        .primary {
            @media (max-width: #{$bp-tablet - 1}) {
                margin-bottom: 0;
            }

            .action {
                @include account-buttons('primary');
                font-size: 1.4rem;
                line-height: 4.1rem;
                min-width: 140px;
                max-width: 100%;
                height: 4.2rem;
                padding: 0 16px !important;
                text-align: center;
            }
        }

        .secondary {
            @media (min-width: $bp-tablet) {
                margin-left: 10px;
            }

            .action {
                @include account-buttons;
                font-size: 1.4rem;
                line-height: 4.1rem;
                min-width: 140px;
                max-width: 100%;
                height: 4.2rem;
                padding: 0 16px !important;
                text-align: center;
            }
        }
    }

    .account-btn {
        @include dashboard-button;
    }

    .customer-container {
        @include customer-container;

        & + .actions-toolbar {
            @include customer-container;
            @include toolbar-account-container;
        }
    }

    .customer-container-title {
        @include customer-container-title;
    }

    .amsl-accounts.customer-container {
        display:flex;
        flex-direction:column;
        @media (min-width:$bp-desktop-s) {
            flex-direction:row;
        }
        legend.legend span {
            font-size: 20px;
            font-weight: 800;
            display: block;
            margin-bottom: 20px;
            text-transform:uppercase;
        }
        .amsl-social-login {
            border-radius:0;
            .amsl-buttons {
                justify-content:space-between;
                padding:24px;
                .amsl-button-wrapper {
                    margin:0;
                }
            }
        }
        .amsl-accounts-block {
            width: 100%;
            margin-bottom: 0;
            @media (min-width:$bp-desktop-s) {
                width:calc(50% - 12px);
            }
            @media (max-width:$bp-desktop-s) {
                &:nth-of-type(1) {
                    margin-bottom:24px;
                }
            }

            .amsl-linked {
                margin-left:0;
                a.amsl-unlink {
                    text-decoration:none!important;
                    font-size:14px;
                }
                .amsl-button {
                    width:30px;
                    height:30px;
                    margin-left:8px;
                    position:relative;
                    transform:translateX(0);
                    .amsl-social-icon {
                        background-size:contain;
                    }
                }
            }
            .amsl-button {
                text-decoration:none!important;
            }
        }
    }

    .page-title-card {
        @include customer-container;
        clear: both;
        margin-bottom: $account-spacer;

        @media (max-width: #{$bp-tablet - 1}) {
            position: relative;
            z-index: 1;
            margin-top: -18px;
        }

        .page-title-card-container {
            > h2 {
                display: inline-block;
            }

            > a {
                display: inline-block;
                float: right;
                @media (min-width: $bp-tablet) {
                    @include button;
                }
            }
        }

        .title-card {
            font-size: 1.8rem;
            font-weight: 800;
            line-height: 2.2rem;
            margin: 0;
            text-transform: none;
            color: $jpp-off-black;

            @media (min-width: $bp-tablet) {
                font-size: 2.2rem;
                line-height: 2.8rem;
            }

            span {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;
                color: inherit;
            }
        }
    }

    .message {
        &.info {
            &.empty {
                margin: 0;
                //margin-top: 20px;
            }
        }
    }

    .modals-wrapper {
        .modal-popup {
            .action-close {

                &::before {
                    color: $jpp-blue;
                }
            }

            .modal-title {
                font-size: 2rem;
                line-height: 2.2rem;
                margin: 0 0 10px;
                padding: 0 0 10px;
            }

            .modal-content {
                padding: 0;
                .exist-notification-message {
                    span {
                        font-size:12px;
                    }
                }
                &[data-role='content'] {
                    padding: 20px;

                    & + .modal-footer {
                        padding: 20px;
                    }
                }
            }
        }

        .modal-inner-wrap {
            @media (max-width: #{$bp-tablet - 1}) {
                .fieldset {
                    margin-bottom: 10px;
                }

                .additional-fields {
                    margin-top: 0;
                }

                &[class] {
                    background: $white;
                }
            }

            .modal-footer {
                @include flex-container;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0;
                border-top: 0;

                button {
                    line-height: 40px;
                    flex: 1;
                    height: 40px;
                    padding: 0 24px !important;

                    &:nth-of-type(1) {
                        margin-right: 8px !important;
                    }

                    &:nth-of-type(2) {
                        margin-left: 8px !important;
                    }

                    @media (max-width: #{$bp-tablet - 1}) {
                        width: auto;
                    }

                    &.blue {
                        @include account-buttons('primary');
                    }
                    &.orange {
                        @include account-buttons('orange');
                    }
                    &.secondary {
                        @include account-buttons;
                        color:#fff;
                        height:36px;
                        line-height:36px;
                    }

                    &.submit-payment,
                    &.primary {
                        @include account-buttons('primary');
                        line-height: 36px;
                        height: 36px;
                        padding: 0 24px !important;
                    }
                }
            }
        }
    }
}

