.jpp-trending-recent-product-tiles {
    position:relative;
    z-index:3;
    padding-top:72px;
    padding-bottom:64px;
    display:flex;
    flex-wrap:wrap;
    width:1500px;
    max-width:92%;
    margin-left:auto;
    margin-right:auto;

    @media (max-width:1280px) {
        padding:48px 0;
        display:flex;
        flex-wrap:wrap;
    }
    @media (max-width:991px) {
        padding:0 0 48px;
    }
    @media (max-width:768px) {
        //padding:0 0 40px;
        padding:0 0 22px;
        max-width:100%;
    }

    h2 {
        margin-top:0;
        margin-bottom:20px;
        font-size:40px;
        text-transform:none;
        display:inline-block;

        @media (max-width:1280px) {
            font-size:26px;
        }
        @media (max-width:480px) {
            font-size:20px;
            //font-weight:700;
        }
        + span {
            font-size:16px;
            line-height:16px;
            display:inline-block;
            transform:translateX(10px) translateY(-5px);
            @media (max-width:480px) {
                display:none;
            }
        }
    }
    .heading-wrapper {
        @media (max-width:991px) {
            position:absolute;
            top:48px;
        }
        @media (max-width:768px) {
            left:16px;
            top:28px;
        }
        @media (max-width:660px) {

        }
    }

    .trending-products-row {
        width:100%;

        @media (max-width:991px) {
            overflow-x:scroll;
            min-width:100vw;
            transform:translateX(-4vw);
            padding:98px 4vw 12px;
        }
        @media (max-width:768px) {
            transform:translateX(0);
            padding:64px 16px 12px;
        }
        @media (max-width:660px) {
            padding-top:64px;
        }
        .trending-row-inner {
            display:flex;
            @media (max-width:991px) {
                //min-width:1046px;
                min-width:1250px;
            }
            @media (max-width:480px) {
                //min-width:294vw;
                min-width:940px;
            }
        }
    }
    .tile-wrapper {
        display:flex;
        position:relative;
        width:74.4%;

        @media (max-width:1280px) {
            width:calc(75% - 16px);
            margin-right:16px;
        }
        @media (max-width:991px) {
            width:calc(73% - 16px);
            margin-right:10px;
        }
        .product-tiles {
            display:flex;
            position:relative;
            align-self:flex-start;
            width:100%;
            .product {
                flex:1;
                margin-right:14px;
                border-radius:4px;
                box-shadow:0px 0px 8px rgba(2,2,2,0.12);
                position:relative;
                align-self:flex-start;
                @media (max-width:991px) {
                    margin-right:8px;
                }
                &:last-of-type {
                    @media (max-width:1280px) {
                        margin-right:0;
                    }
                }
                a {
                    display:block;
                    width:100%;
                    padding-top:100%;
                    border-radius:4px;
                    background:#fff;
                    &:hover {
                        label {
                            color:#0088ce;
                        }
                    }
                }

                img {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-60%);
                    //max-width:114px;
                    //max-height:66%;
                    max-width:156px;
                    max-height:76%;
                    @media (max-width:1280px) {
                        transform:translateX(-50%) translateY(-56%);
                        max-width:100px;
                        max-height:70%;
                    }
                    @media (max-width:480px) {
                        transform:translateX(-50%) translateY(-62%);
                        max-height:64%;
                    }
                }
                label {
                    font-size:13px;
                    line-height:13px;
                    text-transform:uppercase;
                    position:absolute;
                    bottom:8px;
                    left:10px;
                    right:10px;
                    font-weight:700;
                    color:#000;
                    text-align:center;
                    transition:0.18s ease;
                    pointer-events:none;
                    @media (max-width:1280px) {
                        font-size:11px;
                        line-height:11px;
                        bottom:6px;
                        left:6px;
                        right:6px;
                    }

                    @media (max-width:480px) {
                        font-size:11px;
                        bottom:8px;
                    }
                }
            }
        }
    }
    .cta-tile-wrapper {
        width:25.6%;
        position:relative;
        align-self:flex-end;

        @media (max-width:1280px) {
            align-self:flex-end;
            width:25%;
        }
        @media (max-width:991px) {
            width:18%;
        }
        .cta-tile {
            width:100%;
            padding-top:calc(100% - 8px);
            border-radius:8px;
            border:4px solid #fff;
            box-shadow:0px 0px 8px rgba(2,2,2,0.12);
            position:absolute;
            left:0;
            right:0;
            bottom:0;

            a {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                text-decoration:none!important;
                border-radius:4px;
                overflow:hidden;
                z-index:1;
                .cta-button {
                    background:#0088ce;
                    color:#fff;
                    font-size:13px;
                    font-weight:700;
                    text-align:center;
                    padding:6px 16px;
                    box-shadow:0px 0px 12px rgba(2,2,2,0.2);
                    display:inline-block;
                    position:absolute;
                    bottom:16px;
                    left:50%;
                    transform:translateX(-50%);
                    z-index:3;
                    transition:0.18s ease;
                    min-width:101.79px;
                    max-width:calc(100% - 24px);
                    
                    @media (min-width:991px) {
                        white-space:nowrap;
                    }

                    @media (max-width:991px) {
                        font-size:11px;
                        padding:4px 13px;
                        min-width:calc(100% + 8px);
                        transform:translateX(-50%) scale(0.9);
                        bottom:4px;
                    }
                    @media (max-width:480px) {
                        bottom:4px;
                    }
                }
                .image {
                    position:relative;
                    z-index:1;
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    background:#333;
                    background-position:center center;
                    background-size:cover;
                    opacity:0;
                    animation: fadein 0.4s ease;
                    animation-fill-mode:forwards;
                    &:before {
                        position:absolute;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        background:linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        opacity:0.2;
                        content:'';
                        z-index:2;
                        display:none;
                    }

                }

                &:hover {
                    .cta-button {
                        filter:brightness(1.06);
                    }
                }
            }

            &:after {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                content:'';
                z-index:-1;
                opacity:1;
                background:#f9f9f9;
            }
        }
    }


    &.new-user {
        .recently-viewed-purchased {
            display:none;
        }
    }
}


.recently-viewed-purchased {
    display:flex;
    //width:100%;
    width:1500px;
    max-width:92%;
    margin:0 auto;
    margin-bottom:64px;

    @media (max-width:991px) {
    }
    @media (max-width:768px) {
        padding-left:16px;
        padding-right:16px;
        max-width:100%;
        //margin-bottom:40px;
        margin-bottom:30px;
    }
    @media (max-width:660px) {
        flex-direction:column;
    }
    .recently-viewed, .recently-purchased {
        width:calc(50% - 20px);

        @media (max-width:660px) {
            width:100%;
        }
        h2 {
            font-size:32px;
            text-transform:none;
            margin-top:0;
            margin-bottom:20px;
            @media (max-width:1280px) {
                font-size:26px;
            }
            @media (max-width:991px) {
                font-size:24px;
                margin-bottom:12px;
            }
            @media (max-width:480px) {
                font-size:20px;
                font-weight:700;
            }
        }
        .product-tiles {
            width:100%;
            display:flex;
            @media (max-width:991px) {
                flex-wrap:wrap;
            }
        }
        .product {
            position:relative;
            margin-right:12px;
            border-radius:4px;
            background:#fff;
            box-shadow:0px 0px 12px rgba(2,2,2,0.08);
            width:calc(25% - 9px);
            @media (max-width:991px) {
                margin-right:8px;
                margin-bottom:8px;
                width:calc(50% - 4px);
                &:nth-of-type(2n) {
                    margin-right:0;
                }
            }
            @media (max-width:660px) {
                width:calc(25% - 6px);
                &:nth-of-type(2n) {
                    margin-right:8px;
                }
                &:last-of-type {
                    margin-right:0;
                }
            }
            &:last-of-type {
                margin-right:0;
            }
            a {
                display:block;
                width:100%;
                padding-bottom:100%;
                position:relative;
                z-index:1;

                &:before {
                    position:absolute;
                    content:'';
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    background:linear-gradient(120deg, rgba(0,5,10,0.3) -50%, rgba(0,5,10,0.54) 50%, rgba(0,5,10,0.3) 150%);
                    pointer-events:none;
                    opacity:0;
                    transition:0.18s ease;
                    z-index:3;
                    display:block!important;
                    @media (max-width:768px) {
                        display:none!important;
                    }
                }
                span {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                    width:calc(100% - 24px);
                    font-size:13px;
                    line-height:15px;
                    color:#fff;
                    text-shadow:0px 0px 4px rgba(2,2,2,0.56), 0px 0px 4px rgba(2,2,2,0.06), 0px 0px 6px rgba(2,2,2,0.12);
                    text-align:center;
                    font-weight:600;
                    opacity:0;
                    transition:0.18s ease;
                    z-index:4;
                    display:block!important;
                    @media (max-width:768px) {
                        display:none!important;
                    }
                }
                &:hover {
                    &:before, span {
                        transition-delay:0.18s;
                        opacity:1;
                    }
                }
            }
            img {
                position:absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                max-width:80%;
                max-height:74%;
            }
        }
    }
    .recently-viewed {
        margin-right:40px;
        @media (max-width:660px) {
            margin-right:0;
            margin-bottom:16px;
        }
    }
    .recently-purchased {

    }


    //Situational Views
    &.has-purchased:not(.has-viewed) {
        .recently-purchased {
            @media (max-width:991px) {
                width:100%;
                .product {
                    width:calc(25% - 4px);
                    width:148px;
                    max-width:calc(25% - 4px);
                    &:nth-of-type(2) {
                        margin-right:8px;
                    }
                }
            }
        }
    }
    &.has-viewed:not(.has-purchased) {
        .recently-viewed {
            @media (max-width:991px) {
                width:100%;
                .product {
                    width:calc(25% - 4px);
                    width:148px;
                    max-width:calc(25% - 4px);
                    &:nth-of-type(2) {
                        margin-right:8px;
                    }
                }
            }
        }
    }
}