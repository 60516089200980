.get-inspired-posts-wrapper {
    padding: #{$spacer * 3} 0;

    .get-inspired {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto 50vw 50vw 50vw 50vw 50vw 50vw 50vw 50vw;

        @media (min-width: $bp-tablet) {
            grid-template-columns: 45% 10% 45%;
            grid-template-rows: 31.81vw 31.36vw 11.59vw 27.27vw 43.18vw 16.59vw 20.22vw;
        }

        @media (min-width: 1280px) {
            grid-template-columns: 5.01% 25.01% 6.57% 18.66% 14.7% 30.01%;
            grid-template-rows: 25.51% 22.69% 8.11% 7.69% 19.99% 8.69% 7.28%;
        }
        .description-container {
            .content {
                z-index: 1;
            }
        }
    }

    .description-container {
        box-sizing: border-box;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: $spacer 0;
        z-index: 2;

        @media (min-width: $bp-tablet) {
            grid-column: 1 / 3;
        }

        @media (min-width: 1280px) {
            grid-column: 6 / 7;
            grid-row: 1 / 2;
        }

        .temp-remove {
            @media (min-width: $bp-tablet) and (max-width: $bp-laptop - 1) {
                display: none;
            }
        }

        .content {
            margin: auto;
            max-width: 100%;
            position: relative;
            width: 480px;

            @media (min-width: $bp-tablet) {
                padding-right: $spacer;
                width: auto;
            }

            @media (min-width: 1280px) {
                padding: $spacer;
            }
        }

        img {
            max-width: 200px;

            @media (min-width: 1280px) {
                margin-left: -70px;
                margin-top: -80px;
            }

            @media (min-width: $bp-desktop-xl) {
                max-width: 300px;
            }
        }
    }

    .grid-item {
        margin: #{$spacer / 4} 0;
        overflow: hidden;

        @media (min-width: $bp-tablet) {
            margin: $spacer / 4;
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }

    .one,
    .two,
    .three,
    .four,
    .five,
    .six,
    .seven,
    .eight {
        grid-column: 1 / 2;
        position: relative;

        &::before {
            background: radial-gradient(rgba(2, 2, 2, .6), rgba(2, 2, 2, .4)); // sass-lint:disable-line no-color-literals
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            user-select: none;
            @include smooth;
        }

        &::after {
            color: $white;
            content: 'See How We Made This';
            font-size: 18px;
            font-weight: 700;
            left: 0;
            letter-spacing: 2px;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-align: center;
            text-shadow: rgba(2, 2, 2, .2) 1px 2px 2px; // sass-lint:disable-line no-color-literals
            text-transform: uppercase;
            top: 50%;
            transform: translateY(-50%);
            user-select: none;
            width: 100%;
            @include smooth;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, .1) 0 12px 80px -6px; // sass-lint:disable-line no-color-literals

            &::after {
                opacity: 1;
            }

            &::before {
                opacity: 1;
            }
        }
    }

    .one {
        grid-row: 2 / 3;

        @media (min-width: $bp-tablet) {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
        }

        @media (min-width: 1280px) {
            grid-column: 1 / 4;
            grid-row: 1 / 2;
        }
    }

    .two {
        grid-row: 3 / 4;

        @media (min-width: $bp-tablet) {
            grid-row: 2 / 3;
        }

        @media (min-width: 1280px) {
            grid-column: 4 / 6;
            grid-row: 1 / 2;
        }
    }

    .three {
        grid-row: 4 / 5;

        @media (min-width: $bp-tablet) {
            grid-column: 2 / 4;
            grid-row: 2 / 4;
        }

        @media (min-width: 1280px) {
            grid-column: 1 / 3;
            grid-row: 2 / 4;
        }
    }

    .four {
        grid-row: 5 / 6;

        @media (min-width: $bp-tablet) {
            grid-row: 3 / 5;
        }

        @media (min-width: 1280px) {
            grid-column: 3 / 5;
            grid-row: 2 / 3;
        }
    }

    .five {
        grid-row: 6 / 7;

        @media (min-width: $bp-tablet) {
            grid-column: 2 / 4;
            grid-row: 4 / 5;
        }

        @media (min-width: 1280px) {
            grid-column: 5 / 7;
            grid-row: 2 / 5;
        }
    }

    .six {
        grid-row: 7 / 8;

        @media (min-width: $bp-tablet) {
            grid-column: 1 / 3;
            grid-row: 5 / 6;
        }

        @media (min-width: 1280px) {
            grid-column: 2 / 3;
            grid-row: 4 / 6;
        }
    }

    .seven {
        grid-row: 8 / 9;

        @media (min-width: $bp-tablet) {
            grid-column: 3 / 4;
            grid-row: 5 / 7;
        }

        @media (min-width: 1280px) {
            grid-column: 3 / 5;
            grid-row: 3 / 8;
        }

        img {
            object-position: top;
        }
    }

    .eight {
        grid-row: 9 / 10;

        @media (min-width: $bp-tablet) {
            grid-column: 1 / 3;
            grid-row: 6 / 8;
        }

        @media (min-width: 1280px) {
            grid-column: 5 / 7;
            grid-row: 5 / 7;
        }
    }

}
