 // sass-lint:disable no-important
.pagebuilder-triple-callout {
    .triple-callout-container {
        background-color: $white;
        box-shadow: 0 0 10px $box-shadow;
        margin: -30px 20px 0;
        padding: #{$spacer * 3} #{$spacer * 2};
        position: relative;

        @media (min-height: $bp-laptop) {
            margin: -40px 20px 0;
        }

        .heading {
            @include font-bold;
            font-size: 32px;
            margin-bottom: $spacer * 3;
            text-align: center;
            text-transform: uppercase;

            @media (min-width: $bp-laptop) {
                font-size: 40px;
            }
        }
    }

    .callout-blocks {
        @media (min-width: $bp-laptop) {
            @include clearfix;
            @include grid-collapse;
            display: flex;
        }

        .callout-block {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: $spacer * 2;

            @media (min-width: $bp-tablet) and (max-width: $bp-laptop - 1) {
                flex-direction: row;
            }

            @media (min-width: $bp-laptop) {
                @include grid-width(4);
                justify-content: flex-start;
            }
        }

        .image {
            margin-bottom: $spacer;
            max-width: 200px !important;

            @media (min-width: $bp-tablet) {
                max-width: 250px;
            }
        }

        .content {
            text-align: center;

            @media (min-width: $bp-tablet) {
                max-width: 400px;
                padding-left: $spacer * 2;
                text-align: left;
                width: 100%;
            }

            @media (min-width: $bp-laptop) {
                padding-left: 0;
                text-align: center;
            }
        }

        .title {
            @include font-bold;
            color: $jpp-blue;
            font-size: 20px;
            margin-bottom: $spacer;
            text-transform: uppercase;
        }
    }
}
