.post-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    //margin: 0 -15px;

    .post-holder {
        padding: 0 30px;
        max-width: 100%;
        @media (min-width: $bp-tablet) {
            width:50%;
        }
        @media (min-width: $bp-desktop) {
            width:33.3333%;
        }
        .image {
            width: 100%;
            display: block;
            padding-bottom: 60%;
            background-size: cover;
            border: 1px solid #d4d4d4;
            background-position: center;
            margin: 0 0 1.5em;
            background-repeat: no-repeat;
            .sr-only {
                position: absolute;
                width: 1px;
                height: 1px;
                margin: -1px;
                padding: 0;
                overflow: hidden;
                clip: rect(0,0,0,0);
                border: 0;
            }
        }
    }

}
.blog-post-view {
    background-color: $jpp-off-white;

    .page-title-wrapper {
        padding: 2px 0 0;
        @media (max-width:$bp-laptop) {
            padding-top:4px;
        }
        .page-title {
            margin-top: 0;
            margin-bottom:16px;
            text-transform:none!important;
            @media (max-width:$bp-desktop) {
                font-size:36px;
            }
            @media (max-width:$bp-laptop) {
                margin-bottom:8px;
            }
            @media (max-width:$bp-tablet) {
                font-size:28px;
            }
            @media (max-width:$bp-mobile-l) {
                font-size:26px;
            }
            span {
                text-transform:none;
                font-size:inherit!important;
            }
        }

        .base {
            color: $jpp-blue;
        }
    }

    .breadcrumbs {
        margin-bottom: 0;
    }

    .page-main {
        padding-top: $spacer;
    }

    .columns {
        margin-bottom:48px;
        @media (min-width: $bp-laptop) {
            @include clearfix;
            @include grid-collapse;
            margin-bottom:100px;
        }

        .main,
        .sidebar-main {
            box-shadow: 0 0 10px $box-shadow;
            background:#fff;
            padding: 20px;
            @media (max-width:$bp-tablet) {
                padding:16px;
            }
        }

        .main {
            border-top: 8px solid $jpp-blue;
            padding-bottom: 0;
            @media (min-width: $bp-desktop-s) {
                @include grid-width-margin(8.5);
                float: right;
            }
            @media (min-width: $bp-desktop-xl) {
                @include grid-width-margin(9);
                float: right;
            }
            @media (max-width:$bp-tablet) {
                margin-bottom:18px;
            }

            h1, h2, h3, h4, h5, p, li, span {
                a, span {
                    font-size:inherit!important;
                    font-weight:inherit!important;
                }
            }
        }

        .sidebar-main {
            @media (min-width: $bp-desktop-s) {
                @include grid-width-margin(3.5);
            }
            @media (min-width: $bp-desktop-xl) {
                @include grid-width-margin(3);
            }
        }
    }

    .blog-newsletter-container {
        background-color: $jpp-grey-5;
        padding: 15px;
        .heading {
            border-bottom: 1px solid $jpp-orange;
            font-size: 20px;
            margin-bottom: 15px;
            padding-bottom: 5px;
        }
        .description {
            font-size: 14px;
            margin-bottom:0;
        }
        .legal-consent-container {
            margin-top: 20px;
            padding-right:0;
            p {
                font-size: 10px;
                line-height:1.2;
                font-style: italic;
            }
        }
        form {
            margin:0;
            input.hs-input, textarea.hs-input {
                box-shadow:0px 0px 12px rgba(0,0,0,0);
            }
            label.hs-error-msg {
                font-family:'proxima-nova';
            }
            .form-columns-1 {
                > div {
                    width:100%;
                    div.input {
                        margin-right:0;
                    }
                }
            }
            .form-columns-2 {
                > div {
                    width:100%;
                    margin-bottom:0;
                    div.input {
                        margin-right:0;
                    }
                }
                .inputs-list li {
                    height:auto!important;
                }
            }
            .legal-consent-container {
                padding-right:0;
                margin:0!important;
                .hs-richtext {
                    p {
                        margin:0!important;
                        padding-right:0;
                        line-height:1.4;
                    }
                }
            }
            .actions {
                margin:0;
                padding-left:0;
                .hs-button {
                    background-image:none;
                    box-shadow:0px 0px 12px rgba(2,2,2,0);
                    min-width:160px;
                    &:hover {
                        border:0px 0px 12px rgba(2,2,2,0.12);
                    }
                }
            }
        }
    }

    .post-view {
        .post-holder {
            border: 0;
            padding-top: 5px;
            padding:0;
            .post-content {
                .pagebuilder-column {
                    @media (max-width:$bp-tablet) {
                        padding:10px 0;
                    }
                }
                .post-image {
                    max-width: 100%;
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        max-height: 750px;
                    }
                }
            }
        }

        .post-info {
            padding-bottom: 30px;
            @media (max-width:$bp-laptop) {
                padding-bottom:22px;
            }
            a {
                text-decoration:none!important;
            }
            * {
                font-size: 14px !important; // sass-lint:disable-line no-important
            }
        }

        .post-bottom {
            .nextprev-link {
                @include button('border-blue');
            }
        }
    }

    .block-related-posts {
        margin-bottom: $spacer * 2 !important; // sass-lint:disable-line no-important
        margin-top: $spacer * 2;

        .block-title {
            border: 0;
            margin-bottom: 0 !important; // sass-lint:disable-line no-important
        }

        .block-content {
            list-style: none;
            padding-left: 0;
        }

        .item {
            margin-bottom: 5px;
        }

        .post-item-link {
            @include unset-underline;
        }
    }
}
