.vcp-block-wrapper {
    padding-bottom: $spacer * 3;
    padding-top: $spacer * 3;
    margin-top:60px;
    margin-bottom:60px;
    @media (max-width:$bp-laptop - 1) {
        margin-top:32px;
        margin-bottom:32px;
    }
    .vcp-block-container {
        width:1054px;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
    }

    .vcp-image-container {
        margin-bottom: $spacer * 2;

        img {
            width: 450px;
            max-width: 100%;
        }
    }

    .vcp-item {
        margin-bottom: $spacer;

        .tile {
            box-sizing: border-box;
            height: 100%;
            min-height: 100%;
            padding: $spacer;

            @media (min-width: $bp-tablet) {
                min-height: 260px;
            }

            @media (min-width: $bp-laptop) {
                min-height: 300px;
            }
        }

        .icon {
            margin-bottom: $spacer / 1.5;

            img {
                max-width: 70px;

                @media (max-width: $bp-laptop - 1) {
                    max-width: 50px;
                }
            }
        }

        .heading {
            font-size: 20px;
        }

        .description {
            text-align: center;
        }
    }

    .actions-container {
        margin-top: $spacer;

        .button-marigold {
            width:180px;
        }
    }
}
