.jpp-promo-tray {
    position:relative;
    padding:0;
    width:1500px;
    max-width:92vw;
    margin-left:auto!important;
    margin-right:auto!important;
    margin-bottom:20px;
    transition:opacity 0.24s ease;

    margin-top:-8px;
    margin-bottom:60px;
    @media (max-width:768px) {
        max-width:100%;
    }

    .promotions-list {
        display:flex;
        margin-bottom:0!important;
        opacity:0;
        min-width:calc(100% + 10px);
        width:calc(100% + 10px);
        transform:translateX(-10px);

        @media (max-width:991px) {
            height:224px;
            transform:translateX(-10px) translateY(2px);
        }
        @media (max-width:480px) {
            height:200px;
        }
        &::-webkit-scrollbar {
            width:0px;
        }
        &::-webkit-scrollbar-track {
            background: #E8E8E8;
        }
        &::-webkit-scrollbar-thumb {
            background: #0088ce;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #0096E3;
        }
        &.flickity-enabled, &.no-flickity {
            animation:fadein 0.24s;
            animation-fill-mode:forwards;
        }
        &.no-flickity, &:not(.flickity-enabled) {
            padding:20px 0!important;
        }
        &.no-flickity {
            min-width:100%;
            width:100%;
            transform:translateX(0);
            @media (max-width:991px) {
                transform:translateX(0) translateY(2px);
            }
            div[data-content-type="promo_card"]:nth-of-type(4) {
                margin-right:0;
            }
        }
        .flickity-viewport {
            width:100%;
            padding:12px!important;
            min-height:248px;
            transform:translateX(10px)!important;
            @media (max-width:1440px) {
                overflow:visible!important;
            }
            @media (max-width:991px) {
                transform:translateX(10px) translateY(-10px) scale(0.88)!important;
                transform-origin:top left;
                height:224px;
                min-height:224px;
            }
            @media (max-width:768px) {
                transform:translateX(10px) translateY(-10px) scale(0.88)!important;
            }
            .flickity-slider {
                min-height:224px;
                width:100%;
                position:absolute!important;
                
                @media (max-width:991px) {
                    height:224px;
                    min-height:224px;
                }
            }

        }

        div[data-content-type="promo_card"] {
            display:flex;
            margin-right:16px!important;
            width:363px;
            min-width:363px;
            @media (max-width:480px) {
                transform-origin:top left;
                transform:scale(0.9);
            }
        }
        .promo-card {
            height:224px;
            min-height:224px;
            width:100%;
            box-shadow:0px 1px 8px rgba(2,2,2,0.1);
            border-radius:12px;
            background:#fff;
            display:inline-block;
            position:relative;
            overflow:hidden;
            transition:all 0.18s ease, height 0s, width 0s;
            filter:brightness(0.99);

            &:hover {
                box-shadow:0px 2px 12px rgba(2,2,2,0.10);
                filter:brightness(1.02);
                transform:scale(1.015) translateZ(0) perspective(1px);

                .cta-button {
                    filter:brightness(1.1);
                    box-shadow:0px 0px 8px rgba(2,2,2,0.12);
                }
            }

            a {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                .card-inner {
                    height:100%;
                }
            }
            .left-content {
                position:absolute;
                top:0;
                left:0;
                bottom:0;
                z-index:2;
                background:#0088CE;
                width:54%;
                padding:16px;
                padding-right:24px;
                display:flex;
                justify-content:center;
                flex-direction:column;
                clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
                @media (max-width:480px) {
                    padding-left:24px;
                }
                span {
                    font-size:12px;
                    line-height:14px;
                    text-transform:uppercase;
                    color:#FFF;
                    margin-bottom:6px;
                    //transform:translateZ(0) perspective(1px);
                }
                h5 {
                    font-size:17px;
                    line-height:18px;
                    font-weight:800;
                    text-transform:none!important;
                    margin:4px 0;
                    color:#FFF;
                    //transform:translateZ(0) perspective(1px);
                }
                h5.thin {
                    font-size:15px;
                    font-weight:500;
                    text-transform:none!important;
                    color:#FFF;
                    //transform:translateZ(0) perspective(1px);
                }
                .cta-button {
                    min-width:100px;
                    max-width:130px;
                    align-self:flex-start;
                    height:33.6px;
                    line-height:33.6px;
                    text-align:center;
                    white-space:nowrap;
                    padding:0 14px!important;
                    font-size:12px!important;
                    background:#F57F29;
                    color:#FFF;
                    font-weight:700;
                    text-transform:uppercase;
                    transition:0.18s ease;
                }
            }

            .right-content {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                z-index:1;
                width:59.24%;
                display:flex;
                justify-content:center;
                flex-direction:column;
                padding:16px;

                img {
                    align-self:flex-end;
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                    max-width:200%;
                    height:100%;
                }
            }
        }
    }


    .flickity-button {
        position:absolute;
        top:50%;
        transform:translateY(-50%) scale(0.74);
        border-radius:100%;
        border:2px solid #fff;
        background:#0088ce;
        transition:0.18s ease;

        @media (max-width:1660px) {
            transform:translateY(-50%) scale(0.74);
            border:4px solid #fff;
        }
        @media (max-width:991px) {
            transform:translateY(-58%) scale(0.74);
        }
        @media (max-width:991px) {
            transform:translateY(-58%) scale(0.68);
        }
        &:hover {
            background:#0088ce;
        }
        &:disabled {
            opacity:0;
        }
        svg {
            width:18px;
            height:18px;
            top:50%;
            left:50%;
            transform:translateX(-50%) translateY(-50%);
            path {
                color:#fff;
            }
        }

        &.previous {
            left:-18px;
            @media (max-width:768px) {
                left:-17px;
            }
            @media (max-width:480px) {
                left:-14px;
            }
        }
        &.next {
            right:-28px;
            @media (max-width:768px) {
                right:-27px;
            }
            @media (max-width:480px) {
                right:-24px;
            }
        }
    }

}

.catalog-category-view.page-with-filter {

    .jpp-promo-tray .promotions-list.no-flickity, .jpp-promo-tray .promotions-list:not(.flickity-enabled) {
        padding-top:0!important;
    }
}
