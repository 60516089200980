.opc-progress-bar {
    box-shadow: 0 2px 16px 0 $box-shadow-light;
    margin-top:-25px;
    margin-bottom:12px;
    @media (max-width: $bp-tablet - 1) {
        order: 0;
        width: 100%;
    }

    @media (min-width: $bp-tablet) {

    }

    @media (min-width: $bp-laptop) {
        margin: -25px auto 12px;
        width:$content-max;
        max-width:100%;
    }

    .opc-progress-bar-item {
        background-color: $jpp-grey-4;
        span {
            @include ease;
        }
        @media (min-width: $bp-tablet) {
            width: 50%;
        }

        &:not(._active) {
            box-shadow: 0 0 28px inset $jpp-grey;
            &:nth-of-type(1):hover {
                span {
                    color:$jpp-blue;
                }
            }
        }

        &._active {
            background-color: $white;

            > span {
                color: $jpp-blue;
                opacity:1;
            }
        }

        &:nth-child(1) {
            > span {
                user-select:none;
                &::before {
                    content: '1.';
                    display: inline-block;
                    @media (max-width:480px) {
                        margin-left:-18px;
                    }
                }
            }
        }

        &:nth-child(2) {
            > span {
                user-select:none;
                &::before {
                    content: '2.';
                    display: inline-block;
                }
            }
        }

        &::before {
            display: none;
        }

        > span {
            @include font-bold;
            color: $jpp-off-black;
            opacity:0.66;
            font-size: 15px;
            padding-bottom: 15px;
            padding-top: 15px;
            padding-left:8px;
            text-transform: capitalize;
            @media (min-width: $bp-mobile-l) {
                font-size: 16px;
            }
            @media (min-width: $bp-laptop) {
                font-size: 18px;
            }
            @media (max-width:480px) {
                padding:12px 0 12px 12px;
                font-size:13px;
            }


            &::before,
            &::after {
                background-color: transparent;
                display: none;
            }

            &::before {
                font-size: 14px;
                height: auto;
                margin-right: 5px;
                position: static;
                width: auto;

                @media (min-width: $bp-laptop) {
                    font-size: 18px;
                }
            }
        }
    }
}
