.product-attachment-container {
    padding:0;
    .section-title {
        margin-bottom:12px;
    }
    .section-files {
        padding-left:0;
    }
    .downloads-item {
        margin-top:6px;
        margin-bottom:8px;
        &:nth-of-type(1) {
            margin-top: 0;
        }
        a {
            font-size:14px;
            text-decoration:none!important;
            p {
                font-size:14px;
                font-weight:600;
            }
        }
        .item-icon {
            padding-right: 5px;
            padding-top: 0;
            vertical-align: middle;
        }
    }
    .item-filename {
        @include font-bold;
        font-weight:600;
        font-size:14px;
        margin: 0 0 2.5px;
    }
    .item-container {
        display: inline-block;
        a {
            b {
                font-weight: 600;
            }
        }
    }
    .item-info {
        margin-left:6px;
    }
}
