.account {
    .toolbar {
        @include clearfix;
        height:60px;
        padding:17px 24px!important;
        .pager {
            float: left;
        }

        .toolbar-amount {
            line-height:26px;
            margin-bottom:0;

            * {
                font-size:14px;
            }
        }

        .limiter {
            clear:none;
            float:right;
            margin-bottom:0;
            padding:0;
            vertical-align:middle;

            * {
                font-size:14px;
            }

            .label,
            .control,
            .limitear-text {
                display:inline-block;
            }

            .label {
                clip:auto!important;
                height:auto!important;
                margin:0!important;
                overflow:auto!important;
                position:static!important;
                vertical-align:middle;
                width:auto!important;
            }

            select {
                height:26px;
                padding:4px;
                line-height:26px;
            }
        }
    }
}
