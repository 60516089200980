.magento_invitation-index-send, // sass-lint:disable-line class-name-format
.magento_invitation-index-index { // sass-lint:disable-line class-name-format
    .invitations {
        .actions-toolbar {
            margin-top: 20px;
        }
    }
    .rewards {
        display:flex;
        flex-direction:column;
        span.reward-balance {
            order:1;
            margin-bottom:32px;
        }
        .message.info {
            order:2;
        }
    }
    .reward-balance {
        color: inherit;
        display: block;
        font-size: 1.6rem;
        font-weight: 800;
        line-height: 2rem;
        margin-top: 20px;
        text-align: center;
        text-transform: uppercase;

        .balance {
            color: $jpp-orange;
            display: block;
            font-size: 3.2rem;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 3.9rem;
        }
    }

    #invitationForm { // sass-lint:disable-line no-ids
        @include customer-container;

        legend {
            @include customer-container-title;
        }
        .actions-toolbar {
            box-shadow: none;
            margin: 0;
            padding: 0;
        }

        .fieldset {
            > .actions-toolbar {

                .secondary {
                    margin-left: 0;

                    button {
                        @include button('primary');
                        margin-top: 0;
                        padding: 0 10px 0 0 !important; // sass-lint:disable-line no-important

                        span {
                            clip: unset;
                            height: unset;
                            line-height: 3.2rem;
                            margin: 0;
                            position: static;
                            width: unset;
                        }

                        &::before {
                            font-size: 2.2rem;
                            font-weight: 700;
                            line-height: 3.2rem;
                        }
                    }
                }
            }
        }

        > .actions-toolbar {
            .secondary {
                float: right;
            }
        }
    }

    #invitations-options { // sass-lint:disable-line no-ids
        .fieldset {
            position: relative;
            z-index: auto;

            .actions-toolbar {
                padding: 0;
                position: absolute;
                right: 4px;
                top: 0px;
                z-index: 1;

                &:before {
                    content: none;
                }

                .remove {
                    background: $white;
                    margin: 0;
                    position: static;
                }
            }

            .field {
                margin-bottom: 0;
                margin-top: 0;
                width: 100%;
            }

        }
    }
}

.magento_invitation-index-send {
    .column.main {
        .rewards {
            @include customer-container;
            margin-bottom:12px;
        }
    }
}
