// sass-lint:disable no-important no-color-literals
.cms-what-is-sublimation {
    .main {
        section {
            &.triplebluegradient-bg {
                @include full-width;
                padding-left: 0;
                padding-right: 0;

                > .row {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .catalog-img {
                img {
                    @media (min-width: 1600px) {
                        margin: 0;
                        max-height: 327px;
                        position: relative;
                        width: auto !important;
                    }
                }
            }

            .catalog-link-text {
                * {
                    @include unset-underline;
                }
            }
        }

        .substrate-slider {
            .slick-slide {
                background: $white;
                box-shadow: 0 12px 80px -6px rgba(0, 0, 0, .1);
                display: inline-block;
                margin-right: 12px;
                // padding: 24px;
                position: relative;
                z-index: 0;
            }
        }
    }
}
