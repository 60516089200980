.customer-item-history {
    .previously-ordered {
        .field.qty {
            justify-content:flex-end;
            > label span {
                font-size:12px!important;
            }
        }
        input#qty {
            border-color:#ededed!important;
            max-width:62px!important;
        }
        .table-wrapper {
            margin-bottom: 0;
        }
        .block-title {
            @include customer-container;
            h3 {
                margin: 0;
                text-transform: none;

                @media (min-width: $bp-laptop) {
                    font-size: 22px;
                }
            }
        }
        tr {
            @include customer-container;
            display: block;
            margin-bottom: $account-spacer;
            position: relative;
        }
        .col {
            display: block;
            padding: 0 !important;
            @media (min-width: $bp-laptop) {
                display: inline-block;
            }
            &.item {
                @media (min-width: $bp-laptop) {
                    min-width: 75%;
                }
                .product-item-image {
                    border: 1px solid $jpp-grey;
                    float: left;
                    margin-right: 16px;
                }
                .product-item-info {
                    max-width: unset;
                    width: unset;
                    display:flex;
                    flex-direction:column;
                    .product-item-name {
                        @include unset-underline;
                        h5 {
                            display: inline-block;
                            float: left;
                            margin:0 0 2px;
                        }
                    }
                    .item-options {
                        dt,
                        dd {
                            @include font-normal;
                            display: inline;
                            margin-bottom:8px;
                        }
                    }
                }
            }
            &.actions {
                label.label {
                    span {
                        font-size:14px;
                    }
                }
                @media (min-width: $bp-laptop) {
                    position: absolute;
                    right: 28px;
                    top: 32px;
                }
            }
        }
    }
    .toolbar {
        @include customer-container;
        margin-bottom: 0;
    }
}
