.page-layout-cms-fullwidth-exception {
    @media (min-width: $bp-tablet) {
        .page-main {
            max-width: unset;

            div {
                &[data-content-type='row'],
                &[data-appearance='contained'],
                &[data-appearance='contained'][data-appearance='row'] { // sass-lint:disable-line force-attribute-nesting
                    max-width: unset;
                }
            }
        }
    }
}

