.jpp-slider-wrapper {
    overflow:hidden;
    //background:#111;
    background:#fff;
    &:hover {
        .slick-arrow {
            opacity:1;
        }
    }
    @media (max-width:$bp-desktop) {
        width:100%;
        padding:0;
    }
    .jpp-slider {
        //background:#222;
        background:#fff;
        min-height:61.979vw;
        position:relative;
        @media (min-width:769px) {
            min-height:21.25vw;
        }
        opacity:1;
        visibility:visible;
        transition:opacity 0.4s ease;

        @keyframes loadGlow {
            0% {
                //left:-100%;
                opacity:0;
            }
            50% {
                opacity:0.6;
            }
            100% {
                //left:100%;
                opacity:0;
            }
        }

        //Loading Animation
        &:before, &:after {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            bottom:0;
            opacity:1;
            visibility:visible;
            content:'';
            background:#222;
            z-index:2;
            transition:0.44s ease;
            display:none;
        }
        &:after {
            z-index:3;
            left:0;
            background:#444;
            opacity:0.9;
            animation-iteration-count:2;
        }
        &:not(.slick-initialized) {
            &:after {
                animation:loadGlow 2s ease-in-out infinite;
            }
        }
        &.slick-initialized {
            visibility:visible;
            opacity:1;
            &:before, &:after {
                opacity:0!important;
                pointer-events:none!important;
            }
        }

        ul.slick-dots {
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            padding:32px 0;
            background:linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
            pointer-events: none;
            @media (max-width:768px) {
                padding:32px 0 16px;
            }
            li {
                height:auto;
                width:auto;
                pointer-events: initial;
                @media (max-width:768px) {
                    margin:0 8px;
                }
                button {
                    width:22px;
                    height:22px;
                    border:0.5px solid #333;
                    background:rgba(255,255,255,0.55);
                    border-radius:100%;
                    opacity:0.6;
                    box-shadow:0px 0px 6px rgba(2,2,2,0.22);
                    @media (max-width:768px) {
                        width:20px;
                        height:20px;
                    }
                }
                &.slick-active {
                    button {
                        background:rgba(255,255,255,1);
                        border:0.5px solid #000;
                        opacity:1;
                    }
                }
            }
        }
    }
    &.slider-active {
        .slide {
            &:not(.slide-index-1) {
                display: block;
            }
        }
    }

    .slide {
        background-position:center right;
        background-size:cover;
        display:none;
        height:62vw;
        margin-bottom:-6px;
        position:relative;
        @media (min-width: $bp-tablet) {
            height:35.41666vw;
        }

        &:not(.slide-index-1) {
            display:none;
        }

        &::before {
            background: linear-gradient(to top, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0) 100%);
            content: '';
            height:140px;
            left:0;
            right:0;
            bottom:0;
            position: absolute;
            width: 100%;
            z-index: 0;
            pointer-events:none;
        }
        &::after {
            background: linear-gradient(to top, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0) 100%);
            content: '';
            height:100px;
            left:0;
            right:0;
            bottom:0;
            position: absolute;
            width: 100%;
            z-index: 0;
            pointer-events:none;
        }
        .pagebuilder-slide-wrapper {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }

    .slide-content {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:2;
    }

    .content-wrapper {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100vw;

        h1,
        h3,
        a {
            text-shadow:0px 0px 6px rgba(2,2,2,0.6);
            @media (min-width:$bp-tablet) {
                text-shadow:0px 0px 6px rgba(2,2,2,0.14);
            }
            @media (min-width: $bp-laptop) {
                text-shadow: 0 1px 2px rgba(2, 2, 2, .3), 0 1px 12px rgba(2, 2, 2, .1);
            }
        }

        h1 {
            color: $white;
            margin-top: $spacer / 2;

            @media (max-width:$bp-mobile-l) {
                font-size:24px;
            }
            @media (min-width: $bp-laptop) {
                font-size: 26px;
                margin-top: 0;
            }
        }

        h3 {
            color: $white;
            font-size: 16px;
            min-width: 180px;
            text-transform: none;
            width: 48vw;
            font-weight:500;
            @media (max-width:$bp-mobile-l) {
                font-size:14px;
            }
            @media (min-width: $bp-tablet) {
                width: auto;
            }
        }

        a {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            @include smooth;
            @include unset-underline;
        }

        p {
            margin: 0;
        }
    }

    .slick-arrow {
        bottom: 5px;
        color: $white;
        height: 30px;
        margin: 0;
        position: absolute;
        z-index: 10;
        text-shadow:0px 0px 4px rgba(2,2,2,0.12);
        opacity:0;
        transition:0.32s ease;
        cursor:pointer;
        @media (max-width:$bp-tablet) {
            opacity:1;
        }
        svg {
            display:none;
            position:absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%) scale(0.8);
        }
        @media (min-width: $bp-tablet) {
            height:40px;
            width:40px;
            background:#fff;
            box-shadow:0px 0px 6px rgba(2,2,2,0.1);
            border-radius:100%;
            bottom:auto;
            top:50%;
            transform:translateY(-50%);
            svg {
                display:block;
            }
            &::before, &::after {
                display:none!important;
            }
        }

        &::before {
            background-color:rgba(255,255,255,0.15);
            content:'';
            display:block;
            height:35px;
            top:0;
            width:68px;
            text-shadow:0px 0px 4px rgba(2,2,2,0.12);
        }

        &::after {
            bottom:0;
            display:block;
            font-size:13px;
            position:absolute;
            text-transform:uppercase;
            color:#fff;
            width:100%;
            text-align:center;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    .next-arrow {
        right: 0;
        @media (min-width: $bp-tablet) {
            right:2vw;
        }
        &::after {
            content: 'Next';
        }
    }

    .prev-arrow {
        left: 0;
        svg {
            transform: translateX(-50%) translateY(-50%) rotate(-180deg) scale(0.8);
        }
        @media (min-width: $bp-tablet) {
            left:2vw;
        }
        &::after {
            content: 'Prev';
            right: 0;
        }
    }

    .jpp-slider-nav-wrapper {
        @include center-x;
        bottom: $spacer;
        pointer-events:none;
        z-index:5;
        @media (min-width: $bp-laptop) {
            width: $content-max;
            max-width:100%;
        }
        @media (min-width: $bp-laptop) and (max-width:$bp-desktop-xl) {
            max-width:92%;
        }
        @media (max-width:$bp-laptop) {
            width:100%;
            display:flex;
            justify-content:center;
            padding:16px 32px;
            height:68px;
            bottom:0;
        }
        @media(max-width:$bp-tablet) {
            padding-bottom:0;
        }
    }

    .jpp-slider-nav {
        position: relative;
        z-index:2;
        pointer-events:auto;
        display:flex;
        bottom:0;
        @media (min-width: $bp-tablet) {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            padding:0;
            width: 100%;
        }
        @media (min-width: $bp-laptop) {
            left: 12px;
            width: auto;
        }

        @media (min-width: $bp-desktop) {
            left: 0;
        }
        @media (max-width:$bp-laptop) {
            white-space:no-wrap;
            font-size:13px;
            margin-right:14px;
            align-self:center;
            &:last-of-type {
                margin-right:0;
            }
        }

        .slider-nav-item {
            @include unset-underline;
            display: none;
            font-size: 14px;
            font-weight:700;
            opacity: 0.6;
            pointer-events: none;
            text-shadow: 1px 1px 2px rgba(2, 2, 2, 0.2), 1px 1px 3px rgba(2, 2, 2, 0.2), 1px 1px 4px rgba(2, 2, 2, 0.2);
            margin-right:0;
            max-width:100%;
            border-bottom:2px solid #fff;
            text-align:center;
            flex:1;
            padding:14px 12px 4px;
            &:last-of-type {
                margin-right:0!important;
            }
            @media (min-width: $bp-tablet) {
                display: block;
                pointer-events: all;
                margin-right:12px;
                max-width:calc(20% - 9.6px)!important;
            }
            @media (min-width:$bp-laptop) {
                margin-right:16px;
                max-width:calc(20% - 12.8px)!important;
            }
            @media (max-width:$bp-laptop) {
                white-space:nowrap;
                font-size:13px;
            }

            &:hover {
                opacity: 1;
            }

            &.active {
                display: block;
                opacity: 1;
            }
        }
    }
}
