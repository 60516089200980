body.cms-index-index {
    .off-white-gradient-bg {
        background:linear-gradient(to bottom, #f9f9f9 0%, #fff 100%);
    }

    .jpp-trending-recent-product-tiles, .jpp-main-categories, #home-resources, .testimonials-section, .home-why-choose-jpp, .home-feature-pages-block {
        h2 {
            @media (max-width:991px) {
                font-size:28px!important;
            }
            @media (max-width:768px) {
                font-size:24px!important;
            }
            @media (max-width:480px) {
                font-size:22px!important;
            }
        }
        p {
            @media (max-width:768px) {
                font-size:16px!important;
            }
            @media (max-width:400px) {
                font-size:14px!important;
            }
        }
    }
    .recently-viewed-purchased .recently-viewed h2, .recently-viewed-purchased .recently-purchased h2 {
        @media (max-width:480px) {
            font-size:20px!important;
            font-weight:700;
        }
    }

    h1, h2, h3, h4, h5 {
        &.featured-equipment {
            position:relative;
            &:before {
                position:absolute;
                left:0;
                top:-30px;
                content:'FEATURED EQUIPMENT';
                font-size:13px;
                padding:0 10px;
                border-radius:4px;
                background:linear-gradient(to right, #0088ce 0%, #00C0CE 100%);
                color:#fff;
                font-weight:800;
                height:24px;
                line-height:25.5px;
            }
        }
    }
    .featured-equipment-section {
        padding:80px 0;
        @media (max-width:768px) {
            padding:48px 0;
        }
    }

}