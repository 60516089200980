.jpp-tiles-block {
    margin-top:80px;

    @media (max-width:$bp-laptop - 1) {
        margin-top:46px;
    }

    .heading {
        margin-bottom: $spacer;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 80%;
    }

    .tile-wrapper {
        margin-top:68px;
        @media (max-width:$bp-laptop - 1) {
            margin-top:36px;
        }
        &.grid-collapse {
            margin-left: -5px;
            margin-right: -5px;
        }
    }

    .tile-container {
        padding: 5px;
    }

    .tile {
        min-height: 256px;
        position: relative;
        @include smooth;

        @media (max-width: 600px) {
            min-height: 45.45vw;
        }

        &.tile-blue {
            background-color: $tile-blue;
        }

        &.tile-blue-2 {
            background-color: $tile-blue-2;
        }

        &.tile-light-blue {
            background-color: $tile-light-blue;
        }

        &.tile-orange {
            background-color: $tile-orange;
        }

        &.tile-dark-grey {
            background-color: $tile-dark-grey;
        }

        &.tile-grey {
            background-color: $tile-grey;
        }

        &:hover {
            background: $white;
            box-shadow: 0 12px 80px -6px rgba(0, 0, 0, .1); // sass-lint:disable-line no-color-literals
        }
    }

    .tile-title {
        @include center-x;
        box-sizing: border-box;
        color: $white;
        letter-spacing: 2px;
        margin: 0;
        padding: 0 10px;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size:14px;
        bottom:10px;
        
        @media (min-width:$bp-tablet) {
            font-size: 18px;
            bottom: 16px;
        }
        @media (min-width: $bp-laptop-xl) {
            font-size: 20px;
        }
        @media (min-width: $bp-desktop) {
            font-size: 22px;
            bottom:22px;
        }
    }

    .svg-container {
        @include center-x-y;
        top:46%;
        @media (max-width: $bp-desktop-s - 1) {
            top: 45%;
        }
    }

    svg {
        height: 80px;

        @media (min-width: $bp-desktop-s) {
            height: 100px;
        }
    }

    a {
        @include unset-underline;
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        @include smooth;

        h3 {
            font-weight:700;
        }
        .tile-title, svg path {
            @include smooth;
        }
        &:hover {
            .tile-title {
                color: $jpp-orange;
            }
            svg {
                path {
                    fill: $jpp-orange;
                }
            }
        }
    }
}
