// sass-lint:disable no-important
.cms-gyford {
    .main {
        .slick-track {
            display: table !important;
        }

        .slick-slider {
            margin-left: -5px;
            margin-right: -5px;
            padding-top: 60px;

            .slick-arrow {
                top: 20px;
            }

            .slick-next {
                right: 5px;
            }

            .slick-prev {
                right: 43px;
            }
        }

        .slick-slide {
            display: table-cell !important;
            float: none !important;
            padding-left: 5px;
            padding-right: 5px;
            position: relative;

            .shop-all {
                a {
                    background-color: $jpp-blue;

                    span {
                        @include font-bold;
                        font-size: 20px;
                    }
                }

                * {
                    color: $white;
                }
            }
        }

        .slider-controls {
            display: none;
        }

        .gyford-sections {
            .blue-button {
                margin-top: 20px;
            }
        }
    }
}
