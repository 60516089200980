body.catalog-product-view {
    #sns_main {
        padding-bottom:0!important;
    }

    h1.page-title {
        display:none!important;
        @media (max-width:768px) {
            display:none!important;
        }
    }
}

#vue-pdp {
    a {
        text-decoration:none!important;
    }
    section {
        margin:0;
    }

    /* Overlay States (Gallery, Mobile Configurable Selectors) */
    section.overlay-wrapper {
        z-index:999;
        position:relative;
        pointer-events:none;
        width:100vw;
        height:100vh;
        opacity:0;
        pointer-events:none;
        position:fixed;
        top:0;
        left:0;
        right:0;
        .gallery-hover, .gallery-expanded, .configurable-options {
            z-index:10;
        }
        > .background {
            background:rgba(0,0,0,0.16);
            pointer-events:none;
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index:5;
        }

        &.active {
            opacity:1;
            pointer-events:auto;

            > .background {
                pointer-events:auto;
            }
        }
    }

    /* Main Product Section */

    section.jpp-product {
        display:flex;
        z-index:2;
        position:relative;
        @media (max-width:991px) {
            flex-direction:column;
        }
        /* Column 1 */
        div.gallery-area {
            display:flex;
            flex-wrap:wrap;
            flex:2;
            min-width:28%;
            position:relative;
            z-index:50;//NOTE: keep in sync with 032478609243905823409523
            align-self:flex-start;
            margin-bottom:auto!important;
            @media (max-width:1400px) {
                width:28%;
                flex:none;
            }
            @media (max-width:991px) {
                width:100%;
            }

            .gallery-thumbs {
                transform: rotate(90deg) translate(0px, -100%);
                transform-origin: left top;
                position:absolute;
                display:flex;
                //overflow-x:hidden;
                overflow:hidden;
                //z-index:750;
                align-self:flex-start;
                transition:all 0.18s ease;
                opacity:1;
                &:not(.flickity-enabled) {
                    opacity:0;
                    pointer-events:none;
                    .image {
                        opacity:0;
                        transition:0s;
                    }
                }
                &.flickity-enabled {
                    .image {
                        opacity:1;
                        transition:opacity 0.24s;
                        transition-delay:0.24s;
                    }
                }
                @media screen and (max-width: 991px) {
                    display:none;
                }

                .flickity-viewport {
                    width:100%;
                }
                .flickity-slider {
                    height:100%;
                    width:100%;
                }

                .flickity-button {
                    position:absolute;
                    background:#fff!important;
                    width:20px!important;
                    height:45px!important;
                    transition:0.18s ease;
                    border-radius:0px;
                    border:1px solid #dfdfdf;
                    padding:0;

                    &:disabled {
                        opacity:0;
                        pointer-events:none;
                    }
                    &.previous {
                        left:0!important;
                        svg {

                        }
                    }

                    &.next {
                        right:0!important;
                        svg {
                            left:calc(50% - 1px);
                        }
                    }
                    svg {
                        transform:translateX(-50%) translateY(-50%);
                        top:calc(50% - 0.5px);
                        left:calc(50% + 1px);
                        height:12px;
                        position:absolute;
                    }
                }
                a {
                    width:100%;
                    padding-bottom:50%;
                    margin-bottom:8px;

                    img {
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        max-width:calc(100% - 16px);
                        max-height:calc(100% - 16px);
                        user-select:none;
                    }
                }

                .image {
                    width:45px;
                    height:45px;
                    margin-right:8px;
                    border:1px solid #dfdfdf;
                    cursor:pointer;
                    overflow:hidden;

                    @media (max-width:1400px) {
                        margin-right:6px;
                    }
                    &.is-nav-selected {
                        border:1px solid #0088CE;
                        cursor:default;
                    }
                    img {
                        max-width:calc(100% - 8px);
                        max-height:calc(100% - 8px);
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%) rotate(-90deg);
                        user-select:none;
                        font-size:7px;
                    }
                }
                img {
                    transform:translateX(-50%) translateY(-50%) rotate(-90deg);//fallback on load
                }
                .flickity-prev-next-button {
                    width: 40px;
                    height: 40px;
                    background: transparent;

                    &.previous{
                        left: -40px;
                    }
                    &.next{
                        right: -40px;
                    }
                }

            }

            .main-image-viewport {
                border:1px solid #dfdfdf;
                width:calc(100% - 55px);
                position:relative;
                float:right;
                margin-top:0;
                margin-bottom:auto;
                margin-right:0;
                margin-left:auto;
                z-index:500;
                background:#fff;
                @media (max-width:991px) {
                    float:none;
                    margin:0 auto;
                    width:400px;
                    max-width:100%;
                }
                @media (max-width:480px) {
                    float:none;
                    margin:0 auto;
                    width:70.4vw;
                    max-width:100%;
                }
                .flickity-viewport {
                    width:100%;
                    height:100%;
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    overflow:hidden;
                    .flickity-slider {
                        height:100%;
                        width:100%;
                    }
                }
                .product-images {
                    width:100%;
                    transition: opacity 0.18s ease;
                    padding-bottom:100%;
                    position:relative;
                    z-index:50;
                    background:#fff;
                    &:not(.flickity-enabled) {
                        opacity:0;
                    }
                    .image {
                        position:relative;
                        width:100%;
                        height:100%;
                        cursor:pointer;
                        img {
                            display:block;
                            margin:0 auto;
                            max-width:calc(100% - 32px);
                            max-height:calc(100% - 32px);
                            position:absolute;
                            top:50%;
                            left:50%;
                            transform:translateX(-50%) translateY(-50%);
                            @media (max-width:480px) {
                                max-width:calc(100% - 20px);
                                max-height:calc(100% - 20px);
                            }
                        }
                    }

                    .flickity-page-dots {
                        list-style-type:none;
                        display:flex;
                        justify-content:center;
                        margin:0;
                        padding:0;
                        position:absolute;
                        left:50%;
                        transform:translateX(-50%);
                        //top:calc(100% + 14px);
                        top:calc(100% - 28px);

                        @media (min-width:992px) {
                            display:none;
                        }

                        li.dot {
                            width:14px;
                            height:14px;
                            border-radius:100%;
                            background:#fff;
                            border:1px solid #969696;
                            transition:0.18s ease;
                            margin-right:10px;
                            cursor:pointer;
                            &.is-selected {
                                border:1px solid #0088ce;
                                background:#0088ce;
                                cursor:default;
                            }
                            &:last-of-type {
                                margin-right:0;
                            }
                            &:only-child {
                                display:none;
                            }
                        }
                    }
                }
                .extra-image-html-wrapper {
                    position:absolute;
                    top:0;
                    z-index:99;

                    .preorder-sticker {
                        transform:rotate(-10deg) scale(0.69) translateX(2px) translateY(28px);
                        transform-origin:top left;
                    }
                    div img{
                        position: static !important;
                        width: 60% !important;

                    }
                }
                .bubble {
                    position:absolute;
                    pointer-events:none;
                    top:16px;
                    left:0;
                    width:102px;
                    height:38px;
                    border-radius:75%;
                    z-index:999;
                    &.coming-soon, &.preorder {
                        transform:rotate(-30deg);
                    }
                    @media (max-width:991px) {
                        top:18px;
                        left:0px;
                    }
                    &.coming-soon {
                        background:#0048A9;
                        span {
                            top:calc(50% + 0.5px);
                        }
                    }

                    &.preorder {
                        background:#E68138;
                        span {
                            white-space:nowrap;
                        }
                    }
                    span {
                        font-size:12px;
                        line-height:11px;
                        font-weight:800;
                        text-align:center;
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        color:#fff;
                        user-select:none;
                    }
                }
            }

            .gallery-marketing {
                width:100%;
                padding-left:55px;
                margin-top:24px;
                @media (max-width:991px) {
                    padding-left:0;
                    width:400px;
                    max-width:100%;
                    margin-left:auto;
                    margin-right:auto;
                    //margin-top:34px;
                    margin-top:8px;
                    //margin-bottom:-40px;
                    margin-bottom:-18px;
                }
            }
            .gallery-hover, .gallery-expanded {
                position:absolute;
                top:0;
                left:calc(100% + 32px);
                width:66vh;
                height:66vh;
                box-shadow:0px 3px 44px rgba(2,2,2,0.25);
                opacity:0;
                transition:0.24s ease;
                pointer-events:none;
                background:#fff;
                object-fit:contain;
                z-index:50;
                @media (max-width:1400px) {
                    left:calc(100% + 16px);
                }

                .full-image {
                    opacity:0;
                    transition:0.16s ease;
                    position:relative;
                    height:100%;

                    img {
                        display:block;
                        margin:0 auto;
                        width:calc(100% - 32px);
                        max-width:calc(100% - 32px);
                        max-height:calc(100% - 32px);
                        object-fit:contain;
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                    }

                    &.active {
                        opacity:1;
                        transition:0.32s ease;
                    }
                }

                + .background {
                    background:rgba(0,0,0,0.16);
                    pointer-events:none;
                    position:fixed;
                    width:100vw;
                    height:100vh;
                    opacity:0;
                    top:0;
                    left:0;
                    z-index:5;
                    transition:0.24s ease;
                }

                &.active {
                    opacity:1;
                    pointer-events:auto;
                    + .background {
                        opacity:1;
                        pointer-events:none;
                    }
                }
            }
            .gallery-hover {
                @media (max-width:991px) {
                    display:none!important;
                }
                + .background {
                    @media (max-width:991px) {
                        display:none!important;
                    }
                }
            }
            .gallery-expanded {
                display:flex;
                flex-direction:column;
                width:90vw;
                height:90vh;
                max-width:calc(100% - 32px);
                max-height:calc(100% - 32px);
                position:fixed;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                @media (max-width:1400px) {
                    width:92vw;
                }
                @media (max-width:991px) {
                    width:calc(100% - 32px);
                    height:calc(100vw - 32px);
                }
                @media (max-width:480px) {
                    width:calc(100% - 16px);
                    height:calc(140vw - 16px);
                    max-width:calc(100% - 16px);
                    max-height:calc(100% - 16px);
                }
                .gallery-thumbs {
                    display:flex;
                    transform:none;
                    position:absolute;
                    bottom:16px;
                    left:16px;
                    margin-right:0;
                    min-width:100%!important;
                    @media (max-width:991px) {
                        min-width:calc(100% - 32px)!important;
                    }
                    .image {
                        transform:none;
                    }
                }
                .full-image {
                    width:100%;
                    height:calc(100% - 60px);
                }
                a.close {
                    width:40px;
                    height:40px;
                    position:absolute;
                    top:0;
                    right:0;
                    svg {
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        g, line {
                            transition:0.18s ease;
                        }
                    }
                    &:hover {
                        svg {
                            g {
                                opacity:1;
                            }
                            line {
                                stroke:#0088ce;
                            }
                        }
                    }
                }
                &.active {
                    z-index:9999;
                    + .background {
                        pointer-events:auto;
                    }

                    img {
                        transform:translateX(-50%) translateY(-50%) !important;
                    }
                }
            }

        }

        /* Column 2 */
        div.content-area {
            padding:0 48px;
            flex:3;
            position:relative;
            z-index:25;
            overflow:hidden;
            @media (max-width:1400px) {
                width:calc(100% - 28% - 296px);
                padding:0 24px;
                flex:none;
            }
            @media (max-width:1280px) {
                padding:0 18px;
                width:calc(100% - 28% - 268px);
            }
            @media (max-width:991px) {
                width:100%;
                padding:0;
                //margin-top:54px;
                margin-top:24px;
                overflow:visible;
            }

            .product-header {
                border-bottom:1px solid #dfdfdf;
                padding-bottom:20px;
                margin-bottom:24px;
                @media (max-width:1368px) {
                    padding-bottom:14px;
                }
                @media (max-width:480px) {
                    margin-bottom:0;
                    border-bottom:0px solid transparent;
                    padding-bottom:8px;
                }
                h1 {
                    font-size:28px;
                    color:#000;
                    margin:0;
                    margin-bottom:4px;
                    @media (max-width:480px) {
                        font-size:22px;
                    }
                }

                div {
                    display:flex;
                    @media (max-width:768px) {
                        flex-wrap:wrap;
                    }
                    .sku, .reviews {
                        line-height:18px;
                        font-size:13px;
                        span, a {
                            height:24px;
                            line-height:18px;
                            font-size:inherit;
                        }
                    }
                    .sku {
                        color:#666666;
                        margin-right:14px;
                        span {
                            color:#0048A9;
                        }
                    }
                    .reviews {
                        .rating-summary {
                            .rating-result {
                                margin-top:-6px;
                                width:84px;

                                span {
                                    height:20px;
                                }
                                &:before, > span:before {
                                    font-size:24px;
                                    height:20px;
                                }
                                span:before {
                                    color:#f58220;
                                }
                            }
                        }
                        .review-count {
                            margin-right:6px;
                            position:relative;

                            &::after {
                                position:absolute;
                                content:'';
                                top:4px;
                                bottom:4px;
                                width:1px;
                                left:calc(100% + 6px);
                                background:#e7e7e7;
                            }
                        }
                        .add-review {
                            margin-left:6px;
                        }
                        a {
                            color:#0048A9;
                            line-height:24px;
                            transition:0.18s ease;
                            font-size:13px;
                            transform:translateY(-3px);
                            &:hover {
                                color:#F5781F;
                            }
                        }
                    }

                    .add-review, .first-review a {
                        color:#0048A9;
                        font-size:13px;
                    }
                    .first-review a {
                        margin-top:-1px;
                    }
                }

            }

            .configurable-form {
                margin-top:16px;
                .swatch-attribute {
                    > span {
                        font-size:14px;
                        font-weight:600;
                        &.swatch-attribute-label {
                            color:#000;
                            &:after {
                                position:absolute;
                                top:50%;
                                left:calc(100% + 2px);
                                transform:translateY(-50%);
                                content:":";
                                color:#000;
                            }
                        }
                        &.swatch-attribute-selected-option {
                            color:#0048A9;
                            padding-left:7px;
                        }
                    }

                    .swatch-attribute-options {
                        span.swatch-label {
                            display:block;
                            line-height:28px;
                            user-select:none;
                        }
                    }
                }
            }

            .coming-soon {
                div.tag {
                    background:#D9EDF2;
                    color:#0048A9;
                }
                span.coming-soon-message {
                    color:#004F9D;
                    font-size:14px;
                    @media (max-width:480px) {
                        width:100%;
                        display:block;
                        margin-top:8px;
                    }
                }
            }
            .discontinued {
                div.tag {
                    background:#F9E6E6;
                    color:#C81313;
                    padding:6px 10px 4px;
                    font-weight:800;
                    text-transform:uppercase;
                    display:inline-block;
                }
            }

            .adhesive-info {
                background-color: $jpp-info-blue-light;
                border-color: $jpp-info-blue;
                color: $jpp-info-blue;
                line-height:18px;
                padding:8px;
            }

            .prompts-wrapper {
                display:flex;
                flex-direction:column;

                .prompt {
                    padding:8px 0;
                    position:relative;
                    display:flex;
                    font-size:14px;
                    font-weight:600;
                    span {
                        font-size:14px;
                        font-weight:600;
                    }
                    @media (max-width:1400px) {
                        font-size:13px;
                        span {
                            font-size:13px;
                            font-weight:600;
                        }
                    }
                    @media (max-width:991px) {
                        font-size:14px;
                        span {
                            font-size:14px;
                            font-weight:600;
                        }
                    }
                    @media (max-width:480px) {
                        font-size:13px;
                        span {
                            font-size:13px;
                            font-weight:600;
                        }
                    }
                    @media (max-width:1400px) {
                        padding-left:36px;
                    }
                    svg {
                        margin-right:6px;
                        min-width:26px;
                        @media (max-width:1400px) {
                            top:50%;
                            left:0;
                            transform:translateY(-50%);
                            position:absolute;
                        }
                    }

                    &.non-returnable {
                        svg {
                            margin-right:6px;
                        }
                    }
                    &.hazmat {
                        svg {
                            margin-left:2px;
                        }
                    }
                }
                .hazmat {
                    color:#DE7F00;
                    span {
                        color:#DE7F00;
                    }
                    svg {
                        path, rect {
                            stroke:#DE7F00;
                        }
                    }
                }

                .truck-only, .non-returnable, .inner-pack, .made-to-order, .info-alert {
                    color:#004F9D;
                    line-height:18px;
                    span {
                        color:#004F9D;
                        line-height:18px;
                    }
                    svg {
                        path, rect {
                            stroke:#004F9D;
                        }
                    }
                }

                > div  {

                    .tag {
                        height:30px;
                        font-size:16px;
                        line-height:30px;
                        padding:0 14px;
                        text-align:center;
                        display:inline-block;
                        font-weight:800;
                        text-transform:uppercase;
                        margin:12px 12px 0 0;
                        user-select:none;
                    }
                    &:nth-of-type(1) {
                        .tag {
                            margin-top:0;
                        }
                    }
                }

                > div {
                    &.active {
                        padding:16px 0;
                    }

                    span {
                        font-size:13px;
                    }
                }
            }

            .product-content {
                margin:24px 0;
                @media (max-width:480px) {
                    margin-bottom:16px;
                }
                .content-tabs {
                    display:flex;
                    border-bottom:1px solid #dfdfdf;
                    transition:0.18s ease;
                    @media (max-width:1300px) {
                        display:none;
                    }
                    a {
                        background:#F5F5F5;
                        color:#434343;
                        font-size:13.5px;
                        text-align:center;
                        height:40px;
                        line-height:39.5px;
                        padding:0 18px;
                        border:1px solid #dfdfdf;
                        transition:0.18s ease;
                        transform:translateY(1px);
                        font-weight:800;
                        cursor:pointer;
                        margin-left:-1px;
                        user-select:none;
                        white-space:nowrap;
                        @media (max-width:1400px) {
                            font-size:12px;
                            padding:0 12px;
                        }

                        &.active {
                            background:#fff;
                            color:#0088ce;
                            border-bottom:1px solid #fff;
                            cursor:default;
                        }

                        &:not(.active):hover {
                            filter:brightness(1.02);
                            color:#0088ce;
                        }
                        &:nth-of-type(1) {
                            margin-left:0;
                        }
                    }
                }

                .content-accordions {
                    @media (min-width:1301px) {
                        display:none;
                    }
                    > div {
                        transition:height 0.68s;
                        background:#fff;

                        &:not(.active) {
                            height:48px;

                            > div {
                                opacity:0;
                                pointer-events:none;
                            }
                        }
                        &.overview {
                            &:not(.active) {
                                height:270px;
                            }
                        }
                        > a {
                            display:block;
                            width:100%;
                            border-bottom:1px solid #dfdfdf;
                            background:#fff;
                            height:48px;
                            line-height:48px;
                            font-size:16px;
                            font-weight:800;
                            color:#000;
                            transition:0.18s ease;
                            position:relative;

                            &:hover {
                                color:#0088ce;
                                cursor:pointer;
                                background:#f9f9f9;
                                svg {
                                    path {
                                        stroke:#0088ce;
                                    }
                                }
                            }
                            svg {
                                position:absolute;
                                top:50%;
                                transform:translateY(-50%);
                                right:16px;
                                //margin-top:-9px;
                                width:15px;
                                height:10px;
                                transition:0.28s ease;
                                transform-origin:center center;
                                margin-top:0;
                                path {
                                    transition:0.18s ease;
                                }
                            }
                        }
                        &.active {
                            > a {
                                color:#004f9d;
                                svg {
                                    transform-origin:center center;
                                    transform: rotate(180deg) translateY(-50%);
                                    margin-top:-9px;
                                    path {
                                        stroke:#004f9d;
                                    }
                                }
                            }
                        }
                        > div {
                            padding:20px 0;
                            background:#fff;
                            transition:opacity 0.4s ease;
                        }
                        &.reviews {
                            > div > div > div > div {
                                br {
                                    display:none;
                                }
                            }
                        }
                    }

                }
                .tabs-arrow {
                    position:absolute;
                    width:24px;
                    height:24px;
                    background:#0088ce;
                    background-image:url('https://jpmagento-public.s3.amazonaws.com/pdp-content/icons/arrow-right-white.png');
                    background-size:9px;
                    border-radius:100%;
                    box-shadow:0px 1px 2px rgba(2,2,2,0.12);
                    pointer-events:none;
                    opacity:0;
                    transition:0.18s ease;
                    &.active {
                        opacity:1;
                        pointer-events:auto;
                    }
                    &:hover {
                        filter:brightness(1.1);
                    }
                    &:after {
                        position:absolute;
                        content:'';
                        right:-12px;
                        width:32px;
                        height:38px;
                        top:50%;
                        transform:translateY(-50%);
                        background:linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    }
                }
                .content-pages {
                    padding:18px 0;
                    @media (max-width:1300px) {
                        display:none;
                    }
                    > div {
                        opacity:0;
                        position:absolute;
                        &.active {
                            opacity:1;
                            position:relative;
                        }
                        &:not(.active) {
                            pointer-events:none;
                        }
                    }
                }

                .overview {
                    .overview-body {
                        overflow-y:hidden;
                        position:relative;
                        transition:height 0.4s ease;

                        .attribute-icons {
                            img {
                                width:28px;
                                height:28px;
                                margin-bottom:16px;
                                margin-right:8px;
                            }
                        }
                        &.expanded {
                            max-height:none;
                        }
                        .inner {
                            position:relative;
                            overflow:visible;
                        }
                        .description {
                            font-size:16px;
                            color:#000;
                            font-weight:500;
                            @media (max-width:480px) {
                                font-size:14px;
                                line-height:1.34;
                            }
                            div[data-appearance='contained'], div[data-content-type='row'][data-appearance='contained'] {
                                max-width:100%!important;
                                font-size:16px;
                                > div {
                                    padding:0!important;
                                    font-weight:500;
                                }
                                @media (max-width:480px) {
                                    font-size:14px;
                                    line-height:1.34;
                                }
                                span {
                                    font-size:inherit;
                                }
                            }
                        }
                    }
                }

                .details, .availability {
                    .table-wrapper {
                        margin-bottom:0;
                    }
                    table {
                        width:100%;
                    }
                    tbody {
                        width:100%;
                        tr {
                            background:#ededed;
                            > th {
                                width:230px;
                                font-weight:700;
                            }
                            td {
                                font-weight:500;
                            }
                            th, td {
                                border:4px solid #fff;
                                padding:12px 12px 10px !important;
                                line-height:1.24;
                                @media (max-width:640px) {
                                    border:2px solid #fff;
                                    border-left:0px solid transparent;
                                    border-right:0px solid transparent;
                                }
                            }
                            &:nth-of-type(2n) {
                                background:#f9f9f9;
                            }
                        }
                    }
                }

                .reviews {
                    .review-pager {
                        height:40px!important;
                        > div {
                            font-weight:600;
                        }
                    }
                    .review-item {
                        .rating-summary {
                            .rating-result {
                                span {
                                    &:before {
                                        color:#f58220;
                                    }
                                }
                            }
                        }
                        .review-title {
                            font-size:16px;
                            color:#0088ce;
                            font-weight:700;
                            margin-bottom:16px;
                            .review-author {
                                display:block;
                                .review-details-label, .review-details-value  {
                                    font-size:14px;
                                    font-weight:500;
                                    color:#444;
                                }
                            }
                        }
                        .review-ratings {
                            width:100%;
                            max-width:100%;
                            display:block;
                            .rating-label span {
                                font-size:14px;
                            }
                            .rating-summary .rating-result {
                                margin-left:-20px;
                                margin-top:-3px;
                            }
                        }
                        .review-content {
                            margin-left:0!important;
                            margin-bottom:8px;
                            font-size:14px;
                            line-height:1.4;
                            white-space: pre-line;
                        }
                        .review-details {
                            margin-left:0!important;
                        }
                        .review-date {
                            span {
                                font-size:13px;
                                font-weight:500;
                                letter-spacing:0.25px;
                                color:#555;
                            }
                        }
                    }
                    .review-form {
                        .review-field-rating {
                            .control {
                                height:68px;
                                margin-top:8px;
                            }
                        }
                        .review-vote > label {
                            top:0;
                        }
                        .review-vote input {
                            transform:translateY(28px);
                        }
                        button.submit {
                            height:50px;
                        }
                    }
                }
            }

            .related-posts {
                width:100%;
                position:relative;
                background:#fff;
                &:before {
                    position:absolute;
                    bottom:100%;
                    left:0;
                    right:0;
                    height:24px;
                    background:#fff;
                    pointer-events:none;
                    content:'';
                }
                h5 {
                    color:#0048A9;
                    font-size:16px;
                    margin:0;
                    margin-bottom:12px;
                }
                .posts-wrapper {
                    display:flex;
                    width:100%;
                    position:relative;
                    opacity:0;
                    transition:0.24s ease;
                    @media (max-width:480px) {
                        min-width:calc(100% + 32px);
                        transform:translateX(-16px);
                    }
                    &.no-flickity {
                        opacity:1;
                    }
                    &.flickity-enabled {
                        display:block;
                        opacity:1;
                    }
                    .flickity-viewport {
                        width:100%;
                        z-index:1;
                        position:relative;
                        overflow:hidden;

                        .post {
                            &:last-of-type:not(:first-of-type) {
                                margin-left:48px;
                                transform:translateX(-48px);
                            }
                        }
                        @media (max-width:480px) {
                            transform:translateX(16px);
                        }
                        .flickity-slider {
                            width:100%;
                        }
                    }
                    .flickity-button {
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        border-radius:100%;
                        background:#0088ce;
                        background-image:url('https://jpmagento-public.s3.amazonaws.com/pdp-content/icons/arrow-right-white.png');
                        background-size:16px;
                        background-position:center center;
                        background-repeat:no-repeat;
                        border:2px solid #fff;
                        z-index:3;
                        opacity:1;
                        transition:0.18s ease;
                        box-shadow:0px 0px 6px rgba(2,2,2,0.4);
                        width:34px;
                        height:34px;
                        > svg {
                            display:none;
                        }
                        &:hover {
                            filter:brightness(1.1);
                        }
                        &:disabled {
                            opacity:0;
                            pointer-events:none;
                        }
                        @media (max-width:768px) {
                            transform:translateX(-2px) translateY(-50%) scale(0.9);
                        }
                        &.previous {
                            left:0;
                            transform:rotate(180deg) translateY(-50%);
                            transform-origin:top;
                        }
                        &.next {
                            right:0;
                        }
                    }
                }
                .post {
                    width:calc(33.333% - 12px);
                    border-radius:4px;
                    border:1px solid #dfdfdf;
                    position:relative;
                    min-height:200px;
                    margin-right:12px;
                    overflow:hidden;
                    transition:0.18s ease;
                    @media (min-width:1660px) {
                        min-height:218px;
                    }
                    @media (max-width:768px) {
                        width:202px;
                        min-width:202px;
                        min-height:208px;
                    }
                    @media (max-width:480px) {
                        width:186px;
                        min-width:186px;
                    }

                    div.image {
                        width:100%;
                        position:relative;
                        aspect-ratio:16/9;
                        padding-bottom:56.25%;
                        overflow:hidden;
                    }
                    img {
                        transition:0.18s ease;
                        min-height:100%;
                        position:absolute;
                        left:50%;
                        top:50%;
                        transform:translateX(-50%) translateY(-50%);
                        object-fit:cover;
                    }
                    &:hover {
                        box-shadow:0px 0px 12px rgba(2,2,2,0.12);
                        border:1px solid rgba(0,136,206,0.56);
                        img {
                            filter:brightness(1.05);
                        }
                        span {
                            color:#0088ce;
                        }
                    }
                    a {
                        height:100%;
                        display:block;

                        span {
                            font-size:14px;
                            line-height:18px;
                            margin:8px;
                            color:#000;
                            font-weight:600;
                            display:block;
                            transition:0.18s ease;
                            @media (max-width:768px) {
                                font-size:14px;
                            }
                        }
                    }
                    img {
                        width:100%;
                        background:#222;
                        display:block;
                    }

                }
            }
        }

        /* Column 3 */
        div.sidebar-area {
            width:308px;
            position:relative;
            z-index:25;
            @media (max-width:1400px) {
                width:296px;
            }
            @media (max-width:1280px) {
                width:268px;
            }
            @media (max-width:991px) {
                width:100%;
                display:flex;
                flex-direction:column;
            }

            .primary-block {
                padding:24px;
                border-radius:4px;
                background:#F7F7F7;
                border:1px solid #DFDFDF;
                margin-bottom:10px;
                order:1;
                @media (max-width:1400px) {
                    padding:18px;
                }
                @media (max-width:991px) {
                    order:1;
                }
                @media (max-width:480px) {
                    border:0px solid transparent;
                    padding:0;
                    background:transparent;
                    //margin-bottom:24px;
                    margin-bottom:12px;
                }
                .pricing {
                    position:relative;
                    z-index:1;
                    span.price {
                        font-size:28px;
                        color:#0048A9;
                        font-weight:800;
                        margin-right:5px;
                        z-index:1;
                        position:relative;
                    }
                    span.uom {
                        font-size:16px;
                        font-weight:400;
                        color:#000;
                        z-index:1;
                        position:relative;
                    }
                    &.loading {
                        &:before {
                            position:absolute;
                            content:'';
                            background:url("../images/loader-blue.gif") left center no-repeat #F7F7F7;
                            background-size:26px;
                            top:-1px;
                            left:-8px;
                            right:0;
                            bottom:0;
                            z-index:2;
                            @media (max-width:480px) {
                                background:url("../images/loader-blue.gif") left center no-repeat #FFF;
                                left:-2px;
                            }
                        }
                    }
                }

                #financing-block {
                    transform:translateX(-5px);

                    div[role="tabpanel"] {
                        padding:4px;
                        div[role="tablist"] {
                            position:relative;
                        }
                        p {
                            font-size:13px;
                            line-height:16px;
                            margin:0;
                            width:238px;
                            a {
                                display:block;
                            }
                        }
                        .alert-info-close {
                            display:none;
                        }
                    }
                }

                .savings {
                    span {
                        font-size:15px;
                    }
                    span.list-price {
                        color:#9D9D9D;
                        margin-right:2px;
                        white-space:nowrap;
                        @media (max-width:1300px) and (min-width:992px) {
                            display:block;
                            width:100%;
                        }
                        span {
                            text-decoration:line-through;
                        }
                    }
                    span.you-save {
                        color:#0048A9;
                        white-space:nowrap;
                        span {
                            margin-left:2px;
                        }
                        @media (max-width:1300px) and (min-width:992px) {
                            display:block;
                            width:100%;
                        }
                    }
                }

                .promo, .preorder {
                    span {
                        color:#61BB72;
                        font-size:15px;
                        font-weight:600;
                        line-height:17px;
                        margin:4px 0;
                        display:block;
                        @media (max-width:1400px) {
                            font-size:14px;
                        }
                    }
                }
                .preorder {
                    span {
                        color:#F5771F;
                    }
                }
                .clearance, .stop-buy-seasonal {
                    margin-top:8px;
                    display:inline-block;
                    span {
                        display:block;
                        border-radius:4px;
                        font-size:12px;
                        text-transform:uppercase;
                        color:#fff;
                        background:linear-gradient(to right, #F5771F 0%, #F55A1F 100%);
                        display:inline-block;
                        padding:0 10px;
                        user-select:none;
                    }
                }

                .availability {
                    margin-top:12px;
                    span {
                        font-size:14px;
                        color:#000000;
                        @media (max-width:1400px) {
                            font-size:14px;
                        }
                        span {
                            color:#0048A9;
                            font-size:inherit;
                        }
                    }
                }

                .buttons {
                    .notify {

                        input {
                            font-size:13.5px;
                            margin-bottom:8px;
                            background:#fff;

                            &:not(.active) {
                                display:none;
                            }
                            &::placeholder {
                                opacity:1;
                                color:#777;
                            }
                            &.active {
                                + a.notify-stock {
                                    border:1px solid #0088ce;
                                    background:#0088ce;
                                    color:#fff;
                                    svg {
                                        path {
                                            stroke:#fff!important;
                                            transition:0.18s ease;
                                        }
                                    }
                                    &:hover {
                                        background:#0088ce;
                                        color:#fff;
                                        border:1px solid #0088ce;
                                    }
                                }
                            }
                        }
                        a.notify-stock {
                            width:100%;
                            border:1px solid #e5e5e5;
                            background:#fff;
                            color:#3B3B3B;
                            text-align:center;
                            font-weight:800;
                            line-height:38px;
                            height:40px;
                            display:block;
                            font-size:14px;
                            margin-top:2px;
                            overflow:hidden;
                            position:relative;

                            > svg {
                                position:relative;
                                z-index:2;
                            }
                            svg {
                                transform:translateY(5px);
                                margin-right:5px;
                                position:relative;
                                z-index:2;
                                path {
                                    transition:0.24s ease;
                                }
                            }
                            &:hover {
                                background:#0088ce;
                                color:#fff;
                                border:1px solid #0088ce;
                                svg {
                                    path {
                                        stroke:#fff;
                                    }
                                }
                            }
                            &:before {
                                background:#61BB72;
                                position:absolute;
                                content:'';
                                top:-1.5px;
                                left:calc(-100% - 3px);
                                bottom:-1.5px;
                                width:calc(100% + 3px);
                                transition:0.62s cubic-bezier(0.65, 0.05, 0.36, 1);
                                z-index:3;
                            }

                            .notified-message {
                                position:absolute;
                                top:0;
                                left:0;
                                right:0;
                                bottom:0;
                                pointer-events:none;
                                opacity:0;
                                transition:0.4s ease;
                                transition-delay:0s;
                                text-align:center;
                                color:#fff;
                                font-size:14px;
                                font-weight:800;
                                z-index:4;
                            }
                            &.notify-sent {
                                border:1px solid #61BB72!important;
                                &:hover {
                                    border:1px solid #61BB72!important;
                                }
                                &:before {
                                    left:-1.5px;
                                }

                                .notified-message {
                                    opacity:1;
                                    transition:0.4s ease;
                                    transition-delay:0.6s;
                                }
                            }
                        }
                        + .add-to-cart-wrapper {
                            margin-top:8px;
                        }
                    }

                    .add-to-cart-wrapper {
                        display:flex;
                        input {
                            width:76px;
                            border-color:#dfdfdf;
                            background:#fff;
                            font-weight:800;
                            margin-right:0;
                        }
                        .quantity-wrapper {
                            position:relative;
                            margin-right:8px;

                            input {
                                height:42px;
                                width:86px;
                                @media (max-width:1280px) {
                                    width:74px;
                                }
                            }
                            .qty-up, .qty-down {
                                width:20px;
                                max-width:20px;
                                display:block;
                                height:21px;
                                background:#dfdfdf;
                                position:absolute;
                                top:0;
                                right:0;
                                transition:0.18s ease;
                                border:1px solid #dfdfdf;
                                border-radius:0px;
                                padding: 0!important;

                                &:hover {
                                    filter:brightness(1.02);
                                    border:1px solid rgb(194, 194, 194);
                                }
                                svg {
                                    position:absolute;
                                    top:calc(50% + 1px);
                                    left:50%;
                                    transform:translateX(-50%) translateY(-50%);
                                }
                            }
                            .qty-down {
                                top:auto;
                                bottom:0.5px;
                            }
                        }
                        .add-to-cart-button {
                            font-weight:800;
                            position:relative;
                            flex:1;
                            padding:12px!important;
                            height:42px;

                            @media (max-width:991px) {
                                width:308px;
                                max-width:308px;
                            }
                            @media (max-width:480px) {
                                width:164px;
                                max-width:100%;
                            }

                            &:before {
                                background:#0088ce;
                                position:absolute;
                                content:'ADDED!';
                                top:0;
                                left:0;
                                right:0;
                                bottom:0;
                                width:100%;
                                transition:0.62s cubic-bezier(0.65, 0.05, 0.36, 1);
                                z-index:0;
                                pointer-events:none;
                                opacity:0;
                                line-height:40px;
                            }

                            &:hover {
                                filter:brightness(1.1);
                            }

                            &.added {
                                &:before {
                                    transition:0.62s cubic-bezier(0.65, 0.05, 0.36, 1);
                                    opacity:1;
                                }
                            }
                        }
                    }
                }
            }

            .wishlist-favorites-sample {
                display:flex;
                flex-wrap:wrap;
                margin-bottom:12px;
                //min-width:calc(100% + 8px);
                order:2;
                @media (max-width:991px) {
                    justify-content:center;
                    flex-wrap:nowrap;
                }
                @media (max-width:480px) {
                    min-width:100%;
                    flex-wrap:wrap;
                }

                > div, > a {
                    flex:1;
                    @media (max-width:768px) {
                        flex:1;
                    }
                }

                > div {
                    width:50%;
                    @media (max-width:991px) {
                        width:calc(33.333% - 8px);
                    }
                }
                a.secondary-button {
                    width:50%;
                    border-radius:4px;
                    border:1px solid #dfdfdf;
                    display:flex;
                    justify-content:center;
                    height:34px;
                    margin-right:8px;
                    background:#fff;
                    user-select:none;
                    position:relative;
                    @media (max-width:991px) {
                        width:calc(33.333% - 8px);

                        &:nth-of-type(2) {
                            margin-right:8px;
                        }
                    }
                    @media (max-width:480px) {
                        &:nth-of-type(1) {
                            margin-bottom:8px;
                        }
                    }

                    svg {
                        width:17px;
                        margin-right:8px;
                        align-self:center;
                        @media (max-width:1280px), (min-width:992px) {
                            width:16px;
                            margin-right:6px;
                        }
                        @media (max-width:480px) {
                            width:16px;
                            margin-right:6px;
                        }
                        path, rect {
                            stroke:#0088ce;
                        }
                    }
                    span {
                        font-size:11px;
                        color:#0088ce;
                        font-weight:800;
                        line-height:33px;
                        @media (max-width:1280px), (min-width:992px) {
                            font-size:10.5px;
                        }
                        @media (max-width:480px) {
                            font-size:11px;
                        }
                    }

                    &:hover {
                        background:#0088ce;
                        border:1px solid #0088CE;
                        svg {
                            path, rect {
                                stroke:#fff;
                            }
                        }
                        span {
                            color:#fff;
                        }
                    }
                    &.sample-button {
                        width:100%;
                        min-width:100%;
                        margin:0;
                        margin-right:8px;
                        margin-top:8px;

                        @media (max-width:991px) {
                            width:calc(33.333%);
                            min-width:33.333%;
                            margin-top:0;
                            margin-left:8px;
                            margin-right:0;
                        }
                        @media (max-width:480px) {
                            width:100%;
                            min-width:100%;
                            margin-left:0;
                            margin-right:0;
                        }
                        svg {
                            width:19px;
                            @media (max-width:480px) {
                                width:18px;
                            }
                        }
                        span {
                            @media (max-width:480px) {
                                line-height:32px;
                                transform:translateY(1px);
                                text-align:center;
                            }
                        }
                    }
                }
                .add-to-list-wrapper {
                    position:relative;
                    > a {
                        width:100%;
                        svg, span {
                            pointer-events:none;
                        }
                    }
                    .user-lists {
                        position:absolute;
                        right:0;
                        bottom:calc(100% + 8px);
                        width:200px;
                        background:#fff;
                        border-radius:4px;
                        box-shadow:0px 0px 8px rgba(2,2,2,0.2);
                        pointer-events:none;
                        display:flex;
                        flex-direction:column;
                        transform:translateY(4px);
                        transition:0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
                        opacity:0;

                        a {
                            transition:0.18s ease;
                            border-bottom:1px solid #dfdfdf;
                            font-size:13px;
                            height:32px;
                            line-height:32px;
                            padding:0 12px;
                            color:#000;
                            width:100%;
                            display:block;
                            cursor:pointer;
                            user-select:none;
                            font-weight:600;
                            &:hover {
                                background:#f9f9f9;
                                color:#0088ce;
                            }
                            &:last-of-type {
                                border-bottom:1px solid transparent;
                            }
                            &.create-new {
                                font-size:12px;
                                text-transform:uppercase;
                                color:#0088ce;
                            }
                        }

                    }

                    &.active {

                        > a {
                            background:#0088ce;
                            border:1px solid #0088CE;
                            svg {
                                path, rect {
                                    stroke:#fff;
                                }
                            }
                            span {
                                color:#fff;
                            }
                        }
                        .user-lists {
                            opacity:1;
                            pointer-events:auto;
                            transform:translateY(0);
                        }
                    }
                }
            }

            .price-breaks {
                @media (max-width:991px) {
                    order:4;
                }
                span {
                    text-align:center;
                }
                div.not-logged-in {
                    opacity:0.84;
                    margin:16px 0 20px;
                    span {
                        text-align:center;
                        margin:0 auto;
                        width:260px;
                        display:block;
                        line-height:20px;
                        font-size:15px;
                        span {
                            font-size:inherit;
                        }
                        @media (max-width:1400px) {
                            font-size:14px;
                        }
                    }
                }

                div.logged-in {
                    margin:12px 0 16px;
                    h5 {
                        font-size:16px;
                        font-weight:700;
                        text-align:center;
                        color:#000;
                        margin:0 0 10px;
                    }
                    > span {
                        color:#000;
                        font-weight:500;
                        display:block;
                        margin-bottom:4px;
                        line-height:16px;
                        font-size:14px;
                        span {
                            font-size:inherit;
                        }
                        @media (max-width:1400px) {
                            font-size:13px;
                        }
                    }
                }
            }

            .secondary {
                @media (max-width:991px) {
                    order:3;
                }
                .alternate-item {
                    border:2px solid #FCD9C0;
                    border-radius:4px;
                    padding:16px;
                    position:relative;
                    background:#fff;
                    @media (max-width:991px) {
                        width:308px;
                        max-width:100%;
                        margin:0 auto;
                    }
                    @media (max-width:480px) {
                        width:100%;
                    }
                    &:before {
                        content:'';
                        border-top-left-radius:4px;
                        border-top-right-radius:4px;
                        height:5px;
                        position:absolute;
                        top:-2px;
                        left:-2px;
                        right:-2px;
                        background:#F5781F;
                    }

                    h5 {
                        font-size:16px;
                        color:#F5781F;
                        margin-top:2px;
                        margin-bottom:8px;
                    }
                    span {
                        color:#000;
                        font-size:14px;
                        line-height:16px;
                        display:block;
                        margin-bottom:12px;
                        @media (max-width:1400px) {
                            font-size:13px;
                        }
                    }

                    div.product-image {
                        width:100%;
                        height:142px;
                        position:relative;
                        display:flex;
                        flex-direction:column;
                        justify-content:center;

                        &:before {
                            position:absolute;
                            content:'';
                            pointer-events:none;
                            top:0;
                            left:0;
                            right:0;
                            bottom:0;
                            background:#000;
                            opacity:0.05;
                        }

                        img {
                            max-height:calc(100% - 20px);
                            display:block;
                            margin:0 auto;
                        }
                    }

                    .product-title {
                        font-size:16px;
                        margin:12px 0;
                        color:#000;
                    }

                    a.view-product {
                        width:100%;
                        height:40px;
                        background:#F5781F;
                        line-height:41px;
                        color:#fff;
                        text-align:center;
                        display:block;
                        font-weight:800;
                        font-size:14px;
                        &:hover {
                            filter:brightness(1.1);
                        }
                    }

                }
            }
        }


    }

}

/* Crosssell/Upsell */

section.cross-sell {
    margin-top:64px;

    .related, .upsell {
        margin-bottom:0!important;
    }
    h4 {
        text-transform:none;
        padding-top:0;
        margin:0;
    }

    .block-title {
        border-bottom:0px solid transparent;
    }
    > div {
        min-height:246px;
        &.browse-similar {
            position:relative;
            min-height:492px;
            padding-top:40px;
            @media (max-width:480px) {
                padding-top:24px;
            }
            &:after {
                pointer-events:none;
                width:100vw;
                position:absolute;
                content:'';
                top:0;
                left:50%;
                transform:translateX(-50%);
                bottom:0;
                z-index:-1;
                background:#f9f9f9;
            }
        }
    }

    &.new {
        position:relative;
        margin-top:64px!important;
        margin-bottom:120px!important;
        z-index:1;
        @media (max-width:991px) {
            margin-top:54px!important;
            margin-bottom:54px!important;
        }
        @media (max-width:768px) {
            margin-top:40px!important;
            margin-bottom:48px!important;
        }
        @media (max-width:480px) {
            margin-top:32px!important;
        }
        .items-list {
            width:100%;
            max-width:100%;
            margin-top:18px;
            margin-bottom:48px;
            display:flex;
            position:relative;
            opacity:0;
            transition:0.18s ease;
            @media (max-width:480px) {
                min-width:calc(100% + 32px);
                margin-top:12px;
                padding-bottom:6px;
                margin-bottom:12px;
                transform:translateX(-16px);
                &.no-flickity {
                    transform:translateX(0);
                }
            }
            &:not(.no-flickity) {
                &:before {
                    position:absolute;
                    content:'';
                    top:0;
                    bottom:0;
                    right:0;
                    width:12px;
                    background:#fff;
                    z-index:2;
                    opacity:0;
                    @media (max-width:480px) {
                        opacity:0;
                    }
                }
            }
            &.no-flickity, &.flickity-enabled {
                opacity:1;
            }
            &.flickity-enabled {
                overflow:hidden;
            }
            .flickity-button {
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                border-radius:100%;
                background:#0088ce;
                background-image:url('https://jpmagento-public.s3.amazonaws.com/pdp-content/icons/arrow-right-white.png');
                background-size:16px;
                background-position:center center;
                background-repeat:no-repeat;
                border:2px solid #fff;
                z-index:3;
                opacity:1;
                transition:0.18s ease;
                box-shadow:0px 0px 6px rgba(2,2,2,0.4);
                width:34px;
                height:34px;
                > svg {
                    display:none;
                }
                @media (max-width:768px) {
                    transform:translateX(-2px) translateY(-50%) scale(0.9);
                }
                &:hover {
                    filter:brightness(1.1);
                }
                &:disabled {
                    opacity:0;
                    pointer-events:none;
                }
                &.previous {
                    left:0;
                    transform:rotate(180deg) translateY(-50%);
                    transform-origin:top;
                }
                &.next {
                    right:4px;
                }
            }
            .flickity-viewport {
                width:100%;
                z-index:1;
                @media (max-width:480px) {
                    transform:translateX(16px);
                }

                .flickity-slider {
                    height:100%;
                }
            }
            .product {
                width:202px;
                min-width:202px;
                min-height:208px;
                border:1px solid #dfdfdf;
                background:#fff;
                border-radius:4px;
                transition:0.18s ease;
                position:relative;
                margin-right:12px;
                @media (min-width:1440px) {
                    width:238px;
                    min-width:238px;
                    min-height:266px;
                }
                @media (max-width:1368px) {
                    margin-right:12px;
                }
                @media (max-width:991px) {
                    &:last-of-type {
                        margin-left:48px;
                        transform:translateX(-48px);
                    }
                }
                @media (max-width:480px) {
                    width:186px;
                    min-width:186px;
                }
                &:hover {
                    filter:brightness(1.05);
                    box-shadow:0px 0px 6px rgba(2,2,2,0.12);
                    border:1px solid rgba(0,136,206,0.56);
                    .image:before {
                        opacity:0.08;
                    }
                    .info {
                        span.product-title {
                            color:#0088ce;
                        }
                    }
                }
                a {
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    display:flex;
                    flex-direction:column;
                    text-decoration:none!important;

                    div.image {
                        width:100%;
                        height:120px;
                        position:relative;
                        @media (min-width:1440px) {
                            height:141.6px;
                        }
                        &:before {
                            position:absolute;
                            content:'';
                            background:#000;
                            opacity:0.05;
                            pointer-events:none;
                            top:0;
                            left:0;
                            right:0;
                            bottom:0;
                            z-index:5;
                            transition:0.18s ease;
                        }
                        img {
                            max-height:76%;
                            max-width:56%;
                            position:absolute;
                            top:50%;
                            left:50%;
                            right:auto;
                            bottom:auto;
                            transform:translateX(-50%) translateY(-50%);
                            z-index:4;
                        }
                    }

                    .info {
                        padding:12px;
                        flex:1;
                        padding-bottom:30px;
                        span {
                            display:block;
                            width:100%;
                            transition:0.18s ease;
                        }
                        .product-title {
                            font-size:14px;
                            color:#000;
                            font-weight:500;
                            line-height:1.2;
                            letter-spacing:-0.15px;
                            @media (min-width:1440px) {
                                font-size:15px;
                            }
                        }
                        .product-price {
                            color:#0048A9;
                            font-size:16px;
                            font-weight:800;
                            position:absolute;
                            bottom:8px;
                            left:12px;
                            @media (min-width:1440px) {
                                font-size:18px;
                            }
                        }
                    }
                }
            }

        }

        .recommended-products {
            @media (max-width:480px) {
                margin-bottom:24px;
            }
        }

    }



}
