.wishlist-index-index {
    .page-title-wrapper {
        .page-title {
            @include unset-hidden-props;
        }
    }

    .wishlist-toolbar {
        @include customer-container;
        padding:12px 24px!important;
        margin:0;
        display:none;
        @media (max-width:$bp-tablet) {
            padding:12px 16px!important;
        }
        select {
            height:30px;
            line-height:30px;
            padding:0 8px;
        }
        br {
            display: none;
        }
    }
    #sns_main {

        @media (max-width:$bp-laptop) {
            width:100%;
        }
        > div:last-child {
            display:block;
        }
    }

    .sidebar {
        margin-right:10px;
    }
    .products-grid.wishlist { // sass-lint:disable-line force-element-nesting

        .product-item {
            &-name,
            .price-box {
                @media (max-width: $bp-tablet - 1) {
                    margin-left: 0;
                }
            }
        }

        .product-item-actions {
            .action {
                > span {
                    color: $jpp-blue;
                    font-size: 14px;
                }
            }
        }
    }


    .form-wishlist-items {
        margin-bottom: $account-spacer;

        .qty {
            display: none;
        }

        .products-list {
            @include account-product-list-style;

            .product-item-name {
                .product-item-link {
                    display:inline-block;
                }
            }
            .product-item-info {
                display:grid!important;
                grid-template-rows:auto auto;
                grid-template-columns:101px 1fr 156px;
                @media (max-width:$bp-laptop) {
                    grid-template-rows:auto auto auto auto;
                    grid-template-columns:1fr;
                }
                .product-reviews-summary {
                    display:none;
                }
            }
            .product-item-photo {
                width:85px;
                height:85px;
                display:inline-block;
                margin-right:16px;
                grid-row:1/3;
                grid-column:1/2;
                @media (max-width:$bp-laptop) {
                    grid-row:1/2;
                    grid-column:1/2;
                }
            }
            .product-item-name {
                grid-row:1/2;
                grid-column:2/3;
                @media (max-width:$bp-laptop) {
                    grid-row:2/3;
                    grid-column:1/2;
                    margin-top:12px!important;
                }
            }
            .price-box {
                grid-row:2/3;
                grid-column:2/3;
                margin:0!important;
                p {
                    margin:0;
                }
                @media (max-width:$bp-laptop) {
                    grid-row:3/4;
                    grid-column:1/2;
                }
            }
            .product-item-inner {
                grid-row:1/3;
                grid-column:3/4;
                @media (max-width:$bp-laptop) {
                    grid-row:4/5;
                    grid-column:1/2;
                    margin-left:0;
                    margin-right:auto;
                    width:100%;

                    .product-item-actions {
                        display:flex;
                        width:100%;
                        a, button {
                            margin-right:12px!important;
                            &:last-child {
                                margin-right:0!important;
                            }
                        }
                    }
                }
                @media (max-width:$bp-mobile-l) {
                    .product-item-actions {
                        flex-direction:column;
                        a, button {
                            margin-right:0!important;
                        }
                    }
                }

                fieldset:last-child {
                    margin-bottom:12px;
                }
            }

            .product-reviews-summary {
                display: block;
                margin-bottom: 0;
                margin-top: 0;

                .rating-summary,
                .reviews-actions {
                    display: block;
                    left: 0;
                }
            }
        }


        .actions-toolbar {
            @include flex-container;
            justify-content: space-between;
            @media (max-width:$bp-tablet) {
                margin-top:12px!important;
            }
            @media (max-width: $bp-laptop - 1) {
                flex-wrap: wrap;
            }

            > .primary,
            > .secondary {
                @include flex-item;
                flex-basis: auto;
                @media (max-width: $bp-laptop - 1) {
                    flex-basis: 100%;
                }

                .action {
                    height:36px;
                    line-height:34px;
                    background:#fff;
                    span {
                        font-weight:600;
                        font-size:12px;
                        line-height:34px;
                        display:block;
                    }
                    @media (max-width: $bp-laptop - 1) {
                        margin-bottom: 10px;
                    }
                }
            }

            > .secondary {
                margin-left: 0;
                order: 1;
                text-align: left;
                @media (max-width: $bp-laptop - 1) {
                    order: 2;

                    .action {
                        text-align: center;
                    }
                }
            }

            > .primary {
                order: 3;
                text-align: right;
                span {
                    color:#0088ce;
                }
                & button:hover {
                    background:#0088ce;
                    span {
                        color:#fff;
                    }
                }
                @media (max-width: $bp-laptop - 1) {
                    order: 1;
                }
            }
        }
    }
}
