// forms
._keyfocus,
input:not([disabled]), // sass-lint:disable-line force-pseudo-nesting
textarea:not([disabled]), // sass-lint:disable-line force-pseudo-nesting
select:not([disabled]) { // sass-lint:disable-line force-pseudo-nesting
    &:focus {
        box-shadow: none;
    }
}
::selection {
    background:$jpp-blue;
    color:#fff;
}
select {
    &:not([data-role='limiter']) {
        @include jpp-select;

        .checkout-index-index & {
            font-weight: 600;
            z-index: 2;
            position: relative;
        }
    }
    &[aria-invalid='true'] {
        border-color: $jpp-error-red;
    }
}

input {
    &[type='checkbox'],
    &[type='radio'] {
        appearance: none;
        width: 20px;
        height: 19px;
        vertical-align: top;
        border: 0;
        margin-top: 0;
        margin-bottom: 0;
        &:hover {
            cursor: pointer;
        }
    }
    &[type='checkbox']::before, &[type='radio']::before {
        background-color: #f3f3f3;
        content: '✔';
        align-items: center;
        justify-content: center;
        display: flex;
        height: 19px;
        color: #f3f3f3;
        width: 20px;
        transition: 0.18s ease;
        box-shadow: inset 0 0 10px #e7e7e7;
        padding-top: 1px;
    }
    &[type='radio']::before{
        content: '';
    }
    &[type='checkbox']:checked::before, &[type='radio']:checked::before{
        background: #0088ce;
        color: #fff;
        box-shadow: inset 0 0 10px transparent;
        content: "✔";
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.18s ease;
    }
    &[type='radio']:checked::before{
        content: '';
    }

    &[type='radio'] {
        &::before {
            border-radius: 50%;
        }

        &:checked {
            &::before {
                border-radius: 50%;
            }
        }
    }

    &[type='text'],
    &[type='password'],
    &[type='url'],
    &[type='tel'],
    &[type='search'],
    &[type='number'],
    &[type='datetime'],
    &[type='email'] {
        @include jpp-input;

        .checkout-index-index & {
            font-weight: 600;
        }
    }
}

textarea {
    @include jpp-textarea;

    .checkout-index-index & {
        font-weight: 600;
    }
}

.fieldset {
    .legend > br,
    > br {
        display: none;
    }

    &::after {
        color: $jpp-error-red !important; // sass-lint:disable-line no-important
    }

    .field,
    > .fields > .field {
        &:not(.choice) {
            @include jpp-field-element;

            > label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                @include jpp-label;

                .checkout-index-index & {
                    font-weight: 600;
                }
            }

            > .control {
                width: 100%;
            }

            legend.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                display: none;
            }
        }

        &.password {
            .control {
                .input-text {
                    z-index: auto;
                }
            }
        }

        &._required {
            > .label {
                &::after {
                    color: $jpp-error-red;
                }
            }
        }

        &.gender {
            display: none;
        }

        #password-error { // sass-lint:disable-line no-ids
            margin-bottom: 5px;
            position: static;
        }

        #password-strength-meter-container { // sass-lint:disable-line no-ids
            bottom: -32px;
            position: absolute;
        }

        .mage-error {
            &[generated] {
                bottom: -25px;
                color: $jpp-error-red;
                font-size: 14px;
                left: 15px;
                position: absolute;
                @media (max-width: $bp-tablet) {
                    position: inherit;
                }
            }
        }
    }
}

.hs-form {
    .hs-error-msgs {
        .hs-error-msg {
            font-family:'proxima-nova';
        }
    }
}
@media (min-width: 768px) {
    form.form.contact { // sass-lint:disable-line force-element-nesting no-qualifying-elements
        .fieldset {
            .field {
                @include jpp-floating-label;
                margin-bottom: 25px;
            }
        }
    }
}
