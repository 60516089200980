// sass-lint:disable class-name-format no-important
.page-layout-resources_layout {
    .main {
        .category {
            background-color: $white;
            padding: $spacer $spacer 60px;

            @media (min-width: $bp-desktop-s) {
                box-shadow: 0 0 3px $jpp-grey;
                margin-left: 10px !important;
                padding: $spacer #{$spacer * 1.5} 60px;
            }

            &.boxed {
                margin-left: 0;
                margin-right: 0;
            }

            .category-overview {
                color: $jpp-grey-3;
                font-size: 14px;
            }

            .category-list {
                margin-bottom: $spacer * 2;

                * {
                    @include unset-underline;
                }

                .category-item {
                    display: block;
                    margin-bottom: $spacer;
                }

                h4 {
                    color: $jpp-blue;
                    font-size: 15px;
                    text-transform: none;
                }
            }
        }
    }
}
