// sass-lint:disable no-important
.cms-sawgrass {
    .main {
        a {
            @include unset-underline;
        }

        img {
            width: auto !important;
        }

        .productdesc {
            margin-bottom: 40px;
        }

        .button-white {
            p {
                color: $white;
                line-height: auto;
                margin-bottom: 0;
            }
        }

        .button-orange {
            * {
                color: $white;
            }
        }

        .tabs {
            list-style: none;
        }

        .tab-content {
            .tab-pane {
                display: none;

                &.active {
                    display: block;
                }
            }
        }

        .paneltext {
            @include clearfix;
        }
    }
}
