.cms-locations {
    .location {
        .heading {
            margin-bottom: $spacer / 2;

            a {
                @include unset-underline;
                font-size: 20px;
                font-weight:bold;
                text-transform: uppercase;
            }
        }

        .hours {
            margin-bottom: $spacer / 2;
        }

        .label {
            text-transform: uppercase;
        }

        .location-alert {
            font-size: 13px;
        }

        address {
            display: block;
            margin: #{$spacer / 2} 0;

            * {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .street,
        .street-2,
        .phone {
            display: block;
        }
    }
}

.locations-location-view {
    .phone-hours-container {
        border-bottom: 1px solid $jpp-grey;
        margin-bottom: $spacer / 2;
        padding-bottom: $spacer / 4;
    }

    .hours,
    .phone {
        display: inline-block;

        .label {
            padding-right: 4px;
        }
    }

    .phone {
        * {
            @include font-bold;
        }

        &::before {
            content: '|';
            padding: 0 4px;

        }
    }

    address {
        margin: #{$spacer / 2} 0;

        * {
            font-size: 14px;
        }
    }

    .location-actions {
        margin-top: $spacer;
    }

    .call-us-container {
        margin-bottom: $spacer;

        @media (min-width: $bp-tablet) {
            float: left;
        }
    }

    .directions-container {
        @include clearfix;

        @media (min-width: $bp-tablet) {
            float: left;
            padding-left: $spacer * 2;
        }

        .button-wrapper {
            display: inline-block;
            padding-right: 10px;
            vertical-align: top;

            @media (min-width: $bp-tablet) {
                margin-top: 0;
            }
        }

        .origin-wrapper {
            display: inline-block;
            vertical-align: top;

            input {
                height: 40px;
                max-width: 195px;

                @media (min-width: $bp-mobile-l) {
                    max-width: 250px;
                }
            }
        }
    }

    #origin_address::placeholder { /* Chrome/Opera/Safari */
        color: #999999;
    }

    .location-alert {
        font-size: 13px;
    }
}

.locations-location-view, .cms-locations {
    .gray-block {
        background-color: #E1DFDD;
        padding: 20px;

        h2 {
            font-size: 20px;
            font-weight: 900;
            border-bottom: 1px solid #F37021;
            text-transform: uppercase;
            padding-bottom: 10px;
        }

        .legal-consent-container .hs-richtext {
            margin-top: -20px;
        }

        .hs-input {
            padding:0px;
        }
    }

    .gray-static-block {
        background-color: #E1DFDD;
        padding: 20px;
        font-size: 13px;
        margin-bottom: 10px;

        h4 {
            color: #f37021;
        }

        p {
            font-size:13px;
        }
    }

    .hs-form {
        position: relative;
        .hs_second_option_install_date {
            z-index: 2 !important;
        }
        .hs_preferred_install_date {
            z-index: 3 !important;
        }
        .hs-datepicker {
            z-index: 150 !important;
        }
        .fn-date-picker {
            z-index: 150 !important;
        }
        .hs-dateinput {
            input {
                &:hover {
                    cursor: pointer !important;
                }
            }
        }
        input.hs-input {
            transition: 0.2s ease-in-out;
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            font-family: 'Proxima Nova', sans-serif !important;
            color: #444;
            padding-left: 10px;
            font-size: 116%;
            font-size: 116%;
            box-shadow: 0px 0px 0px rgba(0,0,0,0);
        }
        select {
            border: 2px solid transparent !important;
            option[data-default] {
                opacity: 0 !important;
                color: #fff;
            }
            &:-webkit-autofill {
                -webkit-text-fill-color: #cdeeff !important;
                background-color: #cdeeff !important;
                transition: background-color 1s ease-in-out 1s;
                &:hover {
                    -webkit-text-fill-color: #cdeeff !important;
                    background-color: #cdeeff !important;
                    transition: background-color 1s ease-in-out 1s;
                }
            }
            &:-webkit-aftofill {
                &:focus {
                    -webkit-text-fill-color: #cdeeff !important;
                    background-color: #cdeeff !important;
                    transition: background-color 1s ease-in-out 1s;
                }
            }
            &::-ms-expand {
                width: 10px;
                border: 0px solid #fff;
                background-color: transparent;
            }
        }
        .hs-form-field {
            position: relative;
            transition: 0.2s ease-in-out;
            transition: width 0s !important;
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            -webkit-transition: width 0s !important;
            transition: 0.2s;
            input {
                width: 100% !important;
            }
            .hs-fieldtype-text {
                position: absolute;
                top: 0;
                max-height: 100px;
                min-height: 40px;
            }
            .hs-fieldtype-select {
                position: absolute;
                top: 0;
                max-height: 100px;
                min-height: 40px;
            }
            .input {
                position: relative;
            }
        }
        fieldset.form-columns-3 {
            .hs-form-field {
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
                width: 33.33% !important;
            }
        }
        label {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            margin-top: 10px;
            font-weight: 500;
        }
        span.label {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            margin-top: 10px;
            font-weight: 500;
        }
        .form-columns-3 {
            >.hs-zip {
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            >.hs_telephone_extension {
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            position: relative;
            margin-bottom: 6px;
            .hs-form-field {
                .hs-input {
                    transition: width 0s !important;
                    -webkit-transition: width 0s !important;
                    max-width: 200px;
                }
            }
            li.hs-form-radio {
                margin-bottom: 0px;
                height: 34px;
            }
            &:nth-child(1) {
                margin-right: 16px !important;
            }
            &:nth-child(2) {
                margin-right: 16px !important;
            }
            &:nth-child(3) {
                margin-right: 0px !important;
            }
        }
        input[name="zip"] {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            width: 106px;
        }
        input[name="telephone_extension"] {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            width: 106px;
        }
        fieldset.form-columns-2 {
            .hs-form-field {
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
        }
        .form-columns-2 {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            position: relative;
            margin-bottom: 6px;
            >.hs-form-field {
                >.hs-field-desc {
                    max-width: 210px;
                }
            }
            li.hs-form-radio {
                margin-bottom: 0px;
                height: 34px;
            }
            //input[type="text"] {
            //    min-width: 213px;
            //}
            //input[type="tel"] {
            //    min-width: 213px;
            //}
            //input[type="email"] {
            //    min-width: 213px;
            //}
        }
        .hs-input {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            background-color: #ededed;
            border: 1.5px solid transparent;
            -webkit-transition: background-color 0.25s, border-color 0.25s;
            transition: background-color 0.25s, border-color 0.25s;
            z-index: 1 !important;
            color: #4a4947;
            margin-top: 0px;
            box-shadow: 0px 0px 0px rgba(0,0,0,0);
            &:focus {
                .input--filled {
                    label {
                        span {
                            color: #027BBC !important;
                            transform: translateY(6px) !important;
                            transition: 0.2s ease-in-out;
                        }
                    }
                }
                background-color: transparent !important;
                border: 1.5px solid #027BBC !important;
            }
            .hs-form-field {
                label {
                    span {
                        z-index: 99999 !important;
                    }
                }
            }
            &:hover {
                background-color: #f3f3f3;
            }
            &::-webkit-input-placeholder {
                color: transparent;
                color: transparent;
            }
            &::-moz-placeholder {
                color: transparent;
                color: transparent;
            }
            &::-ms-input-placeholder {
                color: transparent;
            }
            &:-ms-input-placeholder {
                color: transparent;
                color: transparent;
            }
            &:-moz-placeholder {
                color: transparent;
            }
        }
        .hs-fieldtype-select {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            margin-top: 0px;
            label {
                &:nth-child(1) {
                    transform: translateY(36px);
                    color: #b3b3b3;
                    margin-left: 10px !important;
                    transition: 0.2s ease-in-out !important;
                    z-index: 99999 !important;
                    position: relative;
                    transition: color 0.1s ease-in-out !important;
                    transition: transform 0.1s ease-in-out !important;
                    font-weight: 400;
                }
                margin-left: 10px;
                transition: transform 1s !important;
            }
            .input {
                .hs-input {
                    &:focus {
                        box-shadow: 0px 2px 14px 2px rgba(10,10,10,0.07);
                        border: 1px solid #ededed;
                    }
                }
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    right: 20px;
                    bottom: 2px;
                    background: #ededed !important;
                    background-size: cover;
                    height: 34px;
                    z-index: 5000 !important;
                    pointer-events: none;
                    transition: 0.25s;
                    opacity: 1;
                    transition: opacity 0.35s;
                }
            }
            legend.hs-field-desc {
                margin-bottom: 0px;
                display: inline-block;
                float: right;
                text-align: right;
                margin-right: 10px;
                padding-top: 14px;
                max-width: 80%;
                line-height: 18px;
                position: absolute;
                right: 0;
                top: 2px;
            }
        }
        textarea.hs-input {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            font-family: 'Proxima Nova', sans-serif !important;
            color: #444;
            padding-left: 10px;
            font-size: 116%;
            font-size: 116%;
            box-shadow: 0px 0px 0px rgba(0,0,0,0);
        }
        select.hs-input {
            transition: width 0s !important;
            -webkit-transition: width 0s !important;
            font-family: 'Proxima Nova', sans-serif !important;
            color: #444;
            padding-left: 10px;
            padding-left: 6px;
            position: relative;
            font-size: 116%;
            height: 40px;
            border: 2px solid transparent;
            box-shadow: inset 0 0 0 5px transparent !important;
            width: 100% !important;
            max-height: 38px !important;
        }
        .hs-fieldtype-text {
            label {
                &:nth-child(1) {
                    transform: translateY(36px);
                    color: #b3b3b3;
                    margin-left: 10px !important;
                    transition: 0.2s ease-in-out !important;
                    z-index: 99999 !important;
                    position: relative;
                    transition: color 0.1s ease-in-out !important;
                    transition: transform 0.1s ease-in-out !important;
                    font-weight: 400;
                }
            }
            .input {
                .hs-input {
                    &:focus {
                        box-shadow: 0px 2px 14px 2px rgba(10,10,10,0.07);
                        border: 1px solid #ededed;
                    }
                }
            }
            &:focus {
                >label {
                    >span {
                        margin-left: 0px !important;
                        color: #000;
                        transition: color 0.1s ease-in-out !important;
                        transition: transform 0.1s ease-in-out !important;
                        opacity: 1;
                    }
                }
            }
            legend.hs-field-desc {
                margin-bottom: 0px;
                display: inline-block;
                float: right;
                text-align: right;
                margin-right: 10px;
                padding-top: 14px;
                max-width: 80%;
                line-height: 18px;
                position: absolute;
                right: 0;
                top: 2px;
            }
            margin-top: 0px;
        }
        .hs-fieldtype-textarea {
            label {
                &:nth-child(1) {
                    transform: translateY(36px);
                    color: #b3b3b3;
                    margin-left: 10px !important;
                    transition: 0.2s ease-in-out !important;
                    z-index: 99999 !important;
                    position: relative;
                    transition: color 0.1s ease-in-out !important;
                    transition: transform 0.1s ease-in-out !important;
                    font-weight: 400;
                }
            }
            .input {
                .hs-input {
                    &:focus {
                        box-shadow: 0px 2px 14px 2px rgba(10,10,10,0.07);
                        border: 1px solid #ededed;
                    }
                }
            }
            .hs-error-msgs {
                position: absolute;
                margin-bottom: 20px;
            }
            .hs-field-desc {
                max-width: 93%;
                margin-right: 0;
                text-align: left;
                position: absolute;
                top: 120px;
            }
            margin-top: 0px;
        }
        .hs-dependent-field {
            >.hs-fieldtype-text {
                &:first-child {
                    label {
                        &:nth-child(1) {
                            transform: translateY(36px);
                            color: #b3b3b3;
                        }
                    }
                }
                label {
                    &:nth-child(1) {
                        transform: translateY(6px);
                        color: #000;
                    }
                }
            }
            >.hs-fieldtype-textarea {
                &:first-child {
                    label {
                        &:nth-child(1) {
                            transform: translateY(36px);
                            color: #b3b3b3;
                        }
                    }
                }
                label {
                    &:nth-child(1) {
                        transform: translateY(6px);
                        color: #000;
                    }
                }
            }
            >.hs-fieldtype-select {
                &:first-child {
                    label {
                        &:nth-child(1) {
                            transform: translateY(36px);
                            color: #b3b3b3;
                        }
                    }
                }
                label {
                    &:nth-child(1) {
                        transform: translateY(6px);
                        color: #000;
                    }
                }
            }
        }
        .hs-fieldtype-number {
            label {
                margin-left: 10px;
            }
            .input {
                .hs-input {
                    &:focus {
                        box-shadow: 0px 2px 14px 2px rgba(10,10,10,0.07);
                        border: 1px solid #ededed;
                    }
                }
            }
            margin-top: 0px;
        }
        textarea.hs-input.invalid {
            &:focus {
                border-color: #c87872 !important;
            }
            &:active {
                border-color: #c87872 !important;
            }
        }
        .input--filled {
            .input {
                .hs-input {
                    border: 1.5px solid #d3ead0;
                }
            }
            >label {
                transition: color 0.1s ease-in-out !important;
                transition: transform 0.1s ease-in-out !important;
                transform: translateY(6px) !important;
                opacity: 1;
                >span {
                    margin-left: 0px !important;
                    color: #000;
                    transition: color 0.1s ease-in-out !important;
                    transition: transform 0.1s ease-in-out !important;
                    opacity: 1;
                }
            }
            &:focus {
                border: 1.5px solid #027BBC;
                color: #027BBC;
            }
            >.hs-form-radio-display {
                color: #000 !important;
            }
            >.input {
                &:before {
                    opacity: 0;
                    transition: 0.25s;
                }
            }
        }
        .input--filled.hs-fieldtype-select {
            label {
                &:nth-child(1) {
                    transition: transform 0.1s ease-in-out !important;
                    transition: color 0.1s ease-in-out !important;
                    transform: translateY(6px) !important;
                    opacity: 1;
                }
            }
        }
        .input {
            z-index: 1 !important;
            position: relative;
            transition: 0.2s;
            textarea {
                min-height: 80px;
            }
        }
        .hs-form-required {
            z-index: 10000 !important;
            transform: scale(1.2) !important;
            color: #027BBC !important;
            padding-left: 2px !important;
        }
        .hs_error_rollup {
            position: absolute;
            bottom: 24px;
            width: 280px;
        }
        .error {
            border-color: #c87872 !important;
            &:focus {
                border-color: #c87872 !important;
            }
        }
        .hs-error-msgs {
            li {
                label {
                    border-color: #c87872 !important;
                    color: #c87872 !important;
                    font-size: 13px !important;
                    padding: 0;
                    margin: 0;
                    line-height: 15px;
                    margin-top: 4px;
                    transform: translateY(0%) !important;
                    transition: 0.2s;
                    animation: fadeIn 0.28s ease-in;
                }
                border-color: #c87872 !important;
                width: 100% !important;
                &:nth-child(2) {
                    margin-top: -8px;
                }
            }
            border-color: #c87872 !important;
            padding-bottom: 0;
            margin-bottom: 0 !important;
            margin-top: 0px;
            transition: 0.2s;
            animation: fadeIn 0.10s ease-in;
        }
        input {
            &:-webkit-autofill {
                -webkit-text-fill-color: #cdeeff !important;
                background-color: #cdeeff !important;
                transition: background-color 1s ease-in-out 1s;
                &:hover {
                    -webkit-text-fill-color: #cdeeff !important;
                    background-color: #cdeeff !important;
                    transition: background-color 1s ease-in-out 1s;
                }
                &:focus {
                    input {
                        &:-webkit-autofill {
                            -webkit-text-fill-color: #cdeeff !important;
                            background-color: #cdeeff !important;
                            transition: background-color 1s ease-in-out 1s;
                        }
                    }
                }
            }
        }
        textarea {
            &:-webkit-autofill {
                -webkit-text-fill-color: #cdeeff !important;
                background-color: #cdeeff !important;
                transition: background-color 1s ease-in-out 1s;
                &:hover {
                    textarea {
                        &:-webkit-autofill {
                            &:focus {
                                -webkit-text-fill-color: #cdeeff !important;
                                background-color: #cdeeff !important;
                                transition: background-color 1s ease-in-out 1s;
                            }
                        }
                    }
                }
            }
            width: 100% !important;
        }
        .uneditable-input {
            width: 100% !important;
        }
        .inputs-list {
            .hs-form-radio {
                width: 100%;
            }
            list-style-type: none;
            padding-left: 0;
            li {
                transition: 0.2s ease-in-out;
            }
        }
        .form-columns-1 {
            position: relative;
            margin-bottom: 6px;
            li.hs-form-radio {
                margin-bottom: 0px;
                height: 34px;
            }
            input[type="radio"] {
                border-color: #fff;
                -webkit-box-shadow: 0 0 3px 0 #fff;
                box-shadow: 0 0 3px 0 #fff;
            }
            .hs-richtext {
                &:first-child {
                    p {
                        margin-bottom: -18px;
                    }
                }
            }
        }
        hs-form-field {
            .hs-fieldtype-number {
                position: absolute;
                top: 0;
                max-height: 100px;
                min-height: 40px;
            }
        }
        .label {
            font-family: 'Proxima Nova', sans-serif;
            font-size: 130% !important;
        }
        label.hs-form-checkbox-display {
            margin-top: 0 !important;
        }
        input.hs-input[type=radio] {
            margin-right: 8px;
            margin-top: -3.5px;
        }
        .hs-form-radio-display {
            span {
                margin-top: 0px;
                color: #000 !important;
            }
            .hs-input {
                height: 20px;
                width: 20px;
            }
            input {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background: #ededed;
                    z-index: 1000;
                    border-radius: 40px;
                    transition: 0.2s;
                    box-shadow: inset 0px 0px 10px 4px rgba(0,0,0,0.07);
                }
                &:checked {
                    &:before {
                        background: #027BBC;
                        border-radius: 40px;
                        transition: 0.2s;
                        padding: 4px;
                    }
                }
            }
        }
        input.hs-input[type=file] {
            height: 60px;
            max-width: 568px;
        }
        h2 {
            text-align: center;
            max-width: 564px;
            padding-right: 0 !important;
            color: #027BBC;
            margin-top: 32px;
            margin-bottom: 24px;
            background: #fff;
            padding-right: 16px;
            font-size: 224%;
        }
        h3 {
            color: #027BBC;
            margin-top: 32px;
            margin-bottom: 24px;
            background: #fff;
            padding-right: 16px;
            font-size: 224%;
        }
        form {
            h2 {
                color: #000;
                text-align: left;
                font-size: 160%;
                margin-bottom: -10px;
            }
            h3 {
                color: #000;
                text-align: left;
                font-size: 160%;
                margin-bottom: -10px;
            }
        }
        span {
            + {
                .hs-input {
                    margin-top: 0px;
                }
            }
        }
        .hs-form-checkbox-display {
            .hs-input {
                height: 20px;
                margin-top: 8px;
                margin-bottom: 8px;
                margin-right: 12px;
                width: auto !important;
                width: 20px !important;
                height: 20px !important;
            }
            width: auto !important;
            span {
                padding-top: 4px !important;
            }
            input {
                color: #fff !important;
                background: #fff !important;
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    width: 20px !important;
                    height: 20px;
                    background: #ededed;
                    z-index: 1000;
                    transition: 0.2s;
                    box-shadow: inset 0px 0px 10px 4px rgba(0,0,0,0.07);
                }
                &:checked {
                    &:before {
                        transition: 0.2s;
                        background: #027BBC;
                        z-index: 1001;
                    }
                }
            }
        }
        .legal-consent-container {
            margin-bottom: 44px;
            padding-right: 24px;
            margin-top: 6px;
            font-size: 85%;
            font-style: italic;
        }
        .hs-button.primary.large {
            height: 36px;
            color: #fff;
            background: #027BBC;
            border: 1px solid #027BBC;
            padding: 0px 32px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            margin-top: 32px;
            transition: 0.18s;
            &:hover {
                background: #fff;
                color: #027BBC;
                transition: 0.18s;
                border: 1px solid #dddddd;
            }
            &:focus {
                background: #fff;
                color: #027BBC;
                transition: 0.18s;
                border: 1px solid #dddddd;
            }
        }
        .legend {
            font-size: 18px;
        }
        .hs-fieldtype-text.field {
            .hs-field-desc {
                color: #222;
                opacity: 0.7;
                font-size: 14px;
                border-bottom: 0px solid #fff;
            }
        }
        .hs-field-desc {
            font-size: 16px;
            max-width: 94%;
            margin-bottom: 8px;
        }
        input[name="message"] {
            min-height: 80px;
        }
        .section {
            display: none;
            input[type="checkbox"] {
                display: none;
            }
        }
        input[type="radio"] {
            span {
                color: #000 !important;
            }
            width: 20px !important;
            background: #fff;
            border: 2px solid #333;
        }
        .hs-fieldtype-file {
            border: 0px;
            padding-left: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            width: 98.2% !important;
            margin-top: 38px;
            margin-bottom: 0px;
            legend {
                max-width: 88% !important;
            }
            .hs-field-desc {
                max-width: 88% !important;
            }
        }
        ul.inputs-list {
            margin-bottom: 8px;
            min-height: 0px;
        }
        li.hs-form-radio {
            span {
                padding-right: 24px;
            }
            display: inline-block;
            float: left;
        }
        .hs-fieldtype-radio {
            margin-top: 0px;
            >label {
                margin: 20px 0 0;
            }
        }
        .hs-fieldtype-checkbox {
            margin-top: 0px;
        }
        label.hs-form-radio-display {
            margin-top: -10px;
        }
        .hs-richtext {
            &:first-of-type {
                + {
                    .hs-fieldtype-radio {
                        margin-top: 30px;
                    }
                }
            }
            p {
                margin-top: 8px;
            }
        }
        hs-fieldtype-select {
            label {
                span {
                    transition: transform 1s !important;
                }
            }
        }
    }
    @media screen and (min-width: 1200px) {
        .hs-form {
            .hs-form {
                max-width: 768px;
                padding-left: 78px;
                padding-right: 78px;
            }
            h2 {
                max-width: 768px !important;
            }
            .form-columns-1 {
                max-width: 100% !important;
            }
            .form-columns-2 {
                max-width: 100% !important;
            }
            .form-columns-3 {
                max-width: 100% !important;
            }
        }
    }
    @media screen and (max-width: 640px) {
        .hs-form {
            fieldset.form-columns-3 {
                .hs-form-field {
                    width: 100% !important;
                    transition: width 0s !important;
                    -webkit-transition: width 0s !important;
                }
            }
            span.hs-form-required {
                right: 14px;
            }
            label.hs-form-radio-display {
                min-width: 176px;
            }
            .hs-form-radio-display {
                span {
                    margin-bottom: -4px;
                }
            }
            .hs-fieldtype-file {
                width: 89.5% !important;
            }
            input[type="radio"] {
                max-width: 20px !important;
            }
            .form-columns-3 {
                .hs-form-field {
                    .hs-input {
                        max-width: 100% !important;
                        width: 100% !important;
                        transition: width 0s !important;
                        -webkit-transition: width 0s !important;
                    }
                }
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
                >.hs-zip {
                    width: 100% !important;
                    transition: width 0s !important;
                    -webkit-transition: width 0s !important;
                }
                >.hs_telephone_extension {
                    width: 100% !important;
                    transition: width 0s !important;
                    -webkit-transition: width 0s !important;
                }
            }
            fieldset.form-columns-2 {
                .hs-form-field {
                    width: 100% !important;
                    transition: width 0s !important;
                    -webkit-transition: width 0s !important;
                }
            }
            .form-columns-2 {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            .hs-form-field {
                width: 100% !important;
                width: 100% !important;
                transition: width 0s !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            .hs-input {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            .hs-fieldtype-select {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            input.hs-input {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            textarea.hs-input {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            select.hs-input {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            input[name="zip"] {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            input[name="telephone_extension"] {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            label {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
            span.label {
                width: 100% !important;
                transition: width 0s !important;
                -webkit-transition: width 0s !important;
            }
        }
    }

    .ct-field-area a {
        display:inline-block;
    }

    .showroombutton {
        height: 36px;
        color: #fff;
        background: #027BBC;
        border: 1px solid #027BBC;
        padding: 0px 32px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        margin-top: 0;
        transition: 0.18s;
        line-height:35px;
        max-width:137px;
    }

    .showroombutton:hover {
        background:#fff;
        border:1px solid #dddddd;
        color:#027BBC;
        transition:0.22s;
    }


    .gray-block .hs-form-field, .hs-form fieldset.form-columns-3 .hs-form-field {
        width:100%!important;
        float:none!important;

    }
    .gray-block .hs-form .form-columns-3 .hs-form-field .hs-input {
        max-width:100%!important;
    }

    .gray-block .legal-consent-container {
        padding-right:0!important;

    }

    .grecaptcha-badge {
        transform: scale(0.84) translateX(-23px);
    }

    .gray-block .hs-form input.hs-input[type=file] {
        font-size:14px!important;
        height:40px;
        transform:scale(1.025) translateX(2px);
    }
    .gray-block .hs-form .hs-error-msgs.inputs-list li {
        width:100%;
    }
    .gray-block .hs-form .hs-error-msgs.inputs-list li label {
        position:absolute;
        left:-10px;
        bottom:-18px;
    }
}

