.vault-cards-listaction {
    .payment-method-row {
        margin-bottom: $account-spacer;
        .actions-toolbar .secondary .action {
            height:32px;
            line-height:29px;
            @include smooth;
            span {
                font-size:12px;
                @include smooth;
            }
            &:hover {
                color: $jpp-blue;
                box-shadow: 0px 1px 4px $box-shadow-light;
                span {
                    color: $jpp-blue;
                }
            }
        }
        .card-icon,
        p {
            float: left;
        }

        p {
            line-height: 2rem;
            margin-left: 12px;
            margin-bottom:0;
        }

        &.expired {
            border-bottom: 4px solid $jpp-error-red;
        }

        .expired-message {
            color: $jpp-error-red;
            display: block;
            margin: $account-spacer 0 (-1 * $account-spacer);
        }
    }
}
