.customer-address-index {
    .column.main { // sass-lint:disable-line force-element-nesting
        .block {
            margin-bottom: $account-spacer;

            @media (max-width: $bp-tablet - 1) {
                border-bottom: 1px solid $jpp-grey;
            }

            &.block-addresses-default {

                @media (max-width:$bp-laptop) {
                    display:flex;
                    flex-direction:column;
                    > .box {
                        width:100%;
                        &:nth-of-type(1) {
                            margin-bottom:16px;
                        }
                    }
                }
                .block-title,
                .block-content {
                    @include customer-container;
                }

                .block-title {
                    margin-bottom: $account-spacer;

                    > strong {
                        @include h5-typography;
                        font-size: 22px;
                    }
                }
                .box {
                    @media (max-width:$bp-tablet) {
                        width:100%;
                        &.box-address-billing {
                            margin-bottom:16px;
                        }
                    }
                }
                .block-content {
                    .box-address-shipping {
                        margin-bottom:0;
                    }
                    @media (min-width:$bp-tablet) {
                        .box {
                            margin-bottom:0;
                            address {
                                margin-bottom:0;
                            }
                        }
                    }

                    .box-title {
                        float: left;
                        margin-bottom: $account-spacer;

                        > span {
                            @include h5-typography;
                        }
                    }

                    .box-actions {
                        //float: right;
                        margin-top: 0;

                        .action {
                            @include unset-underline;
                            display:inline-block;
                            margin-left:10px;
                            width:20px;
                            height:20px;
                            transform:translateY(-2px);
                            > span {
                                @include h5-typography;
                                color: $jpp-dark-blue;
                                display:none;
                            }
                            svg {
                                @include ease;
                                width:20px;
                                height:20px;
                            }
                            &:hover {
                                svg {
                                    stroke:$jpp-orange;
                                }
                            }
                        }
                    }

                    .box-content {
                        clear: both;
                    }
                }
            }

            &.block-addresses-list {
                @include customer-container;

                .block-title {
                    padding-bottom:0;
                    margin-bottom:0!important;
                    > strong {
                        @include h5-typography;
                        font-size: 20px;
                    }
                }
                .block-content {
                    p.empty {
                        margin:12px 0;
                    }
                }
                .additional-addresses {
                    tr {
                        &:not(:first-child) {
                            border-top: 1px solid $jpp-grey;
                        }
                    }

                    .col {
                        padding: 24px 0;
                        @media (min-width: $bp-desktop-s) {
                            &.actions {
                                width: 30%;
                            }
                        }
                    }

                    .action {
                        @include dashboard-button;
                        height: auto;
                        margin-bottom: 12px;
                        margin-right: 0;
                        display:inline-block;
                        &:nth-of-type(1), &:nth-of-type(2) {
                            width:120px;
                            float:left;
                        }
                        &:nth-of-type(2) {
                            margin-left:12px;
                        }
                        &:nth-of-type(3), &:nth-of-type(4) {
                            width:252px;
                        }
                    }
                }
            }

            address {
                font-size: 16px;
                line-height: 24px;
                //margin-bottom: 16px;
                margin-bottom:0;
            }
        }
    }
}

.customer-address-form {
    .main {
        > form {
            .message {
                clear: both;
            }

            &.form-address-edit {
                .customer-container {
                    overflow: auto;

                    legend {
                        @include customer-container-title;
                    }
                }

                .legend {
                    &.legend {
                        margin-bottom: 10px;
                        text-align: left;
                    }
                }

                .fieldset {
                    br {
                        display: none;
                    }
                }

                .nested {
                    .field {
                        width: 100%;
                    }
                }

                .field {
                    @media (min-width: $bp-tablet) {
                        float: right;
                        width: 49%;
                        [generated="true"].mage-error {
                            height: auto;
                        }
                    }

                    &.street {
                        clear: both;
                        width: 100%;
                    }

                    &:nth-child(2n) {
                        @media (min-width: $bp-tablet) {
                            float: left;
                        }
                    }

                    &.field-name-firstname {
                        @media (min-width: $bp-tablet) {
                            float: left;
                        }
                    }

                    &.field-name-lastname {
                        @media (min-width: $bp-tablet) {
                            float: right;
                        }
                    }
                }
                [generated="true"].mage-error {
                    height: 0;
                    overflow: visible;
                }
            }
        }
    }
}
