// sass-lint:disable no-color-literals no-important no-url-domains no-url-protocols
.cms-page-view {
    .main {
        .rowmark-header {
            @include full-width;
            margin-top: -20px;

            .info-section {
                position: absolute;
            }
        }

        .main-categories {
            .category-link {
                h3 {
                    font-size: 20px;
                }
            }
        }

        .product-grid {
            > .product {
                h3 {
                    font-size: 19px;
                }
            }
        }

        .orange-button {
            display: inline-block;
            width: auto;
        }

        .button-wrapper {
            a {
                @include unset-underline;
            }
        }

        a {
            @include unset-underline;

            &.shop-new,
            &.shop-link {
                @include unset-underline;
            }
        }

        div {
            box-sizing: border-box;
        }

        section {
            margin-bottom: $spacer * 4;
        }

        .row {
            margin-left: -15px;
            margin-right: -15px;

            &::before,
            &::after {
                content: '';
                display: table;
            }

            &::after {
                clear: both;
            }

            &.is-flex {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .tile-item {
            a {
                @include unset-underline;
            }
        }

        .slick-slider {
            .slick-prev,
            .slick-next {
                top: 50%;
            }
        }

        .slick-dots {
            margin-top: 0;

            li {
                margin-left: 8px;
                margin-right: 8px;
            }

            button {
                border: 2px solid $jpp-blue;
                height: 18px;
                width: 18px;
            }

            .slick-active {
                button {
                    background-color: $jpp-blue;
                }
            }
        }

        .login-prompt-wrapper {
            height: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            transition: opacity .3s ease;
            width: 0;
            z-index: -50;

            &.active {
                background-color: rgba(0, 0, 0, .5);
                height: 100%;
                opacity: 1;
                pointer-events: all;
                transition: opacity .3s ease;
                width: 100%;
                z-index: 999;
            }

            .login-prompt {
                background-color: $white;
                box-shadow: 0 0 10px $box-shadow;
                left: 50%;
                max-width: 400px;
                padding: $spacer * 2;
                position: relative;
                text-align: center;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 75%;
            }

            svg {
                height: 50px;
                width: 50px;
            }

            p {
                margin: #{$spacer / 2} 0;
            }

            .blue-button {
                @include button('primary');
                display: inline-block;
                margin-top: $spacer / 2;
            }

            .cancel-prompt {
                @include unset-underline;
                position: absolute;
                right: 20px;
                top: 10px;

                span {
                    @include font-bold;
                    color: $jpp-blue;
                    font-size: 28px;
                }
            }
        }
    }

    .column {
        &.off-white {
            background-color: $jpp-off-white;
        }
    }

    .flex-parent {
        display: flex;

        &.wrap {
            flex-wrap: wrap;
        }

        > .half {
            flex-basis: 50%;
        }
    }
}

.cms-dremel {
    .main {
        section {
            padding-left: 5vw;
            padding-right: 5vw;
        }
    }
}

.cms-oki,
.page-layout-cms-fullwidth-exception,
.cms-contest,
.cms-test,
.cms-what-is-sublimation,
.cms-techsupport,
.cms-contactspecialist,
.cms-mutoh,
.cms-makerseries,
.cms-gyford {
    .page-main {
        padding-left: 0;
        padding-right: 0;
    }

    h4,
    p {
        &.center {
            text-align: center;
        }
    }

    h3 {
        &.line {
            &::before {
                background: $jpp-dark-grey;
                content: '';
                height: 1px;
                left: calc(100% + 8px);
                pointer-events: none;
                position: absolute;
                user-select: none;
                width: 42px;
            }
        }
    }

    .large-button {
        @include button('secondary');
        font-size: 18px !important;
        height: 98px;
        letter-spacing: 1.5px;
        line-height: 98px;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        text-align: center;

        &.absolute {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
        }
    }

    .margin-up {
        margin-top: -64px;
    }

    .padbottom {
        padding-bottom: 98px;
    }

    .nopadtop {
        padding-top: 0 !important;
    }

    .flex-row {
        display: flex;

        @media (max-width: 1279px) {
            display: block !important;
        }
    }

    .flex {
        display: flex;

        &.vertical-991 {
            @media (max-width: $bp-laptop) {
                flex-direction: column;
            }
        }

        &.three-col {
            flex-wrap: wrap;

            div {
                &.text {
                    flex-basis: 30%;

                    @media (max-width: $bp-desktop-xl) {
                        flex-basis: calc(50% - 40px);
                    }

                    @media (max-width: $bp-laptop) {
                        flex-basis: 100%;
                        max-width: 100%;
                        text-align: center;
                        width: 600px;
                    }


                }
            }
        }

        &.space-around {
            justify-content: space-around;
        }
    }

    .main {
        .product-list {
            display: flex;
            flex-wrap: wrap;

            .product {
                flex-basis: 50%;
                max-width: 100%;
                min-height: 300px;
                position: relative;

                h3 {
                    text-transform: none;
                    transform: translateX(25%);
                    z-index: 1;
                }

                img {
                    display: inline-block;
                    float: right;
                    width: 300px;
                    z-index: 0;
                }
            }
        }

        .rectangle-tile {
            // background: $white;
            // box-shadow: 0px 12px 80px -6px rgba(0, 0, 0, .1);
            // display: inline-block;
            // margin-right: 12px;
            // position: relative;
            // z-index: 0;
            min-height: 592px;
            padding: 24px;
            width: 390px;

            .title {
                @include font-bold;
                margin-bottom: 16px;
                min-height: 50px;
                text-align: center;
                text-transform: uppercase;

                > p {
                    @include font-bold;
                    bottom: 10px;
                    font-size: 18px !important;
                    left: 16px;
                    line-height: 120% !important;
                    margin: 0;
                    position: absolute;
                    right: 16px;
                    text-align: center;
                    text-transform: uppercase;
                }
            }

            .large-button {
                p {
                    @include font-bold;
                    color: $white;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .selection-area {
            > div {
                flex-basis: 100%;
                min-width: 0;
                opacity: 0;
                order: 2;
                pointer-events: none;
                position: absolute;
                user-select: none;

                &.active {
                    @include ease;
                    opacity: 1;
                    order: 1;
                    pointer-events: auto !important;
                    position: relative;
                    user-select: auto !important;
                }
            }
        }

        .product-column {
            background: $white;
            box-shadow: 0 12px 80px -6px rgba(0, 0, 0, .1);
            display: inline-block;
            margin-right: 12px;
            max-width: 100%;
            min-height: 700px;
            width: 400px;
        }

        .boxed-1600 {
            margin: 0 auto;
            max-width: 100%;
            width: 1600px;
        }

        .comparison-table {
            display: flex;
            justify-content: flex-start;
            margin-left: 12px;
            margin-right: -15px;
            overflow-x: scroll;
            overflow-y: visible;
            padding-bottom: 14px;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 20px;
            transform: translateX(-16px);

            @media (min-width: $bp-tablet) {
                overflow: visible;
            }

            .table-column {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                box-shadow: 0 0 20px rgba(2, 2, 2, .14);
                margin-right: 12px;
                min-width: 240px;
                width: 245px;

                @media (max-width: 1279px) {
                    margin-bottom: 12px;
                    margin-right: 8px;
                    min-width: 194px;
                }

                &.highlighted {
                    .table-title {
                        background: $jpp-orange;

                        h3 {
                            color: $white;
                        }
                    }
                }

                &:nth-of-type(1) {
                    min-width: 460px;
                    width: 460px;

                    @media (max-width: 1279px) {
                        min-width: 330px;
                    }

                    .table-title {
                        h3 {
                            bottom: 10px;
                            color: $white;
                            left: 20px;
                            margin: 0;
                            position: absolute;
                            text-align: left;
                            width: calc(100% - 20px);
                        }
                    }

                    div {
                        &:nth-child(odd) {
                            background: #e5f8fd;

                            &.table-title {
                                background: $jpp-dark-blue;
                            }
                        }

                        &:nth-child(even) {
                            background: #c3dee5;
                        }
                    }
                }

                &:nth-of-type(n+2) {
                    p {
                        @include font-bold;
                        text-align: center;
                    }
                }

                .table-title {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    height: 86px;
                    position: relative;

                    @media (max-width: 1279px) {
                        height: 65px;
                    }

                    h3 {
                        bottom: 10px;
                        color: $jpp-blue;
                        font-size: 20px;
                        left: 0;
                        margin: 0;
                        position: absolute;
                        text-align: center;
                        width: 100%;
                    }
                }

                div {
                    padding: 0 18px;

                    @media (max-width: $bp-laptop) {
                        padding: 0 12px;
                    }

                    &:nth-child(even) {
                        background: #eaedee;
                    }

                    &:nth-child(odd) {
                        background: $white;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 48px;
                    margin: 0;
                }
            }
        }

        .category-selectors {
            display: flex;
            justify-content: center;
            position: relative;

            a {
                color: $jpp-grey-3;
                font-size: 24px;
                opacity: .6;
                position: relative;
                text-transform: uppercase;

                &.active {
                    color: $jpp-blue;
                    opacity: 1;

                    &::before {
                        opacity: 1;
                    }
                }

                &:last-child {
                    margin-right: 0 !important;
                }

                &::before {
                    background: rgba(0, 136, 206, .14);
                    bottom: 0;
                    content: '';
                    height: 20px;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                }
            }
        }

        .big-title {
            h2 {
                font-size: 52px;

                @media (max-width: 1400px) {
                    font-size: 36px;
                }

                * {
                    font-size: 52px;

                    @media (max-width: 1400px) {
                        font-size: 36px;
                    }
                }

                &.large {
                    @media (min-width: $bp-tablet) {
                        font-size: 42px;
                        line-height: 34px;
                    }

                    @media (min-width: $bp-desktop) {
                        font-size: 68px;
                        line-height: 58px;
                        margin-bottom: 24px;
                    }
                }

                span {
                    font-weight: 200;
                }
            }
        }

        .column {
            flex-grow: 1;

            &.vertical {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &.vert-pad {
                padding-bottom: $spacer * 4;
                padding-top: $spacer * 4;

                @media (max-width: 1600px) {
                    padding-bottom: 48px;
                    padding-top: 48px;
                }

                @media (max-width: $bp-laptop) {
                    padding-bottom: 42px;
                    padding-top: 42px;
                }

                @media (max-width: $bp-tablet) {
                    padding-bottom: 36px;
                    padding-top: 36px;
                }
            }

            .inner-wrap {
                margin: auto;
                margin-left: 0;
                max-width: 574px;
                width: 100%;
            }
        }

        .text {
            margin-bottom: 24px;
            margin-left: 20px;
            margin-right: 20px;
            max-width: 100%;
            width: 420px;
        }

        .inner-wrapper {
            margin: auto;
            max-width: 100%;
            width: 1274px;
        }

        .mobile-remove {
            @media (max-width: 600px) {
                display: none !important;
            }
        }

        span {
            &.light {
                font-weight: 300;
            }
        }

        section {
            margin-bottom: 0;

            @media (max-width: 600px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            &.pb-64 {
                padding-bottom: 64px;
            }

            &.pt-80 {
                padding-top: 80px;
            }

            &.main {
                margin: 80px auto;
            }

            &.one {
                .column {
                    &:nth-of-type(1) {
                        padding-left: calc(5.20835vw + 22px);
                    }
                }
            }

            &.full,
            &.full-width {
                @include full-width;
                clear: both;
                padding-left: 0;
                padding-right: 0;

                .text-area {
                    max-width: 100%;
                    padding: 24px;
                }
            }

            &.dark-blue-bg {
                @include full-width;

                * {
                    color: $white;
                }
            }

            &.flex-center {
                flex-direction: column;
            }

            &.vert-pad {
                padding-bottom: $spacer * 4;
                padding-top: $spacer * 4;

                @media (max-width: 1600px) {
                    padding-bottom: 48px;
                    padding-top: 48px;
                }

                @media (max-width: $bp-laptop) {
                    padding-bottom: 42px;
                    padding-top: 42px;
                }

                @media (max-width: $bp-tablet) {
                    padding-bottom: 36px;
                    padding-top: 36px;
                }
            }

            &.content {
                &.vert-pad {
                    &.flex-center {
                        &.boxes {
                            @media (max-width: $bp-tablet) {
                                margin-bottom: 40px;
                            }
                        }
                    }
                }

                &.full {
                    @include full-width;
                }
            }

            .text-area {
                margin: 0 auto;
                max-width: 100%;

                &.wide {
                    width: 670px;

                    p {
                        width: 100%;
                    }
                }

                p {
                    max-width: 100%;
                    width: 480px;
                }
            }

            .title-area {
                display: flex;
                flex-direction: column;
                height: 160px;
                justify-content: center;

                @media (min-width: 600px) {
                    height: 216px;
                }

                > h2 {
                    margin: 0;
                    text-align: center;
                }
            }

            .image-tile {
                margin-left: 8px;
                margin-right: 8px;
                max-width: 100%;
                position: relative;
                width: 250px;

                @media (min-width: 450px) {
                    width: 390px;
                }

                @media (max-width: $bp-laptop) {
                    margin: 0 auto;
                    margin-bottom: 16px;
                }
            }

            .flex-grid {
                display: flex;
                flex-wrap: wrap;
                margin-left: -8px;
                margin-right: -8px;

                .flex-title {
                    &.half {
                        flex-basis: calc(100% - 16px);
                        min-height: 280px;

                        @media (min-width: 1280px) {
                            flex-basis: calc(50% - 16px);
                        }
                    }
                }

                .item {
                    background: $white;
                    border: 1px solid $jpp-off-off-white;
                    position: relative;
                    z-index: 0;

                    &.fourth {
                        flex-basis: calc(50% - 8px) !important;
                        margin: 4px;
                        min-height: 300px;

                        @media (min-width: $bp-laptop) {
                            flex-basis: calc(33.33% - 16px) !important;
                        }

                        @media (min-width: 1280px) {
                            flex-basis: calc(25% - 16px) !important;
                        }

                        &.large {
                            img {
                                width: 200px;
                            }
                        }

                        img {
                            left: 50%;
                            position: absolute;
                            top: 46%;
                            transform: translate(-50%, -50%);
                            width: 130px;
                        }
                    }

                    &:hover {
                        .hover-panel {
                            @include ease;
                            opacity: 1;
                        }

                        h3 {
                            @include ease;
                            color: $white;
                        }
                    }

                    // &::after {
                    //     background: linear-gradient(to top, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
                    //     bottom: 0;
                    //     content: '';
                    //     height: 240px;
                    //     left: 0;
                    //     pointer-events: none;
                    //     position: absolute;
                    //     right: 0;
                    //     user-select: none;
                    //     z-index: 0;
                    // }

                    .hover-panel {
                        background: linear-gradient(to bottom, #0088ce 0%, rgba(0, 136, 206, .64) 100%);
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        padding: 16px;
                        pointer-events: none;
                        position: absolute;
                        right: 0;
                        top: 0;
                        user-select: none;
                        width: 100%;
                        z-index: 2;

                        * {
                            @include unset-underline;
                            color: $white;
                        }

                        .link {
                            &::before {
                                background-color: $white;
                            }
                        }
                    }

                    h3 {
                        bottom: 16px;
                        color: $jpp-off-black;
                        font-size: 18px;
                        left: 12px;
                        margin: 0;
                        position: absolute;
                        right: 12px;
                        text-align: center;
                        text-transform: none !important;
                        z-index: 3;
                    }

                    a {
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        right: 0;
                        text-decoration: none;
                        top: 0;
                    }

                    > a {
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        right: 0;
                        text-decoration: none;
                        top: 0;
                    }
                }
            }

            .flex-wrap {
                display: flex;
                flex-wrap: wrap;

                &.center {
                    justify-content: center;
                }

                .card {
                    border: 1px solid $jpp-off-off-white;
                    margin-bottom: 16px;
                    margin-right: 16px;
                    position: relative;

                    &.slim {
                        height: 392px;
                        width: 275px;

                        @media (max-width: $bp-laptop) {
                            height: 350px;
                        }

                        &.file {
                            @media (max-width: 1279px) {
                                flex-basis: calc(33.33% - 10.66px);
                            }

                            @media (max-width: 768px) {
                                flex-basis: calc(50% - 8px);
                                height: 200px;
                                margin-bottom: 8px;
                                margin-right: 8px;
                            }
                        }
                    }

                    h4 {
                        bottom: 12px;
                        left: 12px;
                        position: absolute;
                        right: 12px;
                        text-align: center;
                        text-transform: none !important;
                    }
                }
            }

            .flex-row {
                div {
                    &.half {
                        display: flex;
                        flex: .5;
                        flex-basis: 45;
                        flex-direction: column;
                        justify-content: center;
                        min-height: 25vw;
                        padding: 24px;
                    }
                }
            }

            div {
                &.box {
                    box-shadow: 0 12px 80px -6px rgba(0, 0, 0, .1);
                    display: inline-block;
                    float: left;
                    margin-bottom: 24px;
                    margin-right: 64px;
                    margin-top: 64px;
                    max-width: 100%;
                    min-height: 420px;
                    position: relative;
                    width: 420px;

                    @media (max-width: 1600px) {
                        float: none;
                        margin-right: 0;
                        margin-top: 24px;
                        width: 100%;
                    }

                    &.fade-top {
                        .header {
                            h3 {
                                font-size: 64px !important;
                                margin: 0;
                                margin-top: 64px;
                                text-align: center;
                            }
                        }
                    }

                    div {
                        &.text {
                            margin-bottom: 24px;
                            margin-left: 20px;
                            margin-right: 20px;
                            max-width: 100%;
                            padding: 24px;
                            width: 420px;

                            @media (max-width: 1600px) {
                                margin: 0 auto;
                                max-width: 100%;
                                padding-bottom: 0;
                                width: 520px;
                            }
                        }
                    }
                }
            }

            &.columns {
                &.content {
                    .box {
                        @media (max-width: $bp-laptop) {
                            flex-direction: column;
                        }
                    }
                }

                div {
                    &.box {
                        @media (max-width: 1600px) {
                            display: flex;
                            margin: 0 auto;
                            margin-bottom: 100px;
                            max-width: 100%;
                            width: 1000px;
                        }

                        > div {
                            &:first-of-type {
                                width: 480px;

                                @media (max-width: $bp-laptop) {
                                    margin: 0 auto;
                                    max-width: 100%;
                                    width: 480px;
                                }
                            }
                        }

                        div {
                            &.text {
                                p {
                                    line-height: 50px;
                                }
                            }
                        }
                    }
                }
            }

            .flex-wrapper {
                display: flex;

                .card {
                    flex: 1;
                    height: 300px;
                    margin-right: 12px;
                    padding: 24px;
                    position: relative;

                    @media (max-width: 1600px) {
                        height: 256px;
                    }

                    &.blue {
                        background-color: $jpp-blue;

                        * {
                            @include unset-underline;
                            color: $white;
                        }

                        .line {
                            &::before {
                                background-color: $white;
                            }
                        }
                    }

                    &.center {
                        h3 {
                            @include font-bold;
                            bottom: 8px;
                            left: 0;
                            position: absolute;
                            right: 0;
                            text-align: center;
                            text-transform: uppercase;
                        }
                    }

                    h3 {
                        font-size: 24px;
                        margin-bottom: 12px;
                        margin-top: 0;
                        text-transform: none !important;
                    }
                }
            }
        }

        .video {
            position: relative;
            width: 100%;

            &.playing {
                .inner {
                    video {
                        z-index: 1;
                    }
                }
            }

            &.medium {
                margin: auto;
                max-width: 100%;
                width: 1246px;
            }

            .inner {
                background-color: $jpp-off-black;
                cursor: pointer;
                padding-bottom: 56.25%;
                position: relative;
                width: 100%;

                &:hover {
                    .play {
                        background-color: $white;
                        box-shadow: 0 0 40px $jpp-blue;
                    }

                    .tri {
                        border-left-color: $jpp-blue;
                    }
                }

                iframe {
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 0;
                }

                video {
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: -1;
                }
            }

            .play {
                border: 1px solid $white;
                border-radius: 1000%;
                height: 80px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 80px;

                @media (min-width: $bp-laptop) {
                    height: 132px;
                    width: 132px;
                }
            }

            .tri {
                border: 15px solid transparent;
                border-left: 22.5px solid $white;
                display: block;
                height: 20px;
                left: calc(50% + 23px);
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 60px;

                @media (min-width: $bp-laptop) {
                    border: 20px solid transparent;
                    border-left: 30px solid $white;
                    left: calc(50% + 20px);
                }
            }
        }

        .row {
            margin-left: auto;
            margin-right: auto;

            &.vert-pad {
                padding-bottom: $spacer * 4;
                padding-top: $spacer * 4;
            }

            &.is-flex {
                display: flex;
                flex-wrap: wrap;

                & > div {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    max-width: 100%;
                }

                &.vertical-1200 {
                    @media (max-width: 1200px) {
                        flex-direction: column;
                    }

                    &.reverse {
                        flex-direction: column-reverse;
                    }
                }
            }
        }

        .jpp-header {
            display: flex;
            min-height: 28vw;
            position: relative;

            &.grid {
                display: grid;
                grid-template-columns: 100% !important;
                grid-template-rows: auto auto;

                @media (min-width: $bp-desktop-s) {
                    grid-template-columns: 43.125% 56.875% !important;
                    grid-template-rows: auto;
                }

                @media (min-width: $bp-tablet) {
                    padding: #{$spacer * 2} 0;
                }

                .column {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &:first-of-type {
                        grid-column: 1 / 2;
                        grid-row: 2 / 3;

                        @media (min-width: $bp-tablet) {
                            padding-left: 5vw;
                            padding-right: 5vw;
                        }

                        @media (min-width: $bp-desktop-s) {
                            grid-column: 1 / 2;
                            grid-row: 1 / 2;
                        }

                        .inner-wrap {
                            @media (max-width: $bp-tablet) {
                                padding: 24px 0;
                            }
                        }
                    }

                    &:last-of-type {
                        grid-column: 1 / 2;
                        grid-row: 1 / 2;

                        @media (min-width: $bp-desktop-s) {
                            grid-column: 2 / 3;
                            grid-row: 1 / 2;
                        }
                    }

                    img,
                    .inner-wrap {
                        @media (max-width: $bp-desktop) {
                            margin: 0 auto;
                            max-width: 800px;
                            width: 100%;
                        }
                    }
                }
            }

            &.banner {
                @include full-width;
                margin-top: -20px;

                &.thin {
                    min-height: 224px;
                }

                h1 {
                    left: 0;
                    margin: 0;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                }

                .background {
                    background-position: center center;
                    background-size: cover;
                    bottom: 0;
                    left: 0;
                    opacity: .16;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: -1;
                }
            }

            h1 {

                .smaller {
                    font-size: 18px;
                    text-transform: uppercase;

                    @media (min-width: 600px) {
                        font-size: 22px;
                    }
                }
            }
        }

        .page-nav {
            @include full-width;
            background: $jpp-off-off-white;
            font-size: 16px;
            font-weight: 700;
            height: 100px;
            letter-spacing: 2px;
            line-height: 100px;
            margin-bottom: 64px;
            overflow-x: overlay;
            overflow-y: hidden;
            position: relative;
            text-transform: uppercase;
            white-space: nowrap;
            z-index: 1;

            @media (min-width: $bp-desktop-xl) {
                height: 128px;
                line-height: 128px;
            }

            .main-wrapper {
                height: 100%;
            }

            .wrapper {
                align-items: center;
                display: flex;
                height: 100%;
                padding: 0 20px;

                @media (min-width: $bp-desktop-s) {
                    justify-content: space-around;
                }
            }

            .nav-link {
                @include font-bold;
                color: $jpp-dark-grey;
                flex: 1;
                float: left;
                font-size: 14px;
                height: 100%;
                line-height: 100px;
                padding: 0 24px;
                text-align: center;

                @media (min-width: $bp-desktop-xl) {
                    line-height: 128px;
                }

                &:hover {
                    @include ease;
                    background: $white;
                    box-shadow: 0 12px 80px -6px rgba(0, 0, 0, .1);
                    color: $jpp-orange;
                }
            }
        }

        .attribute {
            .number {
                * {
                    box-sizing: border-box;
                }
            }
        }
    }

    .link {
        display: inline-block;
        margin-top: 12px;

        &.line {
            @include font-bold;
            color: $jpp-off-black;
            position: relative;
            text-transform: uppercase;

            &:hover {
                &::before {
                    width: 40px;

                    @media (min-width: $bp-tablet) {
                        width: 48px;
                    }
                }
            }

            &::before {
                @include ease;
                background: $jpp-off-black;
                content: '';
                height: 1px;
                left: calc(100% + 8px);
                opacity: 1;
                pointer-events: none;
                position: absolute;
                top: 50%;
                user-select: none;
                width: 22px;

                @media (min-width: $bp-laptop) {
                    width: 30px;
                }
            }
        }
    }
}

.cms-oki,
.cms-dremel,
.cms-contest,
.cms-test,
.cms-what-is-sublimation,
.cms-techsupport,
.cms-contactspecialist,
.cms-mutoh,
.cms-makerseries,
.cms-gyford {
    .main {
        div {
            position: relative;
        }
    }
}
