.blog-page-list {
    .page-title-wrapper {
        margin-bottom:20px;
    }
    .post-list-wrapper {
        margin:0;
    }
    .post-list {
        &.grid-collapse {
                margin-left: -26px;
                margin-right: -26px;
                @media (min-width:$bp-laptop) {
                    margin-left: -20px;
                    margin-right: -20px;
                }
        }

        .post-holder {
            background-color: $white;
            border: 0;
            margin-bottom: $spacer * 2;

            @media (min-width: $bp-laptop) {
                padding-left: $spacer;
                padding-right: $spacer;
            }

            .image {
                margin-bottom:0;
            }
        }

        .post-ftimg-hld {
            img {
                border: 1px solid $jpp-grey;
            }
            a {
                overflow:hidden;
                display:block;
                &:hover {
                    .image {
                        transform:scale(1.02);
                    }
                }
                .image {
                    @include smooth;
                }
            }
            &:hover + .post-header .post-title a {
                color:$jpp-blue;
            }
        }

        .post-title {
            font-size: 24px;
            line-height:1.2;
            margin-top: 4px;
            padding:4px 0;
            max-width:100%;
            @include smooth;

            @media (max-width:$bp-laptop) {
                font-size:24px;
            }
            @media (max-width:$bp-mobile-l) {
                font-size:22px;
            }
            a {
                @include font-bold;
                @include unset-underline;
                color: $jpp-off-black;
                font-size: inherit;
                line-height: inherit;
                text-transform:none!important;
                &:hover {
                    color:$jpp-blue;
                }
            }
        }

        .post-info {
            .item {
                line-height: normal;

                &.post-categories {
                    &::after {
                        display: none;
                    }
                }
            }

            .label,
            a {
                @include font-normal;
                @include unset-underline;
                color: $light-grey;
                font-size: 12px;
                line-height: normal;
                text-transform: uppercase;
            }

            a {
                color: $jpp-blue;
            }
        }

        .post-author {
            display: block!important;

            .posted-by {
                @include font-normal;
                @include unset-underline;
                color: $light-grey;
                font-size: 12px;
                line-height: normal;
            }

            a,
            span {
                color: $light-grey;
                font-size: 12px;
                text-transform: none;
            }
        }
    }
}
