@import
'header/links',
'header/logo',
'header/search',
'header/minicart';


.page-header {
    background-color: $white;
    margin-bottom: 0;
    z-index:104;
    position:relative;

    > .wrapper {
        display: none;
    }

    > .content {
        padding-left:0;
        padding-right:0;
        padding-top:18px;
        margin-bottom:12px;
        @media (min-width:992px) {
            margin: 0 auto;
            padding-top:24px;
            padding-bottom:10px;
            display:flex;
        }
        
        &.search-focused {
            z-index:1000;
        }
    }

    .telephone-link {
        @include grid-width(6);
        padding-right: 12px;
        text-align: right;
        float:right;
        width:128px;
        order:1;
        @media (max-width:$bp-mobile-l) {
            transform: translateY(6px);
        }
        @media (min-width: $bp-mobile-l) and (max-width: $bp-laptop) {
            padding-right: 4px;
            transform: translateY(10px);
        }
        @media (min-width: $bp-laptop) {
            display: none;
            padding-right:0;
            float:left;
        }

        .phone-number {
            @include unset-underline;
            @include font-bold;
            font-size: 14px;
        }
    }

    .nav-toggle {
        @include icon($icon-align-justify, $jpp-blue, 26px);
        margin: 0;
        top: unset;
        left:0;
        bottom:8px;
        z-index:4;

        &::before {
            vertical-align: middle;
        }

        @media (min-width: $bp-tablet) {
            display: block;
        }

        @media (min-width: $bp-laptop) {
            display: none;
        }

        &:hover {
            &::before {
                @include color-transition;
                color: $jpp-orange;
            }
        }

        &::before {
            overflow: visible;
        }
    }
}


body.body-nav-open {
    .page-header {
        z-index:140!important;
    }

    > div:not(.nav-sections) {
        z-index:140!important;
    }
}