.customer-account-createpassword,
.customer-account-forgotpassword,
.customer-account-login {
    background:linear-gradient(to right, #fff 0%, #f7f7f7 50%, #fff 100%);

    > .modals-wrapper {
        display:none;
        pointer-events:none;
    }
    .g-recaptcha {
        transform:translateY(-40px);
    }
    div-breadcrumbs {
        margin-bottom:0;
    }
    main.page-main {
        width:100%;
        max-width:100%!important;
    }
    .page-title-wrapper {
        @include full-width;
        background:linear-gradient(to bottom, #0084C8 0%, #005480 100%);
        box-sizing: border-box;
        padding: 24px 15px 32px;
        @media (max-width:$bp-tablet) {
            padding:32px 15px 44px;
        }
        @media (max-width:$bp-mobile-l) {
            padding:36px 15px 60px;
        }
        @media (min-width: $bp-tablet) {
            padding-bottom: 70px;
            padding-top: 38px;
            text-align: center;
        }

        .page-title {
            text-transform: unset;
            margin-bottom:12px;

            @media (max-width:$bp-tablet) {
                text-align:center;
            }
            @media (max-width:$bp-mobile-l) {
                width:316px;
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
                margin-top:0;
            }
            span {
                @include h2-typography;
                color: $white;
                margin: 0;
                text-transform: unset;
            }
        }

        .page-subtitle {
            @include p-typography;
            color: $white;
            margin-bottom: 0;

            @media (max-width:$bp-tablet) {
                text-align:center;
            }

            @media (max-width:$bp-mobile-l) {
                width:300px;
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
                font-size:14px;
                line-height:1.4;
            }
        }
    }

    .header-area {
        min-height:240px;
        background:linear-gradient(to bottom, #0084C8 0%, #005480 100%);
        display:flex;
        flex-direction:column;
        justify-content:center;

        .content {
            transform:translateY(-14px);
        }
        h1 {
            color:#fff;
            text-transform:none!important;
            text-align:center;
            font-size:40px;
            @media (max-width:bp-tablet) {
                font-size:32px;
            }
            @media (max-width:bp-mobile-l) {
                font-size:24px;
            }
        }
        h2 {
            color:#fff;
            text-transform:none!important;
            text-align:center;
            font-size:19px;
            font-weight:600;
            @media (max-width:bp-tablet) {
                font-size:16px;
            }
            @media (max-width:bp-mobile-l) {
                font-size:14px;
            }
        }
    }
    @keyframes loading {
        0% {
            left:-100%;
        }
        100% {
            left:100%;
        }
    }
    @keyframes fadeUp {
        0% {
            opacity:0;
            top:32px;
        }
        66% {
            opacity:1;
        }
        100% {
            opacity:1;
            top:0;
        }
    }
    #login-register-vue, .login-container {
        display: flex;
        flex-direction:column;
        width:524px;
        max-width:calc(100% - 32px);
        margin:0 auto 64px;
        background:#fff;
        box-shadow:0px 0px 16px rgba(50,50,50,0.05);
        transform:translateY(-28px);
        min-height:520px;
        opacity:0;
        animation:fadeUp 0.5s ease;
        animation-delay:0.28s;
        animation-fill-mode:forwards;
        position:relative;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            opacity:1!important;
            animation:none!important;
        }
        > div {
            transition:all 0.24s ease, height 0s, width 0s, padding 0s;
        }
        &.busy {
            > div {
                filter:saturate(0.5);
                cursor:wait!important;
                button {
                    cursor:wait!important;
                    &:before {
                        animation:loading 1.2s ease infinite;
                    }
                }
            }
        }
        .inner-wrapper {
            padding:46px 96px 96px;
            @media (max-width:$bp-desktop-s) {
                padding:48px;
            }
            @media (max-width:$bp-laptop) {
                padding:32px;
            }
            @media (max-width:$bp-tablet) {
                padding:24px;
            }
        }

        ul.tabs {
            display:flex;
            list-style-type:none;
            padding:0;
            margin:0;
            //background:radial-gradient(#f4f4f4 0%, #ececec 140%);
            background:linear-gradient(to top, rgba(0,0,0,0.1) -20%, rgba(0,0,0,0.05) 10%, rgba(0,0,0,0) 100%);
            li {
                flex:1;
                background:transparent;
                height:58px;
                line-height:58px;
                position:relative;
                margin:0;
                transition:all 0.18s, background 0.32s ease;
                &.active {
                    background:#fff;
                    a {
                        color:#0088ce;
                        cursor:default;
                        span {
                            cursor:default;
                        }
                    }
                }
                &:after, &:before {
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    content:'';
                    opacity:0;
                    background:linear-gradient(160deg, rgba(0,0,0,0), rgba(0,0,0,0.04));
                    transition:all 0.18s, opacity 0.32s ease;
                    pointer-events:none;
                }
                &:before {
                    z-index:-1;
                }
                &:not(.active):after {
                    opacity:1;
                }
                a {
                    color:#888;
                    text-decoration:none;
                    text-align:center;
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    cursor:pointer;
                    span {
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        text-transform:uppercase;
                        font-weight:800;
                        cursor:pointer;
                        user-select:none;
                        pointer-events:none;
                        width:100%;
                        transition:0.18s ease;
                        @media (max-width:480px) {
                            width:calc(100% - 24px);
                        }
                    }
                    &:hover {
                        span {
                            color:#0088ce;
                            transition:0.18s ease;
                        }
                    }
                }
                &.login, &.register {
                    a span {
                        font-size:14px;
                        line-height:14px;
                    }
                }
                &.login.active {
                    &:before {
                        right:auto;
                        left:100%;
                        background:linear-gradient(to right, rgba(0,0,0,0.1), rgba(0,0,0,0));
                        width:36px;
                        opacity:0.28;
                    }
                }
                &.register.active {
                    &:before {
                        right:100%;
                        left:auto;
                        background:linear-gradient(to left, rgba(0,0,0,0.06), rgba(0,0,0,0));
                        width:16px;
                        opacity:0.28;
                    }
                    + li {
                        &:before {
                            right:auto;
                            left:0;
                            background:linear-gradient(to right, rgba(0,0,0,0.06), rgba(0,0,0,0));
                            width:16px;
                            opacity:0.28;
                        }
                    }
                }
            }
        }

        .field.choice {
            p, label span {
                font-size:13px;
                margin:2px 0;
            }
            input[type="radio"] + label {
                margin-right:12px;
            }
            &.newsletter {
                margin-top:18px;
                display:flex;
                justify-content:center;
                input {
                    margin-top:0;
                    margin-right:10px;
                }
            }
        }
        .form-label {
            font-size:14px;
            color:#000;
            position:relative;
            margin:0 auto;
            display:block;
            width:100%;
            text-align:center;
            font-weight:800;
        }
        .separator {
            margin:24px 0;
            span {
                font-size:14px;
                color:#000;
                position:relative;
                margin:0 auto;
                display:block;
                width:100%;
                text-align:center;
                font-weight:800;
                &:before, &:after {
                    position:absolute;
                    content:'';
                    height:1px;
                    background:#e7e7e7;
                    top:50%;
                    transform:translateY(-50%);
                    width:calc(50% - 20px)
                }
                &:before {
                    left:0;
                }
                &:after {
                    right:0;
                }
            }
        }
        .panel-title {
            text-transform:none!important;
            font-size:20px;
            text-align:center;
        }

        .to-find {
            display:flex;
            justify-content:center;
            margin-bottom:24px;
            span, a {
                font-size:13px;
            }
            a {
                text-decoration:none!important;
                margin-left:12px;
                transform:translateY(2px);
                cursor:pointer;
            }
        }
        .forgot-password, .find-your-account, .back-to-login {
            opacity:0.66;
            transition:0.24s ease;
            font-weight:700;
            text-decoration:none!important;
            display:block;
            width:156px;
            text-align:center;
            margin:16px auto 12px;
            color:#646464;
            font-size:13px;
            &:hover {
                opacity:1;
            }
        }
        button.submit, a.submit {
            width:100%;
            font-size:14px!important;
            margin-top:24px;
            display:block;
            text-align:center;
            transition:0.24s ease;
            overflow:hidden;
            position:relative;
            height:52px!important;
            line-height:52px!important;
            padding:0 16px!important;
            user-select:none;
            &:before {
                position:absolute;
                content:'';
                top:0;
                bottom:0;
                width:100%;
                left:-100%;
                background:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 55%, rgba(255,255,255,0) 100%);
                transition:0s;
                opacity:0.24;
                pointer-events:none;
            }
            &.finish-account {
                span {
                    @media (max-width:480px) {
                        display:none;
                    }
                }
            }
        }
        .submit-code {
            p {
                line-height:1.2;
            }
            input[type="number"] {
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
        p.response {
            font-size:14px;
            text-align:center;
            margin:24px 0;
            line-height:1.2;
            opacity:0;
            animation:fadein 0.5s ease;
            animation-fill-mode:forwards;
            span {
                font-size:inherit;
                line-height:1.2;
            }
            .success {
                color:$jpp-success-green;
            }
            .fail {
                color:$jpp-error-red;
            }
        }
        input[readonly='readonly'] {
            border:1.5px solid #68bb5b!important;
            padding-top:0;
            opacity:0.7;
        }
        .second-button {
            @keyframes revealGlow {
                0% {
                    box-shadow:0px 0px 4px rgba(0,136,206,0);
                    filter:brightness(1);
                }
                66% {
                    filter:brightness(1.28);
                    box-shadow:0px 0px 24px rgba(0,136,206,0.4);
                }
                100% {
                    box-shadow:0px 0px 4px rgba(0,136,206,0);
                    filter:brightness(1);
                }
            }
            animation:revealGlow 1.4s ease-in-out;
            animation-delay:0.32s;
            animation-fill-mode:forwards;
        }
    }

    .login-container {
        padding:52px;
        @media (max-width:$bp-laptop) {
            padding:32px;
        }
        @media (max-width:$bp-mobile-l) {
            padding:16px;
        }

        .password-strength-meter-label {
            font-size:14px;
        }
        .fieldset {
            margin-bottom:0;
            &:after {
                margin-left:0;
                text-align:center;
            }
        }
        div.primary {
            margin:0 auto;
            display:block;
            float:none;
        }
    }

    .modals-wrapper {
        position:fixed;
        top:0;
        left:50%;
        right:0;
        bottom:0;
        width:100vw;
        transform:translateX(-50%);
        form {
            @media (max-width:480px) {
                position:fixed;
                left:50%;
                transform:translateX(-50%);
                width:calc(100vw - 32px);
            }
            .modal-title {
                margin:0;
                @media (max-width:480px) {
                    padding:0;
                }
            }
            header, .modal-content, footer {
                padding:16px;
            }
        }
        .modals-overlay {
            height:144vh;
            position:fixed;
            left:0;
            right:0;
            transform:translateY(-50%);
            @media (max-width:480px) {
                height:200vh;
            }
        }
    }
    .confirm-password-hint{
        a{
            height:20px;
            position: absolute;
            bottom: 9px;
            right:-30px;
            width:20px;
            @media(max-width: 768px){
                right:-22px;
            }
            svg{
                top:3px;
                left:-3px;
                height:13px;
                position:relative;
                float:right;
            }
            //svg:hover, svg path:hover, svg circle:hover{
            //    color: #f58220;
            //    fill: #f58220;
            //}
        }
        a:hover{
            svg path{
                fill: #f58220;
            }
            svg circle{
                stroke: #f58220;
            }
        }
    }
    .password-requirements-info{
        background-color: #cce7f5;
        border-color: #004f9d;
        color: #004f9d;
        line-height: 18px;
        padding: 8px;
        margin-top:25px;
        a{
            font-size: 26px;
            font-weight: 800;
            float: right;
            margin-left: 17px;
            position: relative;
            text-decoration: none !important;
        }
        ul {
            padding-right: 30px;
            margin-bottom: -10px;
            margin-top: -1px;
            padding-inline-start: 20px;
            li {
                font-size: 13px;
                color: rgb(0, 79, 157);
                line-height: 24px;
            }
        }
    }
}


/* Social Login */
.amsl-login-page,
.amsl-cart-page,
.am-sl-registration-page {

    margin: 10px 0 0;

    div.divider {
        display:none;
    }
    span.am-title {
        font-size:14px;
        color:#000;
        position:relative;
        margin:0 auto;
        display:block;
        width:100%;
        text-align:center;
        font-weight:800;
    }
    .am-social-login {
        background: none;
        padding: 0;

        .am-title {
            @include font-bold;
            color: $jpp-off-black;
            display: block;
            font-size: 14px;
            margin: 0 auto 18px;
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            &.-short {
                display: none;
            }
        }

        .am-buttons {
            flex-wrap: nowrap;
            width: 100%;
        }

        .am-button-wrapper {
            margin: 0;
            margin-bottom: 12px;
            width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.-rectangular {
                .am-sl-button {
                    border-radius: 0;
                }
            }
        }

        .am-sl-button {
            background: none;
            border: 1px solid $jpp-grey;
            box-shadow: none;
            color: #353535;
            display: block;
            display: flex;
            height: 40px;
            justify-content: center;
            text-align: center;
            text-decoration: none !important;
            width: unset;

            &:visited {
                color: #353535;
            }

            &:hover {
                background: none;
                color: $jpp-blue;
                box-shadow:0 0 6px 0 rgba(0,0,0,0.1);
            }

            span {
                font-size: 14px;
                line-height: 38px;
            }

            .am-social-icon {
                background-image: none;
                line-height: 40px;

                &::before {
                    vertical-align: unset !important; // sass-lint:disable-line no-important
                }
            }

            &.-facebook {
                .am-social-icon {
                    @include icon($icon-facebook, $white, 15px);
                    background: $jpp-facebook;
                    border-radius: 100%;
                    height: 17px;
                    //left: 50%;
                    position: relative;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 17px;

                    &::before {
                        position: relative;
                        top: -10px;
                        font-size:14px;
                    }
                }
            }

            &.-google {
                .am-social-icon {
                    background-image: url('../images/icon/google.png');
                    background-size: contain;
                    background-repeat:no-repeat;
                    height: 17px;
                    position: relative;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 17px;
                }
            }

            &.-twitter {
                .am-social-icon {
                    @include icon($icon-twitter, $jpp-twitter, 18px);
                    transform: translateX(-8px);
                }
            }

            &.-apple {
                .am-social-icon {
                    @include icon($icon-appleinc, $jpp-off-black, 16px);
                }
            }

            &.-paypal {
                .am-social-icon {
                    background-image: url('../images/icon/paypal.png');
                    background-size: contain;
                    background-repeat:no-repeat;
                    height: 17px;
                    position: relative;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 15px;
                }
            }
        }
    }
}

