.customer-account-preferences {
    .block.account-preferences { // sass-lint:disable-line force-element-nesting
        .fieldset {
            margin-top: 40px;

            .field {
                &.choice {
                    &::before {
                        display: none;
                    }
                }
            }

            .actions-toolbar {
                margin-left: 0;
            }
        }

        .form-edit-account {
            > .actions-toolbar {
                margin-left: 0;
            }
        }
    }
}
