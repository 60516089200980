.netpayment-index-index {

    .modal-popup {
        pointer-events: all;
    }

    .back-link {
        display: none;
    }

    .account-overview {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width:100%;

        @media (min-width: $bp-tablet) {
            flex-wrap: nowrap;
        }

        .card {
            @include customer-container;
            flex-basis: calc(50% - 6px);
            margin-bottom: $account-spacer;
            min-height: 100px;

            @media (min-width: $bp-tablet) {
                flex-basis: 24%;
            }

            &:first-child {
                margin-left: 0;
            }

            .title {
                font-size: 17px;
                font-weight: bold;
                margin-top: 0;
            }

            .balance-due {
                color: $jpp-orange;
                display: block;
                font-size: 3.2rem;
                font-weight: 800;
                letter-spacing: 0;
                line-height: 3.9rem;
                margin-bottom: 20px;
                text-align: center;

                small {
                    color: inherit;
                    font-size: 1.6rem;
                    font-weight: 800;
                    line-height: 2rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    #addFundSourceForm { // sass-lint:disable-line no-ids

        ul {
            font-size: 1.4rem;
            line-height: 2rem;

            li {
                font-size: inherit;
                line-height: inherit;
                margin-bottom: 0;
            }
        }

        .fieldset {
            #source-bank { // sass-lint:disable-line no-ids
                flex-wrap: wrap;
                justify-content: space-between;

                .field {
                    flex-basis: 100%;

                    &.field-half {
                        flex-basis: calc(50% - 6px);
                    }
                }
            }

            > .field {
                &:not(.choice) {
                    > .control {
                        float: none;
                        width: 100%;
                    }
                }
            }
        }

        .help-icon-cls-parent {
            height: 20px;
        }

        .modal-footer {
            .secondary {
                @include account-buttons;
            }

            .primary, .blue {
                @include account-buttons('primary');
            }

            .orange {
                @include account-buttons('orange');
            }
        }
    }

    #showMorePayments{
        @include account-buttons('primary');
    }

    .modals-wrapper {
        .modal-popup {
            .modal-inner-wrap {
                max-width: 680px;
                width: 100%;
            }
        }
    }

    .funding-sources-title {
        h4 {
            display: inline-block;
        }

        .add-new-funding {
            @include account-buttons('primary');
            float: right;
        }
    }

    #funding-sources { // sass-lint:disable-line no-ids
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width:100%;

        .card {
            @include customer-container;
            flex-basis: calc(50% - 6px);
            margin-bottom: $account-spacer;
            min-height: 100px;

            @media (min-width: $bp-tablet) {
                flex-basis: 24%;
            }

            .title {
                font-weight: bold;
            }

            .delete {
                text-decoration: none !important; // sass-lint:disable-line no-important
                transform:translateY(12px);
                display:block;
                width:65px;
                margin:0 auto;
                display:flex;
                justify-content:center;
                opacity:0.75;
                transition:0.18s ease;
                &:hover {
                    opacity:1;
                }
                span:nth-of-type(1) {
                    font-size: 28px;
                    vertical-align: bottom;
                    margin-right:6px;
                }

                span {
                    color: $jpp-error-red;
                    font-size: 14px;
                }
            }
        }

    }

    #showMorePayments { // sass-lint:disable-line no-ids
        button {
            @include button;
        }
    }

    .modal-inner-wrap {
        max-width: 700px;
        width: 100%;

        .modal-header {
            border: 1px solid $jpp-light-grey;
            display: flex;
            flex-direction: row;
            padding: 20px;

            .modal-title {
                border-bottom: 0;
                color: $jpp-blue;
                margin: 0;
            }

            .action-close {
                padding: 20px;
            }
        }

        .modal-footer {
            display: flex;
            justify-content: space-between;
        }
    }

    .main {
        background: $white;

        .error {
            select {
                border: 1px solid $jpp-error-red;
            }

            input {
                border: 1px solid $jpp-error-red;
            }
        }

        .filled {
            select {
                border: 1px solid $jpp-success-green;
            }

            input {
                border: 1px solid $jpp-success-green;
            }
        }

        .net-payment {
            .noFunds { // sass-lint:disable-line class-name-format
                p {
                    font-style: italic;
                    margin: 0;
                    padding: 20px 0;
                    text-align: center;
                }
            }

            .hasFunds { // sass-lint:disable-line class-name-format
                margin-bottom: 4%;

                #reviewPayment { // sass-lint:disable-line no-ids
                    margin-top: 1%;
                }

                > .field {
                    &:not(.choice) {
                        > .control {
                            width: 100%;
                        }
                    }
                }

                .button {
                    &.blue {
                        @include button;
                    }
                }

                .alert-danger {
                    color: $jpp-error-red;
                    margin: 2% 0;
                }
            }
        }
    }

    #paymentHistoryWrapper { // sass-lint:disable-line no-ids
        .payment {
            border-top: 1px solid $jpp-grey;
            padding: 25px 0;

            @media (min-width: $bp-tablet) {
                padding-left: 25px;
                padding-right: 25px;
            }

            &:first-child {
                border-top: 0;
            }

            .columns {
                display: flex;
                margin: 0;
                padding: 0;

                .column {
                    padding: 0 5% 0 0;

                    &:first-child {
                        flex-basis: 40%;
                    }

                    .py {
                        .price {
                            font-weight: 700;
                        }

                        .icon {
                            color: $jpp-blue;
                            font-size: 2rem;
                            padding: 2px 3%;

                            &.success {
                                color: $jpp-success-green;
                            }

                            &.error {
                                color: $jpp-error-red;
                            }
                        }
                    }
                }
            }

            .reference {
                color: $jpp-grey-3;
                font-size: 12px;
                padding: 1%;
            }
        }
    }
}
