#financing-block {
    .finance-strip{
        height: 24px;
        line-height: 24px;
        background: #FFF;
        font-size: 14px;
        color: #222;
        padding: 0 6px;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 12px;
        @media (max-width:480px) {
            background:#f2f9ff;
        }
        a.apply {
            font-size:14px;
        }
        span {
            font-weight: 700;
        }
        .finance-trigger {
            line-height: 24px;
            color: $jpp-blue;
            text-decoration: none !important;
            width:18px;
            display:inline-block;
        }
        .apply {
            font-weight: 700;
            color: $jpp-dark-blue;
            text-decoration: none !important;
            display:block;
            width:128px;
            margin-top:3px;
        }
        svg {
            margin-right: 12px;
        }
    }
}
#financing-info {
    overflow: hidden;
    display: none;
    font-size: 1.4rem;

    .alert-info  {
        position: relative;

        .alert-info-close {
            position: absolute;
            top: 8px;
            right: 5px;
            font-weight: 700;
            font-size: 2rem;
            line-height: 1rem;
            text-decoration: none !important;
        }
        a {
            text-decoration:none!important;
        }
        p {
            max-width: 100%;
        }
        a,
        p {
            font-size: inherit;
            margin-bottom: 0;
        }
    }
}
