// sass-lint:disable class-name-format
.product-info-main .box-tocart .product-addto-links .requisition-list-button { // sass-lint:disable-line force-element-nesting
    line-height: 12px;
    margin-bottom: 4px;
    margin-top:2px;
    min-width: 80px;
    padding: 0;
    @media (min-width:$bp-laptop) {
        margin-top:-6px;
    }
    &::before {
        display: none;
    }
}

.requisition_list-requisition-index,
.requisition_list-requisition-view {
    .column {
        &.main {
            order: 1;
        }
    }

    .sidebar-main {
        order: 2;
    }

    .requisition-action-title-container {
        margin-top: 32px;
        order: 0;
        width: 100%;

        @media (min-width: $bp-tablet) {
            @include customer-container;
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 0 12px;
        }
    }
}

.requisition_list-requisition-index {
    .page-title-card-container {
        display: inline-block;
    }

    .heading {
        margin-bottom: 24px;

        @media (min-width: $bp-tablet) {
            display: inline-block;
            margin: 0;
        }

        span {
            @include font-bold;
            display: block;
            font-size: 2.2rem;
            line-height: 2.8rem;
            margin: 0;
            padding: 0;
        }
    }

    .title-actions-links {
        border: 0;
        display: inline-block;
        margin-top: 0;
        padding: 0;
        @media (max-width: $bp-tablet - 1) {
            float: right;
        }

        .action {
            @include button('primary');
            transform:translateY(-8px);
            @media (max-width: $bp-tablet - 1) {
                padding: 6px 10px !important; // sass-lint:disable-line no-important

                &::after {
                    content: '+';
                }

                span {
                    display: none;
                }
            }
        }
    }

    .table-wrapper {
        @include customer-container;
        @media (max-width: $bp-tablet - 1) {
            padding: 18px;
        }

        thead {
            .data-grid-th {
                border-bottom: 0;
                padding-bottom:12px;
                @media (max-width: $bp-tablet - 1) {
                    display: none;
                }

                @media (min-width: $bp-tablet) {
                    padding-left: $spacer;
                    padding-right: $spacer;

                    &.data-grid-actions-cell {
                        padding-right:0;
                    }
                }

                &:not(.data-grid-actions-cell) {
                    @media (min-width: $bp-tablet) {
                        text-align: left;
                    }
                }

                span {
                    @include h4-typography;
                    text-transform: none;
                    font-size: 16px;
                    @media (min-width: $bp-tablet) {
                        font-size: 18px;
                    }
                }
            }

            .list-name-field {
                @media (min-width: $bp-tablet) {
                    padding-left: 0;
                }
            }
        }

        .data-row {
            &:not(:first-child) {
                border-top: 1px solid $jpp-grey;
            }

            td {
                // sass-lint:disable-block no-important
                padding: 0 0 8px !important;

                @media (max-width: $bp-tablet - 1) {
                    display: block;
                }

                @media (min-width: $bp-tablet) {
                    padding: 28px $spacer !important;
                }

                &:first-child {
                    @media (max-width: $bp-tablet - 1) {
                        padding-top: 24px !important;
                    }

                    @media (min-width: $bp-tablet) {
                        padding-left: 0 !important;
                    }
                }

                &:last-child {
                    @media (max-width: $bp-tablet - 1) {
                        padding-bottom: 24px !important;
                    }

                    @media (min-width: $bp-tablet) {
                        padding-right: 0 !important;
                    }
                }

                &:not(.data-grid-actions-cell) {
                    @media (min-width: $bp-tablet) {
                        text-align: left;
                    }
                }

                &[data-th] {
                    &::before {
                        font-size: 14px;
                        font-weight: 500 !important; // sass-lint:disable-line no-important

                        @media (max-width: $bp-tablet - 1) {
                            @include font-bold;
                            content: attr(data-th) ': ';
                            display: inline-block;
                            padding-right: 10px;
                        }
                    }
                }
            }

            .list-name-field,
            .actions-field {
                &::before {
                    display: none !important; // sass-lint:disable-line no-important
                }
            }

            .list-name-field {
                @media (min-width: $bp-tablet) {
                    padding-left: 0;
                    width: 45%;
                }

                .cell-label-line-name {
                    @include font-bold;
                    font-size: 16px;

                    @media (min-width: $bp-tablet) {
                        @include font-normal;
                    }
                }

                .cell-label-line-description {
                    display: none;
                }
            }

            .number-field {
                @media (min-width: $bp-tablet) {
                    width: 20%;
                }
            }

            .actions-field {
                @media (min-width: $bp-tablet) {
                    width: 15%;
                }
            }

            .data-grid-cell-content {
                font-size: 16px;
            }

            .action-menu-item {
                @include font-bold;
                @include unset-underline;
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }

    .requisition-toolbar-actions {

        .remove-selected {
            transform:translateY(-1.5px);
        }
        > .action {
            &::before {
                @include font-bold;
                text-transform: uppercase;
            }

            span {
                @include font-bold;
                color: $jpp-blue;
                font-size: 14px;
                text-transform: uppercase;
            }
        }

        >  .link-button {
            .action {
                @include icon-after($icon-caret-down, $jpp-blue, 14px);

                &::before {
                    @include font-bold;
                    text-transform: uppercase;
                }

                &::after {
                    vertical-align: bottom;
                }

                span {
                    @include font-bold;
                    color: $jpp-blue;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .quotes-grid-toolbar {
        @include customer-container;
        padding:12px 24px!important;
        margin:0;
        @media (max-width:$bp-tablet) {
            padding:12px 16px!important;
        }
        select {
            height:30px;
            line-height:30px;
            padding:0 8px;
        }
    }
}

.requisition_list-requisition-view {
    .requisition-action-title-container {
        @media (max-width: $bp-tablet - 1) {
            border-bottom: 1px solid $jpp-grey;
        }

        @media (min-width: $bp-tablet) {
            display: block;
            position: relative;
        }

        .requisition-list-title {
            @media (max-width: $bp-tablet - 1) {
                margin-bottom: 24px;
            }
            button {
                span {
                    display:none;
                }
                svg {
                    @include ease;
                    width:20px;
                    height:20px;
                }
                &:hover {
                    svg {
                        stroke:$jpp-orange;
                    }
                }
            }
            h1 {
                font-size: 22px;
                margin: 0;

                > span {
                    font-size: 22px;
                    margin: 0;
                    text-transform: none;
                }
            }
        }

        .edit {
            background: $white;
            border: 0;
            margin-left: 4px;
            margin-top: -5px;
            padding: 0;

            span {
                @include font-bold;
                color: $jpp-dark-blue;
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }

    .requisition-content {
        padding: 24px 0;

        @media (min-width: $bp-tablet) {
            @include customer-container;
            margin-bottom: $account-spacer;
            padding: 0;
        }
    }

    .block-requisition-management {
        @include clearfix;
        margin-top: 0;

        .requisition-info {
            float: left;
            margin-bottom: 0;
        }

        .counter {
            @include font-bold;
            font-size: 18px;
            text-transform: uppercase;
        }

        .requisition-toolbar {
            float: right;
            display: flex;
        }

        .requisition-toolbar-select {
            display: inline-block;
            margin-right: 10px;
            transform:translateY(-1.5px);
            .label {
                @include font-normal;
                font-size: 16px;
            }
        }

        .requisition-toolbar-actions {
            margin-right:0;

            .mobile-label {
                display: none;
            }

            > .action,
            > .link-button {
                display: inline-block;
                vertical-align: middle;
                cursor:pointer;
                margin-left:20px;
            }

            .action {
                @include unset-underline;
                padding-bottom: 0;

                &::before {
                    @include font-normal;
                    color: $jpp-off-black;
                    font-family: $proxima-nova;
                    font-size: 16px;

                    @media (max-width: $bp-laptop - 1) {
                        content: attr(data-mobile-label);
                    }
                }

                &.toggle {
                    &::after {
                        content: none;
                    }
                }

                > span {
                    @media (max-width: $bp-laptop - 1) {
                        display: none;
                    }
                }
            }
        }
    }

    .requisition-grid {
        margin-left: 0;
        margin-right: 0;

        .table {
            margin-top: 20px;

            thead {
                display: none;

                @media (min-width: $bp-laptop) {
                    display: table-header-group;
                }

                .col {
                    > span {
                        @include h5-typography;

                        @media (min-width: $bp-laptop) {
                            font-size: 18px;
                        }
                    }

                    &.price,
                    &.qty,
                    &.action {
                        @media (min-width: 640px) {
                            padding-left: 0;
                            padding-right: $spacer;
                        }
                    }

                    &.action {
                        text-align: right;

                        @media (min-width: $bp-tablet) {
                            padding-right: 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-top: 1px solid $jpp-grey;

                    &:last-child {
                        border-bottom: 1px solid $jpp-grey;
                    }
                }

                .col {
                    padding: 0 0 8px !important; // sass-lint:disable-line no-important

                    @media (max-width: $bp-laptop - 1) {
                        display: block;
                    }

                    @media (min-width: $bp-laptop) {
                        padding: 28px $spacer !important; // sass-lint:disable-line no-important
                    }

                    &[data-th] {
                        &::before {
                            font-weight: 500 !important; // sass-lint:disable-line no-important

                            @media (max-width: $bp-laptop - 1) {
                                @include font-bold;
                                content: attr(data-th) ': ';
                                display: inline-block;
                                padding-right: 2px !important; // sass-lint:disable-line no-important
                            }
                        }
                    }

                    &.number,
                    &.col-checkbox {
                        display: block;
                        float: left;
                        height: unset;
                        text-align: center;

                        @media (min-width: $bp-laptop) {
                            padding-left: 0 !important; // sass-lint:disable-line no-important
                        }
                    }

                    &.number {
                        @include font-bold;
                        font-size: 16px;
                        padding-right: 5px !important; // sass-lint:disable-line no-important
                        white-space: nowrap;

                        input {
                            margin-left: 10px;
                            margin-right: 0;
                        }

                        @media (max-width: $bp-laptop - 1) {
                            padding-top: 24px !important; // sass-lint:disable-line no-important
                        }
                    }

                    &.col-checkbox {
                        padding-top: 0;
                    }

                    &.product {
                        @media (max-width: $bp-laptop - 1) {
                            padding-left: 24px !important;  // sass-lint:disable-line no-important

                            padding-top: 24px !important;   // sass-lint:disable-line no-important
                        }

                        &::before {
                            display: none !important; // sass-lint:disable-line no-important
                        }

                        .product-item-image {
                            border: 1px solid $jpp-grey;

                            @media (max-width: $bp-laptop - 1) {
                                display: inline-block;
                                float: none;
                                margin-right: 12px;
                            }

                            img {
                                width: 85px;
                            }
                        }

                        .product-item-description {
                            width: calc(100% - 130px);

                            @media (max-width: $bp-laptop - 1) {
                                display: inline-block;
                                float: none;
                                vertical-align: top;
                            }
                        }

                        .product-item-name {
                            margin-bottom: 0;

                            a {
                                @include unset-underline;
                                @include h5-typography;
                            }
                        }

                        .product-item-sku,
                        .product-item-details {
                            margin-top: 0;

                            * {
                                @include font-normal;
                                font-size: 14px;
                            }
                        }
                    }

                    &.price,
                    &.qty,
                    &.action {
                        @media (max-width: $bp-laptop - 1) {
                            text-align: left;
                        }

                        @media (min-width: 640px) {
                            padding-left: 0;
                            padding-right: $spacer;
                        }

                        .price {
                            @include font-normal;
                            font-size: 14px;
                        }
                    }

                    &.action {
                        @media (max-width: $bp-laptop - 1) {
                            padding-bottom: 24px !important; // sass-lint:disable-line no-important
                        }

                        @media (min-width: $bp-laptop) {
                            padding-right: 0 !important; // sass-lint:disable-line no-important
                        }

                        &::before {
                            display: none !important; // sass-lint:disable-line no-important
                        }
                    }
                }
            }

            .actions-toolbar {
                float: none;
                margin-left: 0;
                position: static;
                text-align: center;

                .action-edit,
                .action-delete {
                    position: unset;
                    width: 47%;

                    @media (max-width: $bp-laptop - 1) {
                        display: inline-block !important; // sass-lint:disable-line no-important
                    }

                    @media (min-width: $bp-laptop) {
                        display: block;
                        margin: 0;
                        width: 100%;
                    }

                    &::before {
                        display: none;
                    }

                    > span {
                        @include unset-hidden-props;
                        font-size:12px!important;
                    }
                }

                .action-edit {
                    @include button('primary');
                    padding-bottom: 8px !important; // sass-lint:disable-line no-important
                    padding-top: 8px !important; // sass-lint:disable-line no-important

                    @media (min-width: $bp-laptop) {
                        margin-bottom: 6px;
                    }
                }

                .action-delete {
                    @include dashboard-button;
                    margin-left: 0;
                }
            }
        }
    }

    .requisition-view-links {
        margin-top: 32px;

        @media (min-width: $bp-laptop) {
            margin-top: 28px;
        }

        .export,
        .print {
            @include dashboard-button;
            display: inline-block;
            padding: 0 12px;
        }
    }

    .requisition-view-buttons {
        @include customer-container;
        padding-bottom: 0;

        @media (min-width: $bp-tablet) {
            padding-bottom: 0;
        }

        .action {
            &.primary,
            &.secondary {
                margin-bottom: 14px;

                &:last-child {
                    @media (min-width: $bp-tablet) {
                        margin-bottom: 28px;
                    }
                }
            }

            &.primary {
                @media (min-width: $bp-tablet) {
                    margin-left: 0;
                }
            }

            &.secondary {
                @media (min-width: $bp-tablet) {
                    margin-left: $spacer;
                }
            }
        }
    }
}


.modal-popup {
    &.requisition-popup {
        .modal-footer {
            .primary {
                height:40px!important;
                line-height:40px!important;
                padding:0 16px!important;
            }
            .secondary {
                height:40px!important;
                line-height:40px!important;
                padding:0 16px!important;
                span {
                    color:$white!important;
                }
            }
        }
    }
}
