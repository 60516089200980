div {
    &[data-element='main'] {
        .pagebuilder-rowmark-banner-wrapper {
            @include full-width;
            background-color: $jpp-blue;
            margin-left: -50vw !important; // sass-lint:disable-line no-important
            margin-top: -20px !important; // sass-lint:disable-line no-important
            min-height: 300px;

            .pagebuilder-rowmark-banner-container {
                @include center-x-y;
                box-sizing: border-box;
                padding-left: $spacer;
                padding-right: $spacer;

                @media (min-width: $bp-tablet) {
                    max-width: 575px;
                    text-align: center;
                    width: 100%;
                }
            }

            .rowmark-image {
                max-width: 350px;
                width: 100%;

                @media (min-width: $bp-mobile) {
                    max-width: 450px;
                }
            }

            .banner-content {
                @include font-bold;
                border-top: 1px solid $white;
                color: $white;
                font-size: 28px;
                margin-top: $spacer;
                padding-top: $spacer;
                text-align: center;
                text-shadow: 0 2px 2px rgba(2, 2, 2, .28); // sass-lint:disable-line no-color-literals

                @media (min-width: $bp-tablet) {
                    font-size: 32px;
                }
            }
        }
    }
}
