.cms-index-index {
    .columns {
        .column {
            &.main {
                margin-top: 0;
                padding-bottom: 0;
            }
        }
    }
}
