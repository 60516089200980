.related,
.upsell {
    margin-bottom: 56px !important; // sass-lint:disable-line no-important

    .block-title {
        border-bottom: 1px solid $jpp-grey;
        margin: 0;
        padding-bottom: 10px;

        strong {
            color: $jpp-dark-blue;
            font-size: 20px !important; // sass-lint:disable-line no-important
            font-weight: 800;
            line-height: 23px;
            text-transform: uppercase;

            @media (max-width: $bp-laptop - 1) {
                display: block;
                margin: 0;
                max-width: 206px;
            }
        }
    }

    .block-content {
        .products {
            margin: 16px 0 0;

            .product-item {
                margin-bottom: 0;
                .price-loader {
                    width:128px;
                    img {
                        transform:translateX(-54px) translateY(-28px);
                    }
                }
                .product-item-info {
                    width: auto;

                    @media (min-width: $bp-tablet) {
                        display: flex;
                    }

                    .product-item-photo {
                        @media (min-width: $bp-tablet) {
                            padding-right: $spacer / 2;
                        }
                    }

                    .product-image-container,
                    .product-image-wrapper {
                        margin: 0;
                    }

                    .product-item-name {
                        margin: 0;
                        margin-top:2px;
                        max-width:calc(100% - 16px);
                        line-height:1.2;
                        @media (min-width:$bp-tablet) {
                            max-width:calc(100% - 32px);
                        }
                        & > a,
                        & > a {
                            @include unset-underline;
                            color: $jpp-blue;
                            font-size: 14px;
                            line-height:1.2;
                        }
                    }

                    .price-box {
                        margin-bottom: unset;
                        margin-top:0;
                        .price {
                            color: $jpp-blue;
                            font-size: 16px;
                            font-weight: 800;
                            line-height: 19px;
                        }
                        .old-price, span.savings {
                            display:none;
                        }
                    }
                }
            }
        }
    }
}

.slick-slider {
    .slick-prev,
    .slick-next {
        background-color: $jpp-grey !important; // sass-lint:disable-line no-important
        border-radius: 50%;
        height: 30px;
        top: -56px;
        transform: unset;
        width: 30px;

        &::before {
            color: $jpp-off-black;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }

    .slick-prev {
        left: auto;
        right: 38px;

        &::before {
            padding-right: 3px;
        }
    }

    .slick-next {
        right: 0;

        &::before {
            padding-left: 3px;
        }
    }
}
