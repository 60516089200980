.magento_customerbalance-info-index, // sass-lint:disable-line class-name-format
.magento_giftcardaccount-customer-index { // sass-lint:disable-line class-name-format
    .block-balance {
        margin-bottom: $account-spacer;

        @media (min-width:$bp-laptop) {
            padding-bottom:48px;
        }
        .customer-container-title {
            margin-bottom:0!important;
        }
        .block-content {
            text-align: center;

            .balance-price-label {
                color: inherit;
                font-size: 1.6rem;
                font-weight: 800;
                line-height: 2rem;
                text-transform: uppercase;
            }

            .price {
                color: $jpp-orange;
                display: block;
                font-size: 3.2rem;
                font-weight: 800;
                letter-spacing: 0;
                line-height: 3.9rem;
                margin-bottom: 20px;
            }
        }
    }

    .giftcard-account {
        margin-bottom: $account-spacer;
        @media (max-width: #{$bp-tablet - 1}) {
            .actions-toolbar {
                flex-wrap: wrap;

                .primary,
                .secondary {
                    flex-basis: 100%;
                }

                .secondary {
                    margin-top: $account-spacer;
                }

                button {
                    width: 100%;
                }
            }
        }
    }

    .block-balance-history {
        @include customer-container;
        margin-bottom: $account-spacer  !important; // sass-lint:disable-line no-important

        .block-title {
            @include customer-container-title;
        }
    }

    .table-balance-history {
        @include account-table;
    }
}
