// sass-lint:disable class-name-format
.page-layout-news_and_events_layout {
    .columns {
        .sidebar {
            display: none;

            @media (min-width: $bp-tablet) {
                display: block;
            }

            .menu {
                border: 1px solid $jpp-grey;
                padding: 10px;
            }

            .title {
                border-bottom: 1px solid $jpp-grey;
                margin-bottom: 10px;
                margin-top: 5px;
                padding-bottom: 10px;
            }

            .nav-items {
                list-style: none;
                margin-bottom: 0;
                padding-left: 0;
            }

            .nav-item {
                &:hover {
                    a {
                        span {
                            @include smooth;
                            color: $jpp-blue;
                        }
                    }
                }

                * {
                    @include unset-underline;
                }
            }
        }
    }
}
