
@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-800.woff2") format("woff2"), url("/custom/fonts/proxima-nova-800.woff") format("woff"), url("/custom/fonts/proxima-nova-800.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-700.woff2") format("woff2"), url("/custom/fonts/proxima-nova-700.woff") format("woff"), url("/custom/fonts/proxima-nova-700.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-600.woff2") format("woff2"), url("/custom/fonts/proxima-nova-600.woff") format("woff"), url("/custom/fonts/proxima-nova-600.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-500.woff2") format("woff2"), url("/custom/fonts/proxima-nova-500.woff") format("woff"), url("/custom/fonts/proxima-nova-500.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-400.woff2") format("woff2"), url("/custom/fonts/proxima-nova-400.woff") format("woff"), url("/custom/fonts/proxima-nova-400.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family:"proxima-nova";
    src:url("/custom/fonts/proxima-nova-400i.woff2") format("woff2"),url("/custom/fonts/proxima-nova-400i.woff") format("woff"),url("/custom/fonts/proxima-nova-400i.otf") format("opentype");
    font-display:auto;
    font-style:italic;
    font-weight:400;
}

@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-300.woff2") format("woff2"), url("/custom/fonts/proxima-nova-300.woff") format("woff"), url("/custom/fonts/proxima-nova-300.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "proxima-nova";
    src: url("/custom/fonts/proxima-nova-300i.woff2") format("woff2"), url("/custom/fonts/proxima-nova-300i.woff") format("woff"), url("/custom/fonts/proxima-nova-300i.otf") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 300;
}

.tk-proxima-nova {
    font-family: "proxima-nova", sans-serif;
}

$proxima-nova: 'proxima-nova', sans-serif;
$icomoon: 'icomoon';

@font-face {
    font-family: $icomoon;
    font-style: normal;
    font-weight: normal;
    src:  url('../fonts/icomoon/icomoon-jpp.eot?wyjtoa');
    src:  url('../fonts/icomoon/icomoon-jpp.eot?wyjtoa#iefix') format('embedded-opentype'), // sass-lint:disable-line no-duplicate-properties
    url('../fonts/icomoon/icomoon-jpp.ttf?wyjtoa') format('truetype'), // sass-lint:disable-line indentation
    url('../fonts/icomoon/icomoon-jpp.woff?wyjtoa') format('woff'), // sass-lint:disable-line indentation
    url('../fonts/icomoon/icomoon-jpp.svg?wyjtoa#icomoon') format('svg'); // sass-lint:disable-line indentation
}

// ### Icons ###
// JPP
$icon-jpp-adacompliant: '\e900';
$icon-jpp-cart: '\e901';
$icon-jpp-freeshippingtracker: '\e902';
$icon-jpp-frontengravable: '\e903';
$icon-jpp-heattranferable: '\e904';
$icon-jpp-homepage-engravablematerials: '\e905';
$icon-jpp-homepage-fabricationservices: '\e909';
$icon-jpp-homepage-heattransfer: '\e90e';
$icon-jpp-homepage-standoffs: '\e912';
$icon-jpp-homepage-sublimation: '\e914';
$icon-jpp-homepage-uvled: '\e91d';
$icon-jpp-interior: '\e91f';
$icon-jpp-laserengravable: '\e920';
$icon-jpp-myaccount: '\e921';
$icon-jpp-outdoorweatherable: '\e922';
$icon-jpp-quickorder: '\e923';
$icon-jpp-resources: '\e924';
$icon-jpp-reverseengravable: '\e925';
$icon-jpp-rotaryengravable: '\e926';
$icon-jpp-sublimation: '\e927';
$icon-jpp-uvledprintable: '\e928';
$icon-jpp-uvstable: '\e929';
$icon-jpp-verified: '\e92a';
$icon-jpp-secondary-ada: '\e92c';
$icon-jpp-secondary-blog: '\e932';
$icon-jpp-secondary-customerservice: '\e93a';
$icon-jpp-secondary-equipment: '\e93f';
$icon-jpp-secondary-heattransfer: '\e946';
$icon-jpp-secondary-lasterengraving: '\e94a';
$icon-jpp-secondary-rotaryengraving: '\e950';
$icon-jpp-secondary-sublimation: '\e954';
$icon-jpp-secondary-support: '\e95d';
$icon-jpp-secondary-tradeshows: '\e966';
$icon-jpp-secondary-uvledprinting: '\e96f';
$icon-jpp-reviews-star-full: '\e97e';
$icon-jpp-reviews-star-half: '\e97c';
$icon-jpp-reviews-star-empty: '\e97d';
// Font Awesome
$icon-arrow-right: '\e971';
$icon-align-justify: '\e972';
$icon-twitter: '\f099';
$icon-facebook: '\f09a';
$icon-envelope: '\f0e0';
$icon-linkedin: '\f0e1';
$icon-instagram: '\f16d';
$icon-pinterest: '\e978';
$icon-plus: '\e973';
$icon-plus-thin: '\e97a';
$icon-minus: '\e974';
$icon-chevron-right: '\e975';
$icon-caret-down: '\f0d7';
$icon-pencil-edit: '\e976';
$icon-trash: '\e977';
$icon-copy-clipboard: '\e979';
$icon-appleinc: '\eabe';
$icon-checkmark-outline: '\e97b';

body {
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    color: $jpp-off-black;
    font-family: $proxima-nova;

    p,
    span {
        @include p-typography;
        color: inherit;
    }

    span {
        margin-bottom: 0;
        margin-top: 0;
    }

    small,
    .small {
        @include small-typography;

        span {
            @include small-typography;
        }

        a {
            @include anchor-small-typography;
        }
    }

    h1 {
        @include h1-typography;

        span {
            @include h1-typography;
            color:inherit;
            font-size:inherit;
        }
    }

    h2 {
        @include h2-typography;

        span {
            @include h2-typography;
            color:inherit;
            font-size:inherit;
        }
    }

    h3 {
        @include h3-typography;

        span {
            @include h3-typography;
        }
    }

    h4 {
        @include h4-typography;

        span {
            @include h4-typography;
            color:inherit;
            font-size:inherit;
        }
    }

    h5 {
        @include h5-typography;

        span {
            @include h5-typography;
            color:inherit;
            font-size:inherit;
        }
    }

    blockquote {
        border-left: 5px solid $jpp-grey;
        font-size: 17.5px;
        font-style: normal;
        margin: 0 0 20px;
        padding: 10px 20px;
    }

    figure {
        margin: 0;

        figcaption {
            @include small-typography;
        }
    }

    hr {
        border: 0;
        border-top: 1px solid $jpp-grey;
        margin-bottom: $spacer;
        margin-top: $spacer;
    }

    ul,
    ol {
        li {
            @include p-typography;
        }
    }

    .modals-wrapper {
        .modal-popup {
            .modal-title {
                @include h3-typography;
            }
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"] {
        font-family: $proxima-nova;
    }

    img {
        font-size:12px;
        color:#555;
        line-height:1;
    }

    div[data-content-type="text"] {
        font-size:16px;
        color:#000;
        line-height:1.4;
    }

    h1, h2, h3, h4, h5, label, span, p, li {
        a {
            font-size:inherit;
        }
    }
}
