.account.sales-order-history {

    .column.main {
        position:relative;
    }
    .actions-toolbar {
        display: none;
    }

    .page-title-card {
        @media (max-width: $bp-laptop) {
            margin-bottom: 0;
        }
    }

    .orders-history {
        overflow: visible;
        margin-bottom: -12px;

        @media (min-width: $bp-laptop) {
            transform: translateY(-12px);

        }
        @media (min-width: 640px) and (max-width: $bp-tablet) {
            transform: translateY(-12px);
        }

        .table.history {
            border-spacing: 0 12px;
            border-collapse: separate;

            tbody {
                position: relative;
                display: block;
                width: 100%;
                @media (min-width: $bp-laptop) {
                    display: inherit;
                }
            }

            @media (min-width: $bp-tablet) {
                margin-top: -12px;
            }

            > caption {
                display: none;
            }

            tr {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 12px;
                border-top: 1px solid $jpp-grey;
                background: $white;
                box-shadow: 0 2px 16px $box-shadow-light;
                @media (min-width: $bp-tablet) {
                    flex-direction: row;
                    &:first-of-type {
                        margin-top: 12px;
                    }
                }
                @media (min-width: $bp-laptop) {
                    display: table-row;
                    flex-direction: none;
                    margin-bottom: 0;
                }

                @media (max-width: $bp-tablet) {
                    margin: $account-spacer 0;
                }
            }

            .col {
                padding: 16px 0;

                &:first-child {
                    padding-left: 16px;
                }

                &:last-child {
                    padding-right: 16px;
                }

                &.info {
                    width: 100%;
                    padding: 16px;
                    @media (min-width: $bp-tablet) {
                        width: 292px;
                        padding-right: 0;
                    }
                    @media (min-width: $bp-laptop) {
                        width: 410px;
                        padding: 24px 0;
                        padding-left: 24px;
                    }
                    @media (min-width: $bp-laptop) and (max-width: $bp-desktop) {
                        p.items {
                            min-width: 384px;
                        }
                    }
                    @media (min-width: $bp-desktop) {
                        width: 514px;
                    }
                    @media (min-width: $bp-tablet) and (max-width: $bp-laptop) {
                        width: 286px;
                    }
                }

                &.actions {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0 16px 16px 16px;

                    @media (max-width: $bp-tablet) {
                        flex-direction: row;

                        a.action {
                            margin-right: 12px;

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                    @media (min-width: $bp-tablet) {
                        width: auto;
                        padding-left: 0;
                    }
                    @media (min-width: $bp-laptop) {
                        width: auto;
                        padding: 24px 0;
                        padding-right: 24px;
                    }
                    @media (min-width: $bp-tablet) and (max-width: $bp-laptop) {
                        width: calc(100% - 286px);
                    }

                    p.order-status {
                        display: block;
                        float: none;
                        width: 100%;
                        text-align: left;
                        @media (max-width: $bp-tablet) {
                            position: absolute;
                            top: 20px;
                        }
                        @media (max-width: ($bp-laptop - 1)) {
                            font-size: 12px;
                            font-weight: 600;
                            width: auto;
                            margin: 16px 0;
                        }
                        @media (min-width: $bp-tablet) {
                            text-align: right;
                        }
                    }

                    a.action.order, a.action.view, a.action.pay {
                        float: left;
                        align-self: flex-start;
                        max-width: 140px;
                        @media (min-width: $bp-tablet) {
                            float: right;
                            align-self: flex-end;
                        }
                    }
                }
            }

            .order-number,
            .order-status {
                @include h5-typography;
                margin: 0 0 27px;
            }

            .item {
                position: relative;
                display: inline-block;
                width: 44px;
                height: 44px;
                text-align: center;
                text-transform: uppercase;
                border: 1px solid $jpp-grey;
                @include smooth;

                .name-label {
                    position: absolute;
                    bottom: calc(100% + 8px);
                    left: 50%;
                    align-items: center;
                    width: 268px;
                    max-width: calc(100vw - 24px);
                    transition-delay: 0s;
                    transform: translateX(-50%) translateY(3px);
                    @include smooth;
                    text-transform: none !important;
                    pointer-events: none;
                    opacity: 0;
                    @media (max-width: $bp-laptop) {
                        display: none;
                    }

                    span {
                        font-size: 12px;
                        line-height: 14px;
                        position: relative;
                        bottom: 100%;
                        left: 50%;
                        display: inline-block;
                        float: left;
                        padding: 8px 10px;
                        transform: translateX(-50%);
                        color: #fff;
                        border-radius: 4px;
                        background: $jpp-blue;
                        box-shadow: 0px 0px 12px rgba(2, 2, 2, 0.12);
                    }

                    & + .name-label {
                        //Hide regular name-label for remaining items label
                        display: none;
                    }
                }

                &:hover {
                    border: 1px solid $jpp-blue;

                    .name-label {
                        transition-delay: 0.18s;
                        transform: translateX(-50%) translateY(0);
                        opacity: 1;
                    }
                }

                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    max-height: 44px;

                    img {
                        max-width: calc(100% - 16px);
                        max-height: calc(100% - 16px);
                        @include center-x-y;
                        @media (max-width: $bp-laptop) {
                            max-width: calc(100% - 8px);
                            max-height: calc(100% - 8px);
                        }
                        @media (max-width: $bp-mobile-l) {
                            max-width: calc(100% - 4px);
                            max-height: calc(100% - 4px);
                        }
                    }
                }

                @media (min-width: $bp-tablet) {
                    width: 48px;
                    height: 48px;
                    a {
                        display: inline-block;
                        max-height: 48px;
                        vertical-align: top;

                        img {
                            max-height: 46px;
                        }
                    }
                }
                @media (min-width: $bp-laptop) {
                    width: 72px;
                    height: 72px;
                    a {
                        display: inline-block;
                        max-height: 72px;
                        vertical-align: top;

                        img {
                            max-height: 70px;
                        }
                    }
                }
                @media (min-width: $bp-desktop) {
                    width: 92px;
                    height: 92px;
                    a {
                        max-height: 92px;

                        img {
                            max-height: 90px;
                        }
                    }
                }

                .counter {
                    @include center-x-y;
                    @include h5-typography;
                    //background: $shadow-overlay;
                    line-height: 44px;
                    top: 50%;
                    right: 0;
                    bottom: 0;
                    left: 50%;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    text-align: center;
                    pointer-events: none;
                    color: $white;
                    background: $shadow-overlay-gradient;
                    text-shadow: 0px 0px 6px rgba(2, 2, 2, 0.9);

                    @media (min-width: $bp-tablet) {
                        line-height: 48px;
                    }
                    @media (min-width: $bp-laptop) {
                        line-height: 72px;
                    }
                    @media (min-width: $bp-desktop) {
                        line-height: 92px;
                    }
                }
            }

            .action {
                @include dashboard-button;
                float: right;
                width: 200px;
                margin-right: 0;

                &:not(:last-child) {
                    margin-bottom: $account-spacer;
                }
            }
        }
    }

    .order-products-toolbar {
        @include customer-container;
        margin: 0;
        padding: 12px 24px !important;
        transform: translateY(-12px);
        @media (max-width: $bp-tablet) {
            padding: 12px 16px !important;
        }

        a.page {
            text-decoration: none;
        }

        select {
            line-height: 30px;
            height: 30px;
            padding: 0 8px;
        }
    }

    .actions-toolbar {
        @include customer-container;
        margin-top: 24px;
    }
}

.sales-order-view {

    #sns_main {
        display:flex;
        flex-direction:column;

        > div {
            order:4;
            &.page-title-card {
                order:1;
            }
            &.order-top {
                order:2;
            }
            &.block-order-comment {
                order:3;
                @include customer-container;
    
                .block-title {
                    strong {
                        font-size:18px!important;
                        font-weight:800!important;
                    }
                }
            }
        }
    }
    .column.main {
        position:relative;
    }
    @media (max-width: $bp-laptop) {
        #sns_main {
            width: 100% !important;
        }
    }

    .page-main {

        ul.order-links {
            display: none;
        }

        .page-title-card {
            .order-status {
                display: none;
            }
        }
    }

    .block-tracking {
        .shipment-box {
            font-size: 1.6rem;
            line-height: 2rem;
            margin-bottom: 8px;
            padding: 15px;
            background-color: $jpp-off-white;

            .product-image {
                display: inline-block;
                width: 50px;
                height: auto;
                max-height: 50px;
                padding: 3px;
                vertical-align: top;
                border: 1px solid $jpp-grey;
                background-color: $white;
                @media (min-width: $bp-laptop) {
                    width: 75px;
                    max-height: 75px;
                    padding: 5px;
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    max-height: 42px;
                    @media (min-width: $bp-laptop) {
                        max-height: 63px;
                    }
                }

            }

            .shipment-box-title {
                font-weight: 700;
                margin-bottom: 10px;
                text-transform: uppercase;

                span {
                    opacity: .5;

                    &:before {
                        content: "(";
                    }

                    &:after {
                        content: ")";
                    }
                }
            }

            .shipment-products {
                display: none;
            }

            .shipment-box-top {
                @include flex-container;
                align-items: flex-start;

                .shipment-left,
                .shipment-right {
                    @include flex-item;
                }

                .shipment-right {
                    flex-basis: 140px;
                    flex-grow: 0;

                    .button {
                        font-size: 1.2rem;
                        font-weight: 700;
                        line-height: 3.2rem;
                        width: 100%;
                        max-width: 150px;
                        padding: 0;
                        cursor: pointer;
                        text-decoration: none;
                        text-transform: uppercase;
                        color: $jpp-dark-grey;
                        border: 1px solid $jpp-grey;
                        background: $white;
                    }
                }
            }

            .shipment-tracking-numbers,
            .shipment-estimated-delivery {
                margin-bottom: 8px;
            }
        }
    }

    .order-links + .customer-container.order-actions {
        @media (max-width:480px) {
            .action.print {
                margin-left:8px;
                margin-right:8px;
            }
            a.action {
                flex:1;
            }
        }
        @media (max-width:400px) {
            .action.print {
                margin-left:4px;
                margin-right:4px;
            }
        }
        @media (min-width:700px) {
            box-shadow:none;
            background:none;
            padding:0;
            margin:0;
            position:absolute;
            top:22px;
            right:22px;
            width:384px;
            .action {
                max-width:120px;
                &.back {
                    margin-top:0;
                }
            }
        }
    }

    .order-top {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: $bp-mobile-l) {
            .account-card.order-actions {
                display: none;
            }
        }
        @media (min-width: $bp-mobile-l) and (max-width: $bp-laptop) {
            .account-card.order-actions {
                flex-wrap: nowrap;

                a.action {
                    margin: 0;
                    &:nth-of-type(1) {
                        order: 2;
                        margin-right: 8px;
                    }
                    &:nth-of-type(2) {
                        order: 3;
                        margin-right: 0;
                    }
                    &:nth-of-type(3) {
                        order: 1;
                        margin-right: 8px;
                    }
                }
            }
        }

        @media (min-width: $bp-laptop) {
            flex-wrap: nowrap;
        }

        .account-card {
            @media (min-width: $bp-laptop) {
                flex-basis: 30%;
            }

        }

        .order-overview {
            flex-basis: 100%;
            @media (min-width: $bp-laptop) {
                flex-basis:auto;
                width:294px;
                padding:22px 24px;
            }
            > div {
                display:flex;
                margin-bottom:8px;
                &:last-of-type {
                    margin-bottom:0;
                }
            }
            label, span {
                font-size:14px;
                display:inline-block;
            }
            label {
                font-weight:800;
                margin-right:8px;
                line-height:24px;
                white-space:nowrap;
            }
            span {
                margin-left:0;
                margin-right:auto;
            }

            dl {
                @include flex-container;
                font-size:14px;
                line-height: 2rem;
                flex-wrap: wrap;
                margin: 0;

                dd,
                dt {
                    font-weight: 600;
                    @include flex-item;
                    flex-basis: 50%;
                    margin-top: 1.6rem;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-size:14px;
                    @media (max-width: $bp-mobile-l) {
                        font-size: 14px;
                    }
                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                dt {
                    font-weight: 800;
                }

                dd {
                    padding-left: 8px;
                    text-align: right;
                    text-transform: capitalize;
                }
            }

            .order-status {
                span {
                    margin-top:4px;
                    line-height:1.2;
                }
            }
        }
    }

    .order-actions {
        @include flex-container;
        width:100%;
        .action {
            @include flex-item;
            @include ease;
            text-align: center;
            margin:0;
            &:hover {
                box-shadow: 0px 1px 4px rgba(2, 2, 2, 0.12);
            }

            &:first-child {
                margin-top: 0;
            }

            &.order {
                @include account-buttons('orange');
                line-height: 3.1rem;
                order:3;
                &:hover {
                    border-color: $jpp-orange-hover;
                    background: $jpp-orange-hover;
                }
            }

            &.print {
                @include account-buttons('primary');
                line-height: 3.1rem;
                margin-left: auto;
                order:2;
                margin-left:12px;
                margin-right:12px;
                &:hover {
                    border-color: $jpp-blue-hover;
                    background: $jpp-blue-hover;
                }
            }
            &.pay {
                @include account-buttons('primary');
                line-height: 3.1rem;
                margin-left: auto;
                border-color: $jpp-success-green;
                background:$jpp-success-green;
                order:1;
                &:hover {

                }
                + .back {
                    display:none;
                }
            }
            &.back {
                @include account-buttons('secondary');
                line-height: 3.1rem;
                margin-top: $account-spacer;
                &:hover {
                    span {
                        color: $jpp-blue;
                    }
                }
            }
        }

        &.bottom {
            @include flex-container;
            justify-content: space-between;

            @media (max-width: $bp-mobile-l) {
                flex-direction: column;
                a.action {
                    width: 100%;
                    margin: 0 0 8px 0 !important;
                    &:nth-of-type(1) {
                        order: 1 !important;
                    }
                    &:nth-of-type(2) {
                        order: 2 !important;
                    }
                    &:nth-of-type(3) {
                        order: 3 !important;
                        margin-bottom: 0 !important;
                    }
                }
            }
            @media (min-width: $bp-laptop) {
                float: right;
                clear: right;
                width: 50%;
                max-width: 500px;
            }

            a {
                @include flex-item;
                flex: 1;

                &.back {
                    order: 1;
                    margin-top: 0;
                }

                &.print {
                    order: 2;
                    margin-top: 0;
                }

                &.order {
                    order: 3;
                    margin-top: 0;
                }
            }
        }
    }

    #ship {
        background:#fff;
        .block-title {
            width:100%;
            flex-basis:100%;
            padding-bottom:0;
        }
        .shipments {
            display:flex;
            flex-wrap:wrap;
        }
        .shipment {
            padding:16px 8px 8px 16px;
            background:#f9f9f9;
            border:1px solid #dfdfdf;
            margin-right:12px;
            width:calc(50% - 6px);
            @media (max-width:991px) {
                width:100%;
                margin-right:0;
            }
            &:only-child {
                width:100%;
                margin:0;
            }
            &:nth-of-type(2n) {
                margin-right:0;
            }
            &:nth-of-type(3), &:nth-of-type(4) {
                margin-top:12px;
            }
            .shipment-title {
                margin-bottom:8px;
                font-size:14px;
                color:#000;
                font-weight:800;
                text-transform:uppercase;
                span {
                    font-size:14px;
                    color:#555;
                    font-weight:600;
                    text-transform:none;
                    margin-left:4px;
                }
            }
            .shipment-info {
                font-size:14px;
                font-weight:600;
                color:#000;
                > div {
                    margin-bottom:4px;
                }
                label {
                    margin-right:4px;
                }
                .processing {
                    color:#004F9D;
                }
                .fulfilled {
                    color:#68BB5B;
                }
                .canceled {
                    color:#C81313;
                }
                .backordered {
                    color:#F57B21;
                }
            }
            .products {
                display:flex;
                flex-wrap:wrap;
                margin:0;
                margin-top:16px;
                .product {
                    width:56px;
                    height:56px;
                    background:#fff;
                    border:1px solid #dfdfdf;
                    overflow:hidden;
                    margin-right:8px;
                    margin-bottom:8px;
                    position:relative;
                    @include smooth;
                    &:hover {
                        border:1px solid $jpp-blue;
                    }
                    a {
                        position:absolute;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                    }
                    img {
                        width:40px;
                        max-height:40px;
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                    }
                }
            }
        }
    }
    #my-orders-table {
        p,
        span,
        ul,
        ul li {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }

        td {
            font-size: 1.4rem;
            font-weight: 600;

            &.col {
                &.price {
                    .unit-price,
                    .subtotal-price {
                        display: block;
                        clear: both;
                        span.blue {
                            color: $jpp-dark-blue;
                        }
                    }
                }

                &.name {
                    a:not(.inline-button) {
                        text-decoration: none !important;
                        color: inherit;
                    }
                    .inline-button {
                        margin-top:10px;
                        background:#fff;
                    }
                }
            }

            .product-badges {
                margin-top: 3px;

                .badge {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    display: inline-block;
                    padding: 0 5px;
                    vertical-align: top;
                }

                .badge-yellow {
                    color: $badge-yellow-text;
                    background: $badge-yellow;
                }
            }
        }

        th {
            font-size: 1.8rem;
            font-weight: 800;
            line-height: 2.4rem;

            &.col.name {
                padding-left:0;
                padding-top:0;
            }

            &.col.qty, &.col.price {
                padding-right:0;
                padding-top:0;
            }
        }

        .info {

            p {
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1.8rem;
                margin: 0;
            }
            input {
                height:28px;
                line-height:28px;
                border:none;
                width:60px;
            }
        }
        .backorder-message {
            margin-left: 112px;
            //clear: both;
            margin-top: 10px;
            @media (max-width: $bp-tablet) {
                margin-left: 77px;
            }
        }

        .image {
            float: left;
            width: 100px;
            height: auto;
            margin-right: 12px;
            text-align: center;
            border: 1px solid $jpp-grey;

            @media (max-width: $bp-tablet) {
                width: 65px;
                margin-bottom: $account-spacer;
            }

            img {
                display: inline-block;
                width: auto;
                height: 100px;
                vertical-align: top;

                @media (max-width: $bp-tablet) {
                    height: auto;
                }
            }
        }

        .product-item-name {
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            width:500px;
            max-width:100%;
            @media (max-width:1280px) {
                width:auto;
            }
        }

        dl {
            @include inline-dl;
        }

        tbody {
            th {
                border:none;
            }
            tr {
                td {
                    @media (min-width: $bp-laptop) {
                        padding: 24px 0;
                        border-top: 1px solid #ebebeb;
                    }

                    &.col {
                        &.name {
                            @media (max-width: #{$bp-laptop + 1}) {
                                border-top: 1px solid $jpp-grey;
                            }
                        }

                        @media (max-width: #{$bp-laptop + 1}) {
                            &::before {
                                content: none;
                            }
                        }
                        @media (min-width: $bp-laptop) {
                            &.qty {
                                min-width: 114px;
                            }
                            &.price {
                                min-width: 164px;
                            }
                        }
                    }
                }

                &:first-child {
                    td {
                        &.col {
                            &.name {
                                @media (max-width: #{$bp-laptop + 1}) {
                                    border-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .order-totals {

        .customer-container-title {
            padding-bottom: 20px !important;
            border-bottom: 1px solid $jpp-grey;

            @media (max-width: $bp-mobile-l) {
                padding-top: 8px;
                padding-bottom: 16px !important;
            }
        }

        tfoot {
            tr {
                &.discount {
                    td.amount {
                        color: $jpp-success-green;
                    }
                }

                th,
                td {
                    font-size: 1.6rem;
                    line-height: 2rem;
                    padding: 10px 0;
                    border-top: 0 !important;

                    strong {
                        font-weight: inherit;
                    }
                }

                .mark {
                    font-weight: 600;
                }

                .amount {
                    text-align: right;
                    span {
                        font-weight: 700;
                    }
                }

                .mark, .amount {
                    @media (max-width: $bp-mobile-l) {
                        font-size: 14px;
                    }
                }

                &.grand_total {
                    th, td {
                        padding-bottom: 0;
                    }

                    .amount {
                        span {
                            font-size: 2rem;
                            font-weight: 800;
                            line-height: 2.4rem;
                            color: $jpp-dark-blue;
                        }
                    }
                }
            }
        }
    }

    .order-details-items {
        margin-bottom: $account-spacer;
        padding-bottom: 0;
        border-bottom: 0;

        .order-totals {
            @media (min-width: $bp-laptop) {
                float: right;
                width: 50%;
                max-width: 500px;
            }
        }

        .table-wrapper {
            margin-bottom: $account-spacer;
            padding-bottom: 0;
            border-bottom: 0;
        }

        &::after {
            display: block;
            clear: both;
            width: 100%;
            content: ' ';
        }
    }

    .column {
        &.main {
            .block-order-details-view {

                @media (min-width: $bp-laptop) {
                    width:calc(100% - 306px);
                }
                @media (max-width:$bp-laptop) {
                    width:100%;
                }
                .box-content, address {
                    font-size:14px;
                }
                address {
                    padding-right:12px;
                }
                .block-content {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content:space-between;
                    @media (max-width:1200px) {
                        justify-content:flex-start;
                    }
                    > .box {
                        width:170px;
                        margin-bottom:0!important;
                        margin-right:16px;
                        @media (max-width:1200px) {
                            width:auto!important;
                        }
                        @media (max-width: $bp-laptop) {
                            &:nth-of-type(1), &:nth-of-type(2) {
                                margin-bottom:20px;
                            }
                        }
                    }

                    @media screen and (min-width: $bp-desktop-s) {
                        flex-wrap: nowrap;
                    }
                }

                &:not(.widget) {
                    @include customer-container;
                    font-size: 1.6rem;
                    clear: both;
                    margin-top: 0;

                    .block-title {
                        @include customer-container-title;
                        font-size: 2rem;
                        font-weight: 800;
                        padding-bottom: 0 !important;
                    }

                    .box-title {
                        span {
                            font-size:14px;
                            font-weight: 800;
                            text-transform: uppercase;
                        }
                    }
                }

                .box-order-billing-address {
                    @media (max-width:400px) {
                        margin-top:16px;
                    }
                }

                .methods {
                    display:flex;
                    flex-direction:column;
                    width:130px;
                    min-width:130px;
                    @media (min-width: 992px) and (max-width:1200px) {
                        flex-direction:row;
                        margin-top:16px;
                        width:100%;
                    }
                    @media (max-width:540px) {
                        flex-direction:row;
                        margin-top:16px;
                        width:100%;
                    }
                    @media (max-width:400px) {
                        flex-direction:column;
                    }
                    span.none {
                        font-size:12px;
                        color:#555;
                        line-height:14px;
                        display:inline-block;
                    }
                    > div {
                        width:100%;
                        @media (max-width:1200px) {
                            width:132px;
                            &:nth-of-type(1) {
                                margin-right:24px;
                            }
                        }
                        @media (max-width:400px) {
                            width:100%;
                        }
                        &:nth-of-type(1) {
                            margin-bottom:16px;
                        }
                    }
                }
            }
        }
    }
}
