// sass-lint:disable force-attribute-nesting no-qualifying-elements
.checkout-index-index {
    .page-wrapper {
        background: linear-gradient(to right, #fff 0%, #f9f9f9 50%, #fff 100%);
        @media (max-width: $bp-tablet) {
            background: $jpp-off-white;
        }

        .page-main {
            @media (min-width: ($bp-tablet - 1)) {
                width: $content-max;
                max-width: calc(100% - 32px);
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
    .checkout-message-wrapper {
        width:1000px;
        max-width:100%!important;
        margin:0 auto;
        .checkout-message {
            padding:16px;
            margin-bottom:12px;
            &.seasonal-handling {
                background:linear-gradient(45deg, rgba(0,107,180,0.14) 0%, rgba(0,157,136,0.08) 100%);
                p {
                    color:#004181;
                }
            }
        }
        p, span {
            margin:0;
            font-size:14px;
            line-height:18px;
        }
    }
    .global-banner-wrapper, .jpplus-top-banner {
        display:none !important;
    }
    .page-header {
        margin-bottom: -1px;
        border: 0;
        background-color:#0084C8;
        @media (min-width: $bp-laptop) {
            position: relative;
            width: 100%;
            max-width: $content-max;
            margin: 0 auto;
            margin-bottom: 0;
        }

        > .header {
            margin-bottom: 0;
            padding-top: $spacer * 1.5;

            @media (min-width: $bp-laptop) {
                position: absolute;
                top: 95px;
                left: 40px;
                width: 100%;
                max-width: 225px;
                padding-top: 0;
            }

            .logo-container {
                position: relative;
                z-index: 100;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (min-width: $bp-laptop) {
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    transform: translateY(-36px);
                }

                .logo {
                    margin: 0;

                    img {
                        max-width: 174px;
                    }

                    @media (min-width: $bp-laptop) {
                        width: 100%;
                        max-width: 100%;
                        padding-right: 0;
                        img {
                            max-width: 220px;
                        }
                    }
                    @media (max-width: $bp-laptop - 1) {
                        margin-top: -18px;
                        margin-left: -16px;
                        padding-top: 10px;
                        padding-bottom: 0;
                    }
                    @media (max-width: $bp-laptop) {
                        margin-left: 0;
                    }

                    a {
                        display: inline;
                    }
                }

                .continue-shopping {
                    @include unset-underline;
                    @include ease;
                    line-height: 14px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: auto;
                    text-align: right;
                    opacity: 0.68;

                    &:hover {
                        opacity: 1;
                    }

                    @media (max-width: $bp-mobile) {
                        top: -7px;
                        width: 62px;
                        height: 32px;
                    }
                    @media (min-width: $bp-laptop) {
                        z-index: 1;
                        top: auto;
                        bottom: -20px;
                        left: 5px;
                        max-width: 100%;
                        margin-top: 4px;
                        margin-right: 16px;
                    }

                    span {
                        line-height: 14px;
                        text-align: right;
                    }
                }
            }
        }

        .nav-toggle {
            display: none;

            &::before {
                color: $white;
            }
        }
    }

    .page-title-wrapper {
        @include unset-hidden-props;
        @include full-width;
        box-sizing: border-box;
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
        background:linear-gradient(to bottom, #0084C8 0%, #005480 100%);
        @media (max-width:$bp-tablet) {
            padding-top:68px;
            padding-bottom:56px;
        }
        @media (min-width: $bp-laptop) {
            padding-top: $spacer * 4;
            padding-bottom: $spacer * 4;
        }
        @media (max-width:480px) {
            padding:40px 0;
        }
        + .page.messages {
            position: relative;
            z-index: 1;
            transform: translateY(-26px);

            .message {
                margin: 0 0 18px;
            }
        }

        .page-title {
            display: block;
            margin: 0 auto;
            text-align: center;
            @media (max-width: $bp-laptop) {
                transform: translateY(-40px);
            }
            @media (min-width: $bp-laptop) {
                transform: translateY(-8px);
            }
            @media (max-width: $bp-tablet) {
                transform: translateY(-18px);
            }
            @media (max-width:480px) {
                transform:translateY(-13px);
            }
            span {
                color: $white;
            }
        }
    }

    .checkout-container {
        max-width: 1000px;
        margin: 0 auto;

        @media (max-width: $bp-tablet - 1) {
            display: flex;
            flex-wrap: wrap;
        }

        > .messages {
            @media (max-width: $bp-tablet - 1) {
                order: 10;
                width: 100%;
            }
        }

        > .authentication-wrapper {
            display: none;
        }

        > .opc-estimated-wrapper {
            display: none;
        }

        > .opc-wrapper {
            padding-right: ($spacer / 2) + 2px;
            @media (min-width: $bp-tablet) and (max-width: $bp-laptop) {
                width: 60%;
            }
            @media (max-width: $bp-tablet - 1) {
                order: 10;
                width: 100%;
                padding-right: 0;
            }

            #checkout-step-shipping {
                position: relative;
                min-height: 45px;

                #co-shipping-form {
                    & + .message, & + .message.error {
                        margin-top:10px;
                        margin-bottom:0;
                    }
                }
            }

            .phone-link {
                pointer-events:none;
                color:#353535;
                text-decoration: none !important;
            }

            .revert-checkout {
                text-decoration:none!important;
                order:99;
                span {
                    font-size:14px;
                    line-height:1.2;
                    text-align:center;
                    display:block;
                    width:400px;
                    max-width:100%;
                }
            }

            #change-address-popup {
                position: absolute;
                top: 0;
                right: 0;
                @media (max-width: $bp-tablet) {
                    top: -55px;
                }
                @media (max-width: 360px) {
                    top: -88px;
                }

                button.action {
                    width: 102px;
                    height: 36px;
                    padding: 0 20px;
                    border: 1px solid #dfdfdf;
                    border-radius: 0px;
                    background-color: $white;
                    @include ease;

                    span {
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 34px;
                        display:block;
                        text-transform: uppercase;
                        @media (max-width:480px) {
                            font-size:12px;
                        }
                    }

                    &:hover {
                        box-shadow: 0px 1px 4px rgba(2, 2, 2, 0.12);

                        span {
                            color: $jpp-blue;
                        }
                    }

                    &:active {
                        box-shadow: 0px 1px 4px rgba(2, 2, 2, 0.12);
                    }
                }
            }

            #hide-addresses {
                display: inline-block;
                float: none;
                margin-right: 30px;
                background-color: white;

                &.no-display {
                    display:none;
                }
                .action {
                    width: 102px;
                    height: 42px;
                    padding: 5px 20px;
                    border: 1px solid $jpp-grey;
                    border-radius: 0;
                    background-color: $white;
                    @include ease;
                    margin-top:12px;
                    span {
                        font-size: 14px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }

                    &:hover {
                        box-shadow: 0px 1px 12px rgba(2, 2, 2, 0.12);

                        span {
                            color: $jpp-blue;
                        }
                    }

                    &:active {
                        box-shadow: 0px 1px 12px rgba(2, 2, 2, 0.12);
                    }
                }
            }

            .shipping-address-items {
                &:not(.change-address-active) {
                    .shipping-address-item {
                        margin-bottom:0!important;
                    }
                }
                .shipping-address-item {
                    line-height: 20px;
                    padding-bottom:18px;
                    padding:19px 14px 17px;

                    &:before {
                        height: 100%;
                    }

                    &.not-selected-item.no-display {
                        display: none;
                    }

                    a, span {
                        font-size: 14px;
                    }

                    .edit-address-link {
                        display: inline-block;
                        width: 100%;
                        margin-top: 5px;
                        text-align: right;
                        transition:all .24s ease, height 0s, bottom 0s!important;
                    }
                    &.not-selected-item {
                        .edit-address-link {
                            bottom:23px;
                        }
                    }
                    a {
                        display: inline-block;
                        margin-top: 2px;
                        text-decoration: none !important;
                        &.phone-link {
                            pointer-events:none;
                            color:#353535;
                        }
                        + br + br {
                            display:none;
                        }
                    }

                    button.edit-address-link {
                        position: absolute;
                        right: 2px;
                        bottom: 3px;
                        width: 32px;
                        text-align: center;
                    }
                }

                .selected-item {
                    border-color: $jpp-blue;

                    &:after {
                        background: $jpp-blue;
                        height:22px;
                        width:22px;
                        margin-top:-1px;
                        right:-1px;
                        font-size:17px;
                        line-height:18px;
                        content: '✔';
                    }
                }

                .selected {
                    border-color: $jpp-blue;
                }

            }

            .new-address-popup {
                display: inline-block;
                float: left;
                margin-right: 12px;
                margin-bottom: 0;

                button {
                    @include button;
                    margin-bottom: 0;
                }
            }

        }

        .opc-summary-wrapper {
            @media (min-width: $bp-tablet) and (max-width: $bp-laptop) {
                width: 40%;
            }
        }

        .opc {
            display: flex;
            flex-wrap: wrap;
            position:relative;

            #shipping-method-form {
                min-width:100%;
            }

            .fieldset {

                &.address:last-child {
                    margin-bottom: 15px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                > .field {
                    &:not(.choice) {
                        > .control {
                            width: 100%;
                        }
                    }
                }
            }

            .field {
                ._with-tooltip {
                    input {
                        width: 100%;
                    }
                }
            }

            .field-tooltip {
                display: none;
            }

            .step-title {
                @include font-bold;
                font-size: 20px;
                margin-bottom: 20px;
                @media (max-width:480px) {
                    margin-bottom:14px;
                }
                @media (min-width: 640px) {
                    padding-bottom: 0;
                    border: 0;
                }
                @media (max-width:$bp-tablet - 1) {
                    font-size:16px;
                }
                &.sub-title {
                    font-size: 16px;
                    margin-bottom: 14px;
                    text-transform: uppercase;
                    @media (max-width:$bp-tablet - 1) {
                        font-size:14px;
                    }
                }
            }

            > .checkout-shipping-address,
            > .checkout-shipping-method,
            > .checkout-payment-method {
                width: 100%;
                margin-top: ($spacer / 2) + 2px;
                background: $white;
                box-shadow: 0 2px 16px 0 $box-shadow-light;
            }

            > .checkout-shipping-address {
                order: 0;
                width: 100%;
                margin-top: 0;
                margin-bottom: ($spacer / 2) + 2px;
                padding: 24px;
                @media (max-width:480px) {
                    padding:16px;
                }
                .action.primary.action-select-shipping-item {
                    line-height: 37.5px;
                    float: none;
                    height: 38px;
                    margin-top: 0;
                    padding: 0 16px !important;
                    transform: scale(0.8);
                    transform-origin: left;
                    border: 1px solid $jpp-blue;
                    background: transparent !important;

                    span {
                        color: $jpp-blue;
                    }

                    &:hover {
                        background: $jpp-blue !important;

                        span {
                            color: $white;
                        }
                    }
                }

                .step-content {
                    margin-bottom: 0;
                }

                .hidden-fields {
                    .note {
                        &::before {
                            display: none;
                        }
                    }

                    .actions-toolbar {
                        @media (min-width: $bp-tablet) {
                            margin-left: 0;
                        }
                    }

                    .secondary {
                        @media (min-width: $bp-tablet) {
                            float: none;
                            margin-top: 3px;
                            margin-left: $spacer;
                        }

                        .remind {
                            @include unset-underline;

                            span {
                                @include font-bold;
                                @include link;
                                @include unset-underline;
                                font-size: 12px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                .form-login {
                    max-width:100%!important;
                    margin-top:-10px;

                    input[type=email] + span.note {
                        span {
                            width:100%;
                        }
                    }
                    span.note {
                        span {
                            display:block;
                            font-size:14px;
                            line-height:1.4;
                            margin-bottom:12px;
                            width:234px;
                        }
                    }
                }
                .form-shipping-address {
                    margin-top:0;
                    margin-bottom:0;
                    max-width:100%!important;
                }

                #shipping-new-address-form {
                    @include clearfix;
                    @include grid-collapse;
                    margin-bottom:0!important;
                    margin-left:0;
                    margin-right:0;
                    div[name='shippingAddress.firstname'],
                    div[name='shippingAddress.lastname'],
                    div[name='shippingAddress.city'],
                    div[name='shippingAddress.region'],
                    div[name='shippingAddress.region_id'],
                    div[name='shippingAddress.postcode'],
                    div[name='shippingAddress.country_id'] {
                        @include grid-width(12);

                        @media (min-width: 600px) and (max-width: 767px) {
                            @include grid-width(6);
                        }

                        @media (min-width: $bp-laptop) {
                            @include grid-width(6);
                        }

                        label.label {
                            left: 10px;
                        }
                    }

                    div[name='shippingAddress.company'],
                    div[name='shippingAddress.telephone'] {
                        label.label {
                            left: 10px;
                        }
                    }

                    div[name='shippingAddress.company'],
                    .field.street,
                    div[name='shippingAddress.telephone'] {
                        @include grid-width(12);
                    }
                    div[name='shippingAddress.telephone'] {
                        margin-bottom:0;
                    }
                    fieldset.street {
                        margin-bottom:0;
                    }
                }
            }

            > .checkout-shipping-method,
            > .checkout-payment-method {
                order: 20;
                box-sizing: border-box;
                width: 100%;

                @media (max-width: $bp-tablet - 1) {
                    order: 40;
                }

                .step-content {
                    margin-bottom: 0;
                }

                .table-checkout-shipping-method {
                    tbody {
                        td {
                            font-size: 16px;

                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            #checkout-step-shipping_method {
                margin-bottom:6px;
                .table-checkout-shipping-method {
                    transform: translateX(-7px);

                    .col-method {
                        .intl-notice {
                            font-size: 14px;
                            color: $jpp-grey-2;
                        }
                    }
                }
            }

            > .checkout-payment-method {
                margin-top: 0;
                padding-top: 0;
                padding-bottom: 0;
                @media (max-width: $bp-tablet - 1) {
                    .step-title {
                        padding: 0 !important;
                    }
                    .payment-methods {
                        max-width: 100% !important;
                        margin: 0 !important;
                    }
                }

                .form {
                    &.payments {
                        padding: 24px;

                        @media (min-height: 640px) {
                            margin-top: 0;
                        }
                        @media (max-width:480px) {
                            padding:16px;
                        }
                        fieldset legend + br {
                            display:none!important;
                        }
                        .step-title {
                            margin-bottom: 13px;
                        }
                        > .opc-payment {
                            margin-bottom:-8px;
                        }
                    }
                }

                .ccard {
                    .fields {
                        .select {
                            padding-right: 5px;
                            padding-left: 15px;
                        }
                    }
                }

                .payment-method {
                    display: block;
                    .tax-exemption-form + .actions-toolbar {
                        display:none;
                    }
                    .payment-method-content #review-buttons-container {
                        display:none;
                    }
                    #paypal-express-in-context-button {
                        width:322px;
                        height:130px;
                        margin:0 auto 0 0;
                        max-width:calc(100% - 24px);
                    }
                    .payment-method-title,
                    .payment-method-content {
                        padding-right: 0;
                        padding-left: 0;
                    }

                    .payment-method-title {
                        padding: 20px 0;
                        padding-bottom:24px;
                        border-color: #e9e9e9;
                        position:relative;
                        @media (max-width:480px) {
                            padding:12px 0;
                        }
                        label {
                            span {
                                font-weight:700;
                                @media (max-width:480px) {
                                    font-size:14px;
                                }
                            }
                        }
                    }

                    .payment-method-content {
                        padding-bottom:0;
                        select {
                            option:first-of-type {
                                color:#222!important;
                            }
                        }
                        .payment-method-billing-address {
                            .actions-toolbar {
                                margin-bottom:24px;
                                .action-cancel {
                                    @include button('secondary');
                                    margin-top:0;
                                }
                                .action-update {
                                    @include button('primary');
                                }
                            }
                        }
                    }
                    .payment-icon {
                        height: 28px;
                        @media (max-width:480px) {
                            height:20px;
                            transform:translateY(-1px);
                        }
                    }

                    #purchaseorder-form {
                        &,
                        & + .payment-method-billing-address {
                            clear: both;
                        }
                    }

                    a {
                        text-decoration: none !important;

                        &.action-help {
                            font-size: 14px;
                            @media (max-width:480px) {
                                position:absolute;
                                bottom:0;
                                right:0;
                                font-size:10px;
                            }
                        }
                    }

                    .checkout-billing-address {
                        .field-select-billing {
                            margin-bottom: 15px;
                        }

                        .choice {
                            margin-bottom: 10px;
                            label span {
                                font-weight:600;
                                @media (max-width:480px) {
                                    font-size:13px;
                                }
                            }
                        }
                    }

                    .billing-address-details {
                        font-size: 16px;
                        @media (max-width:480px) {
                            font-size:14px;
                        }
                        a {
                            color:#353535;
                            pointer-events:none;
                        }
                        * {
                            font-size: 16px;
                            @media (max-width:480px) {
                                font-size:14px;
                            }
                        }
                        br + br {
                            display:none;
                        }
                    }
                    #tax-form {
                        label {
                            margin-bottom:0;
                            span {
                                @media (max-width:480px) {
                                    font-size:13px;
                                    font-weight:600;
                                }
                            }
                        }
                    }

                    .actions-toolbar {
                        margin-top:0;
                        margin-left: 0;
                        &:not(#paypal-express-in-context-button) {
                            @media (max-width: $bp-tablet - 1) {
                                display: none !important;
                            }
                        }

                        div.primary {
                            float: left;
                        }

                        button.primary.checkout {
                            width: 285.33px;
                            max-width: 100%;
                            height: 42px;
                            padding: 0 16px !important;
                        }
                    }
                }
                .payment-group {
                    .payment-method:last-of-type {
                        .payment-method-content {
                            padding-bottom:24px!important;
                        }
                    }
                }
                .opc-payment-additional {
                    > .field {
                        margin-bottom: 0;
                    }
                }
            }

            > .shipping-information {
                order: 15;
                width: 100%;

                @media (max-width: $bp-tablet - 1) {
                    order: 35;
                }

                .ship-to,
                .ship-via {
                    box-sizing: border-box;
                    width: 100%;
                    margin-top: $spacer;
                    padding: #{$spacer * 1.2};
                    box-shadow: 0 2px 16px 0 $box-shadow-light;

                    a {
                        font-size: 14px;
                        text-decoration: none !important;
                    }
                }

                .shipping-information-title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: $spacer;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $jpp-grey;

                    span {
                        @include font-bold;
                        font-size: 20px;
                        @media (max-width:$bp-tablet - 1) {
                            font-size:16px;
                        }
                    }
                }

                .action-edit {
                    line-height: normal;
                    padding: 0;
                    vertical-align: baseline;
                    border: 0;
                    background-color: transparent;

                    span {
                        font-size: 14px;
                        text-transform: uppercase;
                        color: $jpp-blue;
                    }
                }

                .shipping-information-content {
                    font-size: 16px;
                }
            }
        }

        .discount-code {
            @include sidebar-box;
            width: 100%;
            padding: 18px 24px;
            transition:height 0.4s ease;
            @media (max-width: $bp-tablet - 1) {
                order: 35;
            }

            &:not(._active) {
                //height: 60px;
                .control {
                    transition:0.24s ease;
                    opacity:0;
                }
            }

            &._active {
                padding: 18px 24px 24px;
                @media (max-width:480px) {
                    padding:16px;
                }
                .control {
                    opacity:1;
                }
            }

            span {
                user-select: none;
            }

            .payment-option-title {
                position: relative;
                height: 20px;
                margin-bottom: 0;
                @media (max-width:480px) {
                    transform:translateY(-2px);
                }
                &:hover {
                    cursor: pointer;
                }
            }

            .payment-option-content {
                label.label {
                    span {
                        font-size: 14px;
                        display: block;
                        transform: translateY(18px);
                    }
                }

                .messages {
                    transform: translateY(16px);
                }
            }

            .action-toggle {
                @include icon-after($icon-plus-thin, $jpp-blue, 14px);

                &::after {
                    line-height: 17px;
                    position: absolute;
                    top: calc(50% + 2px);
                    right: 0;
                    float: right;
                    box-sizing: border-box;
                    width: 19px;
                    height: 19px;
                    transform: translateY(-50%);
                    text-align: center;
                    vertical-align: baseline;
                    border: 1px solid $jpp-blue;
                    border-radius: 50%;
                }

                span {
                    @include font-bold;
                    font-size: 20px;
                    @media (max-width:$bp-tablet - 1) {
                        font-size:16px;
                    }
                }
            }

            .form-discount {
                max-width: 100%;
                .actions-toolbar{
                    @media(max-width: 768px){
                        button.action-cancel{
                            min-height: 41px;
                            position: relative;
                            margin-top: 25px;
                            span{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .control {
                position: relative;
                margin-top: $spacer;
            }

            .input-text {
                position: relative;
                bottom: 0;
                left: 0;
                padding-right: 90px;
            }

            .action-apply {
                position: absolute;
                top: 5px;
                right: 4px;
                padding: 2px 20px;
                border-color: $jpp-blue;
                border-radius: 0;
                background-color: $jpp-blue;

                span {
                    @include font-bold;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: $white;
                }
            }

            .actions-toolbar {
                position: relative;
                @media (min-width: $bp-tablet) {
                    margin-left: 0;
                }
            }

            .action-cancel {
                @include button('secondary');
                position: absolute;
                right: 0;
                bottom: 5px;
                height: 30px;
                padding: 0 18px !important;

                span {
                    font-size: 12px;
                }
            }
        }

        .message-error {
            padding: 15px;
            p {
                font-size:14px;
                line-height:1.4;
            }
        }
        .message-success{
            padding: 15px;
        }
    }

    .fieldset {
        .field,
        > .fields > .field {
            &:not(.choice) {
                margin-bottom:0;
            }
        }
    }

    #s2k_payment-form {
        .jpp-component-checkbox {
            margin-top:15px;
        }
    }
    #co-shipping-form {
        & > .ship-here {
            margin-top:24px;
        }
    }
    #customer-email-fieldset {
        .jpp-component-input.email {
            &.busy::after {
                content:'Checking for existing account...';
                position:absolute;
                display:inline-block;
                color:$jpp-success-green !important;
                @include small-typography;
                top:12px;
                right:0;
            }
        }
    }

    .billing-address-details {
        position: relative;
        display: inline-block;
        padding-right: 24px;
    }

    .payment-method-billing-address .edit-address-link {
        position: absolute;
        right: 2px;
        bottom: 3px;
        width: 32px;
        text-align: center;
        margin-top:5px;
        display:inline-block;
    }

    .grecaptcha-badge {
        bottom:90px!important;
        z-index:1;
        @media (max-width:991px) {
            bottom:0!important;
            position:relative!important;
            margin-top:-60px;
            transform:translateX(15px) translateY(-2vw) scale(0.8)!important;
            right:0!important;
            &:hover {
                right:0!important;
                margin-top:-60px;
                transform:translateX(15px) translateY(-2vw) scale(0.8)!important;
            }
        }
    }
}
