.magento_reward-customer-info { // sass-lint:disable-line class-name-format

    .block-reward-history,
    .block-reward-info {
        @include customer-container;

        .block-title {
            strong {
                display: none;
            }
            @media (min-width: $bp-tablet) {
                @include customer-container-title;

                strong {
                    display: block;
                }
            }
        }

        table {
            @include account-table;

            td {
                &.date {
                    small {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .block-reward-info {
        position:relative;
        .block-title {
            margin-bottom:0!important;
            padding:0!important;
            strong {
                display:none;
            }
        }
        .points-balance {
            padding:24px 16px;
            background:#FEF2E8;
            background:linear-gradient(45deg, #01509C 0%, #01879C 100%);
            border-radius:8px;
            margin-bottom:24px;
            order:1;
            min-height:200px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            position:relative;
            z-index:1;

            .background-pattern {
                background-color: #0088ce;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%2300507a' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
                opacity:0.08;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                z-index:1;
            }
            .points-notice {
                color:#fff;
                font-size:14px;
                text-align:center;
                z-index:2;
                font-weight:400;
                opacity:0.75;
                margin:2px auto 0;
            }
        }
        .reward-balance {
            color: inherit;
            font-size: 1.6rem;
            line-height:26px;
            text-align: center;
            text-transform: uppercase;
            font-weight:400;
            font-size:16px;
            color:#fff;
            z-index:2;
            text-shadow:0px 1px 4px rgba(2,2,2,0.4);
            margin:0;
            .balance {
                color:#fff;
                display: block;
                font-size:30px;
                font-weight: 800;
                letter-spacing: 0;
                line-height: 2.8rem;
                margin-bottom:0;
                position:relative;
                span {
                    font-size: inherit;
                    font-weight:inherit;
                    color:#fff;
                }
            }
        }
        .rewards-logo {
            z-index:2;
            position:absolute;
            bottom:8px;
            right:8px;
            opacity:1;
            width:84px;
            filter:drop-shadow(0px 1px 2px rgba(2,2,2,0.54));
        }
        
        .rewards-content {
            order:2;
            position:relative;
            @media (max-width:768px) {
                display:flex;
                flex-direction:column;
            }
            h4 {
                margin:0 auto 6px;
                text-transform:none;
                font-size:22px;
                order:1;
            }
            p {
                order:2;
            }
            .rewards-graphic {
                width:722px;
                max-width:100%;
                display:block;
                margin:24px 0;
                order:3;
                #SpentEarned {
                    transform:translateY(10px);
                }
                @media (max-width:991px) {
                    display:none;
                }
            }
            .rewards-graphic-mobile {
                width:324px;
                max-width:100%;
                display:block;
                margin:24px 0;
                order:3;
                @media (min-width:992px) {
                    display:none;
                }
            }
            svg text {
                font-weight:800;
            }
            .more {
                margin-left: 0 !important;
                text-decoration:none!important;
                text-transform:uppercase;
                font-weight:800;
                font-size:14px;
                order:4;
                cursor:pointer;
                @media (min-width: $bp-tablet) {
                    float: right;
                    position:absolute;
                    top:0px;
                    right:0px;
                }
                @media (max-width:768px) {
                    margin-top:16px;
                }
            }
        }

        .reward-rates, .reward-limit, .reward-expiring {
            > strong, > p {
                margin-bottom:6px;
                &:last-child {
                    margin-bottom:0;
                }
            }

            .message span {
                font-size:14px!important;
            }
            p, strong.title {
                font-size:16px!important;
            }

        }
        .reward-rates {
            order:3;
            opacity:0.8;
            margin-bottom:20px;
        }

        .reward-limit {
            order:2;
            margin-bottom:20px;
            p {
                color:#004F9D;
            }
        }

        .reward-expiring {
            order:4;
            opacity:0.8;
        }

        .block-content {
            display:flex;
            flex-direction:column;
        }
    }

    .block-reward-history {
        .block-title {
            margin-bottom:0!important;
        }
        & + .actions-toolbar {
            display: none;
        }
        .block-content {
            > .pager:nth-of-type(1) {
                transform:translateY(-6px);
            }
        }
        .pager {
            .toolbar-amount {
                line-height:32px;
                margin-right:20px;
                span {
                    font-size:12px;
                }
            }
            .pages {
                .items {
                    li {
                        margin-right:8px;
                        span {
                            font-weight:800!important;
                        }
                        a {
                            text-decoration:none!important;
                        }
                    }
                }
            }
            .pages-item-previous, .pages-item-next {
                display:none;
            }
            .limiter {
                display:none;
            }
        }
    }

    .reward-eligibility-form {
        padding-bottom:45px;
        .hs-form {
            .hs-fieldtype-text,
            .hs-fieldtype-textarea,
            .hs-fieldtype-select {
                margin-bottom: 0;
                input {
                    box-shadow: none;
                }
            }
            .hs-fieldtype-select {
                select {
                    @include jpp-select();
                }
            }
            .hs_services_used,
            .hs_market {
                margin-top: 25px;
            }
        }
        & + .actions-toolbar {
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            margin-top:-80px;
            background: transparent;
            box-shadow: none;
            .secondary {
                float:left;
            }
            &:before {
                content : none;
            }
        }
    }

    .hbspt-form {
        .hs_rewards_program_sign_up_consent {
            display:flex;
            position:relative;
            padding:16px;
            border-radius:4px;
            border:2px solid #0088ce;
            flex-wrap:wrap;
            div.input {
                order:1;

                .hs-form-booleancheckbox-display {
                    display:flex;
                    span {
                        position:absolute;
                        top:3px;
                        right:6px;
                        color:#0088ce;
                    }
                }
            }
            legend {
                font-size:14px;
                line-height:18px;
                margin-top:2px;
                font-weight:600;
                color:#000;
                font-family:'proxima-nova';
                order:2;
                width:calc(100% - 40px);
            }

            .hs-error-msgs {
                order:3;
                li {
                    height:15px;
                    transform:translateX(24px);
                }
            }
        }

        .legal-consent-container {
            .hs-richtext p {
                margin-bottom:0;
                margin-top:4px;
            }
        }

        div.actions {
            margin-top:0;
            input {
                background-image:none;
                box-shadow:none;
                min-width:180px;
            }
        }
        .hs_error_rollup {
            li {
                transform:translateX(-10px);
                label {
                    font-family:'proxima-nova';
                }
            }
        }
    }

    .table-reward-history {
        tbody {
            transform:translateY(12px);
        }
    }

    .rewards-faq {
        position:fixed;
        left:50%;
        transform:translateX(-50%) translateY(24px);
        width:900px;
        max-width:calc(100% - 32px);
        pointer-events:none;
        top:5vh;
        background:#fff;
        padding:32px;
        transition:0.32s;
        max-height:calc(100vh - 10vh);
        overflow-y:scroll;
        z-index:9999;
        opacity:0;
        box-shadow:0px 0px 24px rgba(2,2,2,0.4);
        @media (max-width:768px) {
            padding:24px;
        }
        @media (max-width:480px) {
            padding:16px;
        }
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            background: #0088ce;
        }
        &.active {
            pointer-events:auto;
            transform:translateX(-50%) translateY(0);
            opacity:1;
        }

        .close-modal {
            position:absolute;
            top:16px;
            right:16px;
            svg {
                width:16px;
                height:16px;
            }
        }
        h3 {
            color:#0088ce;
            text-align:center;
            margin:22px 0 48px;
        }
        h5 {
            font-size:20px;
            text-transform:none;
            margin-bottom:12px;
        }

        div.small {
            p, a {
                font-size:13px;
                text-align:center;
                opacity:0.7;
            }
            a {
                opacity:1;
            }
        }
    }
    .rewards-modal-bg {
        position:fixed;
        width:100vw;
        height:100vw;
        top:0;
        left:0;
        background:rgba(2,2,2,0.4);
        pointer-events:none;
        transition:0.24s;
        z-index:999;
        opacity:0;
        &.active {
            pointer-events:auto;
            opacity:1;
        }
    }
}
