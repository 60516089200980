// Helper function for the normalize() mixin.
@function _normalize-include($section, $exclude: null) {
    // Initialize the global variables needed by this function.
    @if not global_variable_exists(_normalize-include) { // sass-lint:disable-line function-name-format
        $_normalize-include: () !global;
        $_normalize-exclude: () !global;
    }
    // Since we are given 2 parameters, set the global variables.
    @if $exclude != null {
        $include: $section;
        // Sass doesn't have static variables, so the work-around is to stuff these
        // values into global variables so we can access them in future calls.
        $_normalize-include: if(type-of($include) == 'list', $include, ($include)) !global;
        $_normalize-exclude: if(type-of($exclude) == 'list', $exclude, ($exclude)) !global;
        @return true;
    }

    // Check if $section is in the $include list.
    @if index($_normalize-include, $section) {
        @return true;
    }
    // If $include is set to (all), make sure $section is not in $exclude.
    @else if not index($_normalize-exclude, $section) and index($_normalize-include, all) {
        @return true;
    }
    @return false;
}

@mixin normalize($include: (all), $exclude: ()) {
    // Initialize the helper function by passing it this mixin's parameters.
    $init: _normalize-include($include, $exclude);

    // If we've customized any font variables, we'll need extra properties.
    @if $base-line-height != 24px or $base-unit != 'em' or $h2-font-size != 1.5 * $base-font-size or $h3-font-size != 1.17 * $base-font-size or $h4-font-size != 1 * $base-font-size or $h5-font-size != .83 * $base-font-size or $h6-font-size != .67 * $base-font-size {
        $normalize-vertical-rhythm: true !global;
    }

    //! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss

    @if _normalize-include(document) {
        // Document
        // ==========================================================================
        //
        // 1. Correct the line height in all browsers.
        // 2. Prevent adjustments of font size after orientation changes in
        //    IE on Windows Phone and in iOS.
        //

        html {
            @if $base-font-family {
                // Change the default font family in all browsers (opinionated).
                font-family: $base-font-family;
            }
            @if $base-font-size != 16px or $normalize-vertical-rhythm {
                // Correct old browser bug that prevented accessible resizing of text
                // when root font-size is set with px or em.
                font-size: ($base-font-size / 16px) * 100%;
            }
            @if $normalize-vertical-rhythm {
                line-height: ($base-line-height / $base-font-size) * 1em; // 1
            }
            @else {
                line-height: 1.15; // 1
            }
            -ms-text-size-adjust: 100%; // sass-lint:disable-line no-vendor-prefixes
            -webkit-text-size-adjust: 100%; // sass-lint:disable-line no-vendor-prefixes
        }
    }

    @if _normalize-include(sections) {
        // Sections
        // ==========================================================================

        //
        // Remove the margin in all browsers (opinionated).
        //

        body {
            margin: 0;
        }

        //
        // Add the correct display in IE 9-.
        //

        article,
        aside,
        footer,
        header,
        nav,
        section {
            display: block;
        }

        //
        // Correct the font size and margin on `h1` elements within `section` and
        // `article` contexts in Chrome, Firefox, and Safari.

        h1 {
            @include normalize-font-size($h1-font-size);
            @if $normalize-vertical-rhythm {
                @include normalize-line-height($h1-font-size);
            }

            @if $normalize-vertical-rhythm {
                // Set 1 unit of vertical rhythm on the top and bottom margins.
                @include normalize-margin(1 0, $h1-font-size);
            }
            @else {
                margin: .67em 0;
            }
        }

        @if $normalize-vertical-rhythm {
            h2 {
                @include normalize-font-size($h2-font-size);
                @include normalize-line-height($h2-font-size);
                @include normalize-margin(1 0, $h2-font-size);
            }

            h3 {
                @include normalize-font-size($h3-font-size);
                @include normalize-line-height($h3-font-size);
                @include normalize-margin(1 0, $h3-font-size);
            }

            h4 {
                @include normalize-font-size($h4-font-size);
                @include normalize-line-height($h4-font-size);
                @include normalize-margin(1 0, $h4-font-size);
            }

            h5 {
                @include normalize-font-size($h5-font-size);
                @include normalize-line-height($h5-font-size);
                @include normalize-margin(1 0, $h5-font-size);
            }

            h6 {
                @include normalize-font-size($h6-font-size);
                @include normalize-line-height($h6-font-size);
                @include normalize-margin(1 0, $h6-font-size);
            }
        }
    }

    @if _normalize-include(grouping) {
        // Grouping content
        // ==========================================================================

        @if $normalize-vertical-rhythm {
            //
            // Set 1 unit of vertical rhythm on the top and bottom margin.
            //

            blockquote {
                @include normalize-margin(1 $indent-amount);
            }

            dl,
            ol,
            ul {
                @include normalize-margin(1 0);
            }

            //
            // Turn off margins on nested lists.

            ol,
            ul {
                ol,
                ul {
                    margin: 0;
                }
            }

            dd {
                margin: 0 0 0 $indent-amount;
            }

            ol,
            ul {
                padding: 0 0 0 $indent-amount;
            }
        }

        //
        // Add the correct display in IE 9-.
        //

        figcaption,
        figure {
            display: block;
        }

        //
        // Add the correct margin in IE 8.

        figure {
            @if $normalize-vertical-rhythm {
                @include normalize-margin(1 $indent-amount);
            }
            @else {
                margin: 1em $indent-amount;
            }
        }

        //
        // 1. Add the correct box sizing in Firefox.
        // 2. Show the overflow in Edge and IE.

        hr {
            box-sizing: content-box; // 1
            height: 0; // 1
            overflow: visible; // 2
        }

        //
        // Add the correct display in IE.

        main {
            display: block;
        }

        @if $normalize-vertical-rhythm {
            //
            // Set 1 unit of vertical rhythm on the top and bottom margin.

            p,
            pre {
                @include normalize-margin(1 0);
            }
        }

        //
        // 1. Correct the inheritance and scaling of font size in all browsers.
        // 2. Correct the odd `em` font sizing in all browsers.

        pre {
            font-family: monospace, monospace; // 1
            font-size: 1em; // 2
        }
    }

    @if _normalize-include(links) {
        // Links
        // ==========================================================================

        //
        // 1. Remove the gray background on active links in IE 10.
        // 2. Remove gaps in links underline in iOS 8+ and Safari 8+.

        a {
            -webkit-text-decoration-skip: objects;  // sass-lint:disable-line no-vendor-prefixes
            background-color: transparent; // 1
        }
    }

    @if _normalize-include(text) {
        // Text-level semantics
        // ==========================================================================

        //
        // 1. Remove the bottom border in Chrome 57- and Firefox 39-.
        // 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.

        abbr[title] { -webkit-text-decoration-skip: objects;  // sass-lint:disable-line no-vendor-prefixes force-attribute-nesting no-qualifying-elements
            border-bottom: none; // sass-lint:disable-line border-zero
            text-decoration: underline; // 2
            text-decoration: underline dotted; // sass-lint:disable-line no-duplicate-properties
        }

        //
        // Prevent the duplicate application of `bolder` by the next rule in Safari 6.
        //

        b,
        strong {
            font-weight: inherit;
        }

        //
        // Add the correct font weight in Chrome, Edge, and Safari.
        //

        b,
        strong {
            font-weight: bolder;
        }

        //
        // 1. Correct the inheritance and scaling of font size in all browsers.
        // 2. Correct the odd `em` font sizing in all browsers.

        code,
        kbd,
        samp {
            font-family: monospace, monospace; // 1
            font-size: 1em; // 2
        }

        //
        // Add the correct font style in Android 4.3-.

        dfn {
            font-style: italic;
        }

        //
        // Add the correct background and color in IE 9-.

        mark {
            background-color: #ff0; // sass-lint:disable-line no-color-literals
            color: #000; // sass-lint:disable-line no-color-literals
        }

        //
        // Add the correct font size in all browsers.

        small {
            font-size: 80%;
        }

        //
        // Prevent `sub` and `sup` elements from affecting the line height in
        // all browsers.

        sub,
        sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
        }

        sub {
            bottom: -.25em;
        }

        sup {
            top: -.5em;
        }
    }

    @if _normalize-include(embedded) {
        // Embedded content
        // ==========================================================================

        //
        // Add the correct display in IE 9-.

        audio,
        video {
            display: inline-block;
        }

        //
        // Add the correct display in iOS 4-7.

        audio:not([controls]) { // sass-lint:disable-line force-pseudo-nesting
            display: none;
            height: 0;
        }

        //
        // Remove the border on images inside links in IE 10-.

        img {
            border-style: none;
        }

        //
        // Hide the overflow in IE.

        svg:not(:root) { // sass-lint:disable-line force-pseudo-nesting
            overflow: hidden;
        }
    }

    @if _normalize-include(forms) {
        // Forms
        // ==========================================================================

        //
        // 1. Change the font styles in all browsers (opinionated).
        // 2. Remove the margin in Firefox and Safari.

        button,
        input,
        optgroup,
        select,
        textarea {
            font-family: if($base-font-family, $base-font-family, sans-serif); // 1
            font-size: 100%; // 1
            @if $normalize-vertical-rhythm {
                line-height: ($base-line-height / $base-font-size) * 1em; // 1
            }
            @else {
                line-height: 1.15; // 1
            }
            margin: 0; // 2
        }

        //
        // Show the overflow in IE.

        button {
            overflow: visible;
        }

        //
        // Remove the inheritance of text transform in Edge, Firefox, and IE.
        // 1. Remove the inheritance of text transform in Firefox.

        button,
        select { // 1
            text-transform: none;
        }

        //
        // 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
        //    controls in Android 4.
        // 2. Correct the inability to style clickable types in iOS and Safari.


        button,
        html [type='button'], // 1
        [type='reset'],
        [type='submit'] {
            -webkit-appearance: button; color: inherit; // sass-lint:disable-line no-vendor-prefixes one-declaration-per-line
        }

        button,
        [type='button'],
        [type='reset'],
        [type='submit'] {

            //
            // Remove the inner border and padding in Firefox.

            &::-moz-focus-inner { // sass-lint:disable-line no-vendor-prefixes
                border-style: none;
                padding: 0;
            }

            //
            // Restore the focus styles unset by the previous rule.

            &:-moz-focusring { // sass-lint:disable-line no-vendor-prefixes
                outline: 1px dotted ButtonText;
            }
        }

        //
        // Show the overflow in Edge.

        input {
            overflow: visible;
        }

        //
        // 1. Add the correct box sizing in IE 10-.
        // 2. Remove the padding in IE 10-.

        [type='checkbox'],
        [type='radio'] {
            box-sizing: border-box; // 1
            padding: 0; // 2
        }

        //
        // Correct the cursor style of increment and decrement buttons in Chrome.

        [type='number']::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes force-pseudo-nesting
        [type='number']::-webkit-outer-spin-button { // sass-lint:disable-line no-vendor-prefixes force-pseudo-nesting
            height: auto;
        }

        //
        // 1. Correct the odd appearance in Chrome and Safari.
        // 2. Correct the outline style in Safari.

        [type='search'] {
            -webkit-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
            outline-offset: -2px; // 2

            //
            // Remove the inner padding and cancel buttons in Chrome and Safari on macOS.

            &::-webkit-search-cancel-button, // sass-lint:disable-line no-vendor-prefixes
            &::-webkit-search-decoration { // sass-lint:disable-line no-vendor-prefixes
                -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
            }
        }

        //
        // 1. Correct the inability to style clickable types in iOS and Safari.
        // 2. Change font properties to `inherit` in Safari.

        ::-webkit-file-upload-button { // sass-lint:disable-line no-vendor-prefixes
            -webkit-appearance: button; // sass-lint:disable-line no-vendor-prefixes
            font: inherit; // 2
        }

        //
        // Correct the padding in Firefox.

        fieldset {
            padding: .35em .75em .625em;
        }

        //
        // 1. Correct the text wrapping in Edge and IE.
        // 2. Correct the color inheritance from `fieldset` elements in IE.
        // 3. Remove the padding so developers are not caught out when they zero out
        //    `fieldset` elements in all browsers.

        legend {
            box-sizing: border-box; // 1
            color: inherit; // 2
            display: table; // 1
            max-width: 100%; // 1
            padding: 0; // 3
            white-space: normal; // 1
        }

        //
        // 1. Add the correct display in IE 9-.
        // 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.

        progress {
            display: inline-block; // 1
            vertical-align: baseline; // 2
        }

        //
        // Remove the default vertical scrollbar in IE.

        textarea {
            overflow: auto;
        }
    }

    @if _normalize-include(interactive) {
        // Interactive
        // ==========================================================================

        //
        // Add the correct display in Edge, IE, and Firefox.

        details {
            display: block;
        }

        //
        // Add the correct display in all browsers.

        summary {
            display: list-item;
        }

        //
        // Add the correct display in IE 9-.

        menu {
            display: block;

            @if $normalize-vertical-rhythm {
                //
                // 1. Set 1 unit of vertical rhythm on the top and bottom margin.
                // 2. Set consistent space for the list style image.

                @include normalize-margin(1 0); // 1
                padding: 0 0 0 $indent-amount; // 2

                //
                // Turn off margins on nested lists.

                menu &,
                ol &,
                ul & {
                    margin: 0;
                }
            }
        }
    }

    @if _normalize-include(scripting) {
        // Scripting
        // ==========================================================================

        //
        // Add the correct display in IE 9-.

        canvas {
            display: inline-block;
        }

        //
        // Add the correct display in IE.

        template {
            display: none;
        }
    }

    @if _normalize-include(hidden) {
        // Hidden
        // ==========================================================================

        //
        // Add the correct display in IE 10-.

        [hidden] {
            display: none;
        }
    }
}
