.catalog-category-view.page-with-filter { 
    .sidebar-main {
        width: 25%;
        padding-right:16px;
        @media (min-width:$bp-desktop) {
            padding-right:2%;
        }
    }

}

.catalog-category-view {
    background:#f9f9f9;
    .category-description {
        > div > div {
            padding:0!important;
        }
        div[data-content-type='row'], div[data-appearance='contained'], div[data-content-type='row'][data-appearance='contained'] {
            max-width:100%!important;
        }

        .jpp-promo-tray {
            .promotions-list {
                padding-top:0!important;
            }
        }
    }
    .page-bottom {
        padding:0!important;
    }
    .category-footer {
        > div > div {
            padding:0!important;
        }
        > div, div[data-content-type="row"], div[data-content-type="row"][data-appearance="contained"] {
            max-width:100%!important;
            width:100%;
        }
    }
}

.block.filter { 
    &.filter-active {
        .filter-title {
            strong {
                &:before {
                    content: '-';
                }
            }
        }
    }
    .filter-content {
        &[aria-hidden="true"] {
            @media (max-width: ($bp-tablet - 1)) {
                display: none;
            }
        }
    }

    .filter-title {
        border: 1px solid $jpp-light-grey;
        cursor: pointer;
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: center;

        @media (min-width: $bp-tablet) {
            display: none;
        }

        strong {
            &:before {
                content: '+';
                margin-right: 4px;
            }
        }
    }

    .block-subtitle {
        &.filter-current-subtitle {
            background: $jpp-blue;
            box-sizing: border-box;
            color: $white;
            display: block;
            font-weight: 700;
            height: 40px;
            line-height: 32px;
            margin-bottom: 0;
            padding: 4px 8px;
            text-transform: uppercase;

            .action.clear { 
                float: right;
                line-height: 32px;
                text-transform: none;

                span {
                    color: $white;
                    font-weight: 600;
                }
            }
        }

        &.filter-subtitle {
            display: none;
        }
    }

    a {
        text-decoration: none !important;
    }
}

.filter-current {
    .items {
        border: 1px solid $jpp-light-grey;
        border-top: 0;
        margin-bottom: 16px;
        padding: 12px 8px 2px;
    }

    .item {
        background: $jpp-white;
        border: 1px solid $jpp-light-grey;
        box-sizing: border-box;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > * {
            vertical-align: middle;
        }

        span {
            color: $jpp-grey-700;
            line-height: 1;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .filter-label {
        display: inline-block;
        margin-left: 8px;
        font-size:14px;
    }
    .filter-value {
        font-size:14px;
    }
    .action.remove {
        background: $jpp-light-grey;
        display: inline-block;
        height: 26px;
        position: relative;
        width: 26px;

        &:before {
            color: $jpp-grey-700;
            content: '\e616';
            font-family: 'icons-blank-theme';
            font-size: 24px;
            font-weight: 700;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        span {
            display: none;
        }
    }
}

.filter-options {
    .filter-options-title {
        background: $jpp-blue;
        box-sizing: border-box;
        color: $white;
        font-weight: 700;
        height: 40px;
        line-height: 32px;
        margin-bottom: 0;
        padding: 4px 8px;
        text-transform: uppercase;
    }

    .filter-options-content {
        border: 1px solid $jpp-light-grey;
        background:#fff;
        border-top: 0;
        margin-bottom: 16px;
        margin-top:2px;
        max-height: 216px;
        padding:8px;
        padding-right:12px;
        overflow: scroll;
        overflow-x:hidden;

        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            background: $jpp-blue;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $jpp-blue-hover;
        }
        
        ol.items {
            position: relative;
            z-index: auto;
        }

        .item {
            margin-bottom: 0;
            padding: 8px 0;

            a {
                color: $jpp-off-black;
                display: block;
                font-size:14px;
                span {
                    font-size:14px;
                }
                &:hover {
                    color: $jpp-blue;
                }
            }
        }
        .swatch-attribute-options {
            .swatch-option {
                &.text {
                    height: 30px;
                }
            }
            .swatch-option-link-layered {
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    .count {
        color: $jpp-off-black;
        float: right;
        font-weight: 700;
        margin: 0;

        &:before,
        &:after {
            content: none;
        }
    }
}
