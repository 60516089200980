.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-none {
    display: none;
}

.d-none-i {
    display:none!important;
}

// Quick Flexbox Classes

.flex {
    display:flex;
}
.vertical {
    flex-direction:column;
}
.center {
    justify-content:center;
}
.flex-start {
    justify-content:flex-start;
}
.flex-end {
    justify-content:flex-end;
}

//Spacing Classes

.mt-4 { margin-top:4px; }
.mt-8 { margin-top:8px; }
.mt-16 { margin-top:16px; }
.mt-24 { margin-top:24px; }
.mt-32 { margin-top:32px; }
.mt-40 { margin-top:40px; }
.mt-48 { margin-top:48px; }
.mt-64 { margin-top:64px; }

.mr-4 { margin-right:4px; }
.mr-8 { margin-right:8px; }
.mr-16 { margin-right:16px; }
.mr-24 { margin-right:24px; }
.mr-32 { margin-right:32px; }
.mr-40 { margin-right:40px; }
.mr-48 { margin-right:48px; }
.mr-64 { margin-right:64px; }

.mb-4 { margin-bottom:4px; }
.mb-8 { margin-bottom:8px; }
.mb-16 { margin-bottom:16px; }
.mb-24 { margin-bottom:24px; }
.mb-32 { margin-bottom:32px; }
.mb-40 { margin-bottom:40px; }
.mb-48 { margin-bottom:48px; }
.mb-64 { margin-bottom:64px; }

.ml-4 { margin-left:4px; }
.ml-8 { margin-left:8px; }
.ml-16 { margin-left:16px; }
.ml-24 { margin-left:24px; }
.ml-32 { margin-left:32px; }
.ml-40 { margin-left:40px; }
.ml-48 { margin-left:48px; }
.ml-64 { margin-left:64px; }

.pt-4 { padding-top:4px; }
.pt-8 { padding-top:8px; }
.pt-16 { padding-top:16px; }
.pt-24 { padding-top:24px; }
.pt-32 { padding-top:32px; }
.pt-40 { padding-top:40px; }
.pt-48 { padding-top:48px; }
.pt-64 { padding-top:64px; }

.pr-4 { padding-right:4px; }
.pr-8 { padding-right:8px; }
.pr-16 { padding-right:16px; }
.pr-24 { padding-right:24px; }
.pr-32 { padding-right:32px; }
.pr-40 { padding-right:40px; }
.pr-48 { padding-right:48px; }
.pr-64 { padding-right:64px; }

.pb-4 { padding-bottom:4px; }
.pb-8 { padding-bottom:8px; }
.pb-16 { padding-bottom:16px; }
.pb-24 { padding-bottom:24px; }
.pb-32 { padding-bottom:32px; }
.pb-40 { padding-bottom:40px; }
.pb-48 { padding-bottom:48px; }
.pb-64 { padding-bottom:64px; }

.pl-4 { padding-left:4px; }
.pl-8 { padding-left:8px; }
.pl-16 { padding-left:16px; }
.pl-24 { padding-left:24px; }
.pl-32 { padding-left:32px; }
.pl-40 { padding-left:40px; }
.pl-48 { padding-left:48px; }
.pl-64 { padding-left:64px; }