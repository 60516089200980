.block-related-posts {
    > .block-title {
        margin-bottom: $spacer !important; // sass-lint:disable-line no-important
    }

    > ol {
        &.block-content {
            padding-left: 15px;
        }
    }
}
