$bp-mobile: 400px;
$bp-mobile-l: 500px;
$bp-tablet: 768px;
$bp-laptop: 992px;
$bp-laptop-xl: 1024px;
$bp-desktop-s: 1200px;
$bp-desktop: 1366px;
$bp-desktop-xl: 1600px;

$content-max: 1500px;
$grid-column-count: 12;
$grid-padding: 10px;

$gutter: 16px;
$spacer: 20px;
$account-spacer: 12px;
