// Icons
.icon-jpp-resources {
    @include icon($icon-jpp-resources, $jpp-blue, 32px);
    &::before {
        @media (min-width: $bp-tablet) {
            font-size: 36px;
        }
    }
}

.icon-jpp-quickorder {
    @include icon($icon-jpp-quickorder, $jpp-blue, 32px);
    &::before {
        @media (min-width: $bp-tablet) {
            font-size: 36px;
        }
    }
}

.icon-jpp-myaccount {
    @include icon($icon-jpp-myaccount, $jpp-blue, 32px);
    &::before {
        @media (min-width: $bp-tablet) {
            font-size: 36px;
        }
    }
}




.column-reverse-mobile {
    @media (max-width:991px) {
        flex-direction:column-reverse;
    }
}
.column-reverse-1200 {
    @media (max-width:1200px) {
        flex-direction:column-reverse;
    }
}


// Font
.font-8px {
    font-size: 8px;
}

.font-9px {
    font-size: 9px;
}

.font-10px {
    font-size: 10px;
}

.font-11px {
    font-size: 11px;
}

.font-12px {
    font-size: 12px;
}

.font-13px {
    font-size: 13px;
}

.font-14px {
    font-size: 14px;
}

.font-15px {
    font-size: 15px;
}

.font-16px {
    font-size: 16px;
}

.font-17px {
    font-size: 17px;
}

.font-18px {
    font-size: 18px;
}

.font-19px {
    font-size: 19px;
}

.font-20px {
    font-size: 20px;
}

.text-transform-none {
    text-transform:none;
}

.color-blue,
.blue,
.jpp-blue {
    color: $jpp-blue;
}

.color-dark-blue,
.dark-blue,
.jpp-dark-blue {
    color: $jpp-dark-blue;
}

.color-orange,
.orange,
.jpp-orange {
    color: $jpp-orange;
}

.color-black,
.black {
    color: $black;
}

.color-white,
.white {
    color: $white;
}

.color-gray,
.gray {
    color: $jpp-grey-3;
}

.color-dark-green,
.dark-green {
    color: $jpp-success-green-dark;
}

.color-green,
.green {
    color: $jpp-success-green;
}

.background-blue,
.bluenew-bg {
    background-color: $jpp-blue;
}

.bluenew-bg {
    * {
        color: $white;
    }
}

.background-dark-blue,
.dark-blue-bg {
    background-color: $jpp-dark-blue;
}

.background-blue-gradient {
    background: $blue-gradient-bg;
}

.triplebluegradient-bg {
    background: linear-gradient(to right, #1762b9 0%, #1ba6da 50%, #1b989a 100%);

    * {
        color: $white;
    }
}

.background-white,
.white-bg {
    background-color: $white;
}

.background-black,
.black-strip {
    background-color: $black;
}

.black-bg {
    background-color: $jpp-dark-grey;

    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $white !important;
    }
}

.background-grey-3 {
    background-color: $jpp-grey-3;
}

.background-grey-4,
.white-bg {
    background-color: $jpp-grey-4;
}
.offwhite-bg {
    background:#f9f9f9;
}
.bold {
    @include font-bold;
}

.uppercase {
    text-transform: uppercase;
}

.line {
    @include line;

    &.white {
        &::before {
            background: $white;
        }
    }
}

.small {
    @include small-typography;
}


.margin-l-r-auto {
    margin-left: auto;
    margin-right: auto;
}

.text-center {
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    &.center {
        text-align: center !important;
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.float-left,
.pull-left {
    float: left !important;
}

.float-right,
.pull-right {
    float: right !important;
}

.fullwidth {
    width: 100%;
}


.fullheight {
    height: 100%;
}

.fullview {
    @include full-width;
}

.fullview-padding {
    padding-left: $spacer;
    padding-right: $spacer;
}

.max-1500 {
    margin: 0 auto;
    max-width: 100%;
    width: 1500px;
}


// Misc
img,
.img,
.image {
    height: auto;
    width: auto;
}

.card {
    background-color: $white;
    box-shadow: 0 2px 16px $box-shadow-light;

    .card-content {
        background-color: $white;
        padding: $spacer;
    }
}
.visible-lg {
    @media (max-width: $bp-tablet) {
        display: none;
    }
}
.visible-xs {
    display: none;

    @media (max-width: $bp-tablet) {
        display: block !important;
    }
}

.inline-dl {
    @include inline-dl;
}



// Vue

[v-cloak] {
    display:none;
}