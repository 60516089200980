.customer-account-edit {
    .form-edit-account {
        .fieldset {
            clear: both;
            float: none !important; // sass-lint:disable-line no-important
            margin-bottom: 0;
            width: 100%;

            legend {
                @include customer-container-title;
                padding-bottom:0!important;
                margin-bottom:0!important;
            }

            label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                &[for='company'] {
                    clip: unset;
                    color: $jpp-grey-2;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    transform: translate(1px, 3px);
                }
            }
        }

        .customer-container {
            margin-bottom: $account-spacer;
        }
    }
}

.customer-account-edit,
.customer-account-createpassword {
    .fieldset {
        .field {
            #password-strength-meter-container { // sass-lint:disable-line no-ids
                position: static;
            }
        }
    }
}

.customer-account-createpassword {
    .form {
        &.password {
            &.reset {
                min-width: 0;
                width: 100%;
            }
        }
        a.action.back {
            span {
                font-size:14px;
            }
        }
    }
}


