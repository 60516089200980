.checkout-index-index {
    .um-ordercomment {
        margin-top: $spacer;

        .um-remaining-chars {
            background-color: $white;
            padding-left: 0;

            .chars-value {
                color: $jpp-off-black;
            }
        }

        .toggle-order-comments {
            color: $jpp-dark-blue;
            cursor: pointer;
            display: inline-block;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.3rem;
            margin: 0 0 10px;
        }
    }
}
