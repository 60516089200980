.sample-request-button {
    @include clearfix;
    background: $white;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    position: relative;

    a {
        background: transparent url('../images/icon/sample-request.png') no-repeat right center;
        background-size: auto 100%;
        display: inline-block;
        padding-right: 34px;
        transition: background-size .25s;
        vertical-align: top;

        &:hover {
            //background-size: auto 85%;
        }
    }

    span {
        color: $jpp-blue;
        display: inline-block;
        font-size: 12px;
        line-height: 13px;
        max-width: 60px;
        text-align: right;
        text-transform: uppercase;
        vertical-align: top;
    }
}
