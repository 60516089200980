.with-buttons-wrapper{
    display:flex;
    flex-wrap: wrap;
}
div[data-content-type="simple_cards"] {
    display:flex;
    justify-content:center;
    max-width:92%;
    margin:0 auto!important;
    @media (max-width:1280px) {
        flex-wrap:wrap;
    }
    @media (max-width:991px) {
        max-width:100%;
    }

    .flickity-slider {
        width:100%;
    }
    .card {
        width:374px;
        min-height:374px;
        max-width:calc(25% - 12px);
        border:1px solid transparent;
        margin-right:16px;
        border-radius:4px;
        display:flex;
        flex-direction:column;
        padding:24px;
        position:relative;
        @media (max-width:1280px) {
            width:calc(50% - 8px);
            max-width:100%;
            margin-bottom:16px;
            min-height:316px;

            &:nth-of-type(2n) {
                &:not(.simple-tile) {
                    margin-right:0;
                }
            }
        }
        @media (max-width:768px) {
            padding:16px;
        }
        @media (max-width:660px) {
            width:100%;
            margin-right:0;
            min-height:auto;
        }
        svg {
            display:block;
            margin:0 auto;
            margin-top:10%;
            @media (max-width:1280px) {
                margin-top:5%;
            }
            @media (max-width:768px) {
                width:50px;
            }
        }
        img {
            max-height:72px;
            margin:0 auto;
            margin-top:8%;
            @media (max-width:1280px) {
                margin-top:5%;
            }
            @media (max-width:768px) {
                max-height:72px;
            }
        }
        h4 {
            font-size:24px;
            text-decoration:none;
            text-align:center;
            text-transform:none;
            @media (max-width:768px) {
                font-size:20px;
            }
        }
        p {
            font-size:14px;
            line-height:18px;
            text-align:center;
            color:#222;
            @media (max-width:768px) {
                font-size:13px!important;
            }
            @media (max-width:480px) {
                font-size:13px!important;
            }
        }
        &:last-of-type {
            margin-right:0;
        }

        a.link {
            text-decoration:none!important;
            display:inline-block;
            position:relative;
            left:50%;
            transform:translateX(-50%);
            text-transform:uppercase;
            font-weight:800;
            position:absolute;
            bottom:24px;
            font-size:14px;
        }
        &.dark-blue {
            h4 {
                color:#004F9D;
            }
            svg path {
                stroke:#004F9D;
            }
            a.link {
                color:#004F9D;
            }
        }
        &.orange {
            h4 {
                color:#F57F29;
            }
            svg path {
                stroke:#F57F29;
            }
            a.link {
                color:#F57F29;
            }
        }
        &.blue {
            h4 {
                color:#0088CE;
            }
            svg path {
                stroke:#0088CE;
            }
            a.link {
                color:#0088CE;
            }
        }
        &.green {
            h4 {
                color:#68BB5B;
            }
            svg path {
                stroke:#68BB5B;
            }
            a.link {
                color:#68BB5B;
            }
        }
        &.with-buttons{
            margin: 6px !important;
            @media (min-width:992px) {
                max-width: 30%;
            }
            @media (min-width:768px) and (max-width:991px) {
                max-width: 45%;
            }
            @media (max-width:767px) {
                width: 90% !important;
            }
            .row-full-width-inner{
                width: 100%;
                .pagebuilder-button-primary{
                   // min-width: 200px;
                }
                .pagebuilder-button-secondary{
                    //min-width: 200px;
                }
            }
            img{
                max-height: 100% !important;
            }
        }
        &.simple-tile {
            width:230px;
            height:230px;
            min-height:230px;
            padding:0;
            overflow:hidden;
            @media (max-width:1368px) {
                width:calc(20% - 12.8px);
                height:auto;
                min-height:0;
            }
            @media (max-width:1280px) {
                margin-bottom:0;
            }
            @media (max-width:1200px) {
                width:calc(25% - 12px)!important;
                margin-bottom:16px;
                &:nth-of-type(4n) {
                    margin-right:0;
                }
            }
            @media (max-width:991px) {
                width:calc(33.333% - 10.666px);
                &:nth-of-type(4n) {
                    margin-right:16px;
                }
                &:nth-of-type(3n) {
                    margin-right:0;
                }
            }
            @media (max-width:660px) {
                width:calc(50% - 8px);
                &:nth-of-type(3n) {
                    margin-right:16px;
                }
                &:nth-of-type(2n) {
                    margin-right:0;
                }
            }
            a.link {
                position:relative!important;
                width:100%;
                padding-bottom:100%;
                //top:0;
                //left:0;
                //right:0;
                //bottom:0;
                //transform:translateX(0);
                left:auto;
                bottom:auto;
                transform:translateX(0);
                margin-top:0;

                &:hover {
                    h5 {
                        color:#0088ce;
                    }
                }
            }

            .image-wrapper, > img {
                //height:160px;
                width:calc(100% - 24px);
                max-width:100%;
                margin-left:auto;
                margin-right:auto;
                position:absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                height:auto;
                max-height:none;

                img {
                    width:100%;
                    height:auto;
                    max-height:none;
                    margin:0;
                }
            }

            h5 {
                position:absolute;
                bottom:12px;
                left:8px;
                right:8px;
                text-align:center;
                font-size:13px;
                margin:0;
                transition:0.18s ease;
                z-index:2;
                @media (max-width:480px) {
                    bottom:8px;
                    left:8px;
                    right:8px;
                    line-height:1.1;
                }
            }

            &.contain-image {
                .image-wrapper {
                    height:72%;
                    top:calc(50% - 14px);
                    img {
                        height:100%!important;
                        width:auto!important;
                        display:block;
                        margin:0 auto;
                    }
                }
            }
            &.text-only {
                h5 {
                    bottom:auto;
                    left:auto;
                    right:auto;
                    width:calc(100% - 32px);
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                }
            }

        }

    }
}


//Class-based Wrapper Variations For Simple Cards Component

div[data-content-type='row'] {

    // Wrapper Variation For Simple Cards: Link Tiles Appearance | Place Class On Row
    .tile-section {
        margin-top:64px!important;
        background:#fff!important;
        padding:32px!important;
        width:1500px!important;
        max-width:100%!important;
        margin-left:auto!important;
        margin-right:auto!important;
        z-index:2;
        position:relative;
        @media (max-width:1280px) {
            margin-top:48px!important;
            padding:24px!important;
        }
        @media (max-width:1200px) {
            padding-bottom:8px!important;
        }
        @media (max-width:991px) {
            margin-top:48px!important;
        }
        @media (max-width:768px) {
            max-width:calc(100% - 32px)!important;
            margin-top:32px!important;
            padding:16px!important;
            padding-bottom:0!important;
        }
        @media (max-width:660px) {
            margin-top:24px!important;
        }
        .card-wrapper {
            flex-wrap:wrap;
        }
        h2 {
            text-transform:none;
            margin-top:0;
            font-size:32px;
            width:100%;
            @media (max-width:991px) {
                font-size:24px;
            }
            @media (max-width:660px) {
                font-size:20px;
            }
        }

        .card-wrapper {
            justify-content:flex-start!important;
            width:100%!important;
            max-width:100%!important;
            margin-left:0!important;

            .card.simple-tile {
                width:calc(20% - 12.8px);
                height:auto!important;
                min-height:0!important;
                @media (max-width:991px) {
                    &:nth-of-type(3n) {
                        margin-right:16px;
                    }
                    &:nth-of-type(4n) {
                        margin-right:0;
                    }
                }
                @media (max-width:768px) {
                    width:calc(33.333% - 10.666px)!important;
                    &:nth-of-type(4n) {
                        margin-right:16px!important;
                    }
                    &:nth-of-type(3n) {
                        margin-right:0!important;
                    }
                }
                @media (max-width:660px) {
                    width:calc(50% - 4px)!important;
                    margin-right:8px!important;
                    margin-bottom:8px!important;
                    &:nth-of-type(3n) {
                        margin-right:8px!important;
                    }
                    &:nth-of-type(2n) {
                        margin-right:0!important;
                    }
                }
                a {
                    display:block;
                }
                h5 {
                    font-size:16px;
                    @media (max-width:991px) {
                        font-size:14px;
                    }
                    @media (max-width:480px) {
                        bottom:8px;
                        left:8px;
                        right:8px;
                    }
                }
            }
        }
    }

    //Wrapper Variation For Simple Cards: Link Tiles Appearance | Place Class On Row
    .tile-row {
        margin-top:64px!important;
        width:100%;
        max-width:none!important;
        margin-left:auto!important;
        margin-right:auto!important;
        z-index:2;
        position:relative;

        @media (max-width:991px) {
            margin-top:48px!important;
        }
        @media (max-width:768px) {
            margin-top:32px!important;
            transform:translateX(16px);
        }
        @media (max-width:660px) {
            margin-top:24px!important;
        }
        &::-webkit-scrollbar {
            width:0px;
            height:0px;
        }
        h2 {
            margin-top:0;
            font-size:32px;
            span {
                font-size:inherit;
                font-weight:300;
            }
            @media (max-width:991px) {
                font-size:24px;
            }
            @media (max-width:660px) {
                font-size:20px;
            }
        }
        .card-wrapper {
            justify-content:flex-start!important;
            width:100%!important;
            max-width:100%!important;
            margin-left:0!important;
            opacity:0;
            transition:0.18s ease;
            max-height:229px;

            &-large {
                max-height:390px!important;
                .card.simple-tile {
                    width:366px!important;
                    height:366px!important;
                    min-height:366px!important;
                }
            }
            &.flickity-enabled {
                transform:translateY(-12px);
            }
            &.flickity-enabled, &.no-flickity {
                opacity:1;
            }
        }

        .flickity-viewport {
            width:100%;
            height:229px!important;
            padding:12px 16px;
            transform:translateX(-16px);
            @media (max-width:660px) {
                height:200px!important;
            }
            .flickity-slider {
                height:229px!important;
                position:absolute!important;
                margin-left:16px;
                @media (max-width:660px) {
                    height:200px!important;
                }
            }
        }

        .card.simple-tile {
            height:auto!important;
            min-height:0!important;
            border-radius:8px;
            width:200px!important;
            min-width:200px!important;
            @media (max-width:1200px) {
                margin-bottom:0;
                margin-right:16px!important;
                width:200px!important;
                min-width:200px!important;
            }
            @media (max-width:991px) {
                &:nth-of-type(3n), &:nth-of-type(4n), &:nth-of-type(5n) {
                    margin-right:16px!important;
                }
            }
            @media (max-width:660px) {
                margin-right:8px!important;
                width:178px!important;
                min-width:178px!important;
                &:nth-of-type(2n), &:nth-of-type(3n), &:nth-of-type(4n), &:nth-of-type(5n) {
                    margin-right:8px!important;
                }
            }
            h5 {
                @media (max-width:480px) {
                    bottom:8px;
                    left:8px;
                    right:8px;
                }
            }

        }


    }
}


body.catalog-category-view {
    .category-description {
        .tile-row, .tile-row-large, .tile-section {
            margin-top:0!important;
            margin-bottom:16px!important;
        }
        .card-wrapper {
            width:100%;
        }
        .flickity-viewport {
            width:100%;
            height:200px!important;
            padding:12px 16px;
            transform:translateX(-16px);

            .flickity-slider {
                height:176px!important;
                @media (max-width:768px) {
                    margin-left:0;
                }
                @media (max-width:660px) {
                    height:176px!important;
                }
            }
        }
        div[data-content-type='row'] {
            .card.simple-tile {
                width:176px!important;
                min-width:176px!important;
                height:176px!important;
                min-height:176px!important;
                margin-right:12px!important;
                box-shadow:0px 1px 6px rgba(2,2,2,0.06);
                border-radius:4px;
                @media (max-width:1200px) {
                    margin-right:12px!important;
                    width:176px!important;
                    min-width:176px!important;
                }
                @media (max-width:991px) {
                    &:nth-of-type(3n), &:nth-of-type(4n), &:nth-of-type(5n) {
                        margin-right:12px!important;
                    }
                }
                @media (max-width:660px) {
                    margin-right:8px!important;
                    width:177px!important;
                    min-width:177px!important;
                    &:nth-of-type(2n), &:nth-of-type(3n), &:nth-of-type(4n), &:nth-of-type(5n) {
                        margin-right:8px!important;
                    }
                }
                .image-wrapper {
                    top:calc(50% - 8px);
                }
                img {
                    margin-left:auto;
                    margin-right:auto;
                    display:block;
                    top:calc(50% - 8px);
                }
                h5 {
                    text-transform:none;
                    bottom:10px;
                    @media (max-width:480px) {
                        bottom:8px;
                        left:8px;
                        right:8px;
                    }
                }

                &.text-only {
                    h5 {
                        color:#0088ce;
                        bottom:auto;
                        top:50%;
                        transform:translateY(-50%);
                    }
                }
            }
        }

    }
}
