div.marketing-slider {
    width:1240px;
    max-width:calc(92vw - 120px);
    margin:90px auto 48px!important;
    position:relative;
    transition:0.4s ease;
    box-shadow:0px 0px 12px rgba(2,2,2,0.12);
    max-height:556px;

    &:not(.flickity-enabled) {
        opacity:0;
        pointer-events:none;
    }
    @media (max-width:1540px) {
        height:37.06vw;
    }
    @media (max-width:1024px) {
        width:500px;
        height:auto;
    }
    @media (max-width:991px) {
        max-width:calc(100vw - 32px);
        margin:64px auto!important;
    }
    @media (max-width:768px) {
        max-width:calc(100vw - 48px);
    }
    @media (max-width:480px) {
        max-width:340px;
    }
    .slide {
        display:flex;
        width:100%;
        min-height:556px;
        @media (max-width:1540px) {
            min-height:37.06vw;
        }
        @media (max-width:1024px) {
            flex-direction:column-reverse;
        }
        .left-content {
            flex:1;
            width:50%;
            display:flex;
            flex-direction:column;
            justify-content:center;
            background:#f8f8f8;
            @media (max-width:1024px) {
                flex:auto;
                width:100%;
            }
            .content-inner {
                width:520px;
                max-width:100%;
                margin:auto;
                padding:40px;
                @media (max-width:1024px) {
                    width:100%;
                }
                @media (max-width:991px) {
                    padding:28px;
                }
                @media (max-width:480px) {
                    padding:20px;
                }
                h2 {
                    font-size:32px;
                    margin-top:0;
                    margin-bottom:8px;
                    @media (max-width:991px) {
                        font-size:24px;
                    }
                }
                p {
                    @media (max-width:480px) {
                        font-size:14px;
                        line-height:1.4;
                        margin-bottom:0px;
                    }
                }
                .blue-button {
                    display:inline-block;
                    margin-top:12px;
                }
            }
        }
        .slide-image {
            flex:1;
            width:50%;
           // background:#222;
            position:relative;
            background-position:center center!important;
            background-repeat:no-repeat!important;
            background-size:cover!important;
            @media (max-width:1024px) {
                flex:auto;
                padding-bottom:56.25%;
                width:100%;
            }
            img {
                width:100%;
            }
        }
    }

    .flickity-button {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        border-radius:100%;
        background:#0088ce;
        @media (max-width:1660px) {
            transform:translateY(-50%) scale(0.8);
            border:4px solid #fff;
        }
        &:disabled {
            opacity:0;
        }
        &:hover {
            background:#0088ce;
        }
        svg {
            width:18px;
            height:18px;
            top:50%;
            left:50%;
            transform:translateX(-50%) translateY(-50%);
            path {
                color:#fff;
            }
        }

        &.previous {
            left:-60px;
            @media (max-width:1660px) {
                left:-50px;
            }
            @media (max-width:660px) {
                left:-21px;
            }
            //@media (max-width:480px) {
            //    left:-11px;
            //}
        }
        &.next {
            right:-60px;
            @media (max-width:1660px) {
                right:-50px;
            }
            @media (max-width:660px) {
                right:-21px;
            }
            //@media (max-width:480px) {
            //    right:-11px;
            //}
        }
    }
    .flickity-page-dots {
        bottom:-60px;
        .dot {
            height:20px;
            width:20px;
            border:1.5px solid #0088ce;
            background:transparent;
            opacity:0.8;
            transition:0.18s ease;
            &.is-selected {
                background:#0088ce;
                opacity:1;
            }
        }
    }

}
