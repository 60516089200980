.social-share-wrapper {
    @include clearfix;
    background: $white;
    float: right;
    margin-top: 10px;
    position: relative;

    &:hover {
        .social-share-icons-wrapper {
            display: block;
            z-index: 1;
            pointer-events:auto;
            opacity:1;
        }
    }

    .heading {
        &:hover {
            cursor: default;
        }

        .text {
            color: $jpp-blue;
            display: inline-block;
            font-size: 11px;
            line-height: 13px;
            max-width: 60px;
            text-transform: uppercase;
            @media (min-width: $bp-laptop) {
                margin-right: 8px;
                text-align: right;
            }

        }
    }

    .social-share-icons-wrapper {
        background-color: $white;
        box-shadow: 0 0 5px $box-shadow;
        display: none;
        min-width: 125px;
        padding: 16px 20px;
        position: absolute;
        right: 0;
        top: 100%;
        width:210px;
        display:block;
        pointer-events:none;
        opacity:0;
        @include ease;
        min-height:57.2px;
        min-width:210px;

        @media (max-width:$bp-tablet - 1) {
            left:0;
            right:auto;
        }
        .icon {
            padding:0px 8px;
            &::before {
                @include ease;
            }
            &:hover {
                cursor: pointer;
                &::before {
                    color:$jpp-orange;
                }
            }
        }

        .facebook {
            @include icon($icon-facebook, $jpp-blue, 18px);
        }

        .pinterest {
            @include icon($icon-pinterest, $jpp-blue, 18px);
        }

        .twitter {
            @include icon($icon-twitter, $jpp-blue, 18px);
        }

        .clipboard {
            @include icon($icon-copy-clipboard, $jpp-blue, 18px);

            &:hover {
                cursor: copy;
            }
        }
    }

    .copied-notice {
        @include ease;
        background-color: $white;
        left: 0;
        margin-top: 10px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index:999;
        top: 100%;
        visibility: hidden;

        &.active {
            @include ease;
            opacity: 1;
            visibility: visible;
        }
    }
}

