@keyframes promoGlow {
    0% {
        box-shadow:-2px 0px 0 rgba(0, 136, 206, 0);
        border:1px solid rgba(75,194,255,0.14);
    }
    50% {
        box-shadow:-2px 0px 12px rgba(20, 162, 233, 0.8);
        border:1px solid rgba(75,194,255,0.68);
    }
    100% {
        box-shadow:-2px 0px 0 rgba(0, 136, 206, 0);
        border:1px solid rgba(75,194,255,0.14);
    }
}
@keyframes promoShine {
    0% {
        bottom:-100%;
    }
    20% {
        bottom:100%;
    }
    100% {
        bottom:100%;
    }
}

body.promo-tray-active {
    #maincontent {
        z-index:200;
        transition:z-index 0s step-start;
    }
}

.home-promo-tray-wrapper {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:100vh;
    width:100vw;
    pointer-events:none;
    z-index:9999;

    .home-promo-tray {
        position:fixed;
        left:100%;
        width:400px;
        height:100vh;
        max-width:100vw;
        transition:all 0.8s, height 0s;
        transition-timing-function:cubic-bezier(0.65, 0.05, 0.36, 1);
        background:rgba(255,255,255, 0.86);
        backdrop-filter:blur(20px);
        pointer-events:auto!important;
        z-index:99999;
        padding:0;
        padding-bottom:130px;
        @media (max-width:440px) {
            width:calc(100vw - 40px);
        }

        a.tray-toggle {
            width:48px;
            height:128px;
            background:linear-gradient(45deg, #004F9D 0%, #1798db 50%, #004F9D 100%);
            box-shadow:-2px 0px 12px rgba(0, 136, 206, 0);
            display:block;
            top:50%;
            right:100%;
            transform:translateY(-50%);
            position:absolute;
            border-top-left-radius:4px;
            border-bottom-left-radius:4px;
            overflow:hidden;
            cursor:pointer;
            &:hover {
                filter:brightness(1.1);
            }
            @media (max-width:768px) {
                width:34px;
                height:102px;
            }
            span {
                color:#fff;
                font-size:18px;
                pointer-events:none;
                position:absolute;
                top:50%;
                left:calc(50% + 1px);
                transform:translateX(-50%) translateY(-50%) rotate(-90deg) translateZ(0);
                z-index:3;
                @media (max-width:768px) {
                    font-size:14px;
                    left:calc(50% + 2px);
                }
                &.close {
                    opacity:0;
                }
            }

            &:before {
                position:absolute;
                content:'';
                width:100%;
                height:100%;
                bottom:-100%;
                //background:linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 55%, rgba(255,255,255,0) 100%);
                background:linear-gradient(to top, rgba(0,206,196,0) 0%, rgba(99, 255, 247, 0.6) 55%, rgba(0,206,196,0) 100%);
                transition:0s;
                opacity:0.45;
                z-index:2;
            }

        }

        .promotions-list {
            display:flex;
            flex-direction:column;
            //height:calc(100vh - 130px);
            height:100vh;
            overflow-x:hidden;
            overflow-y:scroll;
            padding:16px 16px 140px!important;
            @media (max-width:480px) {
                padding:12px 12px 140px!important;
            }
            &::-webkit-scrollbar {
                width:0px;
            }
            &::-webkit-scrollbar-track {
                background: #E8E8E8;
            }
            &::-webkit-scrollbar-thumb {
                background: #0088ce;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #0096E3;
            }
            div[data-content-type="promo_card"] {
                display:flex;
            }
            .promo-card {
                width:100%;
                height:218px;
                min-height:218px;
                box-shadow:0px 1px 6px rgba(2,2,2,0);
                border-radius:12px;
                background:#fff;
                display:inline-block;
                margin-bottom:16px;
                position:relative;
                overflow:hidden;
                transition:0.18s ease;
                filter:brightness(0.99);
                @media (max-width:480px) {
                    height:49.4vw;
                    min-height:49.4vw;
                    margin-bottom:12px;
                }
                &:hover {
                    box-shadow:0px 2px 12px rgba(2,2,2,0.2);
                    filter:brightness(1.02);
                    transform:scale(1.015);
                    .cta-button {
                        filter:brightness(1.1);
                        box-shadow:0px 0px 8px rgba(2,2,2,0.12);
                    }
                }
                .left-content {
                    position:absolute;
                    top:0;
                    left:0;
                    bottom:0;
                    z-index:2;
                    background:#0088CE;
                    width:54%;
                    padding:16px;
                    padding-right:24px;
                    display:flex;
                    justify-content:center;
                    flex-direction:column;
                    clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
                    transform:perspective(1px);
                    span {
                        font-size:12px;
                        line-height:14px;
                        text-transform:uppercase;
                        color:#FFF;
                        margin-bottom:6px;
                        //backface-visibility:hidden;
                        //transform:translateZ(0) perspective(1px);
                        //-webkit-font-smoothing: subpixel-antialiased;
                    }
                    h5 {
                        font-size:14px;
                        line-height:15px;
                        font-weight:800;
                        text-transform:none!important;
                        margin:4px 0;
                        color:#FFF;
                        //backface-visibility:hidden;
                        //transform:translateZ(0) perspective(1px);
                        //-webkit-font-smoothing: subpixel-antialiased;
                        @media (max-width:480px) {
                            font-size:13px!important;
                            line-height:14px;
                        }
                    }
                    h5.thin {
                        font-size:14px;
                        font-weight:500;
                        text-transform:none!important;
                        color:#FFF;
                        //backface-visibility:hidden;
                        //transform:translateZ(0) perspective(1px);
                        //-webkit-font-smoothing: subpixel-antialiased;
                        @media (max-width:480px) {
                            font-size:13px!important;
                            line-height:14px;
                        }
                    }
                    .cta-button {
                        min-width:100px;
                        max-width:130px;
                        align-self:flex-start;
                        height:33.6px;
                        line-height:33.6px;
                        text-align:center;
                        white-space:nowrap;
                        padding:0 14px!important;
                        font-size:12px!important;
                        background:#F57F29;
                        color:#FFF;
                        font-weight:700;
                        text-transform:uppercase;
                        //backface-visibility:hidden;
                        //transform:translateZ(0) perspective(1px);
                        //-webkit-font-smoothing: subpixel-antialiased;
                        transition:0.18s ease;
                        &:hover {
                         // filter:brightness(1.1);
                         // box-shadow:0px 0px 8px rgba(2,2,2,0.12);
                        }
                        @media (max-width:480px) {
                            transform:scale(0.88);
                            transform-origin:top left;
                        }
                    }
                }

                .right-content {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    z-index:1;
                    width:59.24%;
                    display:flex;
                    justify-content:center;
                    flex-direction:column;
                    padding:16px;

                    img {
                        align-self:flex-end;
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translateX(-50%) translateY(-50%);
                        max-width:200%;
                        height:100%;
                    }
                }
            }
        }

        .bottom-tray-message {
            height:136px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            pointer-events:none;
            padding-top:8px;
            z-index:99;
            background:linear-gradient(to top, rgba(243,243,243,1) 0%, rgba(243,243,243,0.92) 50%, rgba(243,243,243,0) 160%);
            backdrop-filter:blur(4px);
            &:after {
                pointer-events:none;
                content:'';
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:linear-gradient(to top, rgba(243,243,243,1) 0%, rgba(243,243,243,0) 100%);
                z-index:1;
            }

            svg {
                display:block;
                margin:0 auto;
                width:30px;
                height:30px;
                filter:drop-shadow(0px 0px 8px white);
                position:relative;
                z-index:2;
            }
            span {
                text-align:center;
                color:#000;
                width:226px;
                margin:12px auto 0;
                line-height:1.4;
                text-shadow:0px 0px 12px white, 0px 0px 18px white, 0px 0px 24px white, 0px 0px 24px white;
                position:relative;
                z-index:2;
            }
        }

    }

    .promo-tray-background {
        pointer-events:none;
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        background:rgba(2,2,2,0.4);
        transition:0.4s ease;
        opacity:0;
        z-index:5000;
    }

    &:not(.active) {
        a.tray-toggle {
            animation:promoGlow 3s infinite;
            animation-timing-function:cubic-bezier(0.65, 0.05, 0.36, 1);

            &:before {
                animation:promoShine 6s infinite;
            }
        }
    }
    &.active {
        .home-promo-tray {
            left:calc(100% - 400px);
            box-shadow:0px 0px 24px rgba(2,2,2,0.14);
            @media (max-width:480px) {
                left:40px;
            }

            a.tray-toggle {
                box-shadow:-2px 0px 4px rgba(0,0,0, 0.2);
                span:nth-of-type(1) {
                    opacity:0;
                }
                span.close {
                    opacity:1;
                }
            }
        }

        .promo-tray-background {
            opacity:1;
            pointer-events:auto!important;
        }
    }
}



.home-promo-tray {
    position:fixed;
    left:100%;
    width:400px;
    height:100vh;
    max-width:100vw;
    transition:all 0.8s, height 0s;
    transition-timing-function:cubic-bezier(0.65, 0.05, 0.36, 1);
    background:rgba(255,255,255, 0.86);
    backdrop-filter:blur(20px);
    pointer-events:auto!important;
    z-index:99999;
    padding:0;
    padding-bottom:130px;
    @media (max-width:440px) {
        width:calc(100vw - 40px);
    }

    a.tray-toggle {
        width:48px;
        height:128px;
        background:linear-gradient(45deg, #004F9D 0%, #1798db 50%, #004F9D 100%);
        box-shadow:-2px 0px 12px rgba(0, 136, 206, 0);
        display:block;
        top:50%;
        right:100%;
        transform:translateY(-50%);
        position:absolute;
        border-top-left-radius:8px;
        border-bottom-left-radius:8px;
        overflow:hidden;
        cursor:pointer;
        &:hover {
            filter:brightness(1.1);
        }
        @media (max-width:768px) {
            width:34px;
            height:102px;
        }
        span {
            color:#fff;
            font-size:18px;
            pointer-events:none;
            position:absolute;
            top:50%;
            left:calc(50% + 1px);
            transform:translateX(-50%) translateY(-50%) rotate(-90deg) translateZ(0);
            z-index:3;
            @media (max-width:768px) {
                font-size:14px;
                left:calc(50% + 2px);
            }
            &.close {
                opacity:0;
            }
        }

        &:before {
            position:absolute;
            content:'';
            width:100%;
            height:100%;
            bottom:-100%;
            //background:linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 55%, rgba(255,255,255,0) 100%);
            background:linear-gradient(to top, rgba(0,206,196,0) 0%, rgba(99, 255, 247, 0.6) 55%, rgba(0,206,196,0) 100%);
            transition:0s;
            opacity:0.34;
            z-index:2;
        }

    }

    .promotions-list {
        display:flex;
        flex-direction:column;
        //height:calc(100vh - 130px);
        height:100vh;
        overflow-x:hidden;
        overflow-y:scroll;
        padding:16px 16px 140px!important;
        @media (max-width:480px) {
            padding:12px 12px 0!important;
        }
        &::-webkit-scrollbar {
            width:0px;
        }
        &::-webkit-scrollbar-track {
            background: #E8E8E8;
        }
        &::-webkit-scrollbar-thumb {
            background: #0088ce;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #0096E3;
        }
        div[data-content-type="promo_card"] {
            display:flex;
        }
        .promo-card {
            width:100%;
            height:218px;
            min-height:218px;
            box-shadow:0px 1px 6px rgba(2,2,2,0);
            border-radius:12px;
            background:#fff;
            display:inline-block;
            margin-bottom:16px;
            position:relative;
            overflow:hidden;
            transition:0.18s ease;
            filter:brightness(0.99);
            //backface-visibility:hidden;
            @media (max-width:480px) {
                height:49.4vw;
                min-height:49.4vw;
                margin-bottom:12px;
            }
            &:hover {
                box-shadow:0px 2px 12px rgba(2,2,2,0.2);
                filter:brightness(1.02);
                transform:scale(1.015);
            }
            .left-content {
                position:absolute;
                top:0;
                left:0;
                bottom:0;
                z-index:2;
                background:#0088CE;
                width:54%;
                padding:16px;
                padding-right:24px;
                display:flex;
                justify-content:center;
                flex-direction:column;
                clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
                span {
                    font-size:12px;
                    line-height:14px;
                    text-transform:uppercase;
                    color:#FFF;
                    margin-bottom:6px;
                    //backface-visibility:hidden;
                    //transform:translateZ(0) perspective(1px);
                    //-webkit-font-smoothing: subpixel-antialiased;
                }
                h5 {
                    font-size:17px;
                    line-height:18px;
                    font-weight:800;
                    text-transform:none!important;
                    margin:4px 0;
                    color:#FFF;
                    //backface-visibility:hidden;
                    //transform:translateZ(0) perspective(1px);
                    //-webkit-font-smoothing: subpixel-antialiased;
                }
                h5.thin {
                    font-size:15px;
                    font-weight:500;
                    text-transform:none!important;
                    color:#FFF;
                    //backface-visibility:hidden;
                    //transform:translateZ(0) perspective(1px);
                    //-webkit-font-smoothing: subpixel-antialiased;
                }
                .cta-button {
                    min-width:100px;
                    max-width:130px;
                    align-self:flex-start;
                    height:33.6px;
                    line-height:33.6px;
                    text-align:center;
                    white-space:nowrap;
                    padding:0 14px!important;
                    font-size:12px!important;
                    background:#F57F29;
                    color:#FFF;
                    font-weight:700;
                    text-transform:uppercase;
                    //backface-visibility:hidden;
                    //transform:translateZ(0) perspective(1px);
                    //-webkit-font-smoothing: subpixel-antialiased;
                    transition:0.18s ease;
                    &:hover {
                        filter:brightness(1.1);
                        box-shadow:0px 0px 8px rgba(2,2,2,0.12);
                    }
                }
            }

            .right-content {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                z-index:1;
                width:59.24%;
                display:flex;
                justify-content:center;
                flex-direction:column;
                padding:16px;

                img {
                    align-self:flex-end;
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                    max-width:200%;
                    height:100%;
                }
            }
        }
    }

    .bottom-tray-message {
        height:136px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        pointer-events:none;
        padding-top:8px;
        z-index:99;
        background:linear-gradient(to top, rgba(243,243,243,1) 0%, rgba(243,243,243,0.92) 50%, rgba(243,243,243,0) 160%);
        backdrop-filter:blur(4px);
        &:after {
            pointer-events:none;
            content:'';
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background:linear-gradient(to top, rgba(243,243,243,1) 0%, rgba(243,243,243,0) 100%);
            z-index:1;
        }

        svg {
            display:block;
            margin:0 auto;
            width:30px;
            height:30px;
            filter:drop-shadow(0px 0px 8px white);
            position:relative;
            z-index:2;
        }
        span {
            text-align:center;
            color:#000;
            width:226px;
            margin:12px auto 0;
            line-height:1.4;
            text-shadow:0px 0px 12px white, 0px 0px 18px white, 0px 0px 24px white, 0px 0px 24px white;
            position:relative;
            z-index:2;
        }
    }

}