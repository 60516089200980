.top-banner-wrapper, .global-banner-wrapper {
    @include full-width;
    background-color:#FFFDEC;
    display: none;
    min-height: 30px;
    position: relative;
    z-index:101;
    .content {
        padding: 8px 12px;
        text-align: center;
    }
}

.cms-index-index {
    .top-banner-wrapper {
        display: block;
    }
}

.global-banner-wrapper {
    display: block;
}