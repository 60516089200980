.checkout-index-index {
    #payment_form_s2k_payment { // sass-lint:disable-line no-ids
        .payment-inner-wrapper {
            max-width: 350px;
            padding-right: 40px;
            position: relative;
            z-index: auto;
            @media (max-width:480px) {
                padding:0;
            }
            .field {
                &.cc-type {
                    //height: 100%;
                    margin: 0;
                    padding-top: 32px;
                    position: absolute;
                    right: 0;
                    //top: 0;
                }

                &.date {
                    .fields {
                        display: flex;
                        gap: 10px;

                        .field {
                            flex-basis: 50%;
                            margin-bottom: 0;

                            select {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .amazon-widget-container {
        + .checkout-agreements-block {
            margin-top:24px;
        }
    }
    .checkout-payment-method {
        input[type=number] {
            -moz-appearance: textfield;
        }
        .ccard {
            .number {
                .input-text {
                    width: 100%;
                }
            }
        }

        .credit-card-types {
            max-width: 35px;

            .item {
                display: none;
                margin-right: 0;

                &._active {
                    display: block;
                }
            }
        }

        .action.primary.checkout { // sass-lint:disable-line force-element-nesting
            // sass-lint:disable-block no-important
            background-color: $jpp-success-green !important;
            border-color: $jpp-success-green !important;

            &:hover {
                //background-color: $jpp-success-green-dark !important;
                //border-color: $jpp-success-green-dark !important;
                filter:brightness(1.1);
            }
        }
    }

    .tax-exemption-info {
        margin: 20px 0;
    }

    #tax-exemption-form { // sass-lint:disable-line no-ids
        label[for='i-am-tax-exempt'] { // sass-lint:disable-line force-attribute-nesting no-qualifying-elements
            display: inline-block;
            margin-bottom: 20px;
        }

        .form-list {
            list-style: none;
            padding-left: 0;
        }

        input,
        select {
            border-width: 1.5px;

            &.mage-error {
                border-color: $jpp-error-red;
            }
        }

        .field {
            label {
                font-size: 1.4rem;
            }
        }

        input:valid { // sass-lint:disable-line force-pseudo-nesting
            border-color: $jpp-success-green;
            color: $jpp-off-black;
        }

        .mage-error[generated] { // sass-lint:disable-line force-attribute-nesting
            bottom: auto;
            font-size: 1.2rem;
            left: 0;
            line-height: 1.2rem;
            margin-top: 0;
            top: 100%;
        }
    }

    .opc-block-shipping-information {
        padding: 0 24px;
        background:#fff;
        box-shadow: 0 2px 16px 0 $box-shadow-light;
        .ship-to {
            padding-top:16px;
        }
        .ship-via {
            padding-bottom:18px;
        }
        .ship-to, .ship-via {
            margin-bottom:0;
            a {
                font-size:14px;
                text-decoration:none!important;
            }
        }
        .shipping-information-title {
            margin: 0 0 10px;
            span {
                font-weight: 800;
                font-size: 20px;
                line-height: 19px;
            }
        }
        .shipping-information-content {
            font-size: 14px;
            font-weight: 500;
            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }
            a {
                color:#353535;
                pointer-events:none;
            }
        }
    }
}
