.last-purchased-container {
    @media (min-width: $bp-mobile-l) {
        float: right;
    }

    .last-purchased {
        .date {
            border: 1px solid $jpp-blue;
            border-radius: 4px;
            box-sizing: border-box;
            display: inline-block;
            line-height: normal;
            margin-top: 20px;
            padding: 8px 16px;

            @media (min-width: $bp-laptop) {
                margin-top: 15px;
            }
        }
    }
}
