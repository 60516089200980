.page-footer {
    .block {
        &.newsletter {
            float: none;
            max-width: 250px;
            width: 100%;
        }

        .footer-logo {
            display: block;
            margin-bottom: 10px;
            max-width: 175px;
        }

        .footer-help-links {
            a {
                @include unset-underline;
            }
        }

        .footer-social-icons {
            margin-bottom: 8px;

            .icon-item {
                padding-right: 9px;
            }

            .social-icon {
                line-height: 23px;

                &::before {
                    line-height: 23px;
                    vertical-align: middle !important; // sass-lint:disable-line no-important
                }

                &:hover {
                    &::before {
                        @include color-transition;
                        color: $jpp-orange;
                    }
                }
            }

            .icon-twitter {
                @include icon($icon-twitter, $jpp-blue, 11px);
            }

            .icon-facebook {
                @include icon($icon-facebook, $jpp-blue, 11px);
            }

            .icon-linkedin {
                @include icon($icon-linkedin, $jpp-blue, 11px);
            }

            .icon-instagram {
                @include icon($icon-instagram, $jpp-blue, 11px);
            }

            .icon-email {
                @include icon($icon-envelope, $jpp-blue, 11px);
            }
        }

        .font-13px {
            line-height: 16px;
        }

        .form {
            margin-top: 12px;
        }

        .hbspt-form.hbspt-form {
            fieldset .hs-richtext p span {
                font-size: 13px;
                line-height: 16px;

            }

            fieldset {

                .hs-email {
                    margin-bottom: 12px;
                        label{
                            margin: 0px 0 5px 10px;
                        }
                }

                .legal-consent-container {

                    margin: 0px;
                    padding: 0px;

                    .hs-richtext p {

                        font-size: 10px;
                        line-height: 12px;
                        margin: 0px;
                        padding: 0px;

                        a {
                            font-size: 10px;
                            line-height: 12px;
                            text-decoration: none !important;
                        }

                    }

                }
            }

            .hs_submit {
                .actions {

                    margin: 5px 0px 0px 0px;

                    input {

                        background-color: $jpp-orange !important;
                        border: 1px solid $jpp-orange !important;
                        text-shadow: none !important;
                        background-image: none !important;
                        box-shadow: none !important;

                        &:hover {
                            border-color: $jpp-orange-hover !important;
                            background: $jpp-orange-hover !important;
                        }
                    }
                }
            }
        }

        .fieldset {
            display: block;

            .mage-error {
                &[generated] {
                    font-size: 12px;
                    left: 0;
                    white-space: nowrap;
                }
            }
        }

        .newsletter {
            display: block;

            .control {
                &::before {
                    display: none;
                }
            }

            input {
                height: 36px;
                padding-left: 15px;
            }
        }

        .label {
            @include unset-hidden-props;
            text-align: left !important; // sass-lint:disable-line no-important

            > span {
                font-size: 14px;
                line-height: 20px;
            }

        }

        .small {
            margin-bottom: 5px;
        }

        .actions {
            display: block;
            width: 100%;
            padding: 0px;

            .small {
                font-style: italic;
                margin: 0px;

                a {
                    @include unset-underline;
                }
            }

            .subscribe {
                margin-left: 0;
                width: 100%;
            }
        }

        .hs-error-msgs {
            label {
                margin-left:0;

                a {
                    font-size:10px;
                    line-height:12px;
                }
            }
        }
    }
}
