// sass-lint:disable no-ids no-important no-vendor-prefixes no-css-comments
#maincontent {
    .paypal-review {
        .block-content {
            .box {
                a {
                    @include unset-underline;

                    span {
                        @include font-bold;
                        color: $jpp-blue;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .paypal-review-discount {
            @media (min-width: $bp-tablet) {
                @include clearfix;
                @include grid-collapse;
                border: 0;
                display: flex;
            }

            .fieldset {
                margin-bottom: $spacer / 2;
                padding-bottom: $spacer;
            }
        }

        .block {
            > .title {
                border-color: $jpp-grey;
                margin-bottom: 15px;
                padding: 20px 0 0;

                &::after {
                    display: none;
                }

                strong {
                    @include font-bold;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }

            > .content {
                display: block !important;
            }

            &.discount,
            &.giftcard {
                @media (min-width: $bp-tablet) {
                    @include grid-width-margin(6);
                    border: 1px solid $jpp-grey;
                    padding: 0 15px;
                }

                @media (min-width: $bp-laptop) {
                    @include grid-width-margin(4);
                }

                @media (min-width: $bp-desktop) {
                    @include grid-width-margin(3);
                }

                .title {
                    @media (min-width: $bp-tablet) {
                        border: 0;
                    }
                }

                .label {
                    margin-bottom: 0;
                }

                .control {
                    @media (min-width: $bp-tablet) {
                        width: 100%;
                    }
                }

                input {
                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: transparent;
                    }

                    &::-moz-placeholder { /* Firefox 19+ */
                        color: transparent;
                    }

                    &:-ms-input-placeholder { /* IE 10+ */
                        color: transparent;
                    }

                    &:-moz-placeholder { /* Firefox 18- */
                        color: transparent;
                    }
                }

                .field {
                    @media (min-width: $bp-tablet) {
                        margin-bottom: 20px;
                    }
                }

                .actions-toolbar {
                    @media (min-width: $bp-tablet) {
                        width: 100%;
                    }

                    > .primary,
                    > .secondary {
                        @media (min-width: $bp-tablet) {
                            width: 100%;
                        }
                    }
                }

                .action {
                    @media (min-width: $bp-tablet) {
                        float: none;
                        margin: 0 auto 10px;
                    }

                    &.primary {
                        @include button('border-blue');
                    }

                    &.check {
                        @include button('primary');
                    }
                }
            }
        }

        .paypal-review-items {
            .paypal-review-title {
                @media (min-width: $bp-tablet) {
                    border: 0;
                }

                strong {
                    @include font-bold;
                }

                .edit {
                    @include unset-underline;
                    float: right;
                    margin-top: 12px;

                    span {
                        @include font-bold;
                        color: $jpp-blue;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }

            .product-item-photo {
                @media (min-width: $bp-tablet) {
                    text-align: center;
                    width: 25%;
                }
            }

            .product-item-name {
                @include font-bold;
                text-transform: uppercase;
            }

            .opc-block-summary {
                margin-bottom: 0;

                .mark {
                    text-align: right !important;
                }

                .grand {
                    .price {
                        @include font-bold;
                        color: $jpp-dark-blue;
                    }
                }
            }
        }
    }
}
