.contact-index-index {
    .page-title-wrapper {
        .page-title {
            &,
            & span {
                font-size: 28px;
                line-height: 30px;
                margin: 24px 0;
            }
            & {
                display: block;
                width: 100%;
                border-bottom: 1px solid #C7D1DA;
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
        }
    }
    #maincontent {
        > .page-title-wrapper {
            display: none;
            visibility: hidden;
            position: fixed;
            left: -999999px;
            top: -999999px;
        }
    }
    .contact-us-form {
        max-width: 840px;
        margin-left: auto;
        .toll-free {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 900;
            a {
                font-weight: 900;
                text-decoration: none !important; // sass-lint:disable-line no-important
            }
        }
        .legal-consent-container {
            margin-bottom: 44px;
            padding-right: 24px;
            margin-top: 24px;
            font-size: 85%;
            font-style: italic;
        }
    }
    .column {
        &.main {
            width: 70%;
        }
    }
    .sidebar-main {
        width: 30%;
    }

    .contact-link-blocks {
        padding-top: 30px;
        h4 {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
        ul.links {
            padding: 0 0 0 30px;
            margin: 0;
            list-style: none;
            li {
                margin: 0 0 5px 0;
                padding: 0;
                font-size: 1.4rem;
                line-height: 2.3rem;
                a {
                    font-size: inherit;
                    line-height: inherit;
                    text-decoration: none !important; // sass-lint:disable-line no-important
                }
            }
        }
    }

}



