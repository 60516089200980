.table-wrapper.reviews { // sass-lint:disable-line force-element-nesting
    @include customer-container;

    tr {
        &:not(:first-child) {
            border-top: 1px solid $jpp-grey;
        }
    }

    .col {
        padding: 24px 0;

        &.review {
            
            .rating-result {
                > span:before {
                    color:$jpp-orange;
                }
            }
            .product-name {
                a {
                    @include h5-typography;
                    @include unset-underline;
                }
            }
        }

        &.actions {
            .action {
                @include dashboard-button;
            }
        }
    }

    .review-content {
        margin: 20px 0;
    }
}

.customer-review.view { // sass-lint:disable-line force-element-nesting
    @include customer-container;
    padding-bottom:88px;
    @media (min-width:$bp-tablet) {
        padding-bottom:88px;
    }
    + .actions-toolbar {
        transform:translateY(-78px);
        padding:0 24px;
        @media (max-width:$bp-tablet) {
            transform:translateY(-70px);
            padding-right:0 16px;
        }
    }
    .product-details {
        .product-media {
            widows: 100px;
            max-width:100px;

            .product-image-container {
                border:1px solid #dfdfdf;
                padding:4px;
            }
        }
    }
    .product-info {
        h2 {
            margin-bottom:8px;
            font-size:20px;
        }
        .product-reviews-summary {
            margin-top:0;

            .reviews-actions {
                a {
                    text-decoration:none!important;
                    margin-right:12px;
                }
            }
        }
    }
    .product-name {
        @include h3-typography;
        color: $jpp-dark-blue;
    }

    .rating-average-label,
    .rating-label span { // sass-lint:disable-line force-element-nesting
        @include h5-typography;
    }

    .rating-result {
        margin-left: 0;
        margin-top: -6px;

        > span:before {
            color:$jpp-orange;
        }
    }

    .review-details {
        .title {
            display: none;
        }

        .review-title {
            @include h4-typography;
            font-size:20px;
            margin-bottom: 0;
            @media (min-width:$bp-tablet) {
                font-size:20px;
            }
        }

        .review-content,
        .review-date {
            @include p-typography;
        }
    }

    + .actions-toolbar {
        margin-bottom: 20px !important; // sass-lint:disable-line no-important
    }
}
