.catalog-category-view.page-with-filter { // sass-lint:disable-line force-element-nesting
    .column.main { // sass-lint:disable-line force-element-nesting
        width: 75%;
        padding-left: 0;
        @media (min-width: $bp-desktop) {
            padding-left: 2%;
        }
    }

    .category-view {
        .category-description {
            margin-bottom:0;
        }
        .category-image {
            display: none;
        }
    }

    .columns {
        margin-top:8px;
        @media screen and (min-width: $bp-tablet) {
            margin-top:14px;
        }
    }
}
.catalog-category-view {
    div.breadcrumbs {
        margin-bottom:0!important;
    }
    .page-title-wrapper {
        position:relative;
        padding-top:16px;
        h1.page-title {
            z-index:2;
            position:relative;
            @media (max-width:$bp-mobile-l) {
                font-size:22px;
            }
        }

        &:after {
            z-index:1;
            position:absolute;
            content:'';
            pointer-events:none;
            top:0;
            width:100vw;
            left:50%;
            transform:translateX(-50%);
            height:100px;
            background:linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            @media (max-width:768px) {
                height:64px;
            }
        }
    }
    .breadcrumbs + .page-main {
        padding:0;

        & + .category-view {
            width:1500px;
            max-width:92%;
            padding-left:0;
            margin-left:auto;
            margin-right:auto;

            @media (max-width:$bp-tablet - 1) {
                max-width:calc(100% - 32px);
            }

            .category-image {
                display: none;
            }
        }
    }
}
.catalog-category-view .products-grid .product-item {
    cursor: pointer;

    .new-product-flag {
        font-size: 13px;
        font-weight: 600;
        line-height: 160%;
        position: absolute;
        z-index: 2;
        width: 46px;
        text-align: center;
        color: #fff;
        background: #f5821f;
    }
}

.toolbar-products {
    min-height: 50px;
    margin-bottom: 16px;
    border:1px solid #ededed;
    background:#fff;
    
    @media (max-width: $bp-tablet) {
        .toolbar-amount {
            display: inline-block;
            float: left;
            width: auto;
        }
        .toolbar-sorter {
            display: inline-block;
            float: right;
            transform:translateX(1px) translateY(-1.5px);
        }
    }
    @media (min-width: $bp-tablet) {
        height: 50px;
    }

    p, label, span {
        font-size: 14px !important;
    }

    select#sorter {
        height: 32px !important;
        padding: 0 7px !important;
        color: #222;
        background:#f5f5f5;
        border-color:#fff;
        option:first-of-type {
            color: #222;
        }
    }
}

.toolbar-amount {
    line-height: 50px;
    padding: 0;
}

.sorter,
.limiter {
    padding: 10px 0;

    label {
        @include p-typography;
    }
}

.sorter-options,
.limiter-options {
    height: 30px;

    @media screen and (max-width: 375px) {
        max-width: 200px;
    }
}

.sorter-action {
    vertical-align: middle;

    &:before { // sass-lint:disable-line pseudo-element
        line-height: 1;
        color: $jpp-off-black;
    }
}

.page-products,
.form-wishlist-items {
    .products-grid {
        margin-top: 0;
        .product-items {
            .ias-trigger {
                &:first-child {
                    position: fixed;
                    left: -999999px;
                    top: -9999999px;
                }
            }
        }

        .product-item {
            @include smooth;
            position: relative;
            width: calc((100% - 16px) / 2);
            min-height: 400px;
            margin: 0 16px 16px 0;
            padding: 8px 12px;
            border: 1px solid $jpp-light-grey;
            background:#fff;
            
            &:hover {
                border-color: $jpp-blue;
            }

            .rating-summary .rating-result > span:before {
                color: $jpp-orange;
            }

            @media (min-width: $bp-mobile) {
                min-height: 400px;
            }

            @media (max-width: 639px) {
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            @media (min-width: 640px) {
                width: calc(33.33% - 10.66px);

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }

            @media (min-width: $bp-tablet) {
                padding: 16px;
            }

            @media (min-width: $bp-laptop-xl) {
                width: calc(33.33% - 10.66px);
                min-height: 400px;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }

            @media (min-width: $bp-desktop) {
                padding: 16px 24px;
            }

            .product-item-info {
                width: 100%;

                .jpp-price-container {
                    .price-loader {
                        //Slimming down loader graphic to reduce height and get spinner placed near price
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 60px;

                        img {
                            position: absolute;
                            top: 50%;
                            min-height: 100px;
                            transform: translateX(-42%) translateY(-50%);
                        }
                    }
                }
            }

            .product-item-photo {
                display: block;
                text-align: center;
            }

            .product-item-name {
                a {
                    // sass-lint:disable-block no-vendor-prefixes
                    @include h5-typography;
                    @include unset-underline;
                    display: -webkit-box;
                    overflow: hidden;
                    margin: 0;
                    white-space: normal;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                }
            }

            .sku-container {
                font-size: 12px;
                line-height: 16px;
                overflow-y: auto;
                max-height: 34px;
                margin: 4px 0;
                color: #555; // sass-lint:disable-line no-color-literals

                // sass-lint:disable no-vendor-prefixes
                &::-webkit-scrollbar {
                    width: 7px;
                    margin-right: 2px;
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar-thumb {
                    opacity: .5;
                    border-radius: 0;
                    background-color: $jpp-blue;
                    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5); // sass-lint:disable-line no-color-literals
                }

                &::-webkit-scrollbar-track {
                    background: rgba(2, 2, 2, .05); // sass-lint:disable-line no-color-literals
                }

                // sass-lint:enable no-vendor-prefixes

                > span {
                    font-size: 12px;
                    line-height: 16px;
                }

                .sku {
                    color: $jpp-blue;
                }
            }

            .attribute-icons {
                float: none;
                margin-top: 6px;
                margin-bottom: 0;

                .attribute-icon {
                    width: 26px;
                    height: 26px;
                    margin-bottom:6px;
                }
            }

            .product-reviews-summary {
                margin: 0;

                .rating-summary,
                .reviews-actions {
                    @media (max-width: $bp-tablet - 1) {
                        display: block;
                    }
                }

                .rating-summary {
                    left: unset;
                }

                .reviews-actions {
                    a {
                        @include unset-underline;
                        font-size: 12px;
                        line-height: 28px;
                        margin-top: -6px;
                        color: $jpp-off-black;

                        span {
                            font-size: 12px;
                        }
                    }
                }
            }

            .price-box {
                position: absolute;
                bottom: 0;
                left: 12px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 16px;
                align-items: center;

                .minimal-price-link {
                    position: fixed;
                    top: -9999999px;
                    left: -9999999px;
                    display: none;
                }

                @media (min-width: $bp-tablet) {
                    left: 16px;
                }
                @media (min-width: $bp-desktop) {
                    left: 24px;
                }

                .price-label,
                .price-label::after {
                    display: none;
                }

                .special-price {
                    order: 2;
                    .price-container {
                        line-height: 2.8rem;
                    }
                }

                .old-price {
                    flex-basis: 100%;
                    order: 1;
                    text-decoration:none!important;
                    .price-wrapper {
                        position:relative;
                        span {
                            font-size:14px;
                            color:#a2a2a2;
                        }
                        &::before {
                            position:absolute;
                            content:'';
                            left:0;
                            right:0;
                            top:50%;
                            transform:translateY(-50%);
                            height:1px;
                            background:#636363;
                        }
                    }
                    &,
                    span,
                    .price {
                        font-weight: 500;
                        color: $jpp-grey-2;
                    }

                    .price {
                        font-size: 1.6rem;
                        line-height: 1.6rem;
                    }
                }

                .savings {
                    order: 3;
                    background:linear-gradient(120deg, #68BB5B 0%, #5BBB8B 100%);
                    border-radius:4px;
                    color: white;
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 2.6rem;
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-left: 10px;
                    transform:translateY(-2px);
                    em {
                        font-style: normal;
                    }
                }


                .price {
                    @include h4-typography;
                    color: $jpp-blue;
                }
            }
        }
    }
}

.ias-spinner {
    img {
        width: unset;
    }
}

.ias-trigger {
    margin-top: 16px;

    a {
        @include button('primary');
        @include unset-underline;
    }
}
