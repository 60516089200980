.company-account-create {
    .page-title-wrapper {
        @include full-width;
        background: $jpp-blue;
        box-sizing: border-box;
        padding: 24px 15px 32px;

        + .page.messages {
            transform:translateY(-26px);
            position:relative;
            z-index:1;
            .message {
                margin:0 0 18px;
            }
        }

        @media (min-width: $bp-tablet) {
            padding-bottom: 70px;
            padding-top: 38px;
            text-align: center;
        }

        .page-title {
            text-transform: unset;

            span {
                @include h3-typography;
                color: $white;
                margin: 0;
                text-transform: unset;
            }
        }

        .page-subtitle {
            @include p-typography;
            color: $white;
            margin-bottom: 0;
        }
    }
}
.company-account-request {
    form {
        margin-bottom:0;
    }
}
.company-account-creation {
    background: $white;
    position: relative;
    z-index: auto;

    @media (min-width: $bp-tablet) {
        box-shadow: 0 0 15px 0 $box-shadow-light;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: auto;
        margin-top: -30px;
        max-width: 575px;
        padding: 45px 60px;
    }

    .form.create.account {
        min-width: unset;
        width: unset;

        .actions-toolbar {
            margin-left: 0;
        }

        fieldset {
            margin-bottom: 20px;

            legend {
                @include h5-typography;
                flex-basis: 100%;
                margin: 0 0 20px;

                span {
                    font-weight: 800;
                }
            }
        }

        .fieldset-inner {
            @media (min-width: $bp-tablet) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            & > .field {
                flex-basis: 100%;
                margin-bottom: 10px;

                &:not(.choice) {
                    & > .control {
                        flex-basis: 100%;
                    }
                }

                &.city,
                &.country_id,
                &.region-id,
                &.postcode,
                &.field-name-firstname,
                &.field-name-lastname,
                &.vat-tax-id,
                &.reseller-id {

                    @media (min-width: $bp-tablet) {
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        flex: 0 0 calc(50% - 5px);
                    }
                }
            }
        }
    }
}

.company-index-index {
    .manage-company-accounts {
        @include customer-container;
        > .block {
            padding:0;
            box-shadow:0px 0px 0px transparent;
            .block-title {
                margin-bottom:0!important;
            }
            .block-content {
                .box-actions {
                    padding-bottom:8px;
                    .action {
                        font-size:14px;
                        span {
                            font-size:14px;
                        }
                    }
                }
            }
        }
        .tree {
            > .jstree-container-ul {
                > .jstree-node {
                    &.jstree-last {
                        > ul > li.jstree-last:nth-child(1) {
                            &:after {
                                height:3.2rem;
                            }
                        }
                    }
                    > ul > li:nth-of-type(1) {
                        padding-top:15px;
                    }
                    > ul.jstree-children {
                        transform:translateY(-10px) translateX(-5px);
                    }
                }
            }
        }
        .jstree-anchor {
            padding:0 10px 0 4px;
            display:inline-block;
            line-height:32px;
            min-height:32px;
            font-size:14px;
            text-decoration:none!important;
            &:not(.jstree-clicked):hover {
                background:#f9f9f9;
            }
            &.company-admin {
                padding:0 10px 0 0;
                min-height:40px;
                line-height:40px;
                >.jstree-icon:before {
                    font-size:24px;
                }
            }
            .action-help {
                transform:scale(0.8) translateX(-6px) translateY(-5px);
            }
            .field-tooltip-content {
                padding:10px;
                line-height:18px;
                transform:translateX(-8px);
                &:before, &:after {
                    transform:scale(0.5) translateX(10px);
                }
            }
        }
        .jstree-node {
            .jstree-icon.jstree-ocl {
                &::before {
                    font-size:20px;
                    transform:scale(0.9);
                }
                &::after {
                    width:1.1rem;
                }
            }
        }
        .message.info.empty {
            margin-top: 0;
        }
    }

    .block-dashboard-company {
        @include customer-container;

        .block-title {
            @include customer-container-title;
        }

        .box-actions {
            @include flex-container;
            border-bottom: 1px solid $jpp-swatch-grey;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-bottom: 20px;
            @media (max-width: #{$bp-tablet - 1}) {
                flex-wrap: wrap;
            }

            .action {
                color: $jpp-dark-blue;
                font-size: 1.6rem;
                font-weight: 800;
                padding: 0;
                text-transform: uppercase;
                @media (max-width: #{$bp-tablet - 1}) {
                    flex-basis: 50%;
                    text-align: right;

                    &:nth-child(2n + 1) {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.company-profile-index {
    .block-company-profile-payment-methods,
    .block-company-profile-contacts,
    .block-company-profile-address,
    .block-company-profile-account {
        @include customer-container;
        font-size: 1.6rem;
    }
}

.company-role-edit,
.company-profile-edit {
    .form-edit-role,
    .form-edit-company {
        @include flex-container;
        align-items: stretch;
        flex-wrap: wrap;

        > .fieldset {
            @include customer-container;
            @include flex-item;
            flex-basis: 100%;

            .legend {
                @include customer-container-title;
            }

            @media (min-width: $bp-tablet) {
                flex-basis: calc(50% - 6px) !important;
                flex-grow: 0;
                width: unset !important;
            }
        }

        > .actions-toolbar {
            flex-basis: 100%;
            margin: $account-spacer 0 !important;
        }
    }
}

.company-role-index,
.company-users-index {
    .admin__data-grid-outer-wrap {
        @include customer-container;

        table {
            @include account-table;

            td,
            th {
                &:last-child {
                    text-align: right;
                }
            }
        }

        & + .actions {
            @include customer-container;
            @include toolbar-account-container;
        }

        .data-grid-filters-wrap {
            @media (max-width: #{$bp-tablet - 1}) {
                margin-top: 0;

                .action {
                    margin: 0;
                }
            }

            button {
                span {
                    font-size: inherit;
                }

                @media (max-width: #{$bp-tablet - 1}) {
                    padding: 0 10px;
                }
                @media (min-width: $bp-tablet) {
                    @include button('secondary');
                }
            }
        }
    }
}
