div.home-why-choose-jpp {
    padding:78px 16px 90px!important;
    background:#f8f8f8;
    @media (max-width:768px) {
        padding:48px 16px!important;
    }
    h2 {
        font-size:36px;
        text-align:center;
        text-transform:none;
        margin-top:0;
        @media (max-width:480px) {
            width:230px;
            margin-left:auto;
            margin-right:auto;
        }
    }

    p, div[data-content-type="text"] {
        width:800px;
        max-width:100%;
        margin:0 auto!important;
        font-size:14px;
        text-align:center;
        margin-bottom:40px!important;
    }
}