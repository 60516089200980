.catalog-category-view {
    .events-list {
        .product-item {
            border-top: 1px solid $jpp-grey;
            margin-top: $spacer * 2;
            padding-top: $spacer * 2;

            &:first-child {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }
        }

        .product-item-info {
            @include clearfix;
            @include grid-collapse;
            display: block;
            width: 100%;
        }

        .event-date-container {
            @include grid-width(4.5);
            display: flex;
            flex-direction: column;

            @media (min-width: 600px) {
                flex-direction: row;
            }

            @media (min-width: $bp-tablet) {
                @include grid-width(5);
            }

            @media (min-width: $bp-laptop) {
                @include grid-width(4);
            }

            @media (min-width: $bp-desktop-s) {
                @include grid-width(3);
            }

            .start-date,
            .end-date {
                > span {
                    border: 4px solid $jpp-grey;
                    border-radius: 6px;
                    display: inline-block;
                    padding: 2px 10px;

                    span {
                        margin: 0;

                        &:first-child {
                            font-size: 12px;
                            text-transform: uppercase;
                        }

                        &:last-child {
                            @include font-bold;
                            font-size: 32px;
                        }
                    }
                }
            }

            .through {
                @include font-bold;
                display: block;
                font-size: 14px;
                margin: 10px 0;
                text-transform: uppercase;

                @media (min-width: 600px) {
                    font-size: 12px;
                    margin: 0;
                    padding: 0 5px;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }

                @media (min-width: $bp-tablet) {
                    font-size: 14px;
                }
            }
        }

        .product-item-details {
            @include grid-width(7.5);
            display: block;

            @media (min-width: $bp-tablet) {
                @include grid-width(7);
            }

            @media (min-width: $bp-laptop) {
                @include grid-width(8);
            }

            @media (min-width: $bp-desktop-s) {
                @include grid-width(9);
            }

            .product-item-link {
                @include font-bold;
                @include unset-underline;
                font-size: 24px;
                text-transform: uppercase;
            }

            .sku-container {
                display: none;
            }

            .price {
                @include font-bold;
                color: $jpp-dark-blue;
                font-size: 16px;
            }

            .price-label {
                font-size: 16px;
            }

            .product-item-inner {

                .product-item-description {
                    float: none !important; // sass-lint:disable-line no-important
                    margin-bottom: 0;
                    padding-bottom: 60px;
                    position: relative;
                    width: 100% !important; // sass-lint:disable-line no-important
                }

                .primary {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                }
            }
        }
    }
}
