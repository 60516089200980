body {
    overflow-x: hidden;
}

.page-wrapper {
    overflow-x:hidden;
}

div {
    &[data-appearance='contained'],
    &[data-content-type='row'][data-appearance='contained'] {
        max-width: 92%;
        width: $content-max;
        margin-left:auto;
        margin-right:auto;
        @media (max-width: $bp-tablet) {
            max-width:100%;
        }
    }
    &[data-content-type='row'][data-appearance='full-width'] {
        width:100%;
        max-width:100%;
        > .row-full-width-inner {
            width:1500px;
            margin-left:auto;
            margin-right:auto;
            max-width:92%;
            @media (max-width: $bp-tablet) {
                max-width:100%;
            }
        }
    }
    &[data-element='inner'] {
        padding:0;
    }
}



.navigation,
.breadcrumbs .items,
.page-header .header.panel,
.header.content,
.footer.content,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container,
.vcp-banner-container.content-container,
.jpp-slider-wrapper .content-wrapper,
.page-main {
    @media (max-width: ($bp-tablet - 1)) {
        margin-left: auto;
        margin-right: auto;
        max-width: calc(100% - 32px);
        width: $content-max;
    }
    @media (min-width: $bp-tablet) {
        margin-left: auto;
        margin-right: auto;
        max-width: 92%;
        width: $content-max;
    }
    @media (min-width: 1620px) {
        max-width: 100%;
        width: $content-max;
    }
}
body.page-layout-cms-full-width, body.page-layout-empty {
    .page-main, .page-main-full-width {
        max-width:100%!important;
        width:100%!important;
        div[data-appearance='full-bleed'] {
            width:100%!important;
            max-width:100%!important;
        }
    }
}


#maincontent {
    padding-left: 0;
    padding-right: 0;
    min-height:calc(100vh - 199px);
    z-index:100;
    position:relative;
    transition:z-index 1s step-end;




    //&::before {
    //    content: '';
    //    position: absolute;
    //    z-index: 4000;
    //    top: 0;
    //    right: 0;
    //    bottom: 0;
    //    left: 0;
    //    transition: 0.4s ease-in-out;
    //    opacity: 0;
    //    background: rgba(0, 0, 0, 0.12);
    //    backdrop-filter: blur(4px);
    //    transition-delay: 0s
    //}
    //&.blur::before {
    //    transition: 0.8s ease-in-out;
    //    opacity: 1;
    //    transition-delay: 0.18s
    //}
    //&:not(.blur)::before {
    //    pointer-events: none
    //}

}
body._has-modal {
    #maincontent {
        z-index:5000;
        .modals-wrapper {
            z-index:12000;
        }
    }
    .modals-wrapper {
        position:fixed;
        z-index:10000;

        .modal-popup {
            pointer-events:auto;
            z-index:15000;
        }
    }
}
#sns_main {
    padding-bottom:80px;

    @media (min-width:1400px) {
        padding-bottom:140px;
    }
    @media (max-width:480px) {
        padding-bottom:48px;
    }
}

* {
    box-sizing: border-box;
}

//autofill detection hack
@keyframes onAutoFillStart {  from {/**/}  to {/**/}}
@keyframes onAutoFillCancel {  from {/**/}  to {/**/}}
input:-webkit-autofill, select:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
}
input:not(:-webkit-autofill), select:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
}
.jpp-loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.6);
    //background: radial-gradient(circle, rgba(255,255,255,0.99) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.5) 100%);
    cursor: wait;

    &::before {
        content:'';
        position: relative;
        display:inline-block;
        width:80px;
        height:80px;
        background-image: url('../images/loader-blue.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    span {
        @include small-typography;
        padding: 10px;
        background-color: #cce7f5;
        border-color: #004f9d;
        color: #004f9d;
        border-radius: 25px;
        font-size: 11px;
    }

    /*
    &::after {
        content:'Loading, please wait...';
        position: relative;
        display:inline-block;
        @include small-typography;
    }
    */

}
.jpp-component-input, .jpp-component-select {
    position:relative;
    &.empty {
        label {
            color:#b3b3b3;
            top:32px;
        }
    }
    label {
        opacity:1;
        font-size:14px;
        transition:0.24s ease;
        pointer-events:none;
        position:relative;
        font-weight:600;
        transition:0.24s ease;
        padding-left:12px;
        span.required {
            color:#0088ce;
            font-size:inherit!important;
            margin-left:2px;
        }
    }
    input, select {
        border:1.5px solid transparent;
        transition:0.24s ease;
        margin-top:0;
    }
    &.focus, &.valid, &.error:not(.empty) {
        label {
            top:2px;
            font-size:12px;
            color:#222;
        }
    }
    &.focus {
        input, select {
            border:1.5px solid #0088ce;
        }
    }
    &.valid {
        input, select {
            border:1.5px solid #68bb5b;
        }
    }
    &.error {
        input, select {
            border:1.5px solid #b81f27;
        }
    }
    &.first-name {
        margin-right:12px;
    }
    &.first-name, &.last-name {
        width:calc(50% - 6px);
        float:left;
        display:inline-block;
        position:relative;
        z-index:2;
    }

    .mage-error {
        position:absolute;
        font-size:11px;
        transform:translateY(2px);
        color:#b81f27;
        right:0;
    }

    .field-tooltip {
        display: inline-block !important;
        margin-top: 30px;
        margin-right: 1px;
    }
}
.jpp-component-select {
    position:relative;
    label {
        z-index: 5;
    }
    select {
        color:#222;
        padding-left:7px;
    }
    &.empty:not(.focus) select {
        color: transparent;
    }
    &:before {
        position:absolute;
        content:'';
        bottom:1.5px;
        left:1.5px;
        right:20px;
        background:#ededed;
        pointer-events:none;
        transition:0.18s ease;
        height:37px;
    }
    &:hover {
        &:before {
            background:#f5f5f5;
        }
    }
    &.focus, &.valid, &.error {
        &:before {
            opacity:0;
        }
    }
    &.error.empty {
        label {
            top:2px;
            font-size:12px;
            color:#222;
        }
    }
    option {
        color:#222!important;
    }
}
.jpp-component-address-display {
    span {
        //white-space: nowrap;
    }
}
.jpp-readmore-component {
    .wrapper {
        position:relative;
        overflow-y: hidden;
        transition:height 0.4s ease;
    }
    &.controls:not(.expanded){
        .wrapper::after {
            content:'';
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            height:26px;
            background:linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.56) 40%, rgba(255,255,255,0) 90%, rgba(255,255,255,0) 100%);
            pointer-events:none;
            opacity:1;
            transition:0.9s ease;
            z-index:100;
        }
    }
    .toggle {
        //position:absolute;
        //bottom: 0;
        //z-index: 200;
        font-weight:800;
        background:#fff;
        border:0px solid transparent;
        border-radius:0px;
        width:400px;
        max-width:calc(100% - 32px);
        //height:0;
        line-height:40px;
        text-align:center;
        display:block;
        font-size:14px;
        user-select:none;
        transition:0.18s ease;
        box-shadow:0px 0px 2px rgba(2,2,2,0.28);
        padding:0;
        opacity:1;
        pointer-events:auto;
        margin:20px auto 4px;
        height:40px;

        //&.active {
        //    opacity:1;
        //    pointer-events:auto;
        //    margin:20px auto 0;
        //    height:40px;
        //}
        @media (max-width:1440px) {
            transform:scale(0.88);
        }
        &:hover {
            background:#0088ce;
            color:#fff;
            box-shadow:0px 0px 2px rgba(2,2,2,0.28);
            filter:brightness(1.1);
            span {
                color:#fff;
            }
        }
        span {
            font-size:14px;
            font-weight:800;
            user-select:none;
            transition:0.18s ease;
            color:#0088ce;
        }
    }
}

.field-tooltip .field-tooltip-content._active {
    display:block;
}
@keyframes shake {
    0%, 100% {transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
    20%, 40%, 60%, 80% {transform: translateX(10px);}
}
.shake {
    animation-name: shake;
    animation-duration: 1s;
    animation-fill-mode: both;
}
