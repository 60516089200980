.breadcrumbs {
    background-color: $breadcrumbs-background;
    box-sizing: border-box;
    height: 48px;
    max-width: 100%;
    padding: 0;

    .items {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;

        @media (min-width: 1620px) {
            max-width: 100%;
            width: $content-max;
        }

        .breadcrumbs-item {
            font-family: $proxima-nova;
            font-size: 12px;
            font-weight: 500;
            line-height: 48px;
            text-transform: uppercase;

            @media (max-width: $bp-tablet - 1) {
                display: none;
            }

            a {
                color: #6d6d6d;
                font-size: 12px;
                text-decoration: none !important; // sass-lint:disable-line no-important
            }

            strong {
                color: $jpp-blue;
            }

            &:not(:last-child) {
                display: inline-block;
                text-decoration: none;

                &::after {
                    color: $jpp-off-black;
                    content: '/';
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 12px;
                    margin-bottom: 2px;
                    padding: 0 5px;
                    vertical-align: middle;
                }
            }
        }

        @media (max-width: $bp-tablet - 1) {
            padding: 0 15px;

            & > div {
                &:last-of-type {
                    display: block;

                    a {
                        color: $jpp-blue;
                    }

                    &::before {
                        color: $jpp-blue;
                        content: '<';
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 13px;
                        padding-right: 3px;
                        vertical-align: middle;
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: $bp-tablet) {
            overflow-x: auto;
            white-space: nowrap;
        }
    }
}
