.cms-workshops {
    min-width: 100vw;

    div.breadcrumbs {
        margin-bottom: 0;
    }

    .page-wrapper {
        background: #f9f9f9;
    }

    .page-main {
        background: #fff;
        @media (max-width: $bp-tablet) {
            max-width: 100% !important;
        }
    }

    h1 {
        @media (max-width: $bp-mobile-l) {
            font-size: 24px !important;
            padding: 80px 0 20px 20px !important;
        }
    }
    .workshop-details-modal {
        .modal-title {
            display: none;
        }

        .modal-inner-wrap {
            width: 800px;
            max-width: calc(100% - 32px);
            height: auto;
            @media (max-width: ($bp-laptop - 1)) {
                margin-top: 50%;
                transform: translateY(-50%) !important; // sass-lint:disable-line no-important
                backface-visibility: hidden;
            }

            .content {
                display: flex;

                > img {
                    float: left;
                    align-self: flex-start;
                    max-width: 250px;
                    margin: 0 20px 20px 0;
                }

                @media (max-width: $bp-tablet) {
                    flex-direction: column;
                    img {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        .modal-footer {
            border-color: $jpp-grey;
        }
    }

    .main {
        .row {
            margin-right: auto;
            margin-left: auto;
        }

        .workshop-banner {
            position: relative;
            z-index: auto;
            cursor: pointer;

            &:hover {
                .workshop-banner-inner {
                    opacity: 1;
                    background: $jpp-blue;
                    background: #00669a;
                    backface-visibility: hidden;

                    .center-center {
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        background: $jpp-blue;
                        box-shadow: 0px 0px 4px $box-shadow-light;

                        h2 {
                            opacity: 0;
                        }

                        p {
                            opacity: 1;
                        }
                    }
                }
            }

            .workshop-banner-inner {
                @include ease;
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                backface-visibility: hidden;
            }

            .center-center {
                font-weight: 600;
                position: absolute;
                z-index: 1;
                top: 10px;
                right: 10px;
                bottom: 10px;
                left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                border: 1px solid transparent;
                box-shadow: 0px 0px 4px transparent;
                backface-visibility: hidden;
                @include ease;

                > * {
                    z-index: 2;
                    margin: 0;
                    color: $white;
                }

                h2 {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.4;
                    width: 151px;
                    margin-left: 16px;
                    text-align: left;
                    opacity: 1;
                    @include ease;

                    @media (min-width: $bp-tablet) {
                        font-size: 18px;
                        width: 240px;
                    }
                }

                h3 {
                    font-size: 1.8rem;
                }

                p {
                    font-size: 1.4rem;
                    font-weight: 300;
                    position: absolute;
                    z-index: auto;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    text-transform: uppercase;
                    @include ease;
                    opacity: 0;
                    backface-visibility: hidden;
                }
            }

            .banner-image {
                display: inline-block;
                width: 100%;
                height: auto;
                vertical-align: top;
                opacity: 1;
                @include ease;
                min-height: 90px;
            }
        }

        .workshop-info {
            @media (max-width: $bp-mobile-l) {
                p, address, time {
                    font-size: 14px !important;
                }
            }

            time {
                strong + br {
                    //display:none;
                }
            }
        }

        div[data-content-type='row'] {
            div.webinars-container {
                display: flex;
                flex-direction: row !important;
                flex-wrap: wrap;
                padding: 0 16px !important;
                @media (min-width: $bp-tablet) {
                    padding: 0 24px !important;
                }
                .pagebuilder-column-group {
                    //flex-basis: 100%;
                }

                div.pagebuilder-gauge-banner,
                div.webinar,
                div[data-content-type='text'],
                div[data-content-type='html'],
                div.pagebuilder-column {
                    display: inline-block;
                    float: left;
                    width: 100%;
                    margin-right: 0 !important;
                    margin-bottom: 16px !important;

                    div[data-element='content'] {
                        overflow: hidden !important;
                    }

                    &:nth-of-type(2n) {
                        margin-right: 0 !important;
                    }

                    @media (min-width: $bp-tablet) {
                        width: calc(50% - 8px);
                        margin-right: 16px !important;
                        &:nth-of-type(2n) {
                            margin-right: 0 !important;
                        }
                    }
                    @media (min-width: $bp-laptop) {
                        width: calc(33.333% - 10.666px);
                        &:nth-of-type(2n) {
                            margin-right: 16px !important;
                        }
                        &:nth-of-type(3n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }

        //Hiding Modal Content That Shows Too Early
        article.workshop + div {
            height: 0px;
            pointer-events: none;
            opacity: 0;
        }

        .event-intro {
            padding-right: 0;
            padding-left: 0;

            img {
                width: 100%;
            }
        }

        .overlay-target {
            position: relative;
        }

        .overlay-content {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;

            * {
                font-size: 18px;
                line-height: 24px;
                margin: 0;
                color: $white;
            }
        }

        .valign-middle {
            display: table;
            width: 100%;
            height: 100%;

            > * {
                display: table-cell;
                vertical-align: middle;
            }
        }

        .modal {
            position: fixed;
            z-index: 1050;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: none;
            overflow: auto;
            outline: 0;
        }

        .event-desc {
            .col-xs-12 {
                padding-right: 0;
                padding-left: 0;
            }

            img {
                margin-top: 20px;
            }
        }

        .previous-webinars-container {
            padding: 0 16px !important;
            display: flex;
            flex-wrap:wrap;
            width:100%!important;
            margin-left:0;
            margin-right:0;

            .webinar {
                align-self: stretch;
                justify-self: center;
                max-width:100%!important;
                width:100%;
                margin-bottom:16px;
                @media (min-width: $bp-tablet) {
                    width: calc(50% - 8px);
                    margin-right:16px!important;
                    &:nth-of-type(2n) {
                        margin-right:0!important;
                    }
                }
                @media (min-width: $bp-laptop) {
                    width: calc(33.333% - 10.666px);
                    &:nth-of-type(2n) {
                        margin-right:16px!important;
                    }
                    &:nth-of-type(3n) {
                        margin-right:0!important;
                    }
                }
            }

            @media (min-width: $bp-tablet) {
                padding: 0 24px!important;
            }

            > div[data-content-type="html"] {
                display: flex;
                flex-direction: row!important;
                flex-wrap: wrap;
            }

            h3 {
                font-size: 14px;
                font-weight: 700;
                margin-top: 12px;
                padding:0 16px;
                @media (min-width: $bp-tablet) {
                    font-size: 18px;
                }
            }
        }
    }
}

.video-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    padding-bottom: 56.25%;

    iframe, video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
